import Container from '../../components/UI/Container/Container';
import { Carousel, Col, Row } from "antd";
import SapLandingWrapper from "./SapLanding.style";
import { Link } from 'react-router-dom';


export default function OurProperties() {
    return (
        <SapLandingWrapper>

            <div className="bg-black">
                <Container className="container">
                    <Row className="main pt-3 pb-3">
                        <Col lg={24}>
                            <h1 className="fw-bolder txt text-white font-46 text-center mb-0"> Our Properties</h1>

                        </Col>

                    </Row>
                    <Row className='pb-6'>
                        <Col lg={8}>
                            <div className='properties-card'>
                                <Link to={'/marketing-details'}>
                                    <img src="/images/properties-1.webp" alt="check-mark" className="properties-images" />
                                </Link>
                                <div className='px-3'>
                                    <h4 className='properties-heading mb-2'>
                                        Ellington House 4
                                    </h4>
                                    <p className='properties-txt'>DUBAI HILLS ESTATE,</p>
                                </div>

                                <div>
                                    <p className='btn-properties border-bottom mb-1'>Prices Starting From AED</p>
                                </div>
                                <div className='px-3'>
                                    <div className='border-bottom d-flex justify-content-between mb-1'>
                                        <p className='text-gray font-600 '>Bedrooms</p>
                                        <p>4,5 and 6 Bedroom Options</p>
                                    </div>
                                    <div className='border-bottom d-flex justify-content-between mb-1'>
                                        <p className='text-gray font-600 '>Community</p>
                                        <p>Dubai Hills Estate</p>
                                    </div>
                                    <div className='border-bottom d-flex justify-content-between mb-1'>
                                        <p className='text-gray font-600 '>Type</p>
                                        <p>Apartment</p>
                                    </div>
                                </div>

                            </div>
                        </Col>
                        <Col lg={8}>
                            <div className='properties-card'>
                                <Link to={'/marketing-details'}>
                                    <img src="/images/properties-2.webp" alt="check-mark" className="properties-images" />
                                </Link>
                                <div className='px-3'>
                                    <h4 className='properties-heading mb-2'>
                                        The Sanctuary By Ellington
                                    </h4>
                                    <p className='properties-txt'>MOHAMMED BIN RASHID CITY,</p>
                                </div>
                                <div>
                                    <p className='btn-properties border-bottom mb-1'>AED 1,787,828</p>
                                </div>
                                <div className='px-3'>
                                    <div className='border-bottom d-flex justify-content-between mb-1'>
                                        <p className='text-gray font-600 '>Bedrooms</p>
                                        <p>4,5 and 6 Bedroom Options</p>
                                    </div>
                                    <div className='border-bottom d-flex justify-content-between mb-1'>
                                        <p className='text-gray font-600 '>Community</p>
                                        <p>Mohammed Bin Rashid City</p>
                                    </div>
                                    <div className='border-bottom d-flex justify-content-between mb-1'>
                                        <p className='text-gray font-600 '>Type</p>
                                        <p>Villa</p>
                                    </div>
                                </div>

                            </div>
                        </Col>
                        <Col lg={8}>
                            <div className='properties-card'>
                                <Link to={'/marketing-details'}>
                                    <img src="/images/properties-3.webp" alt="check-mark" className="properties-images" />
                                </Link>
                                <div className='px-3'>
                                    <h4 className='properties-heading mb-2'>
                                        Belgravia Heights 2
                                    </h4>
                                    <p className='properties-txt'>JUMEIRAH VILLAGE CIRCLE (JVC),</p>
                                </div>
                                <div>
                                    <p className='btn-properties border-bottom mb-1'>Prices Starting From AED 480,828</p>
                                </div>
                                <div className='px-3'>
                                    <div className='border-bottom d-flex justify-content-between mb-1'>
                                        <p className='text-gray font-600 '>Bedrooms</p>
                                        <p>4,5 and 6 Bedroom Options</p>
                                    </div>
                                    <div className='border-bottom d-flex justify-content-between mb-1'>
                                        <p className='text-gray font-600 '>Community</p>
                                        <p>Jumeirah Village Circle (JVC)</p>
                                    </div>
                                    <div className='border-bottom d-flex justify-content-between mb-1'>
                                        <p className='text-gray font-600 '>Type</p>
                                        <p>Villa</p>
                                    </div>
                                </div>
                                
                            </div>
                        </Col>
                    </Row>

                </Container>
            </div>
        </SapLandingWrapper>
    )
}