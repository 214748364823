import React, { useContext, useState } from 'react';
import { Modal, Button, Alert } from 'antd';
import Heading from '../../../components/UI/Heading/Heading';
import { MainContext } from 'constants/MainProvider';
import { resendVerificationEmail } from 'library/helpers/axiosClient'
import Loader from 'components/Loader/Loader';
import { useNavigate } from "react-router-dom";
const EmailConfirmationModal = (props) => {
    let navigate = useNavigate();

    const { signUpMail,setMailResent} = useContext(MainContext);
    const [description, setDescription] = useState("");
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const resendMail = () => {
        setLoading(true);
        resendVerificationEmail(signUpMail).then((data) => {
            if (data?.code === 200 && data?.errorCode === 0) {
                setDescription(data?.description);
                setShow(true);
                setLoading(false);
                setMailResent(false)
                setTimeout(() =>   {
                    props.onCancel()
                    window.location.reload();
                },2000)
            } else {
                setLoading(false);
                setDescription(data?.errorDescription);
                setShow(true);
            }
        });
    }
    return (
        <div>
            <Modal
                className="success-popup-modal"
                centered
                width={500}
                visible={props.modalStatus}
                footer={null}
                onCancel={props.onCancel}>
                <div className="text-center">
                    <img src="/images/tick-circle.png" alt="check-mark" className="mb-15" />
                    <Heading as="h5" fontWeight="600" className="text-primary font-24" content="Verify Email Address" />
                    <p className="font-12 success-text mb-30px lh-20">
                        Thank you for using RealCube portal. we now need to verify your email address. If you did not receive the email,
                        check your junk folder or have us resend it by clicking below.
                    </p>
                    <Button
                        onClick={resendMail}
                        className="resend-btn"
                        type="primary"
                        htmlType="submit"
                        size="large">
                        Resend Mail
                    </Button>
                    {show &&
                        <Alert message={description} type="success" />
                    }
                    {loading && <Loader />}
                </div>
            </Modal>
        </div>
    );
};

export default EmailConfirmationModal;
