import React from 'react';
import { Modal, Row, Col } from 'antd';
// import { LOGIN_PAGE } from '../../../setup/constant';
import SignUpForm from './SignUpForm';
import SocialLogin from '../SocialLogin';
import Wrapper from '../Auth.style';
import { useTranslation } from 'react-i18next'
import cookies from 'js-cookie'
import {Link} from 'react-router-dom'

const languages = [
  {
    code: 'en',
    name: 'English',
    country_code: 'gb',
  },
  {
    code: 'ar',
    name: 'العربية',
    dir: 'rtl',
    country_code: 'sa',
  },
];

const SignUp = (props) => {

  const currentLanguageCode = cookies.get('i18next') || 'en';
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();

  return (
    <Wrapper>
      <div>
        <Modal
          className="login-popup"
          width={1000}
          centered
          visible={props.modalStatus}
          footer={null}
          onCancel={props.onCancel}
        >
          <Row gutter={1}>
            <Col className="gutter-row" span={12}>
                <img
                    src="/images/register-bg.png"
                    width="664"
                    height="844"
                    loading="lazy"
                    alt="Login Image"
                    className="login-image"
                  />
                   <div className='secure-position'>
                    <div className="secure-img">  <img src="/images/flash-circle.png" /> {t('Secured system')} </div>
                    <div className='makeyr-property'>
                        <img src="/images/register-search.png" /> <br />
                        <p>{t('Sign up to makeyour property searching process easy in few clicks')}</p>
                    </div>
                  </div>
            </Col>
            <Col className="gutter-row" span={12}>
              <div className='p-40-30'>
                <div class="text-center mb-20">
                  <img
                    src="/images/realcube-logo.svg"
                    width="150"
                    height="27"
                    loading="lazy"
                    alt="RealCube Logo"
                  />

                </div>
                <div className="title-modal">
                  <h2> {t('sign_up')} </h2>
                  <div className="signup-uaepass">
                      <Link to="" className='signin-pass'><img src="/images/uae-pass-icon.png" alt="UAE passs"/>{t('Sign in with UAE pass')} </Link>
                  </div>
                  <div className="or-condition">{t('Or')}</div>
                </div>
                {/* <SocialLogin />
                <div className="or-text">
                  <span> {t('or')}  </span>
                </div> */}
                <SignUpForm onEmailConfirmationModalOpen={props.onEmailConfirmationModalOpen} onLoginModalOpen={props.onLoginModalOpen} onOTPModalOpen={props.onOTPModalOpen} />
              </div>
            </Col>
          </Row>
        </Modal>
      </div>
    </Wrapper>
  );
};

export default SignUp;
