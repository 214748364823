import axios from "axios";
import FormData from "form-data"
import { APIs } from "../../library/helpers/apis";

export const axiosClient = axios.create({
	baseURL: APIs.URL,
	headers: "",
});
export const getPropertyFilterList = async () => {
	try {
		const response = await axiosClient.post(`${APIs.propertyFilter.endPoint}`, {
			location: "0-1",
		});
		return response.data;
	} catch (err) {
		console.log("err -->", err);
		return err.response.status;
	}
};

export const getPropertyFilterListAll = async () => {
	try {
		const response = await axiosClient.post(`${APIs.propertyFilter.endPoint}`);
		return response.data.data;
	} catch (err) {
		console.log("err -->", err);
		return err.response.status;
	}
};

export const getPropertyFilterSearchList = async (payload, token) => {
	try {
		const response = await axiosClient.post(
			`${APIs.propertyFilter.endPoint}`,
			payload?.data,
			{
				headers: token.headers
			}
		);
		return response;
	} catch (err) {
		return err.response;
	}
};
export const getPropertyFilterSearchListNerBy = async (payload) => {
	try {
		const response = await axiosClient.post(
			`${APIs.propertyFilter.endPoint}`,
			payload?.data
		);
		return response;
	} catch (err) {
		return err.response;
	}
};
export const getPropertyFilterListById = async (id) => {
	try {
		const response = await axiosClient.get(
			`${APIs.propertyDetails.endPoint}/${id}`
		);
		return response;
	} catch (err) {
		console.log("err -->", err);
		return err.response;
	}
};

export const getVisitScheduleList = async (payload) => {
	try {
		const response = await axiosClient.post(
			`${APIs.visitScheduleList.endPoint}`,
			payload.data,
			{
				headers: payload.headers,
			}
		);
		return response;
	} catch (err) {
		console.log("err -->", err);
		return err.response;
	}
};

export const postVisitSchedule = async (payload) => {
	try {
		const response = await axiosClient.post(
			`${APIs.scheduleVisit.endPoint}`,
			payload.data,
			{
				headers: payload.headers,
			}
		);
		return response;
	} catch (err) {
		console.log("err -->", err);
		return err.response;
	}
};

//Broker Visit Scheduled 
export const postBrokerVisitSchedule = async (payload) => {
	try {
		const response = await axiosClient.post(
			`${APIs.scheduleBrokerVisit.endPoint}`,
			payload.data,
			{
				headers: payload.headers,
			}
		);
		return response;
	} catch (err) {
		console.log("err -->", err);
		return err.response;
	}
};

export const postAlert = async (payload) => {
	try {
		const response = await axiosClient.post(
			`${APIs.addAlert.endPoint}`,
			payload.data,
			{
				headers: payload.headers,
			}
		);
		return response;
	} catch (err) {
		console.log("err -->", err);
		return err.response;
	}
};

// export const getPropertyFilters = async () => {
// 	try {
// 		const response = await axiosClient.get(APIs.getPropertyFilterData.endPoint);
// 		return response.data.data;
// 	} catch (err) {
// 		console.log("err -->", err);
// 		return err.response.status;
// 	}
// };

export const getPropertyFilters = async (payload = '') => {
	try {
		const response = await axiosClient.get(APIs.getPropertyFilterData.endPoint + payload);
		return response.data.data;
	} catch (err) {
		console.log("err -->", err);
		return err.response.status;
	}
};

export const getMarketPlaceList = async (payload) => {
	var cat = ''
	if (payload > 19) {
		cat = { 'category': '' + payload + '' };//'' + payload + '';
	} else if (payload === '6') {
		cat = { 'is_redeemed': '1' };//'' + payload + '';
	} else {
		cat = { 'category': '' }
	}
	// console.log('payload', cat, ' - ', payload)
	try {
		const response = await axiosClient.post(`${APIs.getAllMarketPlace.endPoint}`, cat);
		return response.data;
	} catch (err) {
		console.log("err -->", err);
		return err.response.status;
	}
};

export const postIsRedeemed = async (id) => {
	const { data } = await axiosClient
		.post(`${APIs.updateIsRedeemed.endPoint}`, {
			"id": id,
			"is_redeemed": 1
		})
		.catch((err) => console.log(err));
	return data;
};

export const postContactUs = async (payload) => {
	const { data } = await axiosClient
		.post(`${APIs.contactUs.endPoint}`, payload.data, {
			headers: payload.headers,
		})
		.catch((err) => console.log(err));
	return data;
};

export const getMediaInFo = async (payload) => {
	const {
		data: { data },
	} = await axiosClient
		.get(`${APIs.getMediaInFo.endPoint}${payload}`)
		.catch((err) => console.log(err));
	return data;
};

export const getExclusivePropertyFilterData = async (type) => {
	var types = ''
	if (type === 'trending-properties') {
		types = 'trending';
	} else if (type === 'featured-properties') {
		types = 'feature'
	} else if (type === 'all-available-properties') {
		types = 'available'
	} else {
		types = 'latest'
	}
	try {
		const response = await axiosClient.get(`${APIs.getExclusivePropertyFilterData.endPoint}/${types}`,);
		return response.data;
	} catch (err) {
		console.log("err -->", err);
		return err.response.status;
	}
};

export const getExclusivePropertyFilterDetails = async (id) => {
	try {
		const response = await axiosClient.get(`${APIs.getExclusivePropertyFilterDetails.endPoint}/${id}`,);
		return response.data;
	} catch (err) {
		console.log("err -->", err);
		return err.response.status;
	}
};
export const resendVerificationEmail = async (email) => {
	const { data } = await axiosClient
		.post(`${APIs.resendVerificationEmail.endPoint}`, {
			"email": email
		})
		.catch((err) => console.log(err));
	return data;
};
export const getJSONConstantDataFile = async () => {
	const { data } = await axios
		.get('/Constants.json')
		.catch((err) => console.log(err));
	return data;
};
export const getHospitalityList = async (id) => {
	try {
		const response = await axiosClient.get(`${APIs.getHospitalityList.endPoint}`,);
		return response.data;
	} catch (err) {
		// console.log("err -->", err);
		return err.response.status;
	}
};
export const getJSONConstants = async () => {
	const { data } = await axios
		.get(`assets/constants/constants.json`)
		.catch((err) => console.log(err));
	return data;
};
//*  Submit Interest
export const submitInterest = async (payload) => {
	try {
		const response = await axiosClient.post(
			`${APIs.submitInterest.endPoint}`,
			payload.data,
			{
				headers: payload.headers,
			}
		);
		return response;
	} catch (err) {
		console.log("err -->", err);
		return err.response;
	}
};
//*    make offer
export const makeAnOffer = async (payload) => {
	try {
		const response = await axiosClient.post(
			`${APIs.makeAnOffer.endPoint}`,
			payload.data,
			{
				headers: payload.headers,
			}
		);
		return response;
	} catch (err) {
		console.log("err -->", err);
		return err.response;
	}
};

//*    make offer brokeradmin
export const postMakeAnOfferBroker = async (payload) => {
	try {
		const response = await axiosClient.post(
			`${APIs.postMakeAnOfferBroker.endPoint}`,
			payload.data,
			{
				headers: payload.headers,
			}
		);
		return response;
	} catch (err) {
		console.log("err -->", err);
		return err.response;
	}
};

//*    trending properties
export const trending = async (payload) => {
	try {
		const response = await axiosClient.post(
			`${APIs.trending.endPoint}`,
			payload.data,
			{
				headers: payload.headers,
			}
		);
		return response;
	} catch (err) {
		console.log("err -->", err);
		return err.response;
	}
};
function handleErrors(err) {
	if (err.response) {
		console.log("Problem with response", err.response.status);
	} else if (err.request) {
		console.log("problem with request");
	} else {
		console.log("error", err.message);
	}
}

export const getCommunities = async () => {
	try {
		const response = await axiosClient.get(`${APIs.getCommunities.endPoint}`,);
		return response.data;
	} catch (err) {
		// console.log("err -->", err);
		return err.response.status;
	}
};
//* OTP verifyEmail
export const otpVerify = async (payload) => {
	const { data } = await axiosClient
		.post(`${APIs.otpVerify.endPoint}`, payload.data, {
			headers: payload.headers,
		})
		.catch((err) => console.log(err));
	return data;
};
//*Create new user(PT)  
export const createTenant = async (payload) => {
	try {
		const response = await axiosClient.post(
			`${APIs.createTenant.endPoint}`,
			payload.data,
			{
				headers: payload.headers,
			}
		);
		return response.data;
	} catch (err) {
		console.log("err -->", err);
		return err.response;
	}
};

//*Tenant Search
export const tenantSearch = async (payload) => {
	try {
	  const response = await axios.get(`${APIs.URL + '/' + APIs.tenantSearch.endPoint + '/' + payload}`)
	  return response.data
	} catch (err) {
	  return handleErrors(err)//err.response.status
	}
}

//* Resend otp
export const resendOTP = async (payload) => {
	const { data } = await axiosClient
		.post(`${APIs.resendOTP.endPoint}`, payload.data, {
			headers: payload.headers,
		})
		.catch((err) => console.log(err));
	return data;
};

//* Testimonial Get
export const getTestimonial = async () => {
	try {
		const response = await axiosClient.get(APIs.getTestimonial.endPoint);
		return response.data.data;
	} catch (err) {
		console.log("err -->", err);
		return err.response.status;
	}
};

export const getConfig = async () => {
	try {
		const response = await axiosClient.get(`${APIs.getConfig.endPoint}`,);
		return response.data;
	} catch (err) {
		// console.log("err -->", err);
		return err.response.status;
	}
};
