import React from 'react';
import { Row, Col, Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';
import { HomeOutlined } from '@ant-design/icons';

const languages = [
    {
        code: 'en',
        name: 'English',
        country_code: 'gb',
    },
    {
        code: 'ar',
        name: 'العربية',
        dir: 'rtl',
        country_code: 'sa',
    },
  ];

const AboutBlog = () => {
    const currentLanguageCode = cookies.get('i18next') || 'en';
    const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
    const { t } = useTranslation();
    return (
        <>
            <Row className="mb-10">
                <Col  lg={24} md={24} xs={24}>
                <Breadcrumb separator=">" className="breadcrumb">
                    <Breadcrumb.Item className="back-breadcrumb-link">
                        <HomeOutlined /><Link to='/' >{t('home_1')}</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to={''}>{t('about_us')}</Link>
                    </Breadcrumb.Item>
                </Breadcrumb>



                </Col>
                {/*<Col className="gutter-row">
                <Link className="slas"> > </Link>
                </Col>
                <Col className="gutter-row">
                    <Link to='/' className="bread-cum-blog">  </Link>
                </Col>*/}
            </Row>
            <Row gutter={[24, 0]} className="d-flex align-items-center">
                <Col lg={12} md={12} xs={24}>
                    <h2>{t('about_us')}</h2>
                    <p>{t('We believe in the power of technology to change the world for the better, and we are committed to creating products that empower people and businesses to achieve their goals.')}</p>

                    <p> {t('With a focus on innovation, excellence, and integrity, RealCube is dedicated to leading the way in the technology industry.')}</p> 
                </Col>
                <Col lg={12} md={12} xs={24}>
                    <img loading="lazy" src="/images/aboutus/about-banner-img.png" alt="aboutus-img" className='img-fulid' />
                </Col>
            </Row>
        </>
    );
};
export default AboutBlog;

