import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import theme from './utils/themes/default.theme';
import GlobalStyles from './utils/themes/global.style';
import MainProvider from './constants/MainProvider';
import AppRoutes from './navigation/router';

import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux';
import { store, persistor } from './constants/redux/features/store';
import i18next from 'i18next'
import { initReactI18next, I18nextProvider } from 'react-i18next'
import HttpApi from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import LogRocket from 'logrocket';
import SplashScreen from 'components/UI/SplashScreen/SplashScreen';
LogRocket.init('p8o67z/realestate');
LogRocket.identify('p8o67z/realestate', {
  name: 'Your Name',
  email: 'yourmail@mail.com',
});
i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['en', 'ar'],
    fallbackLng: 'en',
    debug: false,
    // Options for language detector
    detection: {
      order: ['path', 'cookie', 'htmlTag'],
      caches: ['cookie'],
    },
    // react: { useSuspense: false },
    backend: {
      loadPath: '/assets/locales/{{lng}}/translation.json',
    },
  })

// const loadingMarkup = (
//   <div className="py-4 text-center">
//     <h3>Loading..</h3>
//   </div>
// )

const App = () => (
  <ThemeProvider theme={theme}>
    <React.Fragment>
      <GlobalStyles />
      <Router>
        <MainProvider>
          <I18nextProvider >
            <Provider store={store}>
              <PersistGate persistor={persistor} loading={null}>
                <AppRoutes />
              </PersistGate>
            </Provider>
          </I18nextProvider>
        </MainProvider>
      </Router>
    </React.Fragment>
  </ThemeProvider>
);


ReactDOM.render(
  // <Suspense fallback={loadingMarkup}>
  <Suspense fallback={SplashScreen}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Suspense>,
  document.getElementById('root')
)
