import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const homeApi = createApi({

    reducerPath: 'homeApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
    }),
    endpoints: (builder) => ({
        getWhyICT: builder.query({
            query: (data) =>
            ({
                url: 'propertyFilter',
                method: 'POST',
                body:
                    data
            }),
        }),

        getNews: builder.query({
            query: () =>
            ({
                url: 'mc/news',
                method: 'GET',
            }),
        }),
        getVideos: builder.query({
            query: () =>
            ({
                url: 'mc/video',
                method: 'GET',
            }),
        }),
        getTop: builder.query({
            query: (id) =>
            ({
                url: `topSearches?search_type=${id}`,
                method: 'GET',
            }),
        }),

    }),
});

export const { useGetWhyICTQuery, useGetNewsQuery, useGetVideosQuery, useGetTopQuery } = homeApi;