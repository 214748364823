import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

// ** Define Actions "ALL ACTIONS NAMES SHOULD BE UNIQUE!!!!" **
export const actionTypes = {
    SearchFormData: '[SearchFormData] Action',
    PropertyDetails: '[PropertyDetails] Action',
    SignUpData: '[SignUpData] Action',
}
// ** Define initialGeneralDatas **
const initialGeneralDatasState = {
    searchFormData: undefined,
    propertyDetails: undefined,
    signUpData: undefined,
}

export const initialGeneralDatas = {
    searchFormData: '',
    propertyDetails: '',
    signUpData: '',
}
export const reducer = persistReducer(
    { storage, key: 'General', whitelist: ['searchFormData', 'propertyDetails', 'signUpData'] },

    (
        state = initialGeneralDatasState,
        action = actionTypes
    ) => {
        switch (action.type) {
            case actionTypes.SearchFormData: {
                const searchFormData = action.payload?.searchFormData
                return { ...state, searchFormData: searchFormData }
            }
            case actionTypes.PropertyDetails: {
                const propertyDetails = action.payload?.propertyDetails
                return { ...state, propertyDetails: propertyDetails }
            }
            case actionTypes.SignUpData: {
                const signUpData = action.payload?.signUpData
                return { ...state, signUpData: signUpData }
            }
            default:
                return state
        }
    }
)

export const actions = {
    searchFormData: (searchFormData) => ({
        type: actionTypes.SearchFormData,
        payload: { searchFormData },
    }),
    propertyDetails: (propertyDetails) => ({
        type: actionTypes.PropertyDetails,
        payload: { propertyDetails },
    }),
    signUpData: (signUpData) => ({
        type: actionTypes.SignUpData,
        payload: { signUpData },
    }),
}