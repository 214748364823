import Container from '../../components/UI/Container/Container';
import { Col, Row } from "antd";
import SapLandingWrapper from "./SapLanding.style";
import { Link } from 'react-router-dom';


export default function Brand() {
    return (
        <SapLandingWrapper>

            <div className="bg-white">
                <Container className="container">
                    <div className="main pt-3 pb-3">
                       <h1 className='fw-bolder txt text-center'>Popular communities</h1>
                       <Row>
                            <Col lg={6}>
                                <Link to={'/marketing-details'}>
                                <div className='community-card'>
                                    <img src="/images/community-1.webp" alt="check-mark" className="community-img" />
                                    <div>
                                        <h5 className='text-white community-txt'>Business Bay</h5>
                                    </div>
                                </div>
                                </Link>
                                
                            </Col>
                            <Col lg={6}>
                                <Link to={'/marketing-details'}>
                                    <div className='community-card'>
                                        <img src="/images/community-2.webp" alt="check-mark" className="community-img" />
                                        <div>
                                            <h5 className='text-white community-txt'>MBR City</h5>
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                            <Col lg={6}>
                            <Link to={'/marketing-details'}>
                                <div className='community-card'>
                                    <img src="/images/community-1.webp" alt="check-mark" className="community-img" />
                                    <div>
                                        <h5 className='text-white community-txt'>Jumeirah Lake Towers</h5>
                                    </div>
                                </div>
                            </Link>
                            </Col>
                            <Col lg={6}>
                            <Link to={'/marketing-details'}>
                                <div className='community-card'>
                                    <img src="/images/community-2.webp" alt="check-mark" className="community-img" />
                                    <div>
                                        <h5 className='text-white community-txt'>Dubai Hills Estate</h5>
                                    </div>
                                </div>
                                </Link>
                            </Col>
                       </Row>
                      
                    </div>
                </Container>
            </div>
        </SapLandingWrapper>
    )
}