import styled from 'styled-components';

export const BannerBreadCrumbWrapper = styled.div`
  .ant-breadcrumb {
    background-color: rgba(0, 0, 0, 0.39);
    position: absolute;
    width: 100%;
    bottom: 0px;
    padding: 5px 0;
    .ant-breadcrumb-link {
      color: #fff;
      font-size: 16px;
      a {
        color: #fff;
        font-size: 16px;
      }
    }
    .ant-breadcrumb-separator {
      color: rgba(255, 255, 255, 0.5);
      margin: 0 8px;
    }
  }
`;
