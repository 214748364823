import React from 'react';
import { Row, Col } from 'antd';
import Container from "../../../components/UI/Container/Container";
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';

const languages = [
  {
      code: 'en',
      name: 'English',
      country_code: 'gb',
  },
  {
      code: 'ar',
      name: 'العربية',
      dir: 'rtl',
      country_code: 'sa',
  },
];
const Ourvision = () => {
    const currentLanguageCode = cookies.get('i18next') || 'en';
const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
const { t } = useTranslation();
    return (
        <>
            <div className="ourmission-blog align-middle abt-mission mb-50">
                <Container>
                    <div className="our-mission-head" >
                        <h4>{t('Our Vision')}</h4>
                        {/* <p>
                            At RealCube, our mission is to revolutionize the property and
                            facilities management industry through our cutting-edge,
                            cloud-based technology.{" "}
                        </p> */}

                        <p>
                        {t("Our vision is to become the world's leading provider of cutting-edge technology solutions for sustainable communities and cities. We aim to empower people and businesses with the tools they need to succeed in the digital age, and we will continue to push the limits of what's possible to make this vision a reality. With a focus on innovation and excellence, we will continue to create products that transform the world and make a positive impact on society.")}
                        </p>
                    </div>
                    <Row gutter={[24, 0]} className="d-flex align-items-center">
                        <Col lg={6} md={12} xs={24}>
                            <img loading="lazy" src="/images/aboutus/value-1.png" alt="aboutus-img" className='abt-img-1' width="540" height="230"/>
                        </Col>
                        <Col lg={6} md={12} xs={24} className="align-self-end">
                            <img loading="lazy" src="/images/aboutus/value-2.png" alt="aboutus-img" className='abt-img-1  abt-img' width="540" height="230"/>
                        </Col>
                        <Col lg={6} md={12} xs={24} className="align-self-end">
                            <img loading="lazy" src="/images/aboutus/value-3.png" alt="aboutus-img" className='abt-img-1  abt-img' width="540" height="230"/>
                        </Col>
                        <Col lg={6} md={12} xs={24}>
                            <img loading="lazy" src="/images/aboutus/value-4.png" alt="aboutus-img" className='abt-img-1' width="540" height="230"/>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};
export default Ourvision;

