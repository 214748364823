import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { Popconfirm,Popover, message, Alert, Spin, Modal, Row, Col, Button, Tabs, Card, Radio, Form, Input, Checkbox, DatePicker, Upload, Progress } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import PaymentWrapper from './PaymentModal.style';
import moment from 'moment';
import { PAYMENT_PAY_URL_API, BANK_TRANSFER_URL_API, PAY_BY_CHEQUE_URL_API } from '../../setup/constant';

const { TabPane } = Tabs;

function callback(key) {
	console.log(key);
}

function onChange(date, dateString) {
	console.log(date, dateString);
}

const dateFormatList = ['DD/MMM/YYYY'];

const PaymentModal = (props) => {
	const { paymentStatusState, getPaymentStatus, makeRequest } = props;

	function payment_forCallback(key) {
		if (key == 1) {
			props.setPaymentParams(preState => {
				return {
					...preState,
					payment_for: key,
					amount: props.offerDetails.offer_details.annualRent
				}
			});
		} else if (key == 2) {
			props.setPaymentParams(preState => {
				return {
					...preState,
					payment_for: key,
					amount: props.offerDetails.offer_details.securityDeposit
				}
			});
		} else {
			props.setPaymentParams(preState => {
				return {
					...preState,
					payment_for: key,
					amount: props.offerDetails.offer_details.securityDeposit
				}
			});
		}
	}

	/* console.log('payemtn params',props.paymentParams); */

	const [paymentProcessStart, setPaymentProcessStart] = useState(false);

	const [createPaymentState, setCreatePaymentState] = useState({
		loading: false,
		response: null,
		error: null
	});
	// const [createPaymentState,setCreatePaymentState] = useState({
	// 	loading: false,
	// 	paymentResponse: '',
	// 	error: false
	// });

	const processPayment = async () => {
		makeRequest('POST', PAYMENT_PAY_URL_API, setCreatePaymentState, props.paymentParams);
	}


	useEffect(() => {
		if (createPaymentState.response?.orderNo) {
			getPaymentStatus({
				order_no: createPaymentState.response.orderNo
			});
			window.open(createPaymentState.response.paymentUrl, '_blank');
		}
	}, [createPaymentState]);


	const [progress, setProgress] = useState(0);

	/* let paymentTimer = setTimeout(()=>{
		setProgress(preState=>{
			return preState+1
		})
	},4800); */



	const [bankTransferFields, setBankTransferFields] = useState({});



	const [payByChequeFields, setPayByChequeFields] = useState({});


	const bankTransferSubmit = async (value) => {
		setBankTransferFields(value);

		let formData = new FormData();

		formData.append("offer_id", props.paymentParams.offer_id);
		formData.append("payment_for", props.paymentParams.payment_category);
		formData.append("beneficiary_name", value.beneficiary_name);
		formData.append("beneficiary_account", value.beneficiary_account_number);
		formData.append("iban_no", value.iban_number);
		formData.append("beneficiary_bank", value.beneficiary_bank_name);
		formData.append("swift_code", value.swift_code);
		formData.append("branch_address", "");

		makeRequest('POST', BANK_TRANSFER_URL_API, setCreatePaymentState, formData, true, () => {
			props.onPaymentSuccessModalOpen();
			props.getOfferDetails();
		});

	}

	const payByChequeSubmit = async (value) => {
		setPayByChequeFields(value);

		let formData = new FormData();

		formData.append("offer_id", props.paymentParams.offer_id);
		formData.append("payment_for", props.paymentParams.payment_category);
		formData.append("cheque_number", value.chequeNumber);
		formData.append("payment_date", value.chequeDate.format("YYYY-MM-DD"));

		//formData.append("payment_date","2022-01-12");

		formData.append("acc_holder_name", value.accountNumber);
		formData.append("acc_number", value.accountHolderName);
		formData.append("amount", props.paymentParams.amount);
		formData.append("bank", value.bankName);
		formData.append("cheque_img", value.photo.file.originFileObj);

		makeRequest('POST', PAY_BY_CHEQUE_URL_API, setCreatePaymentState, formData, true, () => {
			props.onPaymentSuccessModalOpen();
			props.getOfferDetails();
		});

		// setCreatePaymentState({loading: true});
		// try{
		// 	const token = props.userInfo.access_token;
		// 	const response = await fetch(
		//         PAY_BY_CHEQUE_URL_API,
		//         {
		//           method: 'POST',
		//           headers: {
		// 			'Authorization': `Bearer ${token}`
		//           },
		//           body: formData
		//         }
		//     );
		//     const paymentProcessResponse = await response.json();

		// 	if(paymentProcessResponse.code == 200){
		// 		setCreatePaymentState({loading: false, paymentResponse:paymentProcessResponse, error: false});
		// 		props.onPaymentSuccessModalOpen();
		// 	}else{
		// 		throw "Sever error"
		// 	}
		// }catch(err){	
		// 	let catchError = "";
		// 	if (typeof err !== "string") {
		// 		catchError = "Server error";
		// 	} else {
		// 		catchError = err;
		// 	}
		// 	setCreatePaymentState({response: {}, loading: false, error: catchError});
		// }

		// for(let [name, value] of formData) {
		// 	console.log(`${name} = ${value}`); // key1 = value1, then key2 = value2
		// }
		// console.log('pay by cheque',value);
	}

	const inputValidation= {
		beforeUpload: file => {
		  const isAllowedType = file.type === 'image/png'|| file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'application/pdf';
		  const fileSize =file.size <= 5266467
		  
		  if (!isAllowedType) {
			message.error({
				content: `${file.name} is not a png/jpeg/jpg/PDF  file`,
				className: 'custom-class',
				style: {
					marginLeft: '1px',
				  marginTop: '30vh',
				},
				duration: 60
			  });
		  }  
		  if (!fileSize) {
			message.error({
				content: `File size should not be more then 5MB`,
				className: 'custom-class',
				style: {
					marginLeft: '1px',
				  marginTop: '30vh',
				},
			  });
		  }
		  return isAllowedType && fileSize   || Upload.LIST_IGNORE;
		},
		// onChange: info => {
		//   let fileList = [...info.fileList];
    	// 	fileList = fileList.slice(-1);
		// 	// console.log('fileList',fileList);
		// 	console.log('info',info);
			
			
		// },
	  };
	const dummyRequest = ({ file, onSuccess }) => {
		setTimeout(() => {
			onSuccess("ok");
		}, 0);
	};
	return (
		<PaymentWrapper>
			<div>
				<Modal
					className="payment-popup top-75px"
					title="Payment"
					centered
					width={560}
					visible={props.modalStatus}
					footer={null}
					onCancel={props.onCancel}
				>
					<div className="payment-body">
						{paymentStatusState?.loading ? (
							<Card bordered={false} className="card-select mb-15">
								{/* <Progress type="circle" percent={progress} status="exception" /> */}
								<h3>Payment is processing. Don't refresh the page or close the window.</h3>
								<Spin size="large" />
								<Popconfirm
									placement="bottomRight"
									title='Do you want to cancel payment?'
									onConfirm={()=>{
                						//props.setCancelPayment(true);
										props.cancelPayment.current = true;
									}}
									okText="Yes"
									cancelText="No"
								>
									<Button>Cancel Payment</Button>
								</Popconfirm>
							</Card>
						) : (<Row className="mb-0">
							<Col className="gutter-row" lg={24} md={24}>
								<Tabs defaultActiveKey="1" onChange={payment_forCallback} className="sec-type-tabs">
									<TabPane tab="Security Deposit" key="2">
										<Tabs defaultActiveKey="1" onChange={callback} className="payment-type-tabs">
											<TabPane tab="Card Payment" className="card-ioc" key="1">
												<div className="">
													<Row className="mb-0">
														<Col className="gutter-row" lg={24} md={24}>
															{/* <Card bordered={false} className="card-select mb-15">
																<Radio>
																	Credit Card
																</Radio>
															</Card> */}

															<Form
																labelCol={{ span: 24 }}
																wrapperCol={{ span: 24 }}
																initialValues={{ remember: true }}
																autoComplete="off">
																{/* <Card bordered={false} className="card-select mb-5">
																	<Row className="mb-0">
																		<Col className="gutter-row" lg={24} md={24}>
																			<Row className="mb-0" gutter={[18, 0]}>
																				<Col className="gutter-row" lg={24} md={24}>
																					<Form.Item
																						className="mt-5 mb-10"
																						label="Enter Credit Card Number *"
																						rules={[{ required: true, message: 'Please input your credit card number!' }]}
																					>
																						<Input />
																					</Form.Item>
																				</Col>
																				<Col className="gutter-row" lg={16} md={16}>
																					<Form.Item
																						className="mb-10"
																						label="Expiry Date"
																						rules={[{ required: true, message: 'Please input your expiry date!' }]}
																					>
																						<DatePicker getPopupContainer={(triggerNode) => { return triggerNode.parentNode; }} onChange={onChange} format={dateFormatList} className="date-picker w-100" />
																					</Form.Item>
																				</Col>
																				<Col className="gutter-row" lg={8} md={8}>
																					<Form.Item
																						className="mb-10"
																						label="CVV"
																						rules={[{ required: true, message: 'Please input your cvv!' }]}
																					>
																						<Link to={''} >
																							<span className="help-text">
																								Help?
																							</span>
																						</Link>
																						<Input />
																					</Form.Item>
																				</Col>
																			</Row>
																		</Col>
																	</Row>
																</Card> */}
																<Row>
																	<Col className="gutter-row" lg={24} md={24}>
																		{/* <Form.Item name="remember" className="mb-20" valuePropName="checked" wrapperCol={{ span: 24 }}>
																			<Checkbox>Save my details for future</Checkbox>
																		</Form.Item> */}
																	</Col>
																	<Col className="gutter-row" lg={24} md={24}>
																		{/* <Button type="primary" onClick={props.onPaymentSuccessModalOpen} htmlType="submit" className="w-100 rounded-5"> */}

																		{createPaymentState.loading && (
																			<div 
																				className='flex-css justify-content-center flex-column'
																			>
																				<h3>Payment is processing. Don't refresh the page or close the window.</h3>
																				<Spin />
																			</div>
																		)}
																		{!createPaymentState.loading && (
																			<Button type="primary" onClick={processPayment} htmlType="submit" className="w-100 rounded-5">
																				Confirm Payment
																			</Button>
																		)}

																		{createPaymentState.error && (
																			<Alert message={createPaymentState.error} type="error" className="mt-10" />
																		)}
																		{paymentStatusState?.error && (
																			<Alert message={paymentStatusState?.error} type="error" className="mt-10" />
																		)}
																	</Col>
																</Row>
															</Form>
														</Col>
													</Row>
												</div>
											</TabPane>
											<TabPane tab="Bank Transfer" className="dollar-ioc" key="2">
												<Form
													onFinish={bankTransferSubmit}
													labelCol={{ span: 24 }}
													wrapperCol={{ span: 24 }}
													initialValues={{ remember: true, ...bankTransferFields }}
													autoComplete="off">
													<Row className="mb-0 bank-form">
														<Col className="gutter-row" lg={24} md={24}>
															<Row className="mb-0" gutter={[18, 0]}>
																<Col className="gutter-row" lg={24} md={24}>
																	<Form.Item
																		className="mt-10 mb-10"
																		name="beneficiary_name"
																		label="Beneficiary Name"
																		rules={[
																			{ required: true, message: 'Beneficiary Name Required' },

																		]}>
																		<Input onChange={(e) => {
																			setBankTransferFields(preVal => {
																				return {
																					...preVal,
																					beneficiary_name: e.target.value
																				}
																			});
																		}} value={bankTransferFields.beneficiary_name}
																			onKeyPress={(event) => {
																				if (!/[a-zA-Z]/.test(event.key)) {
																					event.preventDefault();
																				}
																			}} />
																	</Form.Item>
																</Col>
																<Col className="gutter-row" lg={24} md={24}>
																	<Form.Item
																		className="mb-10"
																		name="beneficiary_account_number"
																		label="Beneficiary Account Number"
																		rules={[
																			{ required: true, message: 'Beneficiary Account Number Required' },
																		]}>
																		<Input maxLength={100} onKeyPress={(event) => {
																			if (!/[0-9+]/.test(event.key)) {
																				event.preventDefault();
																			}
																		}} />
																	</Form.Item>
																</Col>
																<Col className="gutter-row" lg={24} md={24}>
																	<Form.Item
																		className="mb-10"
																		name="iban_number"
																		label="IBAN Number"
																		rules={[
																			{ required: true, message: 'IBAN Number Required' },
																		]}>
																		<Input maxLength={50} onKeyPress={(event) => {
																			if (!/[0-9a-zA-Z]/.test(event.key)) {
																				event.preventDefault();
																			}
																		}} />
																	</Form.Item>
																</Col>
																<Col className="gutter-row" lg={24} md={24}>
																	<Form.Item
																		className="mb-10"
																		name="beneficiary_bank_name"
																		label="Beneficiary Bank Name"
																		rules={[
																			{ required: true, message: 'Beneficiary Bank Name Required' },

																		]}>
																		<Input maxLength={150} onKeyPress={(event) => {
																			if (!/[a-zA-Z]/.test(event.key)) {
																				event.preventDefault();
																			}
																		}} />
																	</Form.Item>
																</Col>
																<Col className="gutter-row" lg={24} md={24}>
																	<Form.Item
																		className="mb-20"
																		name="swift_code"
																		label="Swift Code"
																		rules={[
																			{ required: true, message: 'Swift Code Required' },
																		]}>
																		<Input maxLength={100} onKeyPress={(event) => {
																			if (!/[0-9a-zA-Z]/.test(event.key)) {
																				event.preventDefault();
																			}
																		}} />
																	</Form.Item>
																</Col>
															</Row>
															<Row>
																<Col className="gutter-row" lg={24} md={24}>
																	{createPaymentState.loading && (
																		<Spin />
																	)}
																	{!createPaymentState.loading && (
																		<Button type="primary" /* onClick={props.onPaymentSuccessModalOpen} */ htmlType="submit" className="w-100 rounded-5">
																			Submit
																		</Button>
																	)}
																	{createPaymentState.error && (
																		<Alert message={createPaymentState.error} type="error" className="mt-10" />
																	)}
																</Col>
															</Row>
														</Col>
													</Row>
												</Form>
											</TabPane>
											<TabPane tab="Pay by Cheque" className="cheque-ioc" key="3">
												<Form
													onFinish={payByChequeSubmit}
													labelCol={{ span: 24 }}
													wrapperCol={{ span: 24 }}
													initialValues={{ remember: true, ...payByChequeFields }}
													autoComplete="off">
													<Row className="mb-0 bank-form">
														<Col className="gutter-row" lg={24} md={24}>
															<Row className="mb-0" gutter={[18, 0]}>
																<Col className="gutter-row" lg={24} md={24}>
																	<Form.Item
																		name="photo"
																		className="mt-10 mb-10"
																		label="Photo"
																		rules={[
																			{ required: true, message: 'Photo Required' }
																		]}>
																		<Upload
																			className="w-100"
																			listType="picture">
																			<Button className="w-100 upload-photo-btn" icon={<UploadOutlined />}>Click to upload photo</Button>
																		</Upload>
																	</Form.Item>
																</Col>
																<Col className="gutter-row" lg={24} md={24}>
																	<Form.Item
																		name="chequeNumber"
																		className=" mb-10"
																		label="Cheque Number"
																		rules={[
																			{ required: true, message: 'Cheque Number Required' },

																		]}>
																		<Input maxLength={50} onKeyPress={(event) => {
																			if (!/[0-9]/.test(event.key)) {
																				event.preventDefault();
																			}
																		}} />
																	</Form.Item>
																</Col>
																<Col className="gutter-row" lg={24} md={24}>
																	<Form.Item
																		name="chequeDate"
																		className="mb-10"
																		label="Cheque Date"
																		rules={[{ required: true, message: 'Cheque Date Required' }]}>
																		<DatePicker getPopupContainer={(triggerNode) => { return triggerNode.parentNode; }} onChange={onChange} format={dateFormatList} className="date-picker w-100" />
																	</Form.Item>
																</Col>
																<Col className="gutter-row" lg={24} md={24}>
																	<Form.Item
																		name="accountNumber"
																		className="mb-10"
																		label="Account Number"
																		rules={[
																			{ required: true, message: 'Account Number Required' },
																			]}>
																		<Input maxLength={50} onKeyPress={(event) => {
																			if (!/[0-9]/.test(event.key)) {
																				event.preventDefault();
																			}
																		}}/>
																	</Form.Item>
																</Col>
																<Col className="gutter-row" lg={24} md={24}>
																	<Form.Item
																		name="accountHolderName"
																		className="mb-10"
																		label="Account Holder Name"
																		rules={[
																			{ required: true, message: 'Account Holder Name Required' },]}>
																		<Input maxLength={150} onKeyPress={(event) => {
																			if (!/[a-zA-Z]/.test(event.key)) {
																				event.preventDefault();
																			}
																		}}/>
																	</Form.Item>
																</Col>
																<Col className="gutter-row" lg={24} md={24}>
																	<Form.Item
																		name="bankName"
																		className="mb-20"
																		label="Bank Name"
																		rules={[
																			{ required: true, message: 'Bank Name Required' },
																			
																		]}>
																		<Input maxLength={150} onKeyPress={(event) => {
																			if (!/[a-zA-Z]/.test(event.key)) {
																				event.preventDefault();
																			}
																		}}/>
																	</Form.Item>
																</Col>
															</Row>
															<Row>
																<Col className="gutter-row" lg={24} md={24}>

																	{createPaymentState.loading && (
																		<Spin />
																	)}
																	{!createPaymentState.loading && (
																		<Button type="primary" /* onClick={props.onPaymentSuccessModalOpen} */ htmlType="submit" className="w-100 rounded-5">
																			Submit
																		</Button>
																	)}
																	{createPaymentState.error && (
																		<Alert message={createPaymentState.error} type="error" className="mt-10" />
																	)}
																</Col>
															</Row>
														</Col>
													</Row>
												</Form>
											</TabPane>
										</Tabs>
									</TabPane>
									<TabPane tab="Rent" key="1">
										<Tabs defaultActiveKey="1" onChange={callback} className="payment-type-tabs">
											<TabPane tab="Card Payment" className="card-ioc" key="1">
												<div className="">
													<Row className="mb-0">
														<Col className="gutter-row" lg={24} md={24}>
															{/* <Card bordered={false} className="card-select mb-15">
																<Radio>
																	Credit Card
																</Radio>
															</Card> */}
															<Form
																labelCol={{ span: 24 }}
																wrapperCol={{ span: 24 }}
																initialValues={{ remember: true }}
																autoComplete="off">
																{/* <Card bordered={false} className="card-select mb-5">
																	<Row className="mb-0">
																		<Col className="gutter-row" lg={24} md={24}>
																			<Row className="mb-0" gutter={[18, 0]}>
																				<Col className="gutter-row" lg={24} md={24}>
																					<Form.Item
																						className="mt-5 mb-10"
																						label="Enter Credit Card Number *"
																						rules={[{ required: true, message: 'Please input your credit card number!' }]}
																					>
																						<Input />
																					</Form.Item>
																				</Col>
																				<Col className="gutter-row" lg={16} md={16}>
																					<Form.Item
																						className="mb-10"
																						label="Expiry Date"
																						rules={[{ required: true, message: 'Please input your expiry date!' }]}
																					>
																						<DatePicker getPopupContainer={(triggerNode) => { return triggerNode.parentNode; }} onChange={onChange} format={dateFormatList} className="date-picker w-100" />
																					</Form.Item>
																				</Col>
																				<Col className="gutter-row" lg={8} md={8}>
																					<Form.Item
																						className="mb-10"
																						label="CVV"
																						rules={[{ required: true, message: 'Please input your cvv!' }]}
																					>
																						<Link to={''}>
																							<span className="help-text">
																								Help?
																							</span>
																						</Link>
																						<Input />
																					</Form.Item>
																				</Col>
																			</Row>
																		</Col>
																	</Row>
																</Card> */}
																<Row>
																	<Col className="gutter-row" lg={24} md={24}>
																		{/* <Form.Item name="remember" className="mb-20" valuePropName="checked" wrapperCol={{ span: 24 }}>
																			<Checkbox>Save my details for future</Checkbox>
																		</Form.Item> */}
																	</Col>
																	<Col className="gutter-row" lg={24} md={24}>
																		{/* <Button type="primary" onClick={props.onPaymentSuccessModalOpen} htmlType="submit" className="w-100 rounded-5"> */}

																		{createPaymentState.loading && (
																			<div
																				className='flex-css justify-content-center flex-column'
																			>
																				<h3>Payment is processing. Don't refresh the page or close the window.</h3>
																				<Spin />
																			</div>
																		)}
																		{!createPaymentState.loading && (
																			<Button type="primary" onClick={processPayment} htmlType="submit" className="w-100 rounded-5">
																				Confirm Payment
																			</Button>
																		)}

																		{createPaymentState.error && (
																			<Alert message={createPaymentState.error} type="error" className="mt-10" />
																		)}
																		{paymentStatusState?.error && (
																			<Alert message={paymentStatusState?.error} type="error" className="mt-10" />
																		)}
																	</Col>
																</Row>
															</Form>
														</Col>
													</Row>
												</div>
											</TabPane>
											<TabPane tab="Bank Transfer" className="dollar-ioc" key="2">
												<Form
													onFinish={bankTransferSubmit}
													labelCol={{ span: 24 }}
													wrapperCol={{ span: 24 }}
													initialValues={{ remember: true, ...bankTransferFields }}
													autoComplete="off">
													<Row className="mb-0 bank-form">
														<Col className="gutter-row" lg={24} md={24}>
															<Row className="mb-0" gutter={[18, 0]}>
																<Col className="gutter-row" lg={24} md={24}>
																	<Form.Item
																		className="mt-10 mb-10"
																		name="beneficiary_name"
																		label="Beneficiary Name"
																		rules={[
																			{ required: true, message: 'Beneficiary Name Required' },

																		]}>
																		<Input onChange={(e) => {
																			setBankTransferFields(preVal => {
																				return {
																					...preVal,
																					beneficiary_name: e.target.value
																				}
																			});
																		}} value={bankTransferFields.beneficiary_name}
																			onKeyPress={(event) => {
																				if (!/[a-zA-Z]/.test(event.key)) {
																					event.preventDefault();
																				}
																			}} />
																	</Form.Item>
																</Col>
																<Col className="gutter-row" lg={24} md={24}>
																	<Form.Item
																		className="mb-10"
																		name="beneficiary_account_number"
																		label="Beneficiary Account Number"
																		rules={[
																			{ required: true, message: 'Beneficiary Account Number Required' },

																		]}>
																		<Input maxLength={100} onKeyPress={(event) => {
																			if (!/[0-9+]/.test(event.key)) {
																				event.preventDefault();
																			}
																		}} />
																	</Form.Item>
																</Col>
																<Col className="gutter-row" lg={24} md={24}>
																	<Form.Item
																		className="mb-10"
																		name="iban_number"
																		label="IBAN Number"
																		rules={[
																			{ required: true, message: 'IBAN Number Required' },
																		]}>
																		<Input maxLength={50} onKeyPress={(event) => {
																			if (!/[0-9a-zA-Z]/.test(event.key)) {
																				event.preventDefault();
																			}
																		}} />
																	</Form.Item>
																</Col>
																<Col className="gutter-row" lg={24} md={24}>
																	<Form.Item
																		className="mb-10"
																		name="beneficiary_bank_name"
																		label="Beneficiary Bank Name"
																		rules={[
																			{ required: true, message: 'Beneficiary Bank Name Required' },
																		]}>
																		<Input maxLength={150} onKeyPress={(event) => {
																			if (!/[a-zA-Z]/.test(event.key)) {
																				event.preventDefault();
																			}
																		}} />
																	</Form.Item>
																</Col>
																<Col className="gutter-row" lg={24} md={24}>
																	<Form.Item
																		className="mb-20"
																		name="swift_code"
																		label="Swift Code"
																		rules={[
																			{ required: true, message: 'Swift Code Required' },
																		]}>
																		<Input maxLength={100} onKeyPress={(event) => {
																			if (!/[0-9a-zA-Z]/.test(event.key)) {
																				event.preventDefault();
																			}
																		}} />
																	</Form.Item>
																</Col>
															</Row>
															<Row>
																<Col className="gutter-row" lg={24} md={24}>
																	{createPaymentState.loading && (
																		<Spin />
																	)}
																	{!createPaymentState.loading && (
																		<Button type="primary" /* onClick={props.onPaymentSuccessModalOpen} */ htmlType="submit" className="w-100 rounded-5">
																			Submit
																		</Button>
																	)}
																	{createPaymentState.error && (
																		<Alert message={createPaymentState.error} type="error" className="mt-10" />
																	)}
																</Col>
															</Row>
														</Col>
													</Row>
												</Form>
											</TabPane>
											<TabPane tab="Pay by Cheque" className="cheque-ioc" key="3">
												<Form
													onFinish={payByChequeSubmit}
													labelCol={{ span: 24 }}
													wrapperCol={{ span: 24 }}
													initialValues={{ remember: true, ...payByChequeFields }}
													autoComplete="off">
													<Row className="mb-0 bank-form">
														<Col className="gutter-row" lg={24} md={24}>
															<Row className="mb-0" gutter={[18, 0]}>
																<Col className="gutter-row" lg={24} md={24}>
																	<Form.Item
																		name="photo"
																		className="mt-10 mb-10"
																		label="Photo"
																		rules={[
																			{ required: true, message: 'Photo Required' }]}>
																		<Upload
																			{...inputValidation}
																			className="w-100"
																			listType="picture" customRequest={dummyRequest} accept='.png,.jpeg,.jpg,application/pdf'>
																			<Button className="w-100 upload-photo-btn" icon={<UploadOutlined />}>Click to upload photo</Button>
																		</Upload>
																	</Form.Item>
																</Col>
																<Col className="gutter-row" lg={24} md={24}>
																	<Form.Item
																		name="chequeNumber"
																		className=" mb-10"
																		label="Cheque Number"
																		rules={[
																			{ required: true, message: 'Cheque Number Required' },
																			
																		]}>
																		<Input  maxLength={50} onKeyPress={(event) => {
																			if (!/[0-9]/.test(event.key)) {
																				event.preventDefault();
																			}
																		}}/>
																	</Form.Item>
																</Col>
																<Col className="gutter-row" lg={24} md={24}>
																	<Form.Item
																		name="chequeDate"
																		className="mb-10"
																		label="Cheque Date"
																		rules={[{ required: true, message: 'Cheque Date Required' }]}>
																		<DatePicker getPopupContainer={(triggerNode) => { return triggerNode.parentNode; }} onChange={onChange} format={dateFormatList} className="date-picker w-100" />
																	</Form.Item>
																</Col>
																<Col className="gutter-row" lg={24} md={24}>
																	<Form.Item
																		name="accountNumber"
																		className="mb-10"
																		label="Account Number"
																		rules={[
																			{ required: true, message: 'Account Number Required' },
																			]}>
																		<Input  maxLength={50} onKeyPress={(event) => {
																			if (!/[0-9]/.test(event.key)) {
																				event.preventDefault();
																			}
																		}}/>
																	</Form.Item>
																</Col>
																<Col className="gutter-row" lg={24} md={24}>
																	<Form.Item
																		name="accountHolderName"
																		className="mb-10"
																		label="Account Holder Name"
																		rules={[
																			{ required: true, message: 'Account Holder Name Required' },
																			
																		]}>
																		<Input  maxLength={150} onKeyPress={(event) => {
																			if (!/[a-zA-Z]/.test(event.key)) {
																				event.preventDefault();
																			}
																		}}/>
																	</Form.Item>
																</Col>
																<Col className="gutter-row" lg={24} md={24}>
																	<Form.Item
																		name="bankName"
																		className="mb-20"
																		label="Bank Name"
																		rules={[
																			{ required: true, message: 'Bank Name Required' },
																			
																		]}>
																		<Input  maxLength={150} onKeyPress={(event) => {
																			if (!/[a-zA-Z]/.test(event.key)) {
																				event.preventDefault();
																			}
																		}}/>
																	</Form.Item>
																</Col>
															</Row>
															<Row>
																<Col className="gutter-row" lg={24} md={24}>
																	{createPaymentState.loading && (
																		<Spin />
																	)}
																	{!createPaymentState.loading && (
																		<Button type="primary" htmlType="submit" className="w-100 rounded-5">
																			Submit
																		</Button>
																	)}
																	{createPaymentState.error && (
																		<Alert message={createPaymentState.error} type="error" className="mt-10" />
																	)}
																</Col>
															</Row>
														</Col>
													</Row>
												</Form>
											</TabPane>
										</Tabs>
									</TabPane>
								</Tabs>
							</Col>
						</Row>)}
					</div>
				</Modal>
			</div>
		</PaymentWrapper>
	);
};

export default PaymentModal;