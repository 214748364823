import React, { useContext, useEffect } from 'react';
import { MainContext } from 'constants/MainProvider';
import Layout from 'pages/Layout/Layout';
import Loader from 'components/Loader/Loader';
import PublicRoutes from './public/router';
import PrivateRoutes from './private/router';

export default function AppRoutes() {
    const { logOut, user, loggedIn: logged } = useContext(MainContext);

    return (
        <>
            {logged ?
                <PrivateRoutes/>
                :
                <PublicRoutes />
            }
        </>
    );
}
