import React from 'react';
import { Col, Modal, Row } from 'antd';
import SignInForm from './SignInForm';
import SocialLogin from '../SocialLogin';
import Wrapper from '../Auth.style';
import { useTranslation } from 'react-i18next'
import cookies from 'js-cookie'
import { Link } from 'react-router-dom'
const languages = [
  {
    code: 'en',
    name: 'English',
    country_code: 'gb',
  },
  {
    code: 'ar',
    name: 'العربية',
    dir: 'rtl',
    country_code: 'sa',
  },
];
const SignIn = (props) => {
  const currentLanguageCode = cookies.get('i18next') || 'en';
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();
  return (
    <Wrapper>
      <div>
        <Modal
          className="login-popup"
          // title="Vertically centered modal dialog"
          width={900}
          centered
          visible={props.modalStatus}
          footer={null}
          onCancel={props.onCancel}
        >
          <Row gutter={1}>
            <Col className="gutter-row" span={12}>
              <img
                src="/images/login-bg.png"
                width="664"
                height="844"
                loading="lazy"
                alt="Login Image"
                className="login-image"
              />
              <div className='secure-position'>
                <div className="secure-img">  <img src="/images/flash-circle-new1.png" />{t('Property finding Software')}  </div>
                <div className='makeyr-property'>
                  <img src="/images/register-search-new1.png" /> <br />
                  <p>{t('Best Rented or selling houses in Dubai under one portal')}</p>
                </div>
              </div>
            </Col>
            <Col className="gutter-row" span={12}>
              <div className='p-40-30'>
                <div class="text-center mb-20">
                  <img
                    src="/images/realcube-logo.svg"
                    width="150"
                    height="27"
                    loading="lazy"
                    alt="RealCube Logo"
                  />
                </div>
                <div className="title-modal welcome-back-text">
                  <h2 className="mb-2">{t('Welcome Back')}</h2>
                  <p>{t('Login into your account')}</p>
                  <SignInForm
                  onForgotModalOpen={props.onForgotModalOpen}
                  onSignUpModalOpen={props.onSignUpModalOpen}
                  onOTPModalOpen={props.onOTPModalOpen}
                  onEmailConfirmationModalOpen={props.onEmailConfirmationModalOpen}
                />
                  
                  
                </div>
                {/* <SocialLogin />
                <div className="or-text">
                  <span>{t('or')}</span>
                </div> */}
                
              </div>
            </Col>
          </Row>

        </Modal>
      </div>
    </Wrapper>
  );
};

export default SignIn;
