import styled, { css } from 'styled-components';

const ContainerWrapper = styled.div` 
  margin-left: auto;
  margin-right: auto;
  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
      max-width: none !important;
    `};
  ${(props) =>
    (props.noGutter &&
        css`
        padding-left: 0;
        padding-right: 0;
      `) ||
    css`
      padding-left: 30px;
      padding-right: 30px;

      @media (max-width: 480px) {
        padding-left: 25px;
        padding-right: 25px;
      }
    `};

  ${(props) =>
    props.fluid &&
    css`
      width: 100% !important;
      max-width: 1920px !important;
      @media (min-width: 1441px) {
        padding-left: 75px;
        padding-right: 75px;
      }
    `}

  @media (min-width: 768px) {
    max-width: 925px;
    width: 100%;
  }

  @media (min-width: 992px) {
    max-width: 970px;
    width: 100%;
  }

  @media (min-width: 1200px) {
    max-width: 1170px;
    width: 100%;
  }
  @media (min-width: 1500px) {
    max-width: 1340px;
    width: 100%;
  }
  .main_page_headding{
    // position: absolute;
    // bottom: 350px;
    color: #000;
    font-size: 38px;
    @media (max-width:991px) {
      bottom: 398px;
    font-size: 30px;
    @media (max-width:640px) {
        bottom: 650px;
        font-size: 22px;
    }
    @media only screen and (min-width:641px) and (max-width:991px){    
      
      bottom:349px;
     
  }
    @media only screen and (min-width:575px) and (max-width:640px) {
      
      bottom: 250px;
      
    }
   
  }
 
   }

   .layout-banners .main_page_headding{
        color:#fff;
   }
`;

export default ContainerWrapper;
