import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query'
import { propertiesApi } from './properties/propertyApi'
import { homeApi } from './properties/homeApi'
import {rootReducer} from './RootReducer'
import {persistStore} from 'redux-persist'
// export const store = configureStore({
//   reducer: {
//     [propertiesApi.reducerPath]: propertiesApi.reducer,
//     [homeApi.reducerPath]: homeApi.reducer,
//   },
//   middleware: (getDefaultMiddelware) => getDefaultMiddelware().concat(propertiesApi.middleware),
//   // devTools: false,
// });
// setupListeners(store.dispatch);
export const store = configureStore({

  reducer: rootReducer,
  //middleware: (getDefaultMiddelware) => getDefaultMiddelware().concat(propertiesApi.middleware),
  middleware: (getDefaultMiddelware) => getDefaultMiddelware().concat(homeApi.middleware).concat(propertiesApi.middleware),
  devTools: process.env.NODE_ENV !== 'production',
});
export const persistor = persistStore(store)
export default store