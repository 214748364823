import { Spin } from 'antd';
import React, { Fragment } from 'react';


const SplashScreen = (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', height: '100vh' }}>
        <div style={{ textAlign: 'center' }}>
            <img
                src="/images/realcube-logo.svg"
                className="logo-foot"
                width="auto"
                height="50px"
                loading="lazy"
                alt="RealCube Logo"
            />
        </div>
        <div style={{ textAlign: 'center', padding: '0px' }}>
            {/* <Spin style={{ filter: 'grayscale(1)' }}/> */}
            <img
                src="/images/splash.gif"
                className="logo-foot"
                height="100" width="100"
                loading="lazy"
                alt="loader"
            />
        </div>
    </div>
)

export default SplashScreen;
