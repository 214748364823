import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next'
import cookies from 'js-cookie'
import { Input, Button, Modal, Row, Col, Checkbox, Form } from 'antd';
import { MainContext } from './../../../constants/MainProvider';
import MakeanOfferWrapper from 'pages/MakeanOffer/MakeAnOffer.style';
import PhoneInput from 'react-phone-input-2';
import CallbackSuccessModal from './CallBackSuccessModal';

const languages = [
    {
        code: 'en',
        name: 'English',
        country_code: 'gb',
    },
    {
        code: 'ar',
        name: 'العربية',
        dir: 'rtl',
        country_code: 'sa',
    },
];
const Callback = (props) => {
    const [isValid, setIsValid] = useState(pi?.isValid ? pi?.isValid : false);
    const [message, setMessage] = useState('');
    const [emailID, setEmailID] = useState(pi?.emailID?.length > 0 ? pi?.emailID : '')
    const [contactNumber, setContactNumber] = useState(pi?.contactNumber?.length > 0 ? pi?.contactNumber : '')
    const { primaryInfos } = props
    const { formErrors } = props
    const pi = primaryInfos?.length > 0 ? JSON.parse(primaryInfos) : ''
    const [customerName, setCustomerName] = useState(pi?.customerName?.length > 0 ? pi?.customerName : '')
    const currentLanguageCode = cookies.get('i18next') || 'en';
    const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
    const { t } = useTranslation();
    const emailRegex = /\S+@\S+\.\S+/;
    const validateEmail = (email) => {
        if (emailRegex.test(email)) {
            setIsValid(true);
            setMessage('');
        } else {
            setIsValid(false);
            setMessage(t('Please enter a valid email!'));
        }
        setEmailID(email)
    };
    const [CallbackModalVisible, setCallbackModalVisible] = useState(false);
    const onHandleCallbackModalClose = () => {
        setCallbackModalVisible(false);
    };
    return (
        <MakeanOfferWrapper>
            <div>
            
                <Modal
                    className='schedule-visit-modal font-18 mb-15'
                    width={562}
                    title={t('EMAIL AGENT')}
                    visible={props.modalStatus}
                    footer={null}
                    onCancel={props.onCancel}
                >
                    <Row>
                        <Col className="gutter-row" lg={24} md={24} xs={24}>
                            <Form
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                initialValues={{ remember: true, ["Email"]: emailID }}
                                autoComplete="off"
                                className="form-design">
                                <Row className="mb-0 tenant-form" gutter={[16, 0]}>
                                    <Col className="gutter-row" lg={12} md={24}>
                                        <Form.Item className="mb-10"
                                            label="Name"
                                            name="Name"
                                            rules={[
                                                { required: true, message: (t('Customer Name Required')) }
                                            ]}
                                        >
                                            <Input placeholder="Name" onChange={(e) => setCustomerName(e?.target?.value)}
                                                value={customerName}
                                                maxLength={30} onKeyPress={(event) => {
                                                    if (!/[a-zA-Z ]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }} />
                                            <p className='text-danger mb-0'>
                                                {formErrors?.customerName}
                                            </p>
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" lg={12} md={24}>
                                        <Form.Item className="mb-10"
                                            label={t("Contact Number")}
                                            name="Number"
                                            rules={[{ required: true, message: (t('Contact Number Required')) }]}
                                        >
                                            <PhoneInput
                                                name="mob"
                                                country={'ae'}
                                                preferredCountries={['ae', 'in', 'us']}
                                                onlyCountries={['ae', 'in', 'us']}
                                                priority={{ ae: 0, in: 1, us: 2 }}
                                                onChange={setContactNumber}
                                                value={contactNumber}
                                                required={true}
                                                autoFocus={true}
                                                enableSearch={true}
                                                enableAreaCodeStretch={true}
                                                defaultErrorMessage={t('mobile_no_error')}
                                                isValid={(value, country) => {
                                                    if (value.match(/12345/)) {
                                                        return 'Invalid value: ' + value + ', ' + country.name;
                                                    } else if (value.match(/1234/)) {
                                                        return false;
                                                    } else if (value.match(/00/)) {
                                                        return false;
                                                    } else {
                                                        return true;
                                                    }
                                                }}
                                            />
                                            <p className='text-danger mb-0'>
                                                {formErrors?.contactNumber}
                                            </p>
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" lg={24} md={24}>
                                        <Form.Item className="mb-10"
                                            label={t("Email ID")}
                                            // name="Email"
                                            rules={[{ required: true, message: (t('Email ID Required')) }]}
                                        >
                                            <Input type="email" placeholder={t("Email ID")} onChange={(e) => validateEmail(e.target.value)} value={emailID} />
                                            <p className='text-danger mb-0'>
                                                {formErrors?.customerName}
                                            </p>
                                        </Form.Item>

                                    </Col>
                                    <Col lg={24} md={24} sm={24} xs={24}>
                                        <Form.Item
                                            label={t('comments_optional')}
                                            className="textarea"
                                        >
                                            <Input.TextArea name="comments"
                                                maxLength={200}
                                                value={'I would like to inquire about your 5 Bedroom Villa in Khalidya Village. Please contact me at your earliest convenience.'} />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={24}>
                                        <div className="form-check form-check-sm custom me-5">
                                            <input className="form-check-input" type="checkbox" id="flexCheckDefault" />
                                            <label className="form-check-label txt-check" htmlFor="flexCheckDefault">
                                                Keep me inform about similar properties
                                            </label>
                                        </div>
                                    </Col>

                                    <Col lg={24} md={24} sm={24} xs={24}>
                                        <div className="d-flex button-sections1">
                                            <Button type="primary" onClick={() => setCallbackModalVisible(true)}>
                                                {t('submit')}
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                                <CallbackSuccessModal
                                    modalStatus={CallbackModalVisible}

                                    onCancel={onHandleCallbackModalClose}
                                />

                            </Form>
                        </Col>
                    </Row>


                </Modal>
            </div>
        </MakeanOfferWrapper>
    );
};

export default Callback;
