import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { MdEmail } from 'react-icons/md';
import { Input, Button, Modal, Row, Col, Form } from 'antd';
import Logo from '../../components/UI/Logo/Logo';
import FormControl from '../../components/UI/FormControl/FormControl';
import axios from 'axios';
import Wrapper, {
  Title,
  TitleInfo,
  FormWrapper,
  BannerWrapper,
} from './Auth.style';
import { APIs } from "library/helpers/apis";
import { useTranslation } from 'react-i18next'
import cookies from 'js-cookie'
import ResendEmail from './SignIn/ResendEmail/ResendEmail';
import * as General from "constants/redux/features/generics/generalStates";
import { useDispatch } from 'react-redux';
import { LeftOutlined } from '@ant-design/icons';
import FloatInput from 'components/FloatInput/FloatInput';
const languages = [
  {
    code: 'en',
    name: 'English',
    country_code: 'gb',
  },
  {
    code: 'ar',
    name: 'العربية',
    dir: 'rtl',
    country_code: 'sa',
  },
];

export default function ForgetPassWord(props) {
  const dispatch = useDispatch()

  const currentLanguageCode = cookies.get('i18next') || 'en';
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();

  const [mailData, setMailData] = React.useState('')
  const [repData, setRepData] = React.useState('')
  const [repErrData, setRepErrData] = React.useState('')
  const [process, setProcess] = React.useState(false);
  const [notVerified, setNotVerified] = React.useState(false);
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: 'onChange',
  });
  const onFinish = (data) => {
    setRepData('')
    setRepErrData('')
    setProcess(true)
    setNotVerified(false)
    setMailData({
      "email": data.email
    })
  };
  useEffect(() => {

    const getData = async () => {
      const resp = await axios.post(`${APIs.URL}/forgotPassword`,

        mailData
      )
      if (resp.data.code === 200 && resp.data.errorCode != 1) {
        setProcess(false)
        setRepData(resp.data.description)
      } else if (resp.data.code === 200 && resp.data.errorCode === 1) {
        setRepErrData(resp.data.errorDescription)
        setProcess(false)
        if (resp?.data?.data?.email_not_verified === 1) {
          dispatch(General.actions.signUpData(mailData?.email))
          setNotVerified(true)
        }
      } else {
        setRepErrData(resp?.data?.errorDescription)
        setProcess(false)
      }
    }
    if (mailData?.email?.length > 0) {
      getData()
    }
  }, [mailData])

  const handleOTPSend = () => {
    props.onCancel()
    props.onOTPModalOpen()
  }

  return (
    <Wrapper>
      <div>
        <Modal
          className="login-popup"
          width={900}
          centered
          visible={props.modalStatus}
          footer={null}
          onCancel={props.onCancel}
        >
          <Row gutter={1}>
            <Col className="gutter-row" span={12}>
              <img
                src="/images/login-bg.png"
                width="664"
                height="844"
                loading="lazy"
                alt="Login Image"
                className="login-image"
              />
              <div className='secure-position'>
                <div className="secure-img">  <img src="/images/flash-circle-new1.png" />{t('Property finding Software')}  </div>
                <div className='makeyr-property'>
                  <img src="/images/register-search-new1.png" /> <br />
                  <p>{t('Best Rented or selling houses in Dubai under one portal')}</p>
                </div>
              </div>
            </Col>
            <Col className="gutter-row" span={12}>
              <div className='p-40-30'>
                <div className="mb-50" onClick={props.onLoginModalOpen}> <LeftOutlined className="fw-boldest" />{t('back')}</div>
                <div class="text-center mb-20">
                  <img
                    src="/images/realcube-logo.svg"
                    width="150"
                    height="27"
                    loading="lazy"
                    alt="RealCube Logo"
                  />
                </div>
                <div className="title-modal">
                  <h2 className="mb-5">{t('forget_yr_password')}</h2>
                  <p className="">{t('enter_your_email_to_recover_your_account')}</p>
                </div>
                <Form
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  initialValues={{ remember: true }}
                  autoComplete="off"
                  onFinish={onFinish}>
                  <Form.Item
                    name="email"
                    rules={[{ required: true, type: 'email', message: t('email_required') }]}>
                    <FloatInput className="mb-10"
                    label={t('email')}
                    name="email"/>
                  </Form.Item>
                  <p className={'text-danger' + (notVerified ? ' mb-0' : '')}>{repErrData} </p>
                  {notVerified ? <p className=''> <ResendEmail onOTPModalOpen={handleOTPSend} /></p> : ''}
                  <p className='text-blue'>{repData}</p>
                  <Button
                    className="signin-btn"
                    type="primary"
                    htmlType="submit"
                    size="large"
                    disabled={process}
                  >
                    {t('send_email')}
                  </Button>
                  <Row>
                    <Col md={24} xs={24} className="text-center gutter-row">
                      <span className="crt-new">
                        {t('create_new')} <span className="c-pointer external-links" onClick={props.onSignUpModalOpen}> {t('sign_up')} </span>
                      </span>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </Modal>
      </div>
    </Wrapper>
  );
}
