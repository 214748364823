import React from 'react';
import { Row, Col } from 'antd';
import Container from "../../../components/UI/Container/Container";
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';

const languages = [
  {
      code: 'en',
      name: 'English',
      country_code: 'gb',
  },
  {
      code: 'ar',
      name: 'العربية',
      dir: 'rtl',
      country_code: 'sa',
  },
];

const Ourmission = () => {
    const currentLanguageCode = cookies.get('i18next') || 'en';
const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
const { t } = useTranslation();
    return (
        <>
            <div className="ourmission-blog align-middle abt-mission mb-50">
            <Container>
                <Row className='abt-exalogic'>
                    <Col lg={24} md={24} xs={24}>
                        <div className='our-mission-head'>
                        <h4>{t('About Exalogic')}</h4>
                        

                        <p>
                        {t('RealCube is a proprietary solution developed by Exalogic Consulting. With close to two decades of expertise in ERP consulting, IT product development and integration, we have grown to become a globally recognized IT consulting firm with presence in America, the Middle East, Europe, and Asia. Our close-knit team of business consultants, engineers and developers has uniquely empowered us to be at the forefront of the exciting new developments in digital technology that promises to revolutionize IT. Our pedigree includes conceptualizing, implementing, and managing the third wave turnkey solutions for some of the largest property management firms in the world.')}

                     </p>
                        </div>
                    </Col>
                    <Col lg={24} md={24} xs={24}>
                        <div className="d-flex align-items-center">
                            <img
                            loading="lazy"
                            src="/images/aboutus/about-exalogic.png"
                            alt="aboutus-img"
                            />
                        </div>
                    </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};
export default Ourmission;

