import React, { useEffect } from 'react';
import zxcvbn from 'zxcvbn';

const PasswordStrengthMeter = ({ password, checkPassword, chk, allProcess }) => {
    const testResult = zxcvbn(password);
    let errorCode = [];
    // console.log(password, ' - ', checkPassword, ' - ', chk);
    const num = testResult.score * 100 / 4;
    validatePassword();
    const createPassLabel = () => {
        if (errorCode?.length > 0) {
            return null;
        }
        switch (testResult.score) {
            case 0:
                return 'Very weak';
            case 1:
                return 'Weak';
            case 2:
                return 'Fair';
            case 3:
                return 'Good';
            case 4:
                return 'Strong';
            default:
                return '';
        }
    }

    const funcProgressColor = () => {
        if (errorCode?.length > 0) {
            return '#FF0000';
        }
        switch (testResult.score) {
            case 0:
                return '#828282';
            case 1:
                return '#EA1111';
            case 2:
                return '#FFAD00';
            case 3:
                return '#9bc158';
            case 4:
                return '#00b500';
            default:
                return 'none';
        }
    }
    function validatePassword() {
        var p = password,
            errors = [];
        if (p.length < 8) {
            errors.push(" least 8 characters");
            errorCode.push(1);
        }
        if (p.search(/[a-z]/) < 0) {
            errors.push(" least one lower case");
            errorCode.push(1);
        }
        if (p.search(/[A-Z]/) < 0) {
            errors.push(" least one upper case");
            errorCode.push(1);
        }
        if (p.search(/[!#$%&?@ ]/) < 0) {
            errors.push(" least one spl char");
            errorCode.push(1);
        }
        if (p.search(/[0-9]/) < 0) {
            errors.push(" least one digit");
            errorCode.push(1);
        }
        if (errors.length > 0) {

            allProcess({ true: false });
            return ("Your password should contain" + errors.join(", \n"));
            return false;
        }
        if (errors.length <= 0) {
            allProcess({ true: true });
        }

        return true;
    }
    const changePasswordColor = () => ({
        width: `${num}%`,
        background: funcProgressColor(),
        height: '4px'
    })
    useEffect(() => {

    }, []);
    return (
        <>
            {password?.length && chk != 1 ? <>
            <div className="progress h-7px">
                <div className="progress-bar" style={changePasswordColor()}></div>
            </div>
            <div style={{ color: funcProgressColor() }}>{createPassLabel()}  
            <p className='text-danger font-11'>{validatePassword()}</p> </div>
                {/* <p style={{ color: funcProgressColor() }}> {testResult?.feedback?.warning}</p> */}
            </> :
                <p className='text-danger font-11'>
                    {checkPassword?.length > 0 && password != checkPassword ? "The two passwords that you entered do not match!" : null}
                </p>
            }
        </>
    )
}

export default PasswordStrengthMeter