import React from 'react';
import Footers from '../../../components/Footer/Footer';

const Footer = ({ path }) => {
    return (
        <Footers />
    );
};

export default Footer;
