import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const HeaderWrapper = styled.header`
  width: 100%;

  .search-button1 {
    min-width: 53px !important;
    min-height: 31px !important;
    padding: 4px 15px !important;
    font-size: 12px !important;
    border-radius: 5px !important;
    font-weight: bold !important;
    color: #000 !important;
    background-color: #fff !important;
    border: 1px solid #d9d9d9 !important;
    &:hover {
      background-color: #F3B521 !important;
      color: #49484c !important;
      border: 1px solid #F3B521 !important;      
      transition: all 0.6s ease-in-out;
    }
    .anticon-search{
      color:#000;
    }
    &:hover{
      color:#000;
    }
  }
  .search-button2 {
    min-width: 53px !important;
    min-height: 31px !important;
    padding: 7px 19px !important;
    font-size: 12px !important;
    border-radius: 5px !important;
    font-weight: bold !important;
    color: #000 !important;
    background-color: #fff !important;
    border: 1px solid #d9d9d9 !important;
    &:hover {
      background-color: #F3B521 !important;
      color: #49484c !important;
      border: 1px solid #F3B521 !important;      
      transition: all 0.6s ease-in-out;
    }
    .anticon-search{
      color:#000;
    }
    &:hover{
      color:#000;
    }
  }
.sticky-outer-wrapper {
    &.isHeaderSticky {
        .sticky-inner-wrapper {
            background-color: #ffffff;
            box-shadow: 0 0 20px rgb(0 0 0 / 10%);
        }
    }
        .sticky-inner-wrapper {
            background-color: #ffffff;
            box-shadow: 0 0 20px rgb(0 0 0 / 10%);
            .is_transparent {
                padding: 0;
                box-shadow: none !important;
                .logo-img {
                  min-width: 50px;
                  @media (max-width:1199px) {
                    min-width: 80px;
                  }
                  @media (min-width:1500px) {
                    min-width: 120px;
                  }
                }
                .login-btn{
                  border-radius: 8px !important;
                  padding: 3px 30px !important;
                }
                .switch-lng {
                  margin-left:22px;
                  border-radius: 25px;
                  padding:0;
                  display:flex;
                  align-items: center;
                  overflow:hidden;
                  margin-right: 10px;
                  font-size:14px;
                  span{
                    padding:5px 15px;
                    min-width: 78px;
                    font-size:13px;
                    &.active{
                      background: #36333D;
                      color: #fff;
                      border-radius: 25px;
                    }
                  }
                }
                .avatar-dropdown{
                    .dropdown-menu{
                        right: 10px;
                    }.topbar-polygon{
                      display: inline-block;
                      position: relative;
                      width:50px;
                      height:50px;
                      background: #F3B521;
                      box-sizing: border-box;
                      -webkit-clip-path: polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%);
                      clip-path: polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%);
                      img{
                       position: absolute;
                       top: 4px;
                       left: 4px;
                       width: 42.95px;
                       height: 42.95px;
                       -webkit-clip-path: polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%);
                       clip-path: polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%);

                      }

                     }
                }
                .main_menu {
    margin-left: 80px;
                    ul {
                        &.ant-menu {
                            .ant-menu-item {
                                    margin: 0 !important;
                                    padding: 0 16px !important;
                                    height: auto;
                                    margin-bottom: 0 !important;
                                    color: #2C2C2C;
                                    font-size: 13px;
                                    line-height: 0;
                                    font-weight: 700;
                                    text-transform: uppercase;
                                    -webkit-transition: color 0.2s ease-in-out;
                                    transition: color 0.2s ease-in-out;
                                    overflow: visible;
                                    @media (max-width: 1200px) {
                                        padding: 0 5px !important;
                                    }
                                  &.callback {
                                    margin-left:24px
                                    padding: 0  !important;
                                    height: auto;
                                    margin-bottom: 0 !important;
                                    color: #2C2C2C;
                                    font-size: 14px;
                                    line-height: 0;
                                    font-weight: 500;
                                    text-transform: initial;
                                    -webkit-transition: color 0.2s ease-in-out;
                                    transition: color 0.2s ease-in-out;
                                }
                                &.about-drop {
                                    margin: 0 !important;
                                    padding: 0  !important;
                                    height: auto;
                                    margin-bottom: 0 !important;
                                    color: #2C2C2C;
                                    font-size: 14px;
                                    line-height: 0;
                                    font-weight: 500;
                                    text-transform: initial;
                                    -webkit-transition: color 0.2s ease-in-out;
                                    transition: color 0.2s ease-in-out;
                                    @media (max-width: 1200px) {
                                        padding: 0 5px !important;
                                    }
                                    
                                }
                               
                                .ant-menu-title-content {
                                    overflow: visible;
                                    padding: 8px 0px;
                                    border-radius: 8px;
                                    .ant-dropdown-link {
                                        padding: 0;
                                        .anticon{
                                    padding-left: 10px;
                                        }
                                    }
                                    a {
                                        padding: 0;
                                        color: #2C2C2C;
                                        font-size: 14px;
                                        font-weight: 500;
                                        text-transform: initial;
                                    }
                                }
                                &.ant-menu-item-selected {
                                    &::after {
                                        border-right: 3px solid transparent;
                                    }
                                }
                            }
                        }
                    }
                }
                .avatar-menu {
                    width: inherit;
    justify-content: space-between;
                    .auth_menu {
                        .ant-menu {
                            .ant-menu-item {
                                .ant-menu-title-content {
                                    .ant-btn-outline-primary {
                                        height: 36px;
                                    }
                                    .ant-btn-primary {
                                      color: #fff;
                                      margin-left: 15px;
                                      background: #49484c;
                                      border-color: #49484c;
                                      border-radius: 5px;
                                      font-size: 15px;
                                      //padding: 2px 23px 4px 16px;
                                      padding: 2px 12px 4px 12px;
                                      line-height: normal;
                                      height: 36px;
                                    }

                                    .login-btn{
                                        width: 120px;
                                    }
                                }
                            }
                        }
                    }
                }
			}
		}
    }

  @media (max-width: 991.98px) {
    .transparent {
      .navbar_search {
        display: none;
      }
    }
  }

  @media (max-width: 667px) {
    nav {
      &:not(.transparent) {
        a {
          > h3 {
            display: none;
          }
        }
      }
    }
    .small-logo {
      img {
        width: 100% !important;
      }
    }
  }
  @media (max-width:991px) {
    .logo-svg{
        display: none;
    }
  }
  @media only screen and (max-width:575.98px) {
    .sticky-outer-wrapper nav {
      padding: 0px 0px;
      background: #fff;
      min-height:52px;
      box-shadow: 0 0 20px rgb(0 0 0 / 10%);
    }
  }

  @media only screen and (min-width: 641px) and (max-width: 991px) {
    .sticky-outer-wrapper nav {
      padding: 0px 0px;
      background: #fff;
      min-height: 52px;
    }
    .small-logo {
      img {
        width: 100% !important;
      }
    }
  }

  .sticky-outer-wrapper {
    nav {
      // padding: 0px 120px;
      // @media (min-width: 1500px) {
      //   padding: 0px 120px;  
      // }
      &.is_transparent {
        > div > div {
          > svg {
            width: 20px;
            position: absolute;
            display: none;
          }

          a {
            img {
              opacity: 1;
              // width: inherit;
            }

            h3 {
              color: ${themeGet('color.1', '#ffffff')};
            }
          }
        }

        .ant-menu:not(.active) {
          li {
            a {
              color: #252525;
              // padding: 18px 8px;
              font-size: 13px;
              font-weight: 700;
              &:hover {
                color: #F3B521 !important;
              }
              &.active {
                color: #F3B521 !important;
                background-color: #EBEEF3;
                border-radius: 5px;
              }
            }
          }
        }
      }

      &.transparent {
        > div > div {
          > svg {
            width: 20px;
            position: absolute;
          }

          a {
            img {
              opacity: 1;
              @media (max-width:991px){
                max-height:30px;
              }
            }

            h3 {
              color: ${themeGet('color.1', '#ffffff')};
            }
          }
        }
      }
    }

    &.isHeaderSticky {
      nav {
        &.is_transparent {
          background-color: ${themeGet('color.1', '#ffffff')};
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

          > div > div {
            > svg {
              display: none;
            }

            a {
              img {
                opacity: 1;
              }

              h3 {
                color: ${themeGet('primary.0', '#008489')};
              }
            }
          }

          .ant-menu {
            li {
              a {
                color: #252525;
//                padding: 18px 10px;
                font-size:13px;
                font-weight: 700;
                &:hover {
                  color: #49484c;
                }
                &.active {
                  color: #252525;
                  background-color: #EBEEF3;
                  border-radius: 5px;
                  padding: 18px 10px;
                }
              }
            }
          }

          .auth_menu {
            .ant-menu {
              li {
                &:last-child {
                  a {
                    color: #252525;
                  }
                }
              }
            }
          }
        }

        &.transparent {
          background-color: ${themeGet('color.1', '#ffffff')};
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

          > div > div {
            > svg {
              display: none;
            }

            a {
              img {
                opacity: 1;
              }

              h3 {
                color: ${themeGet('primary.0', '#008489')};
              }
            }
          }

          .hamburg-btn {
            > span {
              background-color: ${themeGet('text.0', '#2C2C2C')};
            }
          }
        }
      }
    }
    .ant-btn-primary {
      color: #fff;
      margin-left: 24px;
      background: #49484c;
      border-color: #49484c;
      border-radius: 5px;
      font-size: 15px;
      padding: 2px 12px 4px 12px;
      line-height: normal;
      height: 36px;
    }
  }
  span.badge-danger {
    background-color: #fb2323;
    height: 8px;
    width: 8px;
    position: absolute;
    display: block;
    z-index: 20;
    top: 4px;
    right: 12px;
    border-radius: 50%;
  }
  // .about-drop {
  //   i.ant-menu-submenu-arrow {
  //       position: relative;
  //       display: inline-block;
  //       top: inherit;
  //       right: unset;
  //       width: unset;
  //       color: inherit;
  //       transform: inherit;
  //       transition: inherit;
  //       &::before {
  //           position: inherit;
  //           width: inherit;
  //           height: inherit;
  //           background-color: inherit;
  //           border-radius: inherit;
  //           transition: inherit;
  //           content: '';
  //           right: inherit;
  //           top: inherit;
  //           transform: inherit;
  //       }
  //       &::after {
  //         content: "";
  //         background-image: url(/images/arrow-down.png);
  //         background-repeat: no-repeat;
  //         background-position: center;
  //         height: 9px;
  //         width: 11px;
  //         position: absolute;
  //         right: -16px;
  //         top: -8px;
  //         transform: inherit;
  //         background-color: inherit;
  //         border-radius: inherit;
  //       }
  //   }
  //   .ant-menu-submenu-title {
  //       color: #252525 !important;
  //       border: 0 !important;
  //       &:active {
  //           color: #252525;
  //           background-color: #EBEEF3 !important;
  //       }
  //   }
  // }
  .ant-dropdown {
    z-index: 10501;
  }
  .mobile-menu-loggedin {
    padding-bottom: 15px !important;
    &.ant-menu-item-selected::after {
      border-bottom: 0;
    }
    .notif-menu {
      span.badge-danger {
        background-color: #fb2323;
        height: 8px;
        width: 8px;
        position: absolute;
        display: block;
        z-index: 20;
        top: -3px;
        right: 0;
        border-radius: 50%;
      }
    }
  }
`;

export const MobileNavbar = styled.nav`
  display: flex;
  padding: 0 25px;
  align-items: center;
  justify-content: space-between;
  min-height: 70px;
  width: 100%;
  @media(min-width:768px) and (max-width:991.98px){
    // min-height:52px;
  }
  @media (min-width:0px) and (max-width:991.98px) {
    .mobile-container {
      max-width: 100%;
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin: auto;
      padding-left: 30px;
      padding-right: 30px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

@media (min-width: 576px) and (max-width:640px) {
.mobile-container{
  padding-left: 0px !important;
  padding-right: 0px !important;
}
}
  &.default {
    border-bottom: 1px solid ${themeGet('border.3', '#E6E6E6')};
    background-color: ${themeGet('color.1', '#ffffff')};
  }

  .hamburg-btn {
    border: 0;
    padding: 0;
    width: auto;
    height: auto;
    background-color: transparent;
    box-shadow: 0px 0px 4px #fff;

    > span {
      display: block;
      width: 20px;
      height: 2px;
      margin: 4px 0;
      border-radius: 5px;
      background-color: ${themeGet('text.0', '#2C2C2C')};
      transition: all 0.3s ease;
    }

    &:hover,
    &.active {
      background-color: transparent;

      > span {
        width: 23px;
        background-color: ${themeGet('primary.0', '#008489')};
      }
    }

    &::after {
      display: none;
    }
  }

  &.transparent {
    position: fixed;
    z-index: 9999;
    background-color: #ffffff;
    .hamburg-btn {
      > span {
        background-color: ${themeGet('color.1', '#ffffff')};
      }
    }
  }
`;

export const LogoArea = styled.div`
  display: flex;
  align-items: center;

  > a {
    flex-shrink: 0;
    margin-right: 27px;

    @media (max-width: 480px) {
      margin-right: 20px;
    }

    img {
      height: 28px;
      max-width: inherit;
    }
  }
`;

export const CloseDrawer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px 15px 12px;

  @media (max-width: 640px) {
    padding: 5px 8px 6px;
  }
  @media only screen and (min-width: 641px) and (max-width: 991px) {
    padding: 5px 8px 6px;
  }
  > button {
    border: 0;
    padding: 0;
    background-color: transparent;
    font-size: 38px;
    line-height: 1;
    height: auto;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    color: ${themeGet('text.1', '#909090')};
    transition: all 0.3s ease;

    &:hover,
    &:focus {
      outline: 0;
      color: ${themeGet('text.0', '#2C2C2C')};
    }
  }
`;

export const AvatarWrapper = styled.div`
  display: flex;
  padding: 15px 20px;
  align-items: center;
  background-color: ${themeGet('color.2', '#F7F7F7')};
`;

export const AvatarImage = styled.div`
  flex-shrink: 0;
  margin-right: 15px;

  img {
    width: 45px;
    height: 45px;
    overflow: hidden;
    border-radius: 50%;
    object-fit: cover;
  }
`;

export const AvatarInfo = styled.div`
  h3 {
    color: ${themeGet('text.0', '#2C2C2C')};
    font-size: 15px;
    line-height: 18px;
    font-weight: 700;
    margin-bottom: 5px;
  }

  a {
    color: ${themeGet('text.0', '#2C2C2C')};
    font-size: 13px;
    line-height: 16px;
    font-weight: 400;

    &:hover,
    &:focus {
      text-decoration: none;
      outline: 0;
      color: ${themeGet('primary.0', '#008489')};
    }
  }
`;

export const NavbarSearchWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  max-width: 430px;
  width: 100%;
  margin-left: 30px;

  @media (max-width: 1200px) {
    max-width: 320px;
  }

  @media (max-width: 480px) {
    padding-right: 15px;
    box-sizing: border-box;
  }

  .map_autocomplete {
    width: 100%;
  }

  input {
    font-size: 17px;
    font-weight: 700;
    padding-left: 15px;
    padding-right: 30px;
    border-radius: 4px;
    color: ${themeGet('text.0', '#2C2C2C')};
    border: 1px solid ${themeGet('border.0', '#EBEBEB')};
    background-color: ${themeGet('color.2', '#F7F7F7')};
    height: 50px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: all 0.3s ease;
    @media (max-width: 1200px) {
      height: 48px;
    }

    &::placeholder {
      color: rgb(72, 72, 72);
    }

    &:hover,
    &:focus {
      border-color: ${themeGet('primary.0', '#008489')};
    }
  }

  > svg {
    position: absolute;
    z-index: 1;
    top: auto;
    right: 15px;
    width: 20px;
    height: 20px;

    @media (max-width: 480px) {
      right: 25px;
    }
  }
`;

export default HeaderWrapper;
