import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const propertiesApi = createApi({

  reducerPath: 'propertiesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
  }),
  tagTypes: ['Likes'],
  endpoints: (builder) => ({
    getProperties: builder.query({
      query: ({ data }) =>
      ({
        url: 'propertyFilter',
        method: 'POST',
        body:
          data
      }),
      // providesTags: ['Likes'],
    }),
    getPropertiesById: builder.query({
      query: (id) => ({
        url: `propertyDetails/${id}`,
        method: 'GET'
      }),
      // providesTags: ['Likes'],
    }),
    getNearByProperties: builder.query({
      query: (data) => ({
        url: `propertyFilter`,
        method: 'POST',
        body: data
      }),
      // providesTags: ['Likes'],
    }),
    getCommunities: builder.query({
      query: (id) => ({
        url: `property/exclusiveDetails/${id}`,
        method: 'GET'
      }),
      providesTags: ['Likes'],
    }),
    getMyLikes: builder.query({
      query: (token) => ({
        url: `getAllUserLikes`,
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` }
      }),
      providesTags: ['Likes'],
    }),
    postWhistleBlowingDetails: builder.mutation({
      query: (formData) => ({
        url: `whistleblowing/create`,
        method: 'POST',
        body: formData
      })
    }),
    postMyLikes: builder.mutation({
      query: ({ Likes, tkn }) => ({
        url: `myLikes`,
        method: 'POST',
        headers: { Authorization: `Bearer ${tkn}` },
        body: Likes
      }),
      invalidatesTags: ['Likes'],
    })
  }),
});

export const { useGetPropertiesQuery, useGetPropertiesByIdQuery, useGetNearByPropertiesQuery, useGetCommunitiesQuery, useGetMyLikesQuery, usePostWhistleBlowingDetailsMutation, usePostMyLikesMutation } = propertiesApi;