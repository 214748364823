import React, { useState, useEffect } from 'react';
import BannerText from './BannerText/BannerText';
import {
    AboutUsTemplateWrapper,
    BannerWrapper,
    BannerTextWrapper,
} from './AboutUsTemplate.style';

import Container from '../../components/UI/Container/Container';
import { useTranslation } from 'react-i18next'
import cookies from 'js-cookie'
import AboutBlog from './Component/Aboutus';
import OurValues from './Component/Ourvalues';

const languages = [
    {
        code: 'en',
        name: 'English',
        country_code: 'gb',
    },
    {
        code: 'ar',
        name: 'العربية',
        dir: 'rtl',
        country_code: 'sa',
    },
];

const AboutUsTemplate = () => {
    const currentLanguageCode = cookies.get('i18next') || 'en';
    const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
    const { t } = useTranslation();

    useEffect(() => {
        // console.log('Setting page stuff')
        document.body.dir = currentLanguage.dir || 'ltr';
        document.title = t('app_title')
    }, [currentLanguage, t]);
    return (
        <AboutUsTemplateWrapper>
            {/* <BannerWrapper>
                <div>
                    <BannerText
                        content={t('who_we_are')}
                        data={t('about_us')}
                    />
                </div>
            </BannerWrapper> */}
            <section className='aboutus-sec1'>
                <Container>
                    <AboutBlog />
                </Container>
            </section>
            <section className='aboutus-sec2'>
                <OurValues />
            </section>
        </AboutUsTemplateWrapper>
    );
};
export default AboutUsTemplate;
