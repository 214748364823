import React from 'react';
import { Modal, Button } from 'antd';
import { SmileFilled, FrownOutlined } from '@ant-design/icons';
import Heading from 'components/UI/Heading/Heading';
import { useTranslation } from 'react-i18next'
import cookies from 'js-cookie'

const languages = [
    {
        code: 'en',
        name: 'English',
        country_code: 'gb',
    },
    {
        code: 'ar',
        name: 'العربية',
        dir: 'rtl',
        country_code: 'sa',
    },
];

const GeneralModals = (props) => {
    const renderEmoji = () => {
        if (props.emoji === 'sad') {
            return (
            <img
                alt="Notifications"
                src="/images/frown.svg"
                className="position-relative mb-20"
                loading="lazy"
                    height="50"
                    width="50"
            />
//                <FrownOutlined
//                    className='frown-outline'
//                />

            );
        } else {
            return (
            <img
                            alt="Notifications"
                            src="/images/tick-circle.svg"
                            className="position-relative mb-20"
                            loading="lazy"
                    height="50"
                    width="50"
                        />
//                <SmileFilled />
            );
        }
    };
    const renderImg = () => {
        if (props?.img) {
            return (
                <img 
                    class="img-fluid"  
                    src={props?.img} 
                    alt= 'QR' 
                    height="160"
                    width="160"
                />
            );
        } else {
            return (
                ''
            );
        }
    };
    const currentLanguageCode = cookies.get('i18next') || 'en';
    const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
    const { t } = useTranslation();

    return (
        <>
            <Modal
                visible={props.modalStatus}
                width={420}
                centered
                className="success-popup-modal contacted-success"
                onCancel={props.onCancel}
            >
                <div className="text-center">

                    {renderEmoji()}
                    <Heading
                        as="h3"
                        fontWeight="600"
                        className="text-primary font-20 mb-10"
                        content={props.msg}
                    />
                    {renderImg()}
                    <p className="font-12 px-10 success-text mb-30px">
                    </p>
                    <Button className="w-50 mx-auto" type="primary" onClick={props.onCancel}>
                        {t('close')}
                    </Button>
                </div>
            </Modal>
        </>
    );
};

export default GeneralModals;
