import { Button, Select, Form, Input, InputNumber, Space } from 'antd';
import React, { useState } from 'react';
import { Col, Row } from 'antd';
const { TextArea } = Input;

export default function RequestMoreInformation() {
    const [size, setSize] = useState(8);
    const handleChange = (value) => {
        console.log(`selected ${value}`);
      };
    return (
        <>
            <h3 className='title'>Request More Information</h3>
            <Form name="requestForm" autoComplete="off">
            <Row gutter={[16, 16]} className="requestInfo">
                <Col span={12} className=''>
                    <Select
                        defaultValue="Tell me more about this listing"
                        onChange={handleChange}
                        className='w-100'
                        options={[
                            { value: 'Tell me more about this listing', label: 'Tell me more about this listing' },
                            { value: 'Request a showing', label: 'Request a showing' },
                        ]}
                    />
                </Col>
                <Col span={12} className='gutter-row'>
                    <Input placeholder="Name" className='input-cus'/>
                </Col>
                <Col span={12} className='gutter-row'>
                    <Input placeholder="Email" className='input-cus'/>
                </Col>
                <Col span={12} className='gutter-row'>
                    <Input placeholder="Phone" className='input-cus'/>
                </Col>
                <Col span={12} className='gutter-row'>
                    <TextArea rows={4} placeholder="" className='textarea-cus'/>
                </Col>
                <Col span={24} className='text-end'>
                    <Button type="primary" htmlType="submit">Submit</Button>
                </Col>
            </Row>
            </Form>
        </>
    )
}