export default (state, action) => {
	switch (action.type) {
		case 'PROFILE_DETAILS_REQUEST':
			return {
				...state,
				loading: true
			}
		case 'PROFILE_DETAILS_SUCCESS':
			return {
				loading: false, profileDetails: action.payload.data
			}
		case 'PROFILE_DETAILS_FAIL':
			return {
				loading: false, error: action.payload
			}
		default:
			return state;
	}
}