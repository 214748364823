import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Menu, Dropdown } from 'antd';
import { Row, Col, Divider } from 'antd';
import {
  HOME_PAGE,
  LISTING_POSTS_PAGE,
  // WHO_WE_ARE,
  // WHAT_WE_DO,
  // HOSPITALITY,
  // MEDIA_CENTER,
  // PRESS_RELEASE,
  // PICTURE_GALLERY,
  // VIDEO_GALLERY,
  // MEDIA_QUERIES,
  // MARKETPLACE_LIST_PAGE,
  // CONTACT_US_PAGE,
  // WHISTLE_BLOWING_PAGE,
  PROPERTY_LIST,
  AGENT_PROFILE_PAGE,
  PROPERTY_DETAILS_PAGE,
  AGENT_ACCOUNT_SETTINGS_PAGE

} from '../../../setup/constant';
import { DownOutlined } from '@ant-design/icons';
import { MainContext } from 'constants/MainProvider';
import { getExclusivePropertyFilterData, getCommunities } from 'library/helpers/axiosClient';
import LogoutModal from '../../Auth/Logout/Logout';
import { useTranslation } from 'react-i18next'
import cookies from 'js-cookie'
import i18next from 'i18next'

const languages = [
    {
        code: 'en',
        name: 'English',
        country_code: 'gb',
    },
    {
        code: 'ar',
        name: 'العربية',
        dir: 'rtl',
        country_code: 'sa',
    },
];

const MobileMenu = ({ className }) => {
  const currentLanguageCode = cookies.get('i18next') || 'en';
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();

  const [dataList, setDataList] = useState([]);
  const [logoutModalVisible, setLogoutModalVisible] = useState(false);

  const onHandleLogoutModalOpen = () => {
    setLogoutModalVisible(true);
  };

  const onHandleLogoutModalClose = () => {
    setLogoutModalVisible(false);
  };

  useEffect(() => {
    // setDataList([]);
    getCommunities().then((data) => {
      setDataList(data);
    });
  }, []);
  let navigate = useNavigate();
  const { SubMenu } = Menu;
  // auth context
  const { loggedIn, logOut } = useContext(MainContext);

  const aboutMenu = (
    <Menu className="inner-menu-about">
      {/* <Menu.Item key="0">
        <NavLink
          className=""
          to={WHO_WE_ARE}
        >
            {t('who_we_are')}
        </NavLink>
      </Menu.Item>
      <Menu.Item key="1">
        <NavLink
          className=""
          to={WHAT_WE_DO}
        >
            {t('what_we_do')}
        </NavLink>
      </Menu.Item> */}
    </Menu>
  );
  const ContactMenu = (
    <Menu className="inner-menu-about">
      {/* <Menu.Item key="0">
        <NavLink
          className=""
          to={WHISTLE_BLOWING_PAGE}
        >
            {t('whistleblowing')}
        </NavLink>
      </Menu.Item> */}
    </Menu>
  );
  const PropertiesMenu = (
    <Menu className="inner-menu-about prop-menu-list">
      {dataList?.data && dataList?.data?.length > 0
        ? dataList?.data?.map((item, index) => {
          var Pname = "";
          if (item.community) {
            Pname = item.community.replace(/\s+/g, "-").toLowerCase();
          }
          return (<
            Menu.Item key="0">
            <NavLink
              className=""
              to={`property-details/${Pname}/${item.id}`}
            // {`${PROPERTY_DETAILS_PAGE}/${item.id}`}
            >
              {item.community}
            </NavLink>
          </Menu.Item>
          )
        })
        : null}
    </Menu>
  );
  const mediaCenterMenu = (
    <Menu className="inner-menu-about">
      {/* <Menu.Item key="0">
        <NavLink
          className=""
          to={PRESS_RELEASE}
        >
            {t('press_release')}
        </NavLink>
      </Menu.Item>

      <Menu.Item key="1">
        <NavLink
          className=""
          to={PICTURE_GALLERY}
        >
            {t('picture_gallery')}
        </NavLink>
      </Menu.Item>

      <Menu.Item key="2">
        <NavLink
          className=""
          to={VIDEO_GALLERY}
        >
            {t('video_gallery')}
        </NavLink>
      </Menu.Item>

      <Menu.Item key="3">
        <NavLink
          className=""
          to={MEDIA_QUERIES}
        >
            {t('media_query')}
        </NavLink>
      </Menu.Item> */}
    </Menu>
  );

  return (
    <>
    <Menu className={className}>
      <Menu.Item key="0">
        <NavLink to={HOME_PAGE}>{t('home')}</NavLink>
      </Menu.Item>
      {/* <Menu.Item key="1" className="about-drop">
        <Dropdown overlay={aboutMenu} trigger={['click']}>
          <span
            className="ant-dropdown-link"
            onClick={e => e.preventDefault()}
          >
            {t('about_us')}
            <DownOutlined />
          </span>
        </Dropdown>
      </Menu.Item>
      <Menu.Item key="2" className="about-drop">
        <Dropdown overlay={PropertiesMenu} trigger={['click']}>
          <span
            className="ant-dropdown-link"
            onClick={() => { navigate(PROPERTY_LIST) }}
          >
            {t('properties')}
            <DownOutlined />
          </span>
        </Dropdown>
      </Menu.Item>
      <Menu.Item key="3">
        <NavLink
          className=""
          to={HOSPITALITY}
        >
            {t('hospitality')}
        </NavLink>
      </Menu.Item>

      <Menu.Item key="4" className="about-drop">
        <Dropdown overlay={mediaCenterMenu} trigger={['click']}>
          <span
            className="ant-dropdown-link"
            onClick={() => { navigate(MEDIA_CENTER) }}
          >
            {t('media_center')}
            <DownOutlined />
          </span>
        </Dropdown>
      </Menu.Item>

      {loggedIn && (
        <Menu.Item key="5">
          <NavLink
            className=""
            to={MARKETPLACE_LIST_PAGE}
          >
              {t('market_place')}
          </NavLink>
        </Menu.Item>
      )}
      <Menu.Item key="6" className="about-drop">
        <Dropdown overlay={ContactMenu} trigger={['click']}>
          <span
            className="ant-dropdown-link"
            onClick={() => { navigate(CONTACT_US_PAGE) }}
          >
            {t('contact_us')}
            <DownOutlined />
          </span>
        </Dropdown>
      </Menu.Item> */}
      {/* <Menu.Item key="7">
        <NavLink
          className=""
          to={LISTING_POSTS_PAGE}
        >
            {t('property_search')}
        </NavLink>
      </Menu.Item> */}
      {loggedIn && (
        <Menu.Item key="8">
          <button
            // onClick={logOut}
            onClick={() => setLogoutModalVisible(true)}
          >
              {t('logout')}
          </button>
        </Menu.Item>
      )}
    </Menu>
      <LogoutModal
          modalStatus={logoutModalVisible}
          onCancel={onHandleLogoutModalClose}
      />
    </>
  );
};

export default MobileMenu;
