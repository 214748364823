import Container from '../../components/UI/Container/Container';
import { Button, Col, Input, Row } from "antd";
import SapLandingWrapper from "./SapLanding.style";
import RegisterInterstModal from './sap_landing_details/RegisterInterstModal';
import { Link } from 'react-router-dom';


export default function Footer() {
    // const showModal = () => {
    //     setIsModalOpen(true);
    // };
    // const [isModalOpen, setIsModalOpen] = useState(false);
    // const showModal = () => {
    //     setIsModalOpen(true);
    // };

    // const handleOk = () => {
    //   setIsModalOpen(false);
    // };
  
    // const handleCancel = () => {
    //   setIsModalOpen(false);
    // };
    return (
        <>
        <SapLandingWrapper>
            <div className="banner-footer">
                <Container className="container">
                   <Row>
                        
                        <Col className="gutter-row margin-top text-white" span={24}>
                            <h1 className="fw-bolder txt text-white font-46 mb-40 max-w-txt">Easiest way to Find your Dream Home.</h1>
                            <div>
                                <p className="mb-40 font-18">DUBAI'S LATEST OFF-PLAN PROJECTS ALL IN THE ONE PLACE</p>
                            </div>
                            <Col className="gutter-row my-3" lg={8} >
                                <Link
                                
                                    to={'/marketing-details'}
                                    className="w-100 get-started"
                                >
                                   ENQUIRE NOW
                                </Link>

                            </Col>
                        </Col>
                        
                   </Row>
                </Container>
            </div>
        </SapLandingWrapper>
         {/* <Modal title="Register Your Interest" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer=''>
            <RegisterInterstModal/>
        </Modal> */}
        </>
        
    )
}