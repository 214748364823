import React from "react";
import { Row, Col } from "antd";
import Container from "../../../components/UI/Container/Container";
import Ourmission from "./Ourmission";
import Ourvaluesnew from "./Ourvaluesnew";
import Ourvision from "./Ourvision";
import Aboutexalogic from "./Aboutexalogic";
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';
const languages = [
  {
      code: 'en',
      name: 'English',
      country_code: 'gb',
  },
  {
      code: 'ar',
      name: 'العربية',
      dir: 'rtl',
      country_code: 'sa',
  },
];
const OurValues = () => {
  const currentLanguageCode = cookies.get('i18next') || 'en';
const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
const { t } = useTranslation();
  return (
    <>
      <div className="ourvalues mb-50">
        <Container>
          <Row gutter={[24, 0]} className="d-flex align-items-center">
            <Col lg={8} md={8} xs={24}>
              <a className="d-flex align-items-center aboutus-tabs" href='#componentToScrollTo2'>
                <div>
                  <img
                    loading="lazy"
                    src="/images/aboutus/our-mission.png"
                    alt="aboutus-img"
                  />
                  <span> {t('Our Mission')} </span>
                </div>
              </a>
            </Col>
            <Col lg={8} md={8} xs={24}>
              <a className="d-flex align-items-center aboutus-tabs" href='#componentToScrollTo1'>
              <div>
                <img
                  loading="lazy"
                  src="/images/aboutus/our-value.png"
                  alt="aboutus-img"
                />
                <span> {t('Our Values')} </span>
                </div>
              </a>
            </Col>
            <Col lg={8} md={8} xs={24}>
              <a className="d-flex align-items-center aboutus-tabs" href='#componentToScrollTo'>
              <div>
                <img
                  loading="lazy"
                  src="/images/aboutus/our-vision.png"
                  alt="aboutus-img"
                />
                <span> {t('Our Vision')} </span>
                </div>
              </a>
            </Col>
          </Row>
        </Container>
      </div>

      <Ourmission />
      <Ourvaluesnew/>
      <Ourvision/>
      <Aboutexalogic/>
    </>
  );
};
export default OurValues;
