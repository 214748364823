import React, { useContext, useState, useEffect } from "react";
import { Row, Col, Input, Select, Form } from "antd";
import { useNavigate } from "react-router-dom";
import { MainContext } from "constants/MainProvider";
import { useTranslation } from 'react-i18next'
import cookies from 'js-cookie'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import startsWith from 'lodash.startswith';
// import 'react-phone-input-2/lib/bootstrap.css'

const languages = [
	{
		code: 'en',
		name: 'English',
		country_code: 'gb',
	},
	{
		code: 'ar',
		name: 'العربية',
		dir: 'rtl',
		country_code: 'sa',
	},
];

const { Option } = Select;

const CreateScheduleVisit = (props) => {
	const currentLanguageCode = cookies.get('i18next') || 'en';
	const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
	const { t } = useTranslation();
	const { logOut, user, loggedIn: logged } = useContext(MainContext);
	const { userInfo } = user;
	const { getFormData } = props;
	const { formErrors } = props;
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const [customerType, setCustomerType] = useState(userInfo?.id_role === 4 || userInfo?.id_role === 7 ? "1" : userInfo?.id_role === 6 || userInfo?.id_role === 5 ? "" : "2");
	const initialValues = {
		customerType: customerType,
		org: userInfo?.id_role && (userInfo?.id_role !== 6 && userInfo?.id_role !== 5) ? userInfo?.first_name : '',
		firstName: userInfo?.id_role && (userInfo?.id_role !== 6 && userInfo?.id_role !== 5) ? userInfo?.first_name : '',
		mName: "",
		lastName: userInfo?.id_role && (userInfo?.id_role !== 6 && userInfo?.id_role !== 5) ? userInfo?.last_name != "" ? userInfo?.last_name : null : '',
		email: userInfo?.id_role && (userInfo?.id_role !== 6 && userInfo?.id_role !== 5) ? userInfo?.email : '',
		mob: userInfo?.id_role && (userInfo?.id_role !== 6 && userInfo?.id_role !== 5) ? userInfo?.phone : '',
		comments: "",
	};
	const [formValues, setFormValues] = useState(initialValues);
	const [phone, setPhone] = useState();
	getFormData(formValues);
	const onChangeCustomerType = (type) => {
		setCustomerType(type);
		setFormValues((state) => ({
			...state,
			customerType: type,
		}));
	};
	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormValues({ ...formValues, [name]: value });
	};
	const handleChangePhone = (e) => {
		const { name, value } = e.target;
	};
	useEffect(() => {
		setFormValues({ ...formValues, ['mob']: phone?.length > 0 ? phone : formValues.mob });
	}, [phone]);
	return (
		<>
			<Form
				labelCol={{ span: 24 }}
				wrapperCol={{ span: 24 }}
				layout="vertical"
				autoComplete="off"
				className="form-design"
			>
				<Row gutter={30}>
					{(userInfo?.id_role === 6 || userInfo?.id_role === 5) ?
						<Col lg={24} md={24} xs={24}>
							<Form.Item
								className="select-contact mb-0"
								label={t('customer_type')}
								name="customer_type"
								rules={[{ required: true, message: t('customer_tye_error') }]}
								required>
								<Select getPopupContainer={(triggerNode) => { return triggerNode.parentNode; }} value={customerType} onChange={onChangeCustomerType} placeholder={t('please_select')} className="w-100" allowClear>
									<Option value=""> {t('select')} </Option>
									<Option value="1"> {t('individual')} </Option>
									<Option value="2"> {t('org')} </Option>
								</Select>
								<p
									className={`${formErrors.customerType ? "text-danger" : ""}`}
								>
									{formErrors.customerType}
								</p>
							</Form.Item>
						</Col>
						:
						<Col
							lg={24} md={24} sm={24} xs={24}
							className=""
						>
							<Form.Item
								className="select-contact mb-0"
								label={t('customer_type')}
								rules={[
									{ required: true, message: t('customer_tye_error') },
								]}
								required
							>
								<Select
									getPopupContainer={(triggerNode) => {
										return triggerNode.parentNode;
									}}
									defaultValue={customerType}
									onChange={onChangeCustomerType}
									placeholder={t('select')}
									className="w-100"
									// allowClear
									name="customerType"
									disabled
								>
									<Option value="0"> {t('select')} </Option>
									<Option value="1"> {t('individual')} </Option>
									<Option value="2"> {t('Company')} </Option>
								</Select>
								<p
									className={`${formErrors.customerType ? "text-danger" : ""}`}
								>
									{formErrors.customerType}
								</p>
							</Form.Item>
						</Col>
					}

					{customerType != "0" ? <>
						{customerType === "2" ? (
							<Col lg={12} md={12} sm={24} xs={24}>
								<Form.Item
									label={t('Company Name')}
									rules={[
										{
											required: true,
											message: t('org_error'),
										},
									]}
									required
									className="mb-0"
								>
									<Input name="org"

										value={formValues.org}
										onChange={handleChange}
										maxLength={50}
										onKeyPress={(event) => {
											if (!/[a-zA-Z+]/.test(event.key)) {
												event.preventDefault();
											}
										}} />
									<p
										className={`${formErrors.org ? "text-danger" : ""}`}
									>
										{formErrors.org}
									</p>
								</Form.Item>
							</Col>
						) : (
							<>
								<Col lg={12} md={12} sm={24} xs={24}>
									<Form.Item
										label={t('first_name')}
										rules={[
											{
												required: true,
												message: t('first_name_error'),
											},
										]}
										required
										className="mb-0"
									>
										<Input
											name="firstName"
											value={formValues.firstName}
											onChange={handleChange}
											maxLength={50}
											onKeyPress={(event) => {
												if (!/[a-zA-Z+]/.test(event.key)) {
													event.preventDefault();
												}
											}}
										/>
										<p
											className={`${formErrors.firstName ? "text-danger" : ""}`}
										>
											{formErrors.firstName}
										</p>
									</Form.Item>
								</Col>
								{/*<Col lg={12} md={12} sm={24} xs={24}>
									<Form.Item
										label={t('middle_name')}

										className="mb-0"
									>
										<Input name="mName" onChange={handleChange}
											maxLength={50}
											onKeyPress={(event) => {
												if (!/[a-zA-Z+]/.test(event.key)) {
													event.preventDefault();
												}
											}} />
										<p></p>
									</Form.Item>
								</Col>*/}
								<Col lg={12} md={12} sm={24} xs={24}>
									<Form.Item
										label={t('last_name')}
										rules={[
											{
												required: true,
												message: t('first_name_error'),
											},
										]}
										required
										className="mb-0"										
									>
										<Input
											name="lastName"
											value={formValues.lastName}
											onChange={handleChange}
											maxLength={50}
											onKeyPress={(event) => {
												if (!/[a-zA-Z+]/.test(event.key)) {
													event.preventDefault();
												}
											}}
										/>
										<p
											className={`${formErrors.lastName ? "text-danger" : ""}`}
										>
											{formErrors.lastName}
										</p>
									</Form.Item>
								</Col>
							</>
						)}
						<Col lg={12} md={12} sm={24} xs={24}>
							<Form.Item
								label={t('email_id')}
								rules={[
									{
										type: "email",
										required: true,
										message: t('email_id_error'),
									},
								]}
								required
								className="mb-0"
							>
								<Input
									name="email"
									value={formValues.email}
									onChange={handleChange}
								/>
								<p
									className={`${formErrors.email ? "text-danger" : ""}`}
								>
									{formErrors.email}
								</p>
							</Form.Item>{" "}
						</Col>
						<Col lg={12} md={12} sm={24} xs={24}>
							<Form.Item
								label={t('mobile_no')}
								rules={[
									{
										required: true,
										message: t('mobile_no_error'),
									},
								]}
								required
								className="mb-0"
							>
								{/* <Input
									value={formValues.mob} name="mob" onChange={handleChange} />
								<p>
									{formErrors.mob}
								</p> */}
								<PhoneInput
									name="mob"
									country={'ae'}
									preferredCountries={['ae', 'in', 'us']}
									onlyCountries={['ae', 'in', 'us']}
									priority={{ ae: 0, in: 1, us: 2 }}
									value={formValues?.mob?.length > 0 ? formValues?.mob : phone}
									onChange={setPhone}
									required={true}
									autoFocus={true}
									enableSearch={true}
									enableAreaCodeStretch={true}
									defaultErrorMessage={t('mobile_no_error')}
									isValid={(value, country) => {
										if (value.match(/12345/)) {
											return 'Invalid value: ' + value + ', ' + country.name;
										} else {
											return true;
										}
									}}
								/><p
									className={`${formErrors.mob ? "text-danger" : ""}`}
								>
									{formErrors.mob}
								</p>
							</Form.Item>
						</Col>
						<Col lg={24} md={24} sm={24} xs={24}>
							<Form.Item
								label={t('comments_optional')}
								className="textarea"
							>
								<Input.TextArea name="comments" onChange={handleChange}
									maxLength={200} />
							</Form.Item>
						</Col></> : null}
				</Row>
			</Form>
		</>
	);
};

export default CreateScheduleVisit;
