import React, { useState, useContext, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom'
import { Menu, Button } from 'antd';
import { Popover } from 'antd';
import { Input } from 'antd';
import { LOGIN_PAGE, REGISTRATION_PAGE } from '../../../setup/constant';
import { SearchOutlined } from '@ant-design/icons';
import LoginModal from '../../Auth/SignIn/SignIn';
import SignUpModal from '../../Auth/SignUp/SignUp';
import EmailConfirmationModal from '../../Auth/EmailConfirmationModal/EmailConfirmationModal';
import ForgotPasswordModal from '../../Auth/ForgetPassword';
import InboxModal from '../../InboxModal/InboxModal';
import PaymentModal from '../../PaymentModal/PaymentModal';
import ScheduleModal from '../../ScheduleVisit/ScheduleVisit';
import SignatureModal from '../../ContactView/SignatureCanvas/SignatureCanvas';
import PaymentSuccessModal from '../../PaymentSuccessModal/PaymentSuccessModal';
import { MainContext } from '../../../constants/MainProvider';
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import cookies from 'js-cookie'
import VerifyOTP from 'library/helpers/VerifyOTP';

const languages = [
    {
        code: 'en',
        name: 'English',
        country_code: 'en',
    },
    {
        code: 'ar',
        name: 'العربية',
        dir: 'rtl',
        country_code: 'ar',
    },
]
const AuthMenu = ({ className }) => {
    let navigate = useNavigate();
    const currentLanguageCode = cookies.get('i18next') || 'en'
    const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
    const { t } = useTranslation()

    const { loggedIn } = useContext(MainContext);

    const [loginModalVisible, setLoginModalVisible] = useState(false);
    const [signUpModalVisible, setSignUpModalVisible] = useState(false);
    const [emailConfirmationModalVisible, setEmailConfirmationModalVisible] = useState(false);
    const [emailOTPModalVisible, setEmailOTPModalVisible] = useState(false);
    const [forgotModalVisible, setForgotModalVisible] = useState(false);
    const [inboxModalVisible, setInboxModalVisible] = useState(false);
    const [paymentModalVisible, setPaymentModalVisible] = useState(false);
    const [scheduleModalVisible, setScheduleModalVisible] = useState(false);
    const [signatureModalVisible, setSignatureModalVisible] = useState(false);
    const [paymentSuccessModalVisible, setPaymentSuccessModalVisible] = useState(
        false
    );

    const onHandleLoginModalClose = () => {
        setLoginModalVisible(false);
    };

    const onHandleSignUpModalClose = () => {
        setSignUpModalVisible(false);
        // setLoginModalVisible(true);
    };

    const onHandleSignUpModalOpen = () => {
        setLoginModalVisible(false);
        setEmailConfirmationModalVisible(false);
        setForgotModalVisible(false);
        setSignUpModalVisible(true);
    };

    const onHandleLoginModalOpen = () => {
        setLoginModalVisible(true);
        setSignUpModalVisible(false);
        setForgotModalVisible(false);
    };
    const onHandleOTPModalOpen = () => {
        setEmailOTPModalVisible(true);
        setSignUpModalVisible(false);
        setLoginModalVisible(false);
    };
    const onHandleEmailConfirmationModalOpen = () => {
        setEmailConfirmationModalVisible(true);
        setSignUpModalVisible(false);
    };

    const onHandleEmailConfirmationModalClose = () => {
        setEmailConfirmationModalVisible(false);
    };
    const onHandleEmailOTPModalClose = () => {
        setEmailOTPModalVisible(false);
    };
    const onHandleForgotModalClose = () => {
        setForgotModalVisible(false);
        // setLoginModalVisible(true);
    };

    const onHandleForgotModalOpen = () => {
        setLoginModalVisible(false);
        setForgotModalVisible(true);
    };

    const onHandlePaymentModalOpen = () => {
        setInboxModalVisible(false);
        setPaymentModalVisible(true);
    };

    const onHandleScheduleModalOpen = () => {
        setInboxModalVisible(false);
        setScheduleModalVisible(true);
    };

    const onHandleSignatureModalOpen = () => {
        setInboxModalVisible(false);
        setSignatureModalVisible(true);
    };
    const onHandlePaymentSuccessModalOpen = () => {
        setPaymentModalVisible(false);
        setPaymentSuccessModalVisible(true);
    };

    const onHandlePaymentModalClose = () => {
        setPaymentModalVisible(false);
    };

    const onHandleSignatureModalClose = () => {
        setSignatureModalVisible(false);
    };

    const onHandleScheduleModalClose = () => {
        setScheduleModalVisible(false);
    };

    const onHandlePaymentSuccessModalClose = () => {
        setPaymentSuccessModalVisible(false);
    };

    const onHandleInboxModalClose = () => {
        setInboxModalVisible(false);
    };

    const { Search } = Input;

    const [keyword, setKeyword] = useState("");
    const onSearch = (value) => {
        setKeyword(value);
        const datas = {
            keyword_search: value,
        };
        if (window.location.pathname != '/properties-listing') {
            navigate(`properties-listing/`, { state: { type: 'search', data: datas } });
        } else {
            navigate('/', { replace: true });
            setTimeout(() => {
                navigate('properties-listing', { state: { type: 'search', data: datas } });
            }, 2000);
        }
    }
    // useEffect(() => {
    //     console.log(keyword);
    // }, [keyword]);

    // const searchBox = (
    //     <div>
    //         {/*<Search placeholder="input search text" onSearch={onSearch} enterButton />*/}
    //         <Search
    //             placeholder={t('keyword_search')}
    //             allowClear
    //             htmlType="submit"
    //             enterButton={t('search')}
    //             className="headder_keyword_search"
    //             size="large"
    //             onSearch={onSearch}
    //         />
    //     </div>
    // );

    // useEffect(() => {
    //     console.log('Setting page stuff')
    //     document.body.dir = currentLanguage.dir || 'ltr'
    //     document.title = t('app_title')
    // }, [currentLanguage, t])
    return (
        <Menu className={className}>
            {/* <Menu.Item key="0" className="search-box">
                <Popover placement="left" overlayClassName='header-drop' content={searchBox} trigger="click">
                    <Button type="outline-primary" className="header-search-btn">
                        <SearchOutlined />
                    </Button>
                </Popover>
            </Menu.Item> */}
            <Menu className='search-box'>
                        <Menu.Item ClassName='header-drop'>
                            <NavLink to="/global-search" className="search-button1">
                                <SearchOutlined />
                            </NavLink>
                        </Menu.Item>
                </Menu>
                        
             <Menu.Item key="1">
                <Button className='switch-lng' onClick={() => {
                    i18next.changeLanguage(currentLanguage.dir ? "en" : "ar")
                }}>
                    <span className={currentLanguage.code == "ar" ? "active" : ""}>عربی</span>
                    <span className={currentLanguage.code == "en" ? "active" : ""}>English</span>
                    {/* {currentLanguage.dir ? 'English' : 'عربی'} */}
                </Button>
            </Menu.Item>
            <Menu.Item key="0" className="rounded-5">
                <Button type="primary" className="login-btn" onClick={() => setLoginModalVisible(true)}>
                    {/*Login*/}
                    {t('login')}
                </Button>
            </Menu.Item>
            {loggedIn && (
                <Menu.Item
                    key="2"
                    className="notif-menu" onClick={() => setInboxModalVisible(true)}
                >
                    <img
                        alt="Notifications"
                        src="/images/bell-icon.webp"
                        className="position-relative"
                        loading="lazy"
                        width="16"
                        height="20"
                    />
                    <span className="badge-danger"></span>
                </Menu.Item>
            )}

            {/* <Menu.Item key="3" className=''> */}
                {/* <div
                    className="beta-text"
                >
                    <h6>{t("Beta version")}</h6>
                    <p>{t("Price or availability is not applicable")}</p>
                </div> */}
            {/* </Menu.Item> */}

            <LoginModal
                modalStatus={loginModalVisible}
                onCancel={onHandleLoginModalClose}
                onSignUpModalOpen={onHandleSignUpModalOpen}
                onForgotModalOpen={onHandleForgotModalOpen}
                onOTPModalOpen={onHandleOTPModalOpen}
                onEmailConfirmationModalOpen={onHandleEmailConfirmationModalOpen}
            />
            <SignUpModal
                modalStatus={signUpModalVisible}
                onCancel={onHandleSignUpModalClose}
                onLoginModalOpen={onHandleLoginModalOpen}
                onOTPModalOpen={onHandleOTPModalOpen}
                onEmailConfirmationModalOpen={onHandleEmailConfirmationModalOpen}
            />
            <EmailConfirmationModal
                modalStatus={emailConfirmationModalVisible}
                onCancel={onHandleEmailConfirmationModalClose}
                onSignUpModalOpen={onHandleSignUpModalOpen}
            />
            <VerifyOTP
                modalStatus={emailOTPModalVisible}
                onCancel={onHandleEmailOTPModalClose}
                onSignUpModalOpen={onHandleSignUpModalOpen}
            />
            <ForgotPasswordModal
                modalStatus={forgotModalVisible}
                onCancel={onHandleForgotModalClose}
                onSignUpModalOpen={onHandleSignUpModalOpen}
                onLoginModalOpen={onHandleLoginModalOpen}
            />
            <InboxModal
                modalStatus={inboxModalVisible}
                onCancel={onHandleInboxModalClose}
                onPaymentModalOpen={onHandlePaymentModalOpen}
                onScheduleModalOpen={onHandleScheduleModalOpen}
                onSignatureModalOpen={onHandleSignatureModalOpen}
            />
            <PaymentModal
                modalStatus={paymentModalVisible}
                onCancel={onHandlePaymentModalClose}
                onPaymentSuccessModalOpen={onHandlePaymentSuccessModalOpen}
            />
            <PaymentSuccessModal
                modalStatus={paymentSuccessModalVisible}
                onCancel={onHandlePaymentSuccessModalClose}
            />
            <ScheduleModal
                modalStatus={scheduleModalVisible}
                onCancel={onHandleScheduleModalClose}
            />
            <SignatureModal
                modalStatus={signatureModalVisible}
                onCancel={onHandleSignatureModalClose}
            />
        </Menu>
    );
};

export default AuthMenu;
