export default (state, action) => {
	switch (action.type) {
		case 'USER_REGISTER_REQUEST':
			return {
				loading: true,
                registerationInfo: {},
                error: null
			}
		case 'USER_REGISTER_SUCCESS':
			return {
				loading: false,
                registerationInfo: action.payload,
                error: null
			}
		case 'USER_REGISTER_FAIL':
			return {
				loading: false,
                registerationInfo: {},
                error: action.payload
			}
		default:
			return state;
	}
} 