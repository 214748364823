import styled, { keyframes } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
const SuccessModalWrapper = styled.div`
.success-popup-modal {
        .ant-modal-body {
            padding:24px 24px 0px;
        }
        .ant-modal-close-x {
            display: none;
        }
        .ant-modal-footer {  
            display: none; 
        }
      .ant-btn-primary {
            background-color: #49484c;
            border: 1px solid #49484c;
            color: #ffffff;
            font-size: 14px;
            border-radius: 5px;
            padding: 9px 20px;
            cursor: pointer;
            margin:20px 0px 0px;
            height:46px;
            width: 50%;
            margin: auto;
            display: block;
            text-align: center;
            &:hover {
                background-color: #ffffff;
                border: 1px solid #49484c;
                color: #49484c;
                transition: all 0.6s ease-in-out;  
            }
         }      
             }
             `;
export default SuccessModalWrapper;