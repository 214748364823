import { createGlobalStyle } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const GlobalStyle = createGlobalStyle`
.align-self-end{
    align-self: flex-end;
}
html body .ant-breadcrumb{
    padding: 11px 0;
}
.cat-search{
    @media (max-width: 991.98px)  {
        padding:10px 0 !important;
    }
}
.upcoming-badge {
    top: 10px;
    left: 10px;
    span {
        background-color: rgba(0,0,0,0.6);
        color: #fff;
        display: inline-block;
        padding: 4px 7px;
        border-radius: 5px;
        font-weight: 400;
        font-size: 13px;
    }
}
p.prop-delivery-date {
    font-size: 11px;
    margin-bottom: 3px;
    background-color: #e3e1f2;
    padding: 3px 6px;
    font-weight: 400;
    border-radius: 30px;
    height: min-content;
}
.terms-condition-popup{
  .breadcrumb{display: none !important;}
  .privecy-txt {
      font-size: 22px !important;
  }
  .blg-details1{
      img{
      height: 50px;
      width: 50px;
      margin-right: 10px;
      }
  }
  .prop-overview {
      margin-top:0px !important;
     .padding_left{
           padding-left: 20px !important;
           .mb-25 {
               margin-bottom: 15px !important;
           }
       }

      h1,h2,h3,h4,h5,h6 {
          font-size: 16px !important;
          line-height: 22px !important;
      }
      p {
          font-size: 14px !important;
          line-height: 1.5 !important;
      }
    }

}

.ant-tabs-tab:hover {
    color:#292a2d;
}
.ant-btn:hover, .ant-btn:focus {
    color: #292a2d;
    border-color: #292a2d;
}
.radius-10{
    border-radius:10px;
}
.pad-30{
    padding:30px 0;
}
.or-condition {
    text-align: center;
    margin-bottom: 20px;
    font-weight: 500;
}
.signup-uaepass {
    a {
        background: #F7F7F7;
        border-radius: 4.67083px;
        display: block;
        padding: 10px 18px;
        text-align: center;
        color: rgba(53, 50, 50, 0.8);
        margin-bottom: 17px;
        img{
            margin-right: 8px;
        }
    }
}
.secure-position {
    position: absolute;
    bottom: 0;
    padding: 40px;
    .secure-img {
        position: relative;
        background-color: #fff;
        border-radius: 50px;
        padding: 7px;
        min-width: 210px;
        font-size: 13px;
        display:inline-block;
        line-height: 17px;
        img {
            margin-right: 4px;
        }
    }
    .makeyr-property {
        background: rgba(0, 0, 0, 0.14);
        backdrop-filter: blur(16.718px);
        border-radius: 11.1453px;
        padding: 14px 16px;
        margin-top: 20px;
        display: inline-block;
        max-width: 250px;
        p {
            line-height: initial;
            margin: 10px 0 0;
            color: #fff;
        }
    }
}
.steps-width-full {
	.ant-space {
		width: 100%;
		justify-content: flex-end;
		.ant-space-item {
			width: 50%;
			button {
				display: block;
				width: 100%;
			}
			&:last-child {
				padding-left: 6px;
			}
			&:first-child {
				padding-right: 6px;
			}
		}
	}
}

.view-all-properties {
         text-align: right;
    margin-top: 0;
    padding-top: 20px;
    z-index: 1;
    position: relative;
    &:before {
       border-top: 1px solid #F6F3F3;
    content: "";
    position: absolute;
    top: 30px;
    left: 0;
    width: calc(100% - 110px);
    display: block;
    opacity: 1;
    height: 30px;
    z-index: -1;
    }
    a {
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #FFFFFF;
        position: relative;
        display: inline-block;
        padding-left: 30px;
    }
}
.ant-list-split .ant-list-item:last-child {
    border-right: none !important;
}
.buy{
    max-width:68px;
    @media only screen and (max-width: 1920px) and (min-width: 1366px){
        max-width:90px;
    }
}

.react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus,
.react-calendar__navigation button:disabled ,.react-calendar__tile:disabled,.react-calendar__tile--now,
.schedule-visit-modal .ant-modal-content .react-calendar .react-calendar__viewContainer .react-calendar__month-view .react-calendar__month-view__days .react-calendar__tile:enabled:hover,
.schedule-visit-modal .ant-modal-content .react-calendar .react-calendar__viewContainer .react-calendar__month-view .react-calendar__month-view__days .react-calendar__tile:enabled:hover,.schedule-visit-modal .ant-modal-content .react-calendar .react-calendar__viewContainer .react-calendar__month-view .react-calendar__month-view__days .react-calendar__tile.react-calendar__tile--active{
    background: transparent;
    height: 40px;
    padding: 5px !important;
}

.schedule-visit-modal .ant-modal-content .react-calendar .react-calendar__viewContainer .react-calendar__month-view .react-calendar__month-view__days .react-calendar__tile{
    padding: 5px !important;
}
.schedule-visit-modal .ant-modal-content button.react-calendar__tile.react-calendar__month-view__days__day:disabled abbr{
   background: #ECECEC;
    opacity: .8;
}
.schedule-visit-modal .ant-modal-content .react-calendar .react-calendar__viewContainer .react-calendar__month-view .react-calendar__month-view__days .react-calendar__tile abbr{
    width: 30px !important;
    height: 30px !important;
    line-height: 30px !important;
    color: #49484c !important;
    font-weight: 500 !important;
}
.schedule-visit-modal .ant-modal-content .react-calendar .react-calendar__tile--active abbr{
width: 30px !important;
    height: 30px !important;
    line-height: 30px !important;
    color: #49484c !important;
    border: 1px solid  #F3B521;
    background-color:#F3B521;
    font-weight: 500 !important;
}
button.react-calendar__tile.react-calendar__tile--now abbr {
    border: 1px solid  #F3B521;
    background: #f8d47f;
}
.schedule-visit-modal{
    .react-calendar__navigation {
        button {
            &:nth-child(2) {
                order: 3;
            }
            &:nth-child(4) {
                order: 3;
            }
            &:nth-child(3) {
                order: 1;
            }
        }
    }
}

.slot-wrap{
    flex-wrap:wrap;
    margin:0 -6px;

.time-newslot {
	padding: 6px;
	.time-slot-item {
		background: rgba(255, 255, 255, 0.95);
    box-shadow: 0 0.3rem 0.6rem rgba(0,0,0,.15)!important;
		backdrop-filter: blur(40px);
		border-radius: 8px;
		padding: 8px 10px;
        &.slick-current{
            background-color: #FFF3DE;
        }
		h3 {
			margin: 0;
			font-size: 12px;
		}
	}
}

}
.residential{
    max-width:108px;
    @media only screen and (max-width: 1920px) and (min-width: 1366px){
        max-width:125px;
    }
}
.property{
    max-width:115px;
    @media only screen and (max-width: 1920px) and (min-width: 1366px){
        max-width:132px;
    }
}
.amenity{
    max-width:116px;
    @media only screen and (max-width: 1920px) and (min-width: 1366px){
        max-width:124px;
    }
}
.bread-cum-home{
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    font-size:20px;
}
.bread-cum-home:hover{
    text-decoration: underline;
    color: #F3B521;
}
.bread-cum-blog{
    color: rgba(0,0,0,.46);
    font-weight: 600;
    font-size:20px;
}
.bread-cum-blog:hover{
    text-decoration: underline;
    color: #F3B521;
}
.NewsDetails p {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #636363;
}
.blog-card{
//    height:390px;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.090)!important;
    margin-bottom: 20px;
    // @media only screen and (max-width: 1920px) and (min-width: 1366px){
    //     height: 427px;
    // }
}
.popural_post_head{
    border-bottom: 1px solid #d7dadc;
}
.mb-blg {
    border-bottom: 1px solid #787e81;
    margin-bottom: 44px;
}
.heading-blg {
    font-size: 36px;
    color: #000;
    font-weight: 600;
    line-height: 43px;
    margin-bottom: 43px;
}
.head-details-blog {
    font-weight: 600;
    font-size: 30px;
    line-height: 45px;
    color: #181818!important;
    margin-top: 55px;
    margin-bottom: 26px;
}
.popural_post_img{
    img{
        max-height:185px;
    }
}
.blg-head {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #181818!important;
    padding: 20px 20px 10px 20px;
    height: 100px;
    text-overflow: ellipsis;
}
.blg-details1 {
    display: flex;
    // padding: 0px 20px 0px 20px;
}
.blg-details {
    display: flex;
    padding: 0px 20px 0px 20px;
}
.blg-date {
    color: #181818!important;
    font-weight: 400;
    padding-left: 10px;
    padding-top: 12px;
}
.custom-tab-ant {
    .ant-tabs-nav {
        margin-bottom: 0;
        &:before {
            display: none;
        }
    }
    .ant-tabs-tab {
        padding: 12px 30px;
        height: auto;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        background-color: #292A2D;
        margin: 0;
        color: #fff;
        &.ant-tabs-tab-active {
            background-color: #fff;
            .ant-tabs-tab-btn {
                color: #000000;
                text-shadow: initial;
            }
        }
    }
    .ant-tabs-nav-list {
    border-top-left-radius: 8px;
    overflow: hidden;
    border-top-right-radius: 8px;
}
    .ant-tabs-ink-bar {
        background: #F3B625;
        height: 3px !important;
        max-width: 100%;
        margin: 0 auto;
    }
}
.mainlogo-mobile{
    @media (max-width:991.98px)
    {
        max-width:100px;
    }

}
.mb-30{
    margin-bottom:50px;
}
.card-layout-new{
    // box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 8px;
    overflow: hidden;
    .video-sec  {
        box-shadow: 4px 0px 4px 0px #00000040;
        border-radius: 15px;
    }
    .card-layout-body {
        padding: 24px 0px;
        height: 82px;
        h6{
            font-size: 18px;
            font-weight: 700;
            line-height: 21px;
            letter-spacing: 0px;
            color: #000000;
            display: block;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: inherit;
            margin-bottom: 0;
        }
        p {
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0px;
            color: #767676;
          }
    }
}
html body .title-home {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
}
.align-items-center{
    align-items:center;
}
@media (max-width:420.98px) {
    .mobile-container{
        padding-left: 25px !important;
        padding-right: 25px !important;
    }
}
.inner-menu-about{
    @media only screen and (max-width: 991.98px){
        display: block;
        margin: 0 26px !important;
    }
}
.inner-menu-about.prop-menu-list{
    @media only screen and (max-width: 991.98px){
        max-height: 150px;
        overflow-y: scroll;
    }
}
.tooltip-search {
    .ant-tooltip-inner {
        font-size: 11px;
        font-weight: 600;
    }
}
.am-item {
    margin-left: 3px;
}
 .avatar-menu {
    .auth_menu {
        .ant-menu-title-content {
            .ant-btn-primary {
            @media only screen and (max-width: 1199.98px) and (min-width: 992px){
              padding: 2px 16px 4px 16px;
              }
            }
          }
      }
  }

.react-multiple-carousel__arrow--right {
right: -7px;
}
.react-multiple-carousel__arrow--left {
left: -7px;
}

.beta-text {
    cursor: inherit;
    /* display: flex; */
    flex-direction: column;
    align-items: center;
    /* position: absolute !important; */
    /* right: -116px; */
    flex-wrap: wrap;
    white-space: normal;
    width: 110px;
    margin-left: 13px !important;
}
.beta-text.after-login {
    margin-left: 5px !important;
}
.beta-text h6 {
    font-size: 12px;
    line-height: inherit;
    font-weight: 500;
    color: #000;
    margin-top: 16px;
    margin-bottom: 9px;
}
.beta-text p {
    font-size: 10px;
    font-weight: 500;
    color: #000;
    white-space: normal;
    height: inherit;
    line-height: 13px;
}

@media (max-width:420.98px) {
    .price-value span.min-text {
        font-size: 11px;
    }
    .price-value > span.min-text.pl-5 {
        padding-left: 2px;
    }
}
@media only screen and (max-width: 767.98px){
    .ant-pagination {
        justify-content: center !important;
    }
    .font-res-16 {
        font-size: 16px !important;
    }
}

.sticky-outer-wrapper.header-sticky-list{
  @media only screen and (max-width: 575.98px){
      height: 500px;
      }
    .sticky-inner-wrapper {
    @media only screen and (max-width: 575.98px){
        transform: inherit !important;
    }
    }
}

.ant-popover {
	.header-drop {
		.ant-popover-placement-left {
			@media only screen and (max-width: 767.98px){
            max-width:285px !important;
            }
        }
    }
    @media only screen and (max-width: 767.98px){
        left: 210px;
    }
    @media only screen and (max-width: 640.98px){
        left: 0;
        padding-right: 0;
    }
    .ant-popover-content{
        .ant-input-group-wrapper{
            @media only screen and (max-width: 767.98px){
                max-width:250px !important;
            }
        }
    }
}
.ant-popover-placement-left, .ant-popover-placement-leftTop, .ant-popover-placement-leftBottom{
    @media only screen and (max-width: 575.98px){
        padding-right: 5px;
    }
}
.ant-popover-placement-left .ant-popover-arrow{
    @media only screen and (max-width: 575.98px){
        display:none;
    }
}

// .ant-dropdown-menu{
//     box-shadow: none !important;
// }
.carousel-tab .ant-tabs-nav-wrap {
      @media only screen and (max-width: 767.98px){
        overflow-x: auto;
        overflow-y: hidden;
    }

      .ant-tabs-nav-list {
        @media (max-width: 767.98px) {
          display: flex !important;
          margin: 0 auto !important;
          width: 800px !important;
        }
    }
}
.ant-tabs-nav .ant-tabs-nav-operations {
    @media only screen and (max-width: 767.98px){
        display: none !important;
    }
}
.text-md-left {
   @media (min-width: 768px) {
        text-align: left !important;
   }
}
.text-md-right {
    @media (min-width: 768px) {
        text-align: right !important;
    }
}
.ant-tabs-nav-wrap::-webkit-scrollbar {
    display: none;
}
.ShareDetails{
    .ant-dropdown-link{
        @media (max-width:575.98px){
            margin-left: 0 !important;
        }
    }
}

.now_leasing{
    p {
      @media (max-width: 1200px) {
        font-size:16px;
      }
      @media (max-width: 991.98px) {
        font-size:15px;
      }
  }
.ant-breadcrumb {
    @media (max-width:767.98px){
    padding: 15px 0px;
    }
}

.ant-breadcrumb {
      padding:25px 0px;
      @media (max-width:767.98px) {
        padding: 15px 0;
      }
    .ant-breadcrumb-link,
    .back-breadcrumb-link {
        @media (max-width:991.98px){
            font-size: 14px !important;
        }
        @media (max-width:576.98px){
            font-size: 13px !important;
        }
    }
 }

@media (min-width: 576px) and (max-width:640px) {
.mobile-container{
  padding-left: 0px !important;
  padding-right: 0px !important;
}
}
@media (min-width: 768px) and (max-width:991px) {
.video-sec .poster{
        max-height: 200px;
        min-height: 200px;
        height:200px;
    }
}
 .prop-location {
         .ant-btn-primary {
            @media (max-width: 480px) {
             padding: 9px 10px !important;
            }
        }
        .btn-outline
            @media (max-width: 480px) {
             padding: 9px 10px !important;
            }
        }
 }
.login-popup .ant-alert-message{
    font-size:12px;
    text-align:center;
 }


.resent-emaildata {
    display: flex;
    width: 100%;
    margin-top: 13px;
    align-items: center;
    justify-content: center;
    span {
        border: 1px solid #dbdbdb;
        padding: 6px 12px;
        border-radius: 4px;
    }
}

.verify-otp-popup {
    h3 strong{
        font-weight:500;
    }
    .ant-modal-close{
        top: 15px !important;
        right:13px !important;
    }
    .ant-modal-header{
       background-color: #fff !important;
       .ant-modal-title{
        color: #000 !important;
       }
    }
    .otp-img{
        margin-bottom: 20px;
    }
    input[type="tel"] {
        border-radius: 8px;
        border: 1px solid #ECECEC;
        background: #ECECEC;
        min-width: 36px;
        min-height: 52px;
        margin-bottom: 8px;
        &:focus-visible {
            outline: -webkit-focus-ring-color auto 0 !important;
        }
    }
    .verify-otp-popup button {
        padding: 8px 10px;
        font-size: 12px;
        border: 1px solid #49484C;
        border-radius: 8px;
        color: #000;
        background-color: transparent;
        margin-left:12px;
    }
    .digit-box {
        button{
            border: none;
            background: transparent;
            font-size: 16px;
            text-decoration: underline;
            color: #656565;
            font-weight: 400;
            cursor: pointer;
        }
    }
    .otp_resend{
        display:block !important;
        span{
            font-size: 16px;
            color: #656565;
            font-weight: 400;
        }
    }
}
* {
box-sizing: inherit;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
&::before,
&::after {
  box-sizing: inherit;
}
}
@media only screen and (min-width:641px) and (max-width:991px){
    .login-popup{
        // top:-135px !important;
    }

}
@media(max-width:767px){
          .d-flex.button-sections{
            flex-wrap:wrap;
          }
        }

  ::selection {
    background: ${themeGet('primary.0', '#49484c')};
    color: ${themeGet('color.1', '#ffffff')};
  }
a:hover {
  color: #49484c;
}
.link_wrapper {
    > a:hover {
        color: #49484c;
    }
}
.bg-transparent {background-color: transparent !important;}
.rounded-5 {border-radius: 5px;}
.rounded-10 {border-radius: 10px;}
.d-block {display: block;}
.logo-foot {width: 85px !important;}
.mt-8 {margin-top: 8px;}
.mt-9 {margin-top: 9px !important;}
.my-20 {margin-top: 20px;margin-bottom: 20px;}
.pl-0 {padding-left: 0 !important;}
.ml-0 {margin-left: 0 !important;}
.pl-10 {padding-left: 10px;}
.pl-5 {padding-left: 5px;}
.pr-10 {padding-right: 10px;}
.pr-25 {padding-right: 25px;}
.pr-15 {padding-right: 15px;}
.pt-10 {padding-top: 10px;}
.px-10 {padding: 0 10px;}
.pb-10 {padding-bottom: 10px;}
.mt-0 {margin-top: 0 !important;}
.mb-0 {margin-bottom: 0 !important;}
.mt-10 {margin-top: 10px;}
.ml-10 {margin-left: 10px;}
.mt-15 {margin-top: 15px !important;}
.mr-10 {margin-right: 10px;}
.mr-0 {margin-right: 0 !important;}
.mr-15 {margin-right: 15px;}
.mr-5 {margin-right: 5px;}
.ml-8 {margin-left: 8px;}
.mr-18 {margin-right: 18px;}
.mb-0 {margin-bottom: 0 !important;}
.mt-20 {margin-top: 20px;}
.mt-30 {margin-top: 30px;}
.mt-40 {margin-top: 40px;}
.mt-50 {margin-top: 50px;}
.mt-auto {margin-top: auto;}
.mb-10 {margin-bottom: 10px !important;}
.mb-6 {margin-bottom: 6px !important;}
.mb-8 {margin-bottom: 8px !important;}
.mb-15 {margin-bottom: 15px !important;}
.mb-5 {margin-bottom: 5px !important;}
.mt-5 {margin-top: 5px !important;}
.mb-20 {margin-bottom: 20px;}
.mb-25 {margin-bottom: 25px !important;}
.mb-30 {margin-bottom: 30px !important;}
.mb-50 {margin-bottom: 50px;}
.pt-20 {padding-top: 20px;}
.pt-30 {padding-top: 30px;}
.pb-20 {padding-bottom: 20px;}
.pr-30 {padding-right: 30px;}
.w-100 {width: 100% !important;}
.w-80 {width: 80%;}
.m-0 { margin: 0 !important; }
.p-0 { padding: 0 !important; }
.my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}
.my-auto {
    margin-top: auto;
    margin-bottom: auto;
}
.opacity-0{
    opacity:0;
}
.right-properties-section {
    // background-image: url(/images/right-properties-section-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: scroll;
    padding-top: 50px;
    padding-bottom: 30px;
    height: 100%;
}
.ant-image-preview-operations {
    top: 52px;
}
.mt-mob-0 {
    @media  only screen and (max-width:575.98px) {
        margin-top: 0 !important;
    }
}
.border-top {
    border-top: 1px solid #dee2e6;
}
.share-prop {
    margin-right: 10px;
    height: 25px;
    width: 21px;
}
.img-fluid {
    max-width: 100%;
    height: auto;
}
.border-light {
    border: 1px solid #f0f0f0;
}
.c-no-drop {
    cursor: no-drop !important;
}
.like-dislike {
    padding-left: 8px;
    cursor: auto;
}
.frown-outline {
    color: red;
    margin-bottom: 10px;
    font-size: 40px;
}
.payment-cancel{
    color: #ffc952 !important;
}
.payment-wrong{
    color: #a52a2a !important;
}
@media only screen and (max-width: 991.98px){
    .mobile-notif-menu {
        span.ant-menu-title-content {
            span.badge-danger {
                background-color: #fb2323;
                height: 8px;
                width: 8px;
                position: absolute;
                display: block;
                z-index: 20;
                top: -3px;
                right: 0;
                border-radius: 50%;
            }
        }
    }
}
.mw-inherit {max-width: inherit !important;}
.text-center {text-align: center;}
.text-danger {color: #ff4d4f !important;}
.text-blue {color: #49484c;}
.text-red {color: red;}
.text-black {color: #000000;}
.text-white {color: #FFFFFF;}
.text-gray {color: #9e9292;}
.bg-white {background-color: #FFFFFF;}
.border-gray {border-color: #9e9292 !important;}
.tooltip { z-index: 111151 !important; position: relative; }
.text-right {text-align: right;}
.border-danger {border-color: red !important;}
.border-blue {border-color: #49484c !important;}
.float-right {float: right;}
.red-style {font-size: 16px;color: red;margin-bottom: 20px;}
.cursor-pointer {cursor: pointer;}
.space-between {justify-content: space-between;}
.font-11 {font-size: 11px;}
.font-12 {font-size: 12px;}
.font-15 {font-size: 15px;}
.font-18 {font-size: 18px;}
.font-40 {font-size: 40px;}
.flex-css {display:flex;align-items: center;}
.d-flex { display: flex; }
.flex-column { flex-direction: column; }
.d-none { display: none; }
.justify-content-between { justify-content: space-between; }
.justify-content-center { justify-content: center; }
.justify-content-end { justify-content: end; }
.ant-modal-header {border-radius: 15px 15px 0 0;}
.border-0 {border:none !important;}
.border-right-0 {border-right: 0 !important;}
.position-relative {position: relative;}
.position-absolute {position: absolute;}
.c-pointer {cursor: pointer;}
.text-uppercase {text-transform: uppercase;}
.h-100 {height:100%;}
.lh-20 {line-height: 20px;}
.top-50px {
    top: 50px !important;
}
.top-75px {
    top: 75px !important;
}
.top-25px {
    top: 25px !important;
}
.h-7px {
    height: 7px !important;
}
.h-74px {
    height: 74px !important;
}
.h-33px {
    height: 33px !important;
}

.w-auto{width: auto  !important;}
.w-50{width: 50%  !important;}
.mx-auto{ margin-right: auto !important; margin-left: auto !important;}
.d-inline-block {
    display: inline-block !important;
}
.ant-tooltip {
    z-index: 9999999;
}
.view-all-theme {
    font-size:14px;
    color: #363636 !important;
    @media  only screen and (max-width:575.98px) {
        font-size: 14px;
    }
    .anticon{
        margin-left:8px;
    }
}
.p-home-text {
    color: #707070 !important;
    font-size: 15px;
    font-weight: 400;
    @media  only screen and (max-width:575.98px) {
        font-size: 14px;
    }
}
.verify-otp-popup {
    .ant-modal-header .ant-modal-title {
        @media  only screen and (max-width:575.98px) {
            font-size: 17px !important;
        }
    }
    .ant-modal-content .ant-modal-close {
        @media  only screen and (max-width:575.98px) {
            top: 15px !important;
            right: 12px !important;
        }
    }
    .ant-modal-body {
        padding: 25px !important;
        @media  only screen and (max-width:575.98px) {
            padding: 18px !important;
        }
        h3 {
            font-weight: 400 !important;
            font-size: 16px !important;
            margin-bottom: 20px !important;
            margin-top: 0 !important;
            @media  only screen and (max-width:575.98px) {
                font-size: 13px !important;
            }
        }
        .digit-box {
            margin-bottom: 20px;
            input.ant-input {
                height: 45px;
                width: 45px;
                border-radius: 10px;
                background-color: #f5f5f5;
                border: 0;
                margin: 0 8px;
                box-shadow: none;
                outline: 0;
                text-align: center;
                font-size: 18px;
                font-weight: 600;
            }
        }
        p {
            font-size: 14px !important;
            @media  only screen and (max-width:575.98px) {
                margin-bottom: 5px;
            }
        }
        .ant-btn {
            padding: 6px 25px !important;
            font-size: 14px !important;
            margin-top: 5px !important;
        }
        .ant-btn-outline {
            margin: 5px 12px !important;
            background-color: #ffffff !important;
            border: 1px solid #49484c !important;
            color: #49484c !important;
            span {
                color: #49484c !important;
            }
            &:hover,
            &:focus {
                background-color: #49484c !important;
                border: 1px solid #49484c !important;
                color: #ffffff !important;
                -webkit-transition: all 0.6s ease;
                transition: all 0.6s ease;
                span {
                    color: #ffffff !important;
                }
            }
        }
    }
}
.logout-popup {
max-width: 460px;
    .ant-modal-header .ant-modal-title {
        @media  only screen and (max-width:575.98px) {
            font-size: 17px !important;
        }
    }
    .ant-modal-content .ant-modal-close {
        @media  only screen and (max-width:575.98px) {
            top: 15px !important;
            right: 12px !important;
        }
    }
    .ant-modal-body {
        padding: 25px !important;
        @media  only screen and (max-width:575.98px) {
            padding: 18px !important;
        }
        h1 {
            font-weight: 500 !important;
            font-size: 20px !important;
            margin-bottom: 10px !important;
            margin-top: 0 !important;
            @media  only screen and (max-width:575.98px) {
                font-size: 17px !important;
            }
        }
        p {
            font-size: 14px !important;
            @media  only screen and (max-width:575.98px) {
                margin-bottom: 5px;
            }
        }
        .ant-btn {
            padding: 6px 25px !important;
            font-size: 14px !important;
            margin-top: 5px !important;
        }
        .ant-btn-outline {
//            margin: 5px 12px !important;
            background-color: #ffffff !important;
            border: 1px solid #49484c !important;
            color: #49484c !important;
            span {
                color: #49484c !important;
            }
            &:hover,
            &:focus {
                background-color: #49484c !important;
                border: 1px solid #49484c !important;
                color: #ffffff !important;
                -webkit-transition: all 0.6s ease;
                transition: all 0.6s ease;
                span {
                    color: #ffffff !important;
                }
            }
        }
    }
}
.header-drop.ant-popover-placement-left {
    @media  only screen and (max-width:575.98px) {
        left: inherit !important;
        bottom: inherit !important;
        margin: 0 0 0 38px;
        right: 0;
        top: 98px !important;
        .ant-input-group .ant-input {
            font-size: 13px;
        }
        .ant-btn-primary {
            font-size: 13px;
            padding: 9px 10px;
        }
    }
}
.bg-grey-home {
    background-color: #F5F5F5;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-top: 30px;
    padding-bottom: 40px;
}
.card-index-styles {
    border-radius: 200px;
    height: 120px;
    width: 120px;
    background-color: #F8FAF5;
    text-align:center;
    margin: 0 auto 20px;
}
.no-data-found-img {
    img {
        max-width: 350px;
        filter: grayscale(1);
        @media only screen and (max-width: 767.99px) {
            max-width: 200px;
        }
    }
    @media only screen and (max-width: 767.99px) {
        flex-direction: column;
        width: 100%;
    }
}
.card-index-styles.cancel {
    i.close.icon {
        color: #ffc952;
    }
    h1 {
        color: #ffc952;
    }
}
.card-index-styles.declined {
    i.close.icon {
        color: #a52a2a;
    }
    h1 {
        color: #a52a2a;
    }
}
.ant-image-preview-wrap {
    z-index: 10001 !important;
}
.ant-image-preview-operations {
    background: rgba(0, 0, 0, 0.5) !important;
    height: 70px;
    z-index: 10002 !important;
}
.verify-email{
    h1{
        margin: 0 !important;
        font-weight: 700 !important;
        font-size: 23px;
        color: #000;
    }
    h2{
        margin: 0 !important;
        font-weight: 400 !important;
        font-size: 19px !important;
        color: #000;
    }
}
.virtual-tour-modal {
    top: 5px;
    .ant-modal-content {
        border-radius:5px;
        height: 100vh;
        width: 100%;
        margin: 0;
        top: 0;
        .ant-modal-header {
            border-bottom: 1px solid transparent;
            padding: 25px 45px 0;
            .ant-modal-title {
                color:#3D3D3D;
                margin-bottom:0;
                font-size: 18px;
            }
        }
        .ant-modal-close {
            right: 16px;
            top: 19px;
            @media (max-width: 767.98px){
                right: 5px;
                top: 5px;
            }
            .ant-modal-close-x {
                .anticon  {
                    font-size: 18px;
                    color:#fff;
                }
            }
        }
        .ant-modal-body {
            padding: 10px 10px 0;
            border-radius: 5px;
            iframe {
                height: 97vh;
            }
            .ant-tabs {
                .ant-tabs-nav {
                    &::before {
                        border-bottom: 0;
                    }
                }
                .ant-tabs-nav-operations {
                    @media  only screen and (max-width:575.98px) {
                        display: none;
                    }
                }
                .ant-tabs-nav-wrap {
                    background-color: #f5f5f5;
                    border-radius: 10px;
                    padding: 0 5px;
                    @media  only screen and (max-width:575.98px) {
                      overflow-x: auto;
                      overflow-y: hidden;
                    }
                    .ant-tabs-nav-list {
                        justify-content: space-between;
                        width: 100%;
                        @media  only screen and (max-width:575.98px) {
                          width: 600px;
                        }
                        .ant-tabs-tab {
                            margin: 5px 5px 5px 0;
                            padding: 7px 0;
                            background: transparent;
                            border: 0;
                            color: #000000;
                            font-weight: 400;
                            font-size: 15px;
                            width: 100%;
                            height: 40px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            @media  only screen and (max-width:575.98px) {
                              margin: 5px 11px 5px 0;
                              padding: 7px 12px;
                            }
                            &.ant-tabs-tab-active,
                            &.ant-tabs-tab-active:active,
                            &.ant-tabs-tab-active:focus {
                                color: #ffffff;
                                font-weight: 400;
                                background-color: #49484c;
                                border-radius: 9px;
                                border-bottom: 0;
                                .ant-tabs-tab-btn,
                                .ant-tabs-tab-btn:hover,
                                .ant-tabs-tab-btn:focus,
                                .ant-tabs-tab-btn:active {
                                    color: #ffffff;
                                    text-shadow: none;
                                }
                            }
                            &:hover {
                                color: #ffffff;
                                background-color: #49484c;
                                border-radius: 9px;
                            }
                            &:nth-child(2) {
                                margin-right: 0 !important;
                                @media  only screen and (max-width:575.98px) {
                                  margin-right: 11px !important;
                                }
                            }
                        }
                        .ant-tabs-ink-bar {
                            background: inherit;
                        }
                    }
                }
            }
            .contact-info-form {
                padding: 0 10px 0 25px;
                .prop-box {
                    img {
                        height: 100%;
                        min-height: 90px;
                        max-height: 100px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin: 0 auto 15px;
                    }
                }
                .property-name,
                .agent-name {
                    font-size: 14px;
                    color: #222;
                    font-weight: 400;
                    span {
                        font-weight: 600
                    }
                }
                .prop-code {
                    font-size: 14px;
                    color: #767676;
                    font-weight: 400;
                }
            }
            .form-design {
                .form-sec-title {
                    color: #536475;
                    font-size: 18px;
                    font-weight: 400;
                }
                .ant-form-item-label {
                    padding-bottom: 5px;
                     label {
                        font-size: 13px;
                        color: #536475;
                        &.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
                            display: inline-block;
                            margin-right: 4px;
                            color: #ff4d4f;
                            font-size: 14px;
                            font-family: 'Poppins', sans-serif !important;
                            line-height: 1;
                            content: '*';
                        }
                        &.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
                            display: inherit;
                            margin-right: inherit;
                            color: inherit;
                            font-size: inherit;
                            font-family: inherit;
                            line-height: inherit;
                            content: '';
                        }
                     }
                }
                .ant-form-item-control {
                    .ant-select {
                        .ant-select-selector {
                            position: relative;
                            background-color: #fff;
                            border: 1px solid #CBD6E2;
                            border-radius: 5px;
                            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
                            align-items: center;
                            height: 46px;
                        }
                        &.ant-select-focused {
                            .ant-select-selector {
                                box-shadow: none;
                            }
                        }
                    }
                    .ant-input {
                        background-color: #fff;
                        background-image: none;
                        border: 1px solid #cbd6e2;
                        border-radius: 5px;
                        transition: all 0.3s;
                        height: 46px;
                        &:hover {
                            border-color: #49484c;
                        }
                        &:focus {
                            box-shadow: none;
                            border-color: #49484c;
                        }
                        &.ant-picker-focused {
                            box-shadow: none;
                            border-color: #49484c;
                        }
                    }
                }
                .ant-form-item.textarea {
                    .ant-input {
                        height: auto;
                    }
                }
                .ant-form-item
                    .ant-upload {
                        width: 100%;
                    }
                }
            }
        }
    }
}
.payment-success {
    img {
        margin-bottom: 22px;
        height: 70px;
    }
    span.anticon {
        margin-bottom: 22px;
        font-size: 60px;
        &.anticon-info-circle,
        &.anticon-exclamation-circle {
            color: #49484c;
        }
        &.anticon-close-circle {
            color: #ff4d4f;
        }
    }
    h3 {
        color: #49484c;
        font-size: 24px;
    }
    p {
        margin-bottom: 30px;
        font-weight: 600;
        font-size: 14px;
        color: #707070;
    }
    .ant-btn-primary {
        padding: 5px 50px;
    }
}
.ant-spin-dot-item {
    background-color: #49484c;
}
.ant-pagination {
    //margin: 20px 0;
    margin: 6px 29px 20px 0;
    display: flex;
    justify-content: end;
    .ant-pagination-item-active {
        border-color: #49484c;
        &:hover,
        &:focus {
            border-color: #49484c;
        }
        > a {
            color: #49484c;
        }
    }
    @media (max-width:420px){
      .ant-pagination-prev, .ant-pagination-next, .ant-pagination-jump-prev, .ant-pagination-jump-next {
        min-width: 25px !important;
        height: 25px !important;
        line-height: 24px !important;
      }
      li.ant-pagination {
        font-size: 12px !important;
      }
      li.ant-pagination-item {
        min-width: 27px !important;
        height: 25px !important;
        line-height: 25px !important;
      }
    }
    .ant-pagination-item:focus-visible,
    .ant-pagination-item:hover {
        border-color: #49484c;
         > a {
            color: #49484c;
        }
    }
    .ant-pagination-prev:focus-visible .ant-pagination-item-link,
    .ant-pagination-next:focus-visible .ant-pagination-item-link,
    .ant-pagination-prev:hover .ant-pagination-item-link,
    .ant-pagination-next:hover .ant-pagination-item-link {
        color: #49484c;
        border-color: #49484c;
    }
    .ant-pagination-disabled .ant-pagination-item-link,
    .ant-pagination-disabled:hover .ant-pagination-item-link,
    .ant-pagination-disabled:focus-visible .ant-pagination-item-link {
        color: rgba(0, 0, 0, 0.25) !important;
        border-color: #d9d9d9 !important;
    }
    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link,
    .ant-pagination-item {
        border-radius: 5px;
    }
}
.inner-menu-properties {
    border-radius: 8px !important;
}
    .prop-dropdown {
        width:850px;
        padding:10px;
        .ant-row {
            .ant-col {
                > div {
                    border-right: 1px solid #E3E3E3;
                    padding-right: 6px;
                }
                &:nth-of-type(4n){
                    > div {
                        border-right: none;
                    }
                }
            }
        }
        .ant-dropdown-menu-item{
            padding: 6px 12px;
            font-size: 12px;
            border-radius: 0px;
            .ant-dropdown-menu-title-content{
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;

                a{
                  font-weight: 500;
                }
            }
        }
        .ant-dropdown-menu-item:hover, .ant-dropdown-menu-submenu-title:hover, .ant-dropdown-menu-item.ant-dropdown-menu-item-active, .ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-active, .ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-active, .ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-active {
            background-color: #FFF;
            box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.13);
            border-radius: 8px;
        }
        .ant-dropdown-menu-item:hover{
            &::after{
            content: ">";
             font-size: 12px;
             height: 15px;
             width: 15px;
             top: 7px;
             right: 0;
             display: block;
             position: absolute;
            }
        }
    }
    .message-box{
        position: fixed;
        right: 0px;
        z-index: +3;
        display: block;
        bottom: 0;
        .ant-btn{
            border: 0px;
            background: transparent;
            height: 90px;
        }
    }
    .message-box-model{
        .ant-modal-content{
            border-radius: 10px;
            .ant-modal-close .anticon svg {
                color: #fff;
                fill: #fff;
            }
        }
        .ant-modal-header{
            background:  #49484c;
            padding: 12px 15px;
            border-radius: 10px 10px 0 0;
            img{
                margin-right:10px;
            }
            h5{
                font-size: 22px;
                margin: 0;
            }
        }
        .ant-modal-footer {
            .model-fot-search{
                width:100%;
                .ant-input{
                    border-radius: 10px;
                    height: 45px;
                }
                .ant-input-group-addon{
                    background-color: transparent;
                    height: 45px;
                }
                .ant-input-search-button{
                    background: transparent !important;
                    border-color: #ffffff !important;
                    color: #49484c !important;
                    box-shadow: none;
                    font-size: 20px;
                    border-radius: 10px;
                    padding: 0;
                    margin-left: -55px;
                    width: 50px;
                    height: 40px;
                }
            }
        }
        .ant-modal-body{
            height: 410px;
            overflow-y: scroll;
            &:-webkit-scrollbar-thumb, ol::-webkit-scrollbar-thumb, ul::-webkit-scrollbar-thumb, pre::-webkit-scrollbar-thumb, div::-webkit-scrollbar-thumb {
                background-color: #eff2f5;
            }
            .ant-comment-content-detail{
                box-shadow: 7px 10px 30px rgb(176 171 171 / 14%);
                border-radius: 26px 21px 16px 16px;
                font-size: 10px;
                color: #707070;
                padding: 10px;
            }
            .ant-comment-actions{
                li{
                    span{
                        font-size: 9px;
                    color: #707070;
                    }
                }
            }
        }
    }
    .header-search-btn{
        border-radius: 5px !important;
        height: 36px !important;
         @media (max-width: 991.98px) {
            padding: 4px 12px;
         }
        &:hover{
            color: #49484c;
            border-color: #49484c;
            background: #fff;
        }
        &:focus{
            color: #49484c;
            border-color: #49484c;
            background: #fff;
        }
        @media  only screen and (max-width:575.98px) {
            border: 0 !important;
            height: 32px !important;
        }
    }
    .price-slider-home{
        border-radius: 5px !important;
        .ant-slider-handle{
            border: solid 2px #49484c;
        }
        .ant-slider-handle:focus {
            border-color: #49484c;
            box-shadow: 0 0 0 5px rgb(20 44 106 / 15%);
        }
        .ant-slider:hover .ant-slider-track {
            background-color: #49484c;
        }
        .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
            border-color: #49484c;
        }
        .ant-slider-track {
            background-color: #49484c;
        }
        .ant-tooltip {
            z-index: 9999999 !important;
        }
    }
    @media only screen and (min-width:320px) and (max-width:991.98px){
        .ant-menu-light .ant-menu-item:hover, .ant-menu-light .ant-menu-item-active, .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-light .ant-menu-submenu-active, .ant-menu-light .ant-menu-submenu-title:hover {
            color: #49484c;
        }
        .ant-menu-item-selected {
            color: #49484c;
        }
    }
// Market place
.bg-market-place {
    background-image: url(/images/market-place-banner.webp);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: scroll;
    padding-top: 54px;
    padding-bottom: 54px;
    height: 100%;
    min-height: 440px;
    max-height: 440px;
    position: relative;
}
.transparent .hamburg-btn > span {
    @media only screen and (max-width: 991.98px) {
        background-color: #2C2C2C !important;
    }
}
.float-md-right {
    @media only screen and (max-width: 991px) {
        float: right !important;
        margin-top: 0 !important;
    }
}
.float-left {
    @media only screen and (max-width: 767px) {
        float: left !important;
        margin-top: 10px !important;
    }
}
.ant-btn-primary {
    color: #fff !important;
    background: #292A2D !important;
    border-color: #292A2D !important;
    border-radius: 8px;
    font-weight: 500;
    border: none;
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
    background-color: #F3B521 !important;
    border-color: #F3B521 !important;
    color: #49484c !important;
    border-radius: 4px;
    border: none;
}
.bg-blue-light {
    background-color: #49484c !important;
}
.border-blue-light {
    border-color: #49484c !important;
}
.hotel-loc-input {
    box-sizing: border-box;
    border: 1px solid transparent;
    width: 260px;
    height: 40px;
    margin-top: 10px;
    margin-left: 10px;
    padding: 0 12px;
    border-radius: 2px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    font-size: 15px;
    outline: none;
    text-overflow: ellipsis;
}
.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #49484c !important;
    border-color: #49484c !important;
}
.btn-outline-primary {
    border: 1px solid #49484c;
    color: #49484c;
}
.btn-outline-primary:hover,
.btn-outline-primary:focus {
    background-color: #49484c !important;
    border-color: #49484c !important;
    color: #ffffff !important;
}
  html {
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
  }
  h2.title-home {
    color: #000000 !important;
    font-size: 24px;
    font-weight: 600 !important;
  }
  h3.title-home,
  h4.title-home,
  h5.title-home {
    color: #000000 !important;
    font-size: 25px;
    font-weight: 600 !important;
    line-height: 30px;
  }
  .mb-0 {
    margin-bottom: 0 !important;
  }
   .react-tel-input .invalid-number-message {
        font-size: 13px;
        left: 0;
        top: 46px;
        padding: 0;
        display: flex;
        color: #ff4d4f;
   }
   .signup-form .react-tel-input .invalid-number-message {
        font-size: 11px;
   }
   .react-tel-input .country-list {
        width: 400px;
        box-shadow: 1px 2px 10pxrgba(0,0,0,0.35);
        margin-left: 2px;
    }
    .react-tel-input .country-list .search-box {
        margin-left: 0;
    }
    .react-tel-input .country-list .search {
        padding: 10px 10px 6px 10px;
    }
    .country-input .react-tel-input .form-control {
        background-color: #f8f8f8;
        border-radius: 10px;
        height: 53px !important;
        color: #000000;
        border: none;
    }
    .country-input .react-tel-input .flag-dropdown {
        background-color: #f8f8f8;
        border-radius: 10px 0 0 10px;
        border: 0;
    }
    .country-input .react-tel-input .flag-dropdown.open .selected-flag {
        background: #f8f8f8;
        border-radius: 10px 0 0 10px;
    }
    .country-input .react-tel-input .selected-flag:hover,
    .country-input .react-tel-input .selected-flag:focus {
        background-color: #f8f8f8;
    }
    .country-input .react-tel-input .selected-flag {
        padding: 0 0 0 15px;
        border-radius: 10px 0 0 10px;
    }
    .ant-breadcrumb span:last-child .ant-breadcrumb-separator {
        display: none;
    }
    .redeem-popup {
        .ant-modal-content {
            border-radius: 10px;
            .ant-modal-header {
                padding: 20px 24px;
                border-bottom: 0;
                color:  #49484c;
                border-radius: 10px 10px 0 0;
                .ant-modal-title {
                    color: #49484c;
                    font-weight: 500;
                    font-size: 22px;
                }
            }
            .ant-modal-close {
                top: 14px;
                right: 15px;
                color: #49484c;
                border-radius: 50%;
                background-color: #FFFFFF;
                &:hover {
                    background-color:  #49484c;
                    span.ant-modal-close-x {
                        color: #FFFFFF;
                    }
                }
                .ant-modal-close-x {
                    color: #333333;
                    font-weight: 700;
                    width: 30px;
                    height: 30px;
                    font-size: 16px;
                    line-height: 31px;
                }
            }
            .ant-modal-body {
                background-color: #FFFFFF;
                border-radius: 10px;
                padding: 24px 40px 30px;
                .market-logo {
                    height: 58px;
                    width: 60px;
                    margin: 0 auto;
                    .bg-grey {
                        background-color: #EEEEEE;
                        img {
                            padding: 5px 5px;
                            height: 58px;
                            object-fit: contain;
                            width: 100%;
                        }
                    }
                }
                h1 {
                    color: #000000;
                    font-weight: 400;
                    font-size: 38px;
                    margin-bottom: 5px;
                    margin-top: 3px;
                    @media (max-width:575.98px){
                    font-size:15px !important;
                    }
                }
                p {
                    color: #000000;
                    font-weight: 400;
                    font-size: 13px;
                    @media (max-width:575.98px){
                    font-size:12px !important;
                    }
                }
                p.main-con {
                    color: #959595;
                    font-weight: 400;
                    font-size: 12px;
                }
                .ant-checkbox-wrapper {
                    color: #959595;
                    font-size: 12px;
                    font-weight: normal;
                    .ant-checkbox-inner {
                        width: 14px;
                        height: 14px;
                        border-radius: 0;
                        &::after {
                            left: 19%;
                        }
                    }
                    .ant-checkbox-checked::after {
                        border: 1px solid #49484c;
                    }
                    .ant-checkbox-checked .ant-checkbox-inner {
                        background-color: #49484c;
                        border-color: #49484c;
                    }
                }
                .down-btn {
                    padding: 2px 45px;
                    height: 39px;
                    font-size: 15px;
                    font-weight: 400;
                    color: #FFFFFF;
                    margin-top: 25px;
                    background-color: #49484c;
                    border-radius:4px;
                    border: none;
                    span {
                        color: #FFFFFF;
                    }
                }
                .redeem-offer-QR {
                    .qr-code-img {
                        max-height: 185px;
                        min-height: 185px;
                        height: 100%;
                    }
                    h1 {
                        color:#49484c;
                        font-size: 14px;
                        font-weight: 700;
                    }
                    p {
                        color: #8F8C8C;
                        font-size: 12px;
                        font-weight: 400;
                    }
                    .close-btn {
                        padding: 1px 51px;
                        height: 38px;
                        font-size: 15px;
                        font-weight: 400;
                    }
                }
            }
        }
    }
  html,
  html a,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  p,
  li,
  dl,
  th,
  dt,
  input,
  textarea,
  span,
  div {
    font-family: 'Poppins', sans-serif !important;
    // font-family: 'Open Sans', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
  }

  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    font-family: 'Poppins', sans-serif !important;
    -webkit-tap-highlight-color: transparent;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style-type: none;
  }

  a {
    text-decoration: none;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  #__next {
    > .ant-layout {
      min-height: 100vh;
      overflow: hidden;
      background-color: ${themeGet('color.1', '#ffffff')};

      /* hotel submission style  */
      .hotel-submission-form {
        .ant-progress-outer {
          position: fixed;
          z-index: 9;
          left: 0;
          top: auto;
        }
        .gm-style {
          > input[type="text"] {
            left: 9px !important;
            top: 46px !important;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }
        .hotel-form-location {
          .ant-card-head-title {
            font-size: 15px;
            line-height: 18px;
            font-weight: 700;
            color: ${themeGet('text.0', '#2C2C2C')};
          }
          .ant-card-body p {
            display: flex;
            color: ${themeGet('text.2', '#777777')};
            justify-content: flex-start;
            strong {
              color: ${themeGet('text.0', '#2C2C2C')};
              width: 30%;
            }
          }
        }
      }
    }
  }
  .ant-popover{
    z-index: 99999;
  }
  .react-tel-input {
    input{
        width: 100% !important;
        height: 46px !important;
    border-radius: 8px !important;
    }
    .selected-flag, .flag-dropdown{
        border-radius: 8px 0 0 8px !important;
    }
   }

    .make-affer-check-box{
        margin-bottom: 20px;
        .accept-terms{
            display: contents;
        }
    }

  /*------------------------------------------*/
  // style for navbar profile / account menu
  /*------------------------------------------*/
  .ant-popover {
    &.profile_menu {
      z-index: 9999;
    }
    .ant-popover-content {
      .ant-popover-inner {
        .ant-popover-inner-content {
          ul.ant-menu  {
            border: 0;
            &.account_menu {
              li {
                color: ${themeGet('text.0', '#2C2C2C')};
                font-size: 15px;
                line-height: 18px;
                font-weight: 400;
                height: auto;
                transition: color 0.2s ease-in-out;
                &.ant-menu-item-selected,
                &.ant-menu-item-active {
                  background-color: transparent;
                }
                a {
                  padding: 8px 0;
                  color: ${themeGet('text.0', '#2C2C2C')};
                  transition: color 0.2s ease-in-out;
                  &:hover {
                    color: ${themeGet('primary.0', '#49484c')};
                  }
                  &.active {
                    font-weight: 700;
                    color: ${themeGet('primary.0', '#49484c')};
                  }
                }
                button {
                  padding: 0;
                  border: 0;
                  cursor: pointer;
                  padding: 8px 0;
                  background-color: transparent;
                  transition: color 0.2s ease-in-out;
                  &:hover {
                    color: ${themeGet('primary.0', '#49484c')};
                  }
                  &:focus {
                    outline: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  /*------------------------------------------*/
  // style for date range picker
  /*------------------------------------------*/
  .DateRangePicker {
    .DateRangePickerInput {
      .DateRangePicker_picker {
        .DayPicker_weekHeader {
          .DayPicker_weekHeader_ul {
            .DayPicker_weekHeader_li {
              color: ${themeGet('text.0', '#2C2C2C')};
              small {
                font-size: 13px;
              }
            }
          }
        }
        .DayPicker_focusRegion {
          .CalendarMonthGrid {
            .CalendarMonth {
              .CalendarMonth_caption {
                font-size: 16px;
                color: ${themeGet('text.0', '#2C2C2C')};
              }
            }
          }
        }
        .DayPickerNavigation {
          .DayPickerNavigation_button {
            &:focus {
              outline: none;
            }
          }
        }
        .DayPickerKeyboardShortcuts_buttonReset {
          &:focus {
            outline: none;
          }
        }
      }
    }
  }

  /*------------------------------------------*/
  // style for ant tooltip
  /*------------------------------------------*/
  .ant-tooltip {
    font-size: 13px;
    font-weight: 700;
    .ant-tooltip-inner {
      box-shadow: none;
      text-align: center;
      border-radius: 3px;
      min-height: 26px;
      padding: 4px 10px;
    }
  }

  /*------------------------------------------*/
  // style for dropdown
  /*------------------------------------------*/
  .ant-select-dropdown {
    border-radius: 5px;
    .ant-select-dropdown-menu {
      .ant-select-dropdown-menu-item {
        padding: 12px 12px;
        line-height: 1;
        &.ant-select-dropdown-menu-item-active {
          background-color: rgba(0, 132, 137, 0.1);
        }
      }
    }
    .ant-select-item-option {
        @media  only screen and (max-width:575.98px) {
            font-size: 14px;
        }
    }
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        color: rgba(0, 0, 0, 0.85);
        font-weight: 400;
        background-color: #EBEEF3;
        .ant-select-item-option-state {
            color: #49484c;
        }
    }
  }

  /*------------------------------------------*/
  // here fix height issue for RoomGuest
  /*------------------------------------------*/
  .view_with__popup {
    &.room_guest__component {
      &.active {
        min-height: 54px;
      }
      &.alt {
        .popup_container {
          #popup {
            > div {
              > div {
                padding: 0;
              }
            }
          }
        }
        &.active {
          min-height: inherit;
        }
      }
    }
  }

  /*------------------------------------------*/
  // ant dropdown and social share menu style
  /*------------------------------------------*/
  .ant-dropdown {
   z-index: 999 !important;
   padding-top: 20px;
    &.social_share_menu {
      z-index: 999999 !important;

      .ant-menu {
        padding: 7px 0;
        min-width: 150px;
        border: 0;
        border-radius: 4px;
        box-shadow: 0 2px 8px rgba(0,0,0,0.15);

        .ant-menu-item {
          margin: 0;
          padding: 0;
          height: inherit;
          line-height: inherit;
          > div {
            padding: 15px 20px;
            color: ${themeGet('text.0', '#2C2C2C')};
            svg {
              margin-right: 8px;
            }
          }

          &:hover {
            background-color: ${themeGet('color.2', '#F7F7F7')};
          }
        }
      }
    }
  }
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: #49484c !important;;
    border-color: #49484c !important;;
  }
  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: 1px solid #49484c !important;;
    }
    .ant-picker-today-btn {
        color: #49484c !important;
    }
    .price-slider-listing{
        width: 320px;
        @media  only screen and (max-width:575.98px) {
            width: 100%;
        }
        h5.ant-typography {
            @media  only screen and (max-width:575.98px) {
                font-size: 14px;
            }
        }
        .ant-slider-track{
            background-color: #49484c;
        }
        .ant-slider-handle{
            border: solid 2px #49484c;
        }
      }
      .filter-btns{
        display: flex;
        .btn-outline{
            border-radius: 5px;
            padding: 7px 20px;
            cursor: pointer;
            height: 40px;
            text-align: center;
            margin-right: 12px;
            @media  only screen and (max-width:575.98px) {
                height: 35px;
                font-size: 12px;
                padding: 7px 0;
            }
        }
        .price-clear-btn{
            color: #fff;
            background: #49484c;
            border-radius: 5px;
            padding: 7px 20px;
            cursor: pointer;
            height: 40px;
            @media  only screen and (max-width:575.98px) {
                height: 35px;
                font-size: 12px;
                padding: 7px 0;
            }
        }
      }
      .close-icon{
        float: right;
      }

    .search-p:hover, .search-p:focus {
        color: #49484c;
        border-color: #49484c;
        background: #fff;
    }
    .add_attachment_any{
        .ant-btn:hover, .ant-btn:focus {
            color: #49484c;
            border-color: #49484c;
            background: #fff;
        }
    }

  /*------------------------------------------*/
  // ant modal and drawer style
  /*------------------------------------------*/
  .ant-drawer,
  .ant-modal-mask,
  .ant-modal-wrap  {
    z-index: 10501 !important;
  }
  .ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.35);
  }
  .ant-drawer {
    &.filter_drawer {
      pointer-events: none;
      .ant-drawer-mask {
        display: none;
      }
      &.ant-drawer-bottom {
        &.ant-drawer-open {
          .ant-drawer-content-wrapper {
            box-shadow: none;
          }
        }
      }
      .ant-drawer-content-wrapper {
        height: calc(100vh - 152px) !important;
        pointer-events: all;
        .ant-drawer-wrapper-body {
          height: 100%;
          .ant-drawer-body {
            height: 100%;
            padding-top: 0;
            overflow: hidden;
          }
        }
      }
    }
  }

  .ant-modal-wrap {
    .ant-modal-content {
      box-shadow: 0 1px 10px rgba(0,0,0,0.16);
    }
    &.review_modal {
      .ant-modal {
        max-width: 1170px;
        box-sizing: border-box;
        @media only screen and (max-width: 1260px) {
          padding-left: 30px;
          padding-right: 30px;
        }
        @media only screen and (max-width: 767px) {
          top: 0;
          padding: 0;
          margin-top: 0;
          margin-bottom: 0;
        }
        .ant-modal-body {
          padding-left: 60px;
          padding-right: 60px;
          padding-bottom: 60px;
          padding-top: 30px;
          @media only screen and (max-width: 1260px) {
            padding: 40px 30px;
          }
        }
      }
      .image_uploader{
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        @media only screen and (max-width: 667px) {
          flex-wrap: wrap;
        }
        .ant-upload{
          @media only screen and (max-width: 667px) {
            margin-bottom: 10px;
          }
          &.ant-upload-drag{
            border: 0;
            border-radius: 0;
            background: transparent;
            .ant-upload{
              padding: 0;
              display: block;
              width: auto;
              height:auto;
            }
          }
          &.ant-upload-drag-hover{
            .image-drag-area{
              border-color: #48bdc1;
            }
          }
        }
        .ant-upload-list{
          float: none;
          display: flex;
          .ant-upload-list-item{
            width: 125px;
            height: 125px;
            border-radius: 3px;
          }
        }
        .ant-upload-drag-container{
          display: block;
        }
      }
    }
  }
  .btn-terms-condition{
    border: none !important;
    box-shadow: none !important;
    color: #49484c !important;
    padding: 4px 4px !important;
  }
  .terms-condition-popup{
    .ant-modal-content{
        border-radius: 5px !important;
        height: 450px;
        overflow-y: scroll;
        position: relative;
        display: block;
          &::-webkit-scrollbar {
            width: 10px;
            height:10px;
          }

          &::-webkit-scrollbar-track {
            background: #ffffff;
            border-radius: 0 5px 5px 0;
          }

          &::-webkit-scrollbar-thumb {
            background: #49484c;
            border-radius: 10px;
          }
        .ant-modal-header{
            border-radius: 5px;
            padding: 12px 24px;
            position: relative;
        }
        .ant-modal-close{

        }
    }
    .ant-modal-footer{
        display:none;
    }
  }

  /*------------------------------------------*/
  // map info window style
  /*------------------------------------------*/
  .gm-style-iw-c {
    box-shadow: none !important;
  }
  .gm-style-iw {
    padding: 0 !important;
    border-radius: 3px !important;
    width: 270px !important;
    z-index: 1;
    .gm-style-iw-d {
      overflow-y: auto !important;
      overflow-x: hidden !important;
      max-width: 100% !important;
    }
    .info_window_card {
      width: 270px;
      margin-bottom: 0;
      > div {
        > img {
          width: 100%;
          height: 190px;
          object-fit: cover;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
      .content_wrapper {
        > div + div {
          margin-top: 2px;
          margin-bottom: 4px;
        }
        .meta_wrapper {
          .rating {
            margin-top: 5px;
          }
        }
      }
    }
    button[title="Close"].gm-ui-hover-effect {
      width: 26px !important;
      height: 26px !important;
      padding: 6px !important;
      border-radius: 50%;
      margin: 0 !important;
      top: 14px !important;
      right: 11px !important;
      opacity: 1;
      align-items: center;
      display: inline-flex ;
      background-color: ${themeGet('color.1', '#ffffff')} !important;
      box-shadow: 0 3px 6px rgba(0,0,0,0.16);
      > img {
        margin: 0 !important;
      }
      span {
        left: -7px;
        top: -8px;
        position: relative;
        }
    }
  }

/*------------------------------------------*/
// Image Gallery Modal style
/*------------------------------------------*/
.ant-modal-wrap {
  &.image_gallery_modal {
    .ant-modal {
      top: 50px;
      .ant-modal-content{
        box-shadow: none;
        padding: 0 30px;
        background: transparent;
        @media only screen and (max-width: 480px) {
          padding: 0 20px;
        }
        .ant-modal-body {
          max-width: 1170px;
          padding: 0;
          margin: 0 auto;
          @media only screen and (max-width: 1440px) {
            position: relative;
          }
        }
      }
      .image_gallery_close {
        background: transparent;
        border: 0;
        width: 60px;
        height: 60px;
        position: absolute;
        top: -20px;
        right: 40px;
        &:focus,
        &:hover {
          outline: none;
        }
        @media only screen and (max-width: 1440px) {
          top: 0;
          right: 0;
          svg {
            path {
              opacity: 0.8;
              fill: ${themeGet('color.1', '#ffffff')};
              transition: all 0.3s ease;
            }
          }
          &:hover {
            svg {
              path {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}

/*------------------------------------------*/
// style for Increment Decrement Component
/*------------------------------------------*/
.quantity {
  button.btn {
    border: 1px solid ${themeGet('primary.0', '#49484c')};
    svg {
      fill: ${themeGet('primary.0', '#49484c')};
    }
    &:hover {
      background-color: ${themeGet('primary.0', '#49484c')};
      svg {
        fill: #ffffff;
      }
    }
  }
  input[type="number"],
  input[type="number"].qnt-input {
    color: ${themeGet('text.0', '#2C2C2C')};
  }
}

/*------------------------------------------*/
// style for mobile Header Component
/*------------------------------------------*/
.mobile-header {
  &.ant-drawer {
    z-index: 10000;
  }
  .ant-drawer-body {
    padding: 0;
    .auth-menu {
        border: 0;
        display: flex;
        margin: 0 0 30px;
        padding: 25px 20px;
        align-items: center;
        background-color: ${themeGet('color.2', '#F7F7F7')};
        @media (max-width:640px) {
            margin: 0 0 0;
            padding: 10px 20px;
        }
        @media only screen and (min-width:641px) and (max-width:991px){
            margin: 0 0 0;
            padding: 10px 20px;
        }
        li {
            height: auto;
            line-height: 1;
            padding: 0;
            margin: 0;
            &.ant-menu-item-selected,
            &.ant-menu-item-active {
                background-color: transparent;
            }
            @media (max-width:991.98px) {
                 margin: 0 10px 0 0;
            }
            @media  only screen and (max-width:575.98px) {
                margin: 0 6px;
            }
            a {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                padding: 12px 15px;
                border-radius: 3px;
                font-size: 15px;
                font-weight: 700;
                color: ${themeGet('text.0', '#2C2C2C')};
            }
            &:last-child {
                a {
                    color: ${themeGet('color.1', '#ffffff')};
                    background-color: ${themeGet('primary.0', '#49484c')};
                    transition: opacity 0.2s ease;
                    &:hover {
                        opacity: 0.9;
                    }
                }
            }
            @media  only screen and (max-width:575.98px) {
                .ant-btn-primary {
                    padding: 5px 20px;
                    height: 32px;
                    display: flex;
                    align-items: center;
                }
            }
            @media (min-width: 767.98px) and (max-width:991.98px) {
                .ant-btn-primary {
                    height: 36px;
                }
            }
        }
    }
    .main-menu {
      border: 0;
      padding-top: 30px;
      @media (max-width:640px) {
            padding-top:0;
        }
        @media only screen and (min-width:641px) and (max-width:991px){
            padding-top:0;
        }
      li {
        padding: 0;
        &.ant-menu-item-selected,
        &.ant-menu-item-active {
          background-color: transparent;
          font-weight: 700;
          border-color: #49484c;
          color: #49484c;
        }
        &.ant-menu-item.about-drop {
          .ant-dropdown-link {
            font-size: 15px;
            border-left: 4px solid transparent;
            color: ${themeGet('text.0', '#2C2C2C')};
          }
        }
        &.ant-menu-item-selected.about-drop,
        &.ant-menu-item-active.about-drop {
            .ant-dropdown-link {
                background-color: transparent;
                font-weight: 700;
                border-color: #49484c;
                color: #49484c;
            }
        }
        @media only screen and (min-width:320px) and (max-width:991.98px){
            .ant-dropdown-trigger {
                padding: 0 23px;
            }
        }
        @media (min-width: 768px) and (max-width: 991.98px){
            .ant-dropdown-trigger {
                padding: 0 23px;
            }
        }
        a {
          font-size: 15px;
          padding: 0 23px;
          border-left: 4px solid transparent;
          color: ${themeGet('text.0', '#2C2C2C')};
          &.active {
            font-weight: 700;
            border-color: #49484c;
            color: #49484c;
          }
        }
        button {
          display: block;
          width: 100%;
          border: 0;
          padding: 0 32px;
          cursor: pointer;
          background-color: transparent;
          color: ${themeGet('text.0', '#2C2C2C')};
          transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          &:hover {
            color: ${themeGet('primary.0', '#49484c')};
          }
          &focus {
            outline: 0;
          }
        }
        &:hover {
          a {
            color: #49484c;
          }
        }
      }
    }
  }
}

/*------------------------------------------*/
// style for google map dropdown
/*------------------------------------------*/
.pac-container {
  border: 0;
  z-index: 99999;
  padding: 5px 0;
  margin-top: 0;
  border-radius: 3px;
  box-shadow: 0 15px 46px -10px rgba(26, 26, 29, 0.3);
  @media only screen and (max-width: 991px) {
    margin-top: 0;
  }
  &::after {
    display: none;
  }
  .pac-item {
    border-top: 0;
    line-height: 30px;
    padding: 10px 14px;
    cursor: pointer;
    font-size: 13px;
    color: ${themeGet('text.1', '#909090')};
    .pac-icon {
      margin-top: 6px;
    }
    .pac-item-query {
      font-size: 15px;
      font-weight: 600;
      color: ${themeGet('text.0', '#2C2C2C')};
      .pac-matched {
        font-weight: 700;
        color: ${themeGet('color.0', '#000000')};
      }
    }
    &:hover {
      background-color: ${themeGet('color.2', '#F7F7F7')};
    }
  }
}

/*------------------------------------------*/
// style for reservation form
/*------------------------------------------*/
.reservation_modal {
  .ant-modal {
    width: 100% !important;
    max-width: 450px;
    padding: 0 15px;
  }
  .ant-modal-content {
    box-shadow: none;
    .ant-modal-body {
      padding: 0;
      position: relative;
      .reservation_sidebar {
        box-shadow: 0 1px 10px rgba(0,0,0,0.16);
        header {
          padding-top: 50px;
          padding-bottom: 20px;
          border-color: ${themeGet('border.0', '#EBEBEB')};
          @media only screen and (max-width: 375px) {
            padding-top: 30px;
          }
        }
        .DateInput__small {
          width: 112px;
        }
        input,
        .DateRangePickerInput {
          padding: 0 9px;
        }
        footer {
          border-color: ${themeGet('border.0', '#EBEBEB')};
        }
      }
      > button.close {
        border: 0;
        padding: 0;
        top: 15px;
        right: 15px;
        height: auto;
        line-height: 1;
        position: absolute;
        font-size: 32px;
        background-color: transparent;
        color: ${themeGet('text.1', '#909090')};
        transition: all 0.3s ease;
        @media only screen and (max-width: 375px) {
          top: 10px;
          right: 10px;
          font-size: 25px;
        }
        &:hover,
        &:focus {
          outline: none;
          color: ${themeGet('text.0', '#2C2C2C')};
        }
        &::after {
          display: none;
        }
      }
    }
  }
}

.reservation_sidebar {
  padding: 0 !important;
  background-color: ${themeGet('color.1', '#ffffff')};
  header {
    padding-bottom: 27px;
    margin-bottom: 29px;
    padding: 25px 30px 26px 30px;
    border-bottom: 1px solid ${themeGet('border.0', '#EBEBEB')};
    @media only screen and (max-width: 375px) {
      padding: 25px 15px 26px 15px;
    }
    a {
      &:hover {
        color: #31b8bd;
      }
    }
  }

  p {
    color: ${themeGet('text.0', '#2C2C2C')};
    font-size: 15px;
    font-weight: 400;
    a {
      color: ${themeGet('primary.0', '#49484c')};
      font-weight: 700;
      &:hover,
      &:focus {
        outline: 0;
        color: #49484cd1;
        text-decoration: underline;
      }
    }
  }

  footer {
    padding: 25px 30px 28px 30px;
    margin-top: 29px;
    border-top: 1px solid ${themeGet('border.0', '#EBEBEB')};
    @media only screen and (max-width: 375px) {
      padding: 20px 15px 25px 15px;
    }

    p {
      margin-bottom: 0;
    }
  }
}

.grid_column .placeholder {
  max-width: 100%;
}

.ant-input-affix-wrapper {
  border-radius: 3px;
  border-color: ${themeGet('border.3', '#E6E6E6')};
}

.ant-input-search-icon::before {
  border-color: ${themeGet('border.3', '#E6E6E6')};
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-checked::after {
  border-color: ${themeGet('primary.0', '#49484c')} !important;
}
.login-popup.ant-modal {
    .ant-modal-content {
        border-radius: 5px;
        .ant-modal-close-x {
            width: 40px;
            height: 40px;
            font-size:  16px;
            line-height: 40px;
            font-weight: 700;
            .anticon {
                color: #707070;
                font-weight: 700;
            }
        }
        .ant-modal-body {
            // padding: 50px 35px;
            padding: 10px 0 10px 10px;
            .login-image{
                height: 100%;
                width: 100%;
                object-fit: cover;
                border-radius: 8px;
            }
            .p-40-30 {
                padding:40px 30px
            }
            @media  only screen and (max-width:575.98px) {
                padding: 25px;
            }
            .title-modal {
                h2 {
                    color: #000000;
                    font-size: 18px;
                    font-weight: 700;
                    text-align: center;
                    margin-bottom: 15px;
                }
                p {
                    color: #252525;
                    font-size: 13px;
                    font-weight: 400;
                    text-align: center;
                    margin-bottom: 25px;
                    @media  only screen and (max-width:575.98px) {
                        margin-bottom: 5px;
                    }
                }
                &.welcome-back-text{
                    h2{
                        margin-bottom:6px;
                        font-size:30px;
                    }
                }
            }
            .google-btn {
                background-color: #FFFFFF;
                border-radius: 5px;
                border: 0;
                color: #252525;
                font-size: 12px;
                line-height: 12px;
                font-weight: 500;
                width: 100%;
                height: 42px;
                box-shadow: 0 .2rem 0.6rem rgba(0,0,0,.15)!important;
                img {
                    float: left;
                }
                span {
                    vertical-align: -webkit-baseline-middle;
                }
            }
            .facebook-btn {
                background-color: #3D5A98;
                border-radius: 5px;
                border: 0;
                color: #FFFFFF;
                font-size: 12px;
                font-weight: 500;
                width: 100%;
                height: 42px;
                box-shadow: 0 .2rem 0.6rem rgba(0,0,0,.15)!important;
                line-height: 12px;
                img {
                    float: left;
                }
                span {
                    vertical-align: -webkit-baseline-middle;
                }
            }
            .apple-btn {
                background-color: #000000;
                border-radius: 5px;
                border: 0;
                color: #FFFFFF;
                font-size: 11px;
                font-weight: 500;
                width: 100%;
                height: 42px;
                box-shadow: 0 .2rem 0.6rem rgba(0,0,0,.15)!important;
                img {
                    float: left;
                }
                span {
                    vertical-align: -webkit-baseline-middle;
                }
            }
            .or-text {
                margin: 15px 0;
                span {
                    color: #808186;
                    font-size: 16px;
                    font-weight: 700;
                    display: block;
                    text-align: center;
                    @media  only screen and (max-width:575.98px) {
                        font-size: 14px;
                    }
                }
            }
            .ant-select:not(.ant-select-customize-input) .ant-select-selector {
                width: 100%;
                min-height: 43px;
                border-radius: 5px;
                border-color: #D8E0E9;
                color: #808186 !important;
                font-weight: 600;
                font-size: 12px;
                background-color: #fff !important;
                box-shadow: none !important;
            }
            .ant-select-single .ant-select-selector .ant-select-selection-item,
            .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
                line-height: 40px;
                color: #434343 !important;
                font-weight: 400;
                font-size: 13px;
            }
            .form-control {
                margin-bottom: 17px;
                .field-label {
                    color: #252525;
                    font-size: 13px;
                    font-weight: 600;
                    margin-bottom: 5px;
                }
                .ant-input,
                .ant-picker,
                .ant-input-number {
                    width: 100%;
                    min-height: 43px;
                    border-radius: 5px;
                    border-color: #D8E0E9;
                    color: #434343 !important;
                    font-weight: 400;
                    font-size: 13px;
                    background-color: #fff !important;
                    box-shadow: none !important;
                }
                .ant-input-password {
                    width: 100%;
                    border-radius: 5px;
                    border-color: #D8E0E9;
                    min-height: 43px;
                    .ant-input {
                        min-height: 33px;
                    }
                    &:focus {
                        outline: 0 !important;
                        box-shadow: none !important;
                    }
                }
                .feedback,
                .ant-form-item-explain-error {
                    font-size: 11px;
                    font-weight: 400;
                }
            }
            form {
                .ant-form-item-label {
                    padding: 0;
                    > label {
                        color: #434343;
                        font-size: 12px;
                        font-weight: 400;
                        &.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
                            display: inline-block;
                            margin-right: 4px;
                            color: #ff4d4f;
                            font-size: 14px;
                            font-family: 'Poppins', sans-serif !important;
                            line-height: 1;
                            content: '*';
                        }
                        &.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
                            display: inherit;
                            margin-right: inherit;
                            color: inherit;
                            font-size: inherit;
                            font-family: inherit;
                            line-height: inherit;
                            content: '';
                        }
                    }
                }
                .ant-form-item-with-help .ant-form-item-explain {
                    @media  only screen and (max-width:575.98px) {
                        height: auto;
                        min-height: 0;
                    }
                }
                .country-input .react-tel-input .form-control {
                    @media  only screen and (max-width:575.98px) {
                      height: 43px !important;
                    }
                }
                .react-tel-input .country-list {
                    @media  only screen and (max-width:575.98px) {
                      width: 250px;
                    }
                }
                .react-tel-input .country-list .country {
                    @media  only screen and (max-width:575.98px) {
                        font-size: 14px;
                    }
                }
                .react-tel-input .country-list .search-box {
                    @media  only screen and (max-width:575.98px) {
                        font-size: 14px;
                        height: 43px !important;
                    }
                }
                .ant-input,
                .ant-picker,
                .ant-input-number {
                    width: 100%;
                    min-height: 43px;
                    border-radius: 5px;
                    border-color: rgba(67, 67, 67, 0.35);
                    color: #434343 !important;
                    font-weight: 400;
                    font-size: 13px;
                    background-color: #fff !important;
                    box-shadow: none !important;
                }
                .ant-input-password {
                    width: 100%;
                    border-radius: 5px;
                    border-color: #D8E0E9;
                    min-height: 43px;
                    .ant-input {
                        min-height: 33px;
                    }
                    &:focus {
                        outline: 0 !important;
                        box-shadow: none !important;
                    }
                    &.ant-input-affix-wrapper-focused {
                        outline: 0 !important;
                        box-shadow: none !important;
                    }
                }
                .feedback,
                .ant-form-item-explain-error {
                    font-size: 11px;
                    font-weight: 400;
                }
            }
            .signin-btn {
                background-color: #E7ECEE;
                border: 0;
                border-radius: 5px;
                width: 100%;
                height: 43px;
                color: #808186;
                font-size: 13px;
                font-weight: 600;
                margin-bottom: 13px;
                box-shadow: none;
            }
            .external-links {
                color: #49484c;
                font-size: 11px;
                font-weight: 600;
            }
            .crt-new {
                color: #808186;
                font-size: 12px;
                font-weight: 400;
            }
        }
    }
}
.inbox-popup.ant-modal {
    top: 72px;
    margin-left: auto;
    margin-right: 118px;
    @media only screen and (max-width: 991.98px){
        top: 10px;
        margin-left: auto;
        margin-right: auto;
        width: 100% !important;
    }
    .ant-modal-content {
        border-radius: 0;
        .ant-modal-header {
            padding: 25px 24px;
            border-bottom: 0;
            @media only screen and (max-width: 767.98px){
                padding: 15px;
            }
            @media (min-width: 768px) and (max-width: 991.98px){
                padding: 20px;
            }
            .ant-modal-title {
                color: #202020;
                font-weight: 500;
                font-size: 20px;
                @media only screen and (max-width: 767.98px){
                    font-size: 16px;
                }
                @media (min-width: 768px) and (max-width: 991.98px){
                    font-size: 18px;
                }
            }
        }
        .ant-modal-close-x {
            color: #000000;
            font-weight: 700;
            width: 60px;
            height: 64px;
            font-size: 18px;
            line-height: 65px;
            @media only screen and (max-width: 767.98px){
                width: 40px;
                height: 40px;
                font-size: 15px;
                line-height: 55px;
            }
            @media (min-width: 768px) and (max-width: 991.98px){
                width: 45px;
                height: 45px;
                font-size: 16px;
                line-height: 60px;
            }
        }
        .ant-modal-body {
            padding: 0;
            .bg-light {
                padding: 24px;
                background-color: #F4F7FB;
                @media only screen and (max-width: 767.98px){
                    padding: 10px;
                }
                @media (min-width: 768px) and (max-width: 991.98px){
                    padding: 18px;
                }
                .inbox-body {
                    min-height: 460px;
                    max-height: 460px;
                    overflow: auto;
                    padding-right: 5px;
                    padding-left: 5px;
                    &::-webkit-scrollbar {
                        width: 6px;
                        padding-left: 8px;
                    }
                    &::-webkit-scrollbar-track {
                        background: #F4F7FB;
                        border-radius: 10px;
                        padding-left: 8px;
                    }
                    &::-webkit-scrollbar-thumb {
                        background-color: #49484c;
                        border-radius: 10px;
                        padding-left: 8px;
                    }
                    &:hover ::-webkit-scrollbar-thumb {
                        background-color: #49484c;
                    }
                    @media only screen and (max-width: 991.98px){
                        padding-left: 0;
                    }
                    .bg-white {
                        padding: 16px 10px;
                        @media only screen and (max-width: 767.98px){
                            padding: 10px 10px;
                            margin-bottom: 10px !important;
                        }
                        @media (min-width: 768px) and (max-width: 991.98px){
                            padding: 12px 10px;
                            margin-bottom: 12px !important;
                        }
                        .anticon {
                            line-height: inherit;
                            color: #555555;
                            cursor: pointer;
                            margin-top: 5px;
                            &.filled-yellow {
                                color: #F4D11F;
                            }
                            svg {
                                display: inline-block;
                                height: 21px;
                                width: 20px;
                                @media only screen and (max-width: 767.98px){
                                    height: 16px;
                                    width: 16px;
                                }
                                @media (min-width: 768px) and (max-width: 991.98px){
                                    height: 18px;
                                    width: 18px;
                                }
                            }
                        }
                        .inbox-title {
                            color: #666666;
                            font-weight: 500;
                            font-size: 17px;
                            line-height: normal;
                            display: block;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            @media only screen and (max-width: 767.98px){
                                font-size: 13px;
                            }
                        }
                        .inbox-time {
                            color: #9D9D9D;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 28px;
                            display: block;
                            @media only screen and (max-width: 767.98px){
                                font-size: 13px;
                            }
                            @media (min-width: 768px) and (max-width: 991.98px){
                                text-align: right;
                            }
                        }
                        .inbox-content {
                            color: #666666;
                            font-weight: 400;
                            font-size: 13px;
                            display: block;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            margin-bottom: 0 !important;
                            @media only screen and (max-width: 767.98px){
                                font-size: 12px;
                            }
                        }
                        .actions-inbox {
                            padding: 0 15px;
                            @media only screen and (max-width: 767.98px){
                                padding: 0 0px;
                                display: flex;
                            }
                            .row-inbox-btn {
                                @media only screen and (max-width: 767.98px){
                                    flex-wrap: nowrap;
                                }
                            }
                            .inbox-btn {
                                border-radius: 5px;
                                font-size: 11px;
                                height: 30px;
                                padding: 0 20px;
                                @media only screen and (max-width: 767.98px){
                                    padding: 0 20px !important;
                                }
                            }
                            .ant-btn.outline {
                                border-color: #707070;
                                color: #707070;
                                &:hover {
                                    border-color: #49484c;
                                    color: #49484c;
                                }
                            }
                        }
                        &.box:last-child {
                            margin-bottom: 0 !important;
                        }
                    }
                }
            }
            .inbox-footer {
                .inbox-btn {
                    background-color: #49484c;
                    color: #ffffff;
                    font-size: 18px;
                    font-weight: 500;
                    padding-top: 20px;
                    padding-bottom: 20px;
                    @media only screen and (max-width: 767.98px){
                        padding-top: 10px;
                        padding-bottom: 10px;
                    }
                    @media (min-width: 768px) and (max-width: 991.98px){
                        padding-top: 15px;
                        padding-bottom: 15px;
                    }
                    span {
                        vertical-align: top;
                        @media only screen and (max-width: 767.98px){
                            vertical-align: middle;
                        }
                        @media (min-width: 768px) and (max-width: 991.98px){
                            vertical-align: middle;
                        }
                    }
                    .anticon > svg {
                        height: 25px;
                        width: 28px;
                        @media only screen and (max-width: 767.98px){
                            height: 16px;
                            width: 18px;
                        }
                        @media (min-width: 768px) and (max-width: 991.98px){
                            height: 18px;
                            width: 20px;
                        }
                    }
                    &:hover,
                    &:focus {
                        background-color: #000000 !important;
                        border-color: #000000 !important;
                        color: #ffffff !important;
                    }
                    a {
                        color: #ffffff;
                        @media only screen and (max-width: 767.98px){
                            font-size: 14px;
                        }
                        @media (min-width: 768px) and (max-width: 991.98px){
                            font-size: 15px;
                        }
                    }
                }
            }
        }
    }
}
.register-interest-popup.ant-modal {
    .ant-modal-content {
        border-radius: 5px;
        .ant-modal-header {
            padding: 20px 24px;
            border-bottom: 0;
            .ant-modal-title {
                color: #202020;
                font-weight: 500;
                font-size: 20px;
            }
        }
        .ant-modal-close-x {
            color: #000000;
            font-weight: 700;
            width: 60px;
            height: 64px;
            font-size: 18px;
            line-height: 65px;
        }
        .ant-modal-body {
            padding: 15px 24px;
            .ant-form.ant-form-horizontal {
                .ant-form-item-label {
                    padding-bottom: 5px !important;
                    > label {
                        height: 0;
                        color: #000000;
                        font-size: 15px;
                        font-weight: 400;
                    }
                }
                .ant-input {
                    color: #536475;
                    background-color: #ffffff;
                    border-radius: 10px;
                    height: 42px;
                    font-weight: 300;
                    &:hover {
                        border-color: #49484c;
                    }
                    &:focus {
                        box-shadow: none;
                        border-color: #49484c;
                    }
                    &.ant-picker-focused {
                        box-shadow: none;
                        border-color: #49484c;
                    }
                }
                textarea {
                    &.ant-input {
                        height: auto;
                    }
                }
                .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
                    background-color: #fff;
                    border-radius: 10px;
                    color: #536475;
                    align-items: center;
                    font-weight: 300;
                    height: 42px;
                    &:hover {
                        border-color: #49484c;
                    }
                    &:focus {
                        box-shadow: none;
                        outline: none;
                        border-color: #49484c;
                    }
                    &.ant-picker-focused {
                        box-shadow: none;
                        outline: none;
                        border-color: #49484c;
                    }
                }
                .ant-btn {
                    background-color: #49484c;
                    border: 1px solid #49484c;
                    color: #ffffff;
                    font-size: 15px;
                    height: 42px;
                    cursor: pointer;
                    border-radius: 10px;
                    padding: 8px 50px;
                }
            }
        }
    }
}
.payment-popup.ant-modal {
    .ant-modal-content {
        border-radius: 5px;
        .ant-modal-header {
            padding: 20px 24px;
            border-bottom: 0;
            .ant-modal-title {
                color: #202020;
                font-weight: 500;
                font-size: 20px;
            }
        }
        .ant-modal-close-x {
            color: #000000;
            font-weight: 700;
            width: 60px;
            height: 64px;
            font-size: 18px;
            line-height: 65px;
        }
        .ant-modal-body {
            background-color: #F5F5F5;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            padding: 15px 24px;
            .sec-type-tabs.ant-tabs-top
                > .ant-tabs-nav {
                    margin: 0 0 20px 0;
                    .ant-tabs-nav-list {
                        width: 100%;
                        .ant-tabs-tab {
                            padding: 20px;
                            background-color: #E4E4E4;
                            border-radius: 5px;
                            color: #707070;
                            font-size: 15px;
                            font-weight: 600;
                            width: 50%;
                            margin-right: 14px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            &.ant-tabs-tab-active,
                            &.ant-tabs-tab-active:active,
                            &.ant-tabs-tab-active:focus {
                                color: #ffffff;
                                font-weight: 600;
                                background-color: #49484c;
                                .ant-tabs-tab-btn,
                                .ant-tabs-tab-btn:hover,
                                .ant-tabs-tab-btn:focus,
                                .ant-tabs-tab-btn:active {
                                    color: #ffffff;
                                    text-shadow: none;
                                }
                            }
                            &:hover {
                                color: #ffffff;
                                background-color: #49484c;
                            }
                        }
                        > .ant-tabs-tab:nth-child(2) {
                            margin-right: 0 !important;
                        }
                        .ant-tabs-ink-bar {
                            background: none;
                        }
                    }
                    &::before {
                        border-bottom: 0;
                    }
                }
            }
            .payment-type-tabs.ant-tabs-top
                > .ant-tabs-nav {
                    .ant-tabs-nav-list {
                        width: 100%;
                        .ant-tabs-tab {
                            padding: 13px;
                            background-color: #ffffff;
                            border-radius: 5px;
                            color: #000000;
                            font-size: 14px;
                            font-weight: 600;
                            width: inherit;
                            height: 100px;
                            margin-right: 16px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-bottom: 5px solid #ffffff;
                            border-bottom-left-radius: 6px;
                            border-bottom-right-radius: 5px;
                            flex-direction: column;
                            &.ant-tabs-tab-active {
                                color: #000000;
                                font-weight: 600;
                                background-color: #ffffff;
                                border-bottom: 5px solid #49484c;
                                border-bottom-left-radius: 6px;
                                border-bottom-right-radius: 5px;
                                .ant-tabs-tab-btn {
                                    color: #000000;
                                    text-shadow: none;
                                }
                            }
                            &:hover {
                                color: #000000;
                                font-weight: 600;
                                background-color: #ffffff;
                                border-bottom: 5px solid #49484c;
                                border-bottom-left-radius: 6px;
                                border-bottom-right-radius: 5px;
                            }
                        }
                        > .ant-tabs-tab::before {
                            display: block;
                            height: 29px;
                            width: 29px;
                            background-repeat: no-repeat;
                            background-position: center;
                            margin-bottom: 5px;
                        }
                        > .ant-tabs-tab:nth-child(1)::before {
                            content: "";
                            background-image: url(/images/card-inactive.png);
                        }
                        > .ant-tabs-tab:nth-child(2)::before {
                            content: "";
                            background-image: url(/images/bank-inactive.png);
                        }
                        > .ant-tabs-tab:nth-child(3)::before {
                            content: "";
                            background-image: url(/images/cheque-inactive.png);
                        }
                        > .ant-tabs-tab.ant-tabs-tab-active:nth-child(1)::before {
                            content: "";
                            background-image: url(/images/card-active.png);
                        }
                        > .ant-tabs-tab.ant-tabs-tab-active:nth-child(2)::before {
                            content: "";
                            background-image: url(/images/bank-active.png);
                        }
                        > .ant-tabs-tab.ant-tabs-tab-active:nth-child(3)::before {
                            content: "";
                            background-image: url(/images/cheque-active.png);
                        }
                        > .ant-tabs-tab:nth-child(3) {
                            margin-right: 0 !important;
                        }
                        .ant-tabs-ink-bar {
                            background: none;
                        }
                    }
                    &::before {
                        border-bottom: 0;
                    }
                }
                .ant-tabs-content-holder {
                    .card-select {
                        border-radius: 10px;
                        .ant-card-body {
                            padding: 14px 30px;
                            .ant-radio-wrapper {
                                color: #49484c;
                                font-size: 16px;
                                line-height: normal;
                                font-weight: 500;
                                :hover .ant-radio {
                                    border-color: #49484c;
                                    &:hover .ant-radio-inner {
                                        border-color: #49484c;
                                    }
                                }
                                .ant-radio-checked .ant-radio-inner {
                                    border-color: #49484c;
                                }
                                .ant-radio-inner {
                                    width: 22px;
                                    height: 22px;
                                    &::after {
                                        width: 29px;
                                        height: 29px;
                                        top: 4px;
                                        left: 4px;
                                        background-color: #49484c;
                                    }
                                }
                                span.ant-radio + * {
                                    padding-right: 10px;
                                    padding-left: 10px;
                                }
                                .ant-radio-input:focus + .ant-radio-inner {
                                    border-color: #49484c;
                                }
                            }
                        }
                    }
                    .ant-form.ant-form-horizontal {
                        .ant-form-item-label {
                            padding-bottom: 5px !important;
                            > label {
                                height: 0;
                                color: #1E1E1F;
                                font-size: 15px;
                                font-weight: 500;
                            }
                        }
                        .ant-input,
                        .ant-picker {
                            color: #000000;
                            background-color: #F5F5F5;
                            border: 0;
                            border-radius: 8px;
                            height: 42px;
                            &:hover {
                                border-color: #49484c;
                            }
                            &:focus {
                                border-right-width: 0 !important;
                                box-shadow: none;
                            }
                            &.ant-picker-focused {
                                border-right-width: 0 !important;
                                box-shadow: none;
                            }
                        }
                        .ant-picker-input > input {
                            color: #000000;
                            &:focus {
                                border-right-width: 0 !important;
                                box-shadow: none;
                            }
                        }
                        span.help-text {
                            display: inline;
                            position: absolute;
                            right: 0;
                            top: -27px;
                            color: #B4B4B4;
                            font-size: 14px;
                            font-weight: 400;
                        }
                        .ant-checkbox-wrapper {
                            color: #1E1E1F;
                            font-size: 15px !important;
                            .ant-checkbox-checked .ant-checkbox-inner {
                                background-color: #49484c;
                                border-color: #49484c;
                            }
                        }
                        .ant-btn {
                            height: 42px;
                            font-size: 15px;
                            opacity: 1;
                            font-weight: 500;
                        }
                        .bank-form {
                            .ant-form-item-label {
                                > label {
                                    height: 0;
                                    color: #707070;
                                    font-size: 15px;
                                    font-weight: 400;
                                }
                            }
                            .ant-input,
                            .ant-picker {
                                background-color: #FFFFFF;
                                border: 0;
                                border-radius: 8px;
                                height: 42px;
                                &:hover {
                                    border-color: #49484c;
                                }
                                &:focus {
                                    border-right-width: 0 !important;
                                    box-shadow: none;
                                }
                                &.ant-picker-focused {
                                    border-right-width: 0 !important;
                                    box-shadow: none;
                                }
                            }
                            .ant-picker-input > input {
                                color: #000000;
                                &:focus {
                                    border-right-width: 0 !important;
                                    box-shadow: none;
                                }
                            }
                            .ant-form-item
                                .ant-upload {
                                    width: 100%;
                                }
                            }
                            .ant-upload.ant-upload-select {
                                // display: block;
                                .upload-photo-btn {
                                    border: 0;
                                    border-radius: 8px;
                                    color: #000000;
                                    font-size: 14px !important;
                                    font-weight: 400 !important;
                                    &:hover {
                                        color: #49484c !important;
                                    }
                                    &:focus {
                                        border-right-width: 0 !important;
                                        box-shadow: none;
                                    }
                                }
                            }
                            .ant-upload-list-picture .ant-upload-list-item,
                            .ant-upload-list-picture-card .ant-upload-list-item {
                                height: 85px;
                                padding: 8px;
                                border: 0;
                                border-radius: 8px;
                                margin-top: 12px;
                                background-color: #fff;
                            }
                            .ant-upload-list-picture .ant-upload-list-item:hover,
                            .ant-upload-list-picture-card .ant-upload-list-item:hover {
                                background: #fff;
                            }
                            .ant-upload-list-picture .ant-upload-list-item-thumbnail,
                            .ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
                                top: 13px;
                                left: 11px;
                                width: 138px;
                                height: 63px;
                                line-height: normal;
                                opacity: 1;
                                object-fit: cover;
                            }
                            .ant-upload-list-picture .ant-upload-list-item-thumbnail img,
                            .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
                                object-fit: cover;
                                width: 138px;
                                height: 60px;
                            }
                            .ant-upload-list-picture .ant-upload-list-item-name,
                            .ant-upload-list-picture-card .ant-upload-list-item-name {
                                display: none;
                            }
                            .ant-upload-list-item-card-actions.picture {
                                top: 2px;
                                line-height: 0;px;
                                z-index: 99;
                                left: 135px;
                            }
                            .ant-upload-list-item-card-actions-btn {
                                background-color: #FF0000;
                                border-radius: 50% !important;
                                height: 20px !important;
                                width: 20px !important;
                                top: -28px;
                                left: -11px;
                                &:hover {
                                    background-color: #FF0000 !important;
                                }
                            }
                            .ant-upload-list-item-card-actions .anticon {
                                color: #fff;
                                font-size: 13px;
                            }
                        }
                    }
                }
            }
        }
    }
}
.accept-offer-popup {
    .ant-modal-close {
        .ant-modal-close-x {
            .anticon  {
                font-size: 18px;
                color: #252525;
            }
            display: block !important;
        }
    }
    .modal-title {
        margin-bottom: 20px;
        display: inline-block;
        color: #252525;
    }
}
.contract-popup.ant-modal {
    .ant-modal-content {
        border-radius: 5px;
        .ant-modal-header {
            padding: 20px 24px;
            border-bottom: 0;
            .ant-modal-title {
                color: #202020;
                font-weight: 500;
                font-size: 20px;
            }
        }
        .ant-modal-close-x {
            color: #000000;
            font-weight: 700;
            width: 60px;
            height: 64px;
            font-size: 18px;
            line-height: 65px;
            display: none;
        }
        .ant-modal-body {
            background-color: #F5F5F5;
            border-radius: 5px;
            padding: 22px 20px;
            .title {
                color: #000000;
                font-weight: 600;
                font-size: 16px;
            }
            .navigate-item {
                padding: 16px 25px;
                border-radius: 8px;
                margin-bottom: 20px;
                .title {
                    color: #000000;
                    font-weight: 400;
                    font-size: 14px;
                }
                .anticon {
                    float: right;
                    font-size: 15px;
                    vertical-align: middle;
                    color: #000000;
                    line-height: 30px;
                }
                .anticon-check-circle {
                    font-size: 21px;
                    color: #34D249;
                }
            }
            .ant-btn {
                height: 42px;
                font-size: 15px;
                opacity: 1;
                font-weight: 500;
            }
            .btn.btn-outline-primary {
                background-color: #ffffff;
                border: 1px solid #49484c;
                color: #49484c;
                font-size: 15px;
                border-radius: 5px;
                height: 42px;
                cursor: pointer;
                &:hover {
                    background-color: #49484c;
                    border: 1px solid #49484c;
                    color: #ffffff;
                    transition: all 0.6s ease;
                }
            }
            .ant-breadcrumb {
                padding: 0px 0px;
                a{
                   color: #49484C !important;
                }
                .ant-breadcrumb-link,
                .back-breadcrumb-link {
                        color: #49484C !important;
                        font-size: 14px !important;
                        cursor: pointer;
                    font-weight: 500 !important;

                        @media (max-width:576.98px){
                            font-size: 13px !important;
                        }
                    .anticon {
                        font-size: 15px;
                        vertical-align: middle;
                        color: #000000;
                        margin-right: 5px;
                        font-weight: 900;
                    }
                }
                .ant-breadcrumb-separator {
                    color: #49484C;
                    font-size: 14px;
                    font-weight: 500 !important;
                }
                > span a {
                    color: #000000;
                    font-size: 16px;
                    font-weight: 700;
                }
            }
            .ant-steps {
                .ant-steps-item {
                    margin-right: 30px;
                    white-space: nowrap;
                    width: 50%;
                    text-align: center;
                }
                &::after {
                    content: "";
                    display: block;
                    width: 100%;
                    border-bottom: 2px solid rgba(0,0,0,0.1);
                    position: absolute;
                    left: 5px;
                    right: 5px;
                    top: 43px;
                }
                .ant-steps-item-icon {
                    display: none;
                }
                .ant-steps-item-process.ant-steps-item-active {
                    .ant-steps-item-title {
                        font-weight: 500;
                        color: #0F0F0E;
                        &::after {
                            content: "";
                            border-bottom: 3px solid #49484c;
                            display: block;
//                            width: 220px;
                            position: absolute;
                            top: 42px;
                            left: 0;
                            right: 0;
                            margin: 0 auto;
                        }
                    }
                }
                .ant-steps-item-finish  {
                    .ant-steps-item-title {
                        &::after {
                            content: "";
                            background: transparent;
                        }
                    }
                }
                .ant-steps-item-wait {
                    .ant-steps-item-title {
                        &::after {
                            content: "";
                            background: transparent;
                        }
                    }
                }
                .ant-steps-item-content {
                    display: block;
                    .ant-steps-item-title {
                        padding-right: 0;
                        color: #000000;
                        font-size: 15px;
                        line-height: 48px;
                        font-weight: 400;
                        display: block;
                    }
                }
            }
            .details-form-sec {
                .tenant-form {
                    .d-none {
                        display: none;
                    }
                    .title-form {
                        color: #536475;
                        font-size: 16px;
                        font-weight: 600;
                    }
                    .ant-form-item.h-48 {
                        label {
                            height: 48px;
                        }
                    }
                    .ant-form-item-label {
                        padding-bottom: 5px;
                        > label {
                            height: auto;
                            color: #536475;
                            font-size: 15px;
                            font-weight: 400;
                            display: block !important;
                        }
                    }
                    .ant-input,
                    .ant-picker,
                    .ant-input-number {
                        border: 1px solid #B2BFD2;
                        background-color: #FFFFFF;
                        border-radius: 8px;
                        height: 45px;
                        color: #000000;
                        &:hover {
                            border-color: #49484c;
                        }
                        &:focus {
                            box-shadow: none;
                            border-color: #49484c;
                        }
                        &.ant-picker-focused {
                            box-shadow: none;
                            border-color: #49484c;
                        }
                        .ant-input-number-input {
                            height: 45px;
                            &:hover {
                                border-color: #49484c;
                            }
                            &:focus {
                                box-shadow: none;
                                border-color: #49484c;
                            }
                        }
                        .ant-input-number-handler-wrap {
                            border-radius: 0 8px 8px 0;
                        }
                    }
                    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
                        align-items: center;
                        border: 1px solid #B2BFD2;
                        background-color: #FFFFFF;
                        border-radius: 8px;
                        height: 45px;
                        color: #000000;
                        &:hover {
                            border-color: #49484c;
                        }
                        &:focus {
                            box-shadow: none;
                            border-color: #49484c;
                        }
                    }
                    textarea.ant-input {
                        height: auto !important;
                    }
                    .ant-picker-input > input {
                        color: #000000;
                        &:focus {
                            box-shadow: none;
                            border-color: #49484c;
                        }
                    }
                    .add-files {
                        .upload-photo-btn {
                            background-color: transparent !important;
                            border: 1px dashed #A4A4A4 !important;
                            color: #707070 !important;
                            border-radius: 7px !important;
                            height: 118px !important;
                            justify-content: center;
                            &:hover {
                                background-color: transparent !important;
                                border: 1px dashed #A4A4A4 !important;
                                color: #707070 !important;
                            }
                            &:focus {
                                outline: 0 !important;
                                box-shadow: none !important;
                            }
                            .anticon {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                color: #49484c;
                                font-size: 30px !important;
                            }
                        }
                    }
                    .ant-form-item
                        .ant-upload {
                            width: 100%;
                        }
                    }
                    .ant-upload.ant-upload-select {
                        // display: block;
                        .upload-photo-btn {
                            border: 1px solid #B2BFD2;
                            border-radius: 8px;
                            color: rgba(0,0,0,0.2);
                            font-size: 14px !important;
                            font-weight: 500 !important;
                            height: 45px;
                            padding: 4px 11px;
                            display: flex;
                            align-items: center;
                            &:hover {
                                border-color: #49484c;
                                color: #49484c !important;
                            }
                            &:focus {
                                box-shadow: none;
                                border-color: #49484c;
                            }
                            .anticon {
                                float: right;
                                width: 100%;
                                font-size: 20px;
                                svg {
                                    float: right;
                                }
                            }
                        }
                    }
                    .ant-upload-list-picture .ant-upload-list-item,
                    .ant-upload-list-picture-card .ant-upload-list-item {
                        height: 85px;
                        padding: 8px;
                        border: 0;
                        border-radius: 8px;
                        margin-top: 12px;
                        background-color: #fff;
                    }
                    .ant-upload-list-picture .ant-upload-list-item:hover,
                    .ant-upload-list-picture-card .ant-upload-list-item:hover {
                        background: #fff;
                    }
                    .ant-upload-list-picture .ant-upload-list-item-thumbnail,
                    .ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
                        top: 13px;
                        left: 11px;
                        width: 138px;
                        height: 63px;
                        line-height: normal;
                        opacity: 1;
                        object-fit: cover;
                    }
                    .ant-upload-list-picture .ant-upload-list-item-thumbnail img,
                    .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
                        object-fit: cover;
                        width: 138px;
                        height: 60px;
                    }
                    .ant-upload-list-picture .ant-upload-list-item-name,
                    .ant-upload-list-picture-card .ant-upload-list-item-name {
                        display: none;
                    }
                    .ant-upload-list-item-card-actions.picture {
                        top: 2px;
                        line-height: 0;px;
                        z-index: 99;
                        left: 135px;
                    }
                    .ant-upload-list-item-card-actions-btn {
                        background-color: #FF0000;
                        border-radius: 50% !important;
                        height: 20px !important;
                        width: 20px !important;
                        top: -28px;
                        left: -11px;
                        &:hover {
                            background-color: #FF0000 !important;
                        }
                    }
                    .ant-upload-list-item-card-actions .anticon {
                        color: #fff;
                        font-size: 13px;
                    }
                    .ant-select-focused {
                        &:focus {
                            box-shadow: none;
                            border-color: #49484c;
                        }
                        .ant-select-selector {
                            &:focus {
                                box-shadow: none;
                                border-color: #49484c;
                            }
                        }
                    }
                    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
                        background-color: #fff;
                        border: 0;
                        border-radius: 8px;
                        color: #000000;
                        align-items: center;
                        height: 42px;
                    }
                    .switch-sec {
                        span {
                            color: #AFB0B5;
                            font-size: 14px;
                            font-weight: 400;
                        }
                        .ant-switch {
                            &.ant-switch-checked {
                                background-color: #49484c;
                                .ant-switch-handle {
                                    left: calc(100% - 18px - 1px);
                                }
                            }
                            margin-top: 5px;
                            min-width: 48px;
                            height: 20px;
                            float: right;
                            background-color: #DEDEDE;
                            .ant-switch-handle {
                                top: 1px;
                            }
                        }
                    }
                    .added-person-data {
                        padding: 15px 20px;
                        span {
                            font-size: 16px;
                            font-weight: 400;
                            color: #536475;
                        }
                    }
                }
            }
            .ant-btn.form-submit {
                height: 46px;
                font-size: 16px;
                font-weight: 400;
            }
            .ant-btn.add-btn {
                background-color: #A4A4A6;
                border-color: #A4A4A6;
                color: #ffffff;
                &:hover {
                    background: #49484c;
                    border-color: #49484c;
                }
            }
        }
    }
}
.contacted-success {
    .ant-modal-body {
        padding: 40px 28px !important;
    }
}
.success-popup-modal {
        .ant-modal-body {
            padding: 40px 55px;
            border-radius: 5px;
            .anticon.anticon-smile {
                color: #70BF42;
                margin-bottom: 10px;
                font-size: 40px;
            }
        }
        .ant-modal-content {
            border-radius:5px;
            .success-text {
                color: #707070;
                font-weight: 500;
            }
            .cont-btn {
                height: 38px;
                font-weight: 500;
                font-size: 13px;
            }
            .resend-btn {
                background-color: #E7ECEE;
                border: 0;
                border-radius: 5px;
                width: 100%;
                height: 43px;
                color: #808186;
                font-size: 12px;
                font-weight: 600;
                margin-bottom: 13px;
                box-shadow: none;
            }
            .manage-btn{
                padding-top: 5px;
                @media only screen and (max-width: 767.98px){
                    padding-top: 0;
                }
            }
        }
        .mb-30px {
            margin-bottom:30px;
        }
        .font-24 {
            font-size:24px;
            @media only screen and (max-width: 767.98px){
                font-size: 15px;
            }
        }
        .text-primary {
            color:#49484c;
            font-weight:normal;
        }
        .ant-modal-close-x {
            display: block;
        }
        .ant-modal-footer {
            display: none;
        }
      .ant-btn-primary {
            background-color: #49484c;
            border: 1px solid #49484c;
            color: #ffffff;
            font-size: 14px;
            border-radius: 5px;
            padding: 9px 20px;
            cursor:pointer;
            margin:20px 0px 0px;
            height:46px;
            width: 100%;
            display: block;
            text-align: center;
            @media only screen and (max-width: 767.98px){
                font-size: 14px;
                padding: 6px 20px !important;
                max-width: 100%;
                height: 40px;
            }
            &:hover {
                background-color: #ffffff;
                border: 1px solid #49484c;
                color: #49484c;
                transition: all 0.6s ease-in-out;
            }
         }
      }
.schedule-visit-modal {
    .btn-primary{
        height: 35px;
        padding: 4px 15px;
        font-size: 12px;
        border-radius: 5px;
        font-weight: bold;
        color: #fff;
        background-color: #49484C;
        border: 1px solid #ffffff;
        crusor:pointer
        &:hover{
            color:#49484C !important;
            background-color: #F3B521 !important;
        }
    }
    .btn-secondary{
        height: 35px;
        padding: 4px 15px;
        font-size: 12px;
        border-radius: 5px;
        font-weight: bold;
        color: #49484C;
        background-color: #fff;
        border: 1px solid #49484C;
        crusor:pointer
        &:hover{
            color:#fff !important;
            background-color: #49484C !important;
        }
    }
    .para{
        font-size:13px;
        color:#9c9494;
    }
    .mt-3{
        margin-top:10px
    }
    .border-bottom{
        border-bottom: 1px solid #cbc8c8;
    }
    .property1{
        font-size: 16px;
    }
    .call{
        margin-right:10px;
        background:#c4bebe;
        border-radius:50%;
        padding: 8px;
        color: #fff;
    }
    .text-warning{
        color:#F3B521;
    }
    .button-sections1{
        float:right;
        margin-top:20px;
    }
    .txt-check{
        margin-left:10px;
    }
    .ant-modal-content {
        border-radius:5px;
        .ant-modal-header {
            border-bottom: 1px solid transparent;
            padding: 25px 22px 25px;
            .ant-modal-title {
                color:#3D3D3D;
                margin-bottom:0;
                font-size: 18px;
            }
        }
        .ant-modal-close {
            right: 16px;
            top: 8px;
            @media only screen and (max-width: 767.98px){
                right: 0px;
                top: 10px;
            }
            .ant-modal-close-x {
                .anticon  {
                    font-size: 18px;
                    color:#3D3D3D;
                }
            }
        }
        .ant-modal-body {
            padding:20px;
            border-radius:5px;
            @media only screen and (max-width: 767.98px){
                padding: 15px 20px 20px;
            }
            .font-18 {
                @media only screen and (max-width: 767.98px){
                    font-size: 16px;
                }
            }
            .modal-title {
                margin-bottom: 20px;
                margin-top: 10px;
                display: inline-block;
                @media only screen and (max-width: 767.98px){
                    font-size: 16px;
                }
            }
            .back-icon {
                font-size: 15px;
                vertical-align: middle;
                color: #000000;
                line-height: 39px;
                background-color: #eeeeee;
                border-radius: 50%;
                height: 35px;
                width: 35px;
                padding: 0 6px;
                margin-right: 10px;
            }
            .submit-interest-steps{
                .ant-steps {
                    .ant-steps-item {
                        width:auto;
                        max-width:100px
                    }
                }
            }
            .ant-steps {
                .ant-steps-item {
                    margin-right: 30px;
                    white-space: nowrap;
                    width: 50%;
//    width: fit-content !important;
//    border-top-left-radius: 8px;
//    overflow: hidden;
//    border-top-right-radius: 8px;
                    text-align: center;
                }
                &::after {
                    content: "";
                    display: block;
                    width: 100%;
                    border-bottom: 2px solid rgba(0,0,0,0.1);
                    position: absolute;
                    left: 5px;
                    right: 5px;
                    top: 43px;
                }
                .ant-steps-item-icon {
                    display: none;
                }
                .ant-steps-item-process.ant-steps-item-active {
                    .ant-steps-item-title {
                        font-weight:500;
                        color: #0F0F0E;
                        &::after {
                            content: "";
                            border-bottom: 3px solid #FFA300;
                            display: block;
                            width: 40px;
                            position: absolute;
                            top: 42px;
                            left: 0;
                            right: 0;
                            margin: 0 auto;
                        }
                    }
                }
                .ant-steps-item-finish  {
                    .ant-steps-item-title {
                        &::after {
                            content: "";
                            background: transparent;
                        }
                    }
                }
                .ant-steps-item-wait {
                    .ant-steps-item-title {
                        &::after {
                            content: "";
                            background: transparent;
                        }
                    }
                }
                .ant-steps-item-content {
                    display: block;
                    .ant-steps-item-title {
                        padding-right: 0;
                        color: #A8A8A8;
                        font-size: 14px;
                        line-height: 48px;
                        font-weight: 400;
                        display: block;
                    }
                }
            }
            .steps-action {
                .ant-btn-primary {
                    margin: 0px 0px 0px !important;
                }
            }
            .details-form-sec {
                .tenant-form {
                    .d-none {
                        display: none;
                    }
                    .title-form {
                        color: #536475;
                        font-size: 16px;
                        font-weight: 600;
                    }
                    .ant-form-item.h-48 {
                        label {
                            height: 48px;
                        }
                    }
                    .ant-form-item-label {
                        padding-bottom: 5px;
                        > label {
                            height: auto;
                            color: #536475;
                            font-size: 15px;
                            font-weight: 400;
                            display: block !important;
                        }
                    }
                    .ant-input,
                    .ant-picker,
                    .ant-input-number {
                        border: 1px solid #B2BFD2;
                        background-color: #FFFFFF;
                        border-radius: 8px;
                        height: 45px;
                        color: #000000;
                        &:hover {
                            border-color: #49484c;
                        }
                        &:focus {
                            box-shadow: none;
                            border-color: #49484c;
                        }
                        &.ant-picker-focused {
                            box-shadow: none;
                            border-color: #49484c;
                        }
                        .ant-input-number-input {
                            height: 45px;
                            &:hover {
                                border-color: #49484c;
                            }
                            &:focus {
                                box-shadow: none;
                                border-color: #49484c;
                            }
                        }
                        .ant-input-number-handler-wrap {
                            border-radius: 0 8px 8px 0;
                        }
                    }
                    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
                        align-items: center;
                        border: 1px solid #B2BFD2;
                        background-color: #FFFFFF;
                        border-radius: 8px;
                        height: 45px;
                        color: #000000;
                        &:hover {
                            border-color: #49484c;
                        }
                        &:focus {
                            box-shadow: none;
                            border-color: #49484c;
                        }
                    }
                    textarea.ant-input {
                        height: auto !important;
                    }
                    .ant-picker-input > input {
                        color: #000000;
                        &:focus {
                            box-shadow: none;
                            border-color: #49484c;
                        }
                    }
                    .add-files {
                        .upload-photo-btn {
                            background-color: transparent !important;
                            border: 1px dashed #A4A4A4 !important;
                            color: #707070 !important;
                            border-radius: 7px !important;
                            height: 118px !important;
                            justify-content: center;
                            &:hover {
                                background-color: transparent !important;
                                border: 1px dashed #A4A4A4 !important;
                                color: #707070 !important;
                            }
                            &:focus {
                                outline: 0 !important;
                                box-shadow: none !important;
                            }
                            .anticon {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                color: #49484c;
                                font-size: 30px !important;
                            }
                        }
                    }
                    .ant-form-item
                        .ant-upload {
                            width: 100%;
                        }
                    }
                    .ant-upload.ant-upload-select {
                        // display: block;
                        .upload-photo-btn {
                            border: 1px solid #B2BFD2;
                            border-radius: 8px;
                            color: rgba(0,0,0,0.2);
                            font-size: 14px !important;
                            font-weight: 500 !important;
                            height: 45px;
                            padding: 4px 11px;
                            display: flex;
                            align-items: center;
                            &:hover {
                                border-color: #49484c;
                                color: #49484c !important;
                            }
                            &:focus {
                                box-shadow: none;
                                border-color: #49484c;
                            }
                            .anticon {
                                float: right;
                                width: 100%;
                                font-size: 20px;
                                svg {
                                    float: right;
                                }
                            }
                        }
                    }
                    .ant-upload-list-picture .ant-upload-list-item,
                    .ant-upload-list-picture-card .ant-upload-list-item {
                        height: 85px;
                        padding: 8px;
                        border: 0;
                        border-radius: 8px;
                        margin-top: 12px;
                        background-color: #fff;
                    }
                    .ant-upload-list-picture .ant-upload-list-item:hover,
                    .ant-upload-list-picture-card .ant-upload-list-item:hover {
                        background: #fff;
                    }
                    .ant-upload-list-picture .ant-upload-list-item-thumbnail,
                    .ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
                        top: 13px;
                        left: 11px;
                        width: 138px;
                        height: 63px;
                        line-height: normal;
                        opacity: 1;
                        object-fit: cover;
                    }
                    .ant-upload-list-picture .ant-upload-list-item-thumbnail img,
                    .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
                        object-fit: cover;
                        width: 138px;
                        height: 60px;
                    }
                    .ant-upload-list-picture .ant-upload-list-item-name,
                    .ant-upload-list-picture-card .ant-upload-list-item-name {
                        display: none;
                    }
                    .ant-upload-list-item-card-actions.picture {
                        top: 2px;
                        line-height: 0;px;
                        z-index: 99;
                        left: 135px;
                    }
                    .ant-upload-list-item-card-actions-btn {
                        background-color: #FF0000;
                        border-radius: 50% !important;
                        height: 20px !important;
                        width: 20px !important;
                        top: -28px;
                        left: -11px;
                        &:hover {
                            background-color: #FF0000 !important;
                        }
                    }
                    .ant-upload-list-item-card-actions .anticon {
                        color: #fff;
                        font-size: 13px;
                    }
                    .ant-select-focused {
                        &:focus {
                            box-shadow: none;
                            border-color: #49484c;
                        }
                        .ant-select-selector {
                            &:focus {
                                box-shadow: none;
                                border-color: #49484c;
                            }
                        }
                    }
                    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
                        background-color: #fff;
                        border: 0;
                        border-radius: 8px;
                        color: #000000;
                        align-items: center;
                        height: 42px;
                    }
                    .switch-sec {
                        span {
                            color: #AFB0B5;
                            font-size: 14px;
                            font-weight: 400;
                        }
                        .ant-switch {
                            &.ant-switch-checked {
                                background-color: #49484c;
                                .ant-switch-handle {
                                    left: calc(100% - 18px - 1px);
                                }
                            }
                            margin-top: 5px;
                            min-width: 48px;
                            height: 20px;
                            float: right;
                            background-color: #DEDEDE;
                            .ant-switch-handle {
                                top: 1px;
                            }
                        }
                    }
                    .added-person-data {
                        padding: 15px 20px;
                        span {
                            font-size: 16px;
                            font-weight: 400;
                            color: #536475;
                        }
                    }
                }
            }
            .react-calendar {
                width: 100%;
                margin-bottom: 30px;
                border: none;
                box-shadow: 0 0.3rem 0.6rem rgba(0,0,0,.15)!important;
                backdrop-filter: blur(40px);
                border-radius: 12px;
                overflow: hidden;
                background: #fff;
                .react-calendar__navigation {
                    //background-color:  #a9a9a9;
                    border:1px solid #f5f5f5;
                    margin-bottom: 0;
                    .react-calendar__navigation__prev2-button,
                    .react-calendar__navigation__next2-button {
                        display: none;
                    }
                    .react-calendar__navigation__arrow {
                        border:none;
                        font-size:36px;
                    }
                    .react-calendar__navigation__label {

                        .react-calendar__navigation__label__labelText {
                                color: #000000;
                                font-weight: 600;
                                font-size: 17px;
                                line-height: 30px;
                            @media only screen and (max-width: 767.98px){
                                font-size: 14px;
                            }
                        }
                    }
                    button {
                        &:enabled {
                            &:hover {
                                // background-color:  #a9a9a9;
                            }
                        }
                        &:enabled {
                            &:focus {
                                // background-color: #a9a9a9;
                            }
                        }
                    }
                }
                .react-calendar__viewContainer {
                    background-color: #fff;
                    .react-calendar__month-view  {
                        border: 1px solid #f5f5f5;
                        .react-calendar__month-view__weekdays {
                            // padding: 10px 0px;
                            // background: #fff3de;
                            .react-calendar__month-view__weekdays__weekday {
                                cursor: default;
                                abbr[title], abbr[data-original-title] {
                                    cursor:default;
                                    text-decoration:none;
                                    font-weight: normal;
                                    color: #000000;
                                    font-weight: 500;
                                }
                            }
                        }
                        .react-calendar__month-view__days {
                            .react-calendar__tile--now {
                                // background: #a9a9a9;
                            }
                            .react-calendar__tile {
                                color: #000;
                                font-size: 12px;
                                width: 36px;
                                height: 40px;
                                border:none;
                                @media only screen and (max-width: 767.98px){
                                    height: 40px;
                                }
                                // &:enabled{
                                //     &:hover {
                                //         background: #a9a9a9;
                                //     }
                                // }
                                // &:enabled{
                                //     &:focus {
                                //         background: #a9a9a9;
                                //     }
                                // }
                                // &.react-calendar__tile--active {
                                //     background: #a9a9a9;
                                // }
                                abbr {
                                    width: 22px;
                                    height: 22px;
                                    display: block;
                                    border-radius: 50%;
                                    margin: 0 auto;
                                    line-height: 22px;
                                    color:#000000;
                                }
                            }
                        }
                    }
                    .react-calendar__year-view {
                        border:1px solid #f5f5f5;
                        .react-calendar__year-view__months {
                            .react-calendar__tile {
                                color: #000;
                                font-size: 12px;
                                border:1px solid #f5f5f5;
                                &:enabled{
                                    &:hover {
                                        abbr {
                                            background: #FFF3DE;
                                        }
                                        // background: #a9a9a9;
                                    }
                                }
                                &:enabled{
                                    &:focus {
                                        // background: #a9a9a9;
                                    }
                                }
                            }
                            .react-calendar__tile--now {
                                background: transparent;
                            }
                        }
                    }
                    .react-calendar__decade-view {
                        border:1px solid #f5f5f5;
                        .react-calendar__decade-view__years {
                            .react-calendar__tile {
                                color: #000;
                                font-size: 12px;
                                border:1px solid #f5f5f5;
                                // &:enabled {
                                //     &:hover {
                                //         background: #a9a9a9;
                                //     }
                                // }
                                // &:enabled{
                                //     &:focus {
                                //         background: #a9a9a9;
                                //     }
                                // }
                            }
                            .react-calendar__tile--now {
                                // background: rgba(7, 71,166,0.18);
                            }
                        }
                    }
                    .react-calendar__century-view {
                        border:1px solid #f5f5f5;
                        .react-calendar__century-view__decades {
                            .react-calendar__tile {
                                color: #000;
                                font-size: 12px;
                                border:1px solid #f5f5f5;
                                &:enabled{
                                    &:hover {
                                        background: #a9a9a9;
                                    }
                                }
                                &:enabled{
                                    &:focus {
                                        background: #a9a9a9;
                                    }
                                }
                            }
                            .react-calendar__tile--now {
                                background: rgba(7, 71,166,0.18);
                            }
                        }
                    }
                }
            }
            .form-design {
                .form-sec-title {
                    color: #393939;
                    font-size: 18px;
                    font-weight: 500;
                    background: #F4F4F4;
                    border-radius: 8px;
                    padding: 12px;
                }
                .ant-form-item-label {
                    padding-bottom: 5px;
                     label {
                        font-size: 13px;
                        color: #393939;
                        &.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
                            display: inline-block;
                            margin-right: 4px;
                            color: #ff4d4f;
                            font-size: 14px;
                            font-family: 'Poppins', sans-serif !important;
                            line-height: 1;
                            content: '*';
                        }
                        &.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
                            display: inherit;
                            margin-right: inherit;
                            color: inherit;
                            font-size: inherit;
                            font-family: inherit;
                            line-height: inherit;
                            content: '';
                        }
                     }
                }
                .ant-form-item-control {
                    .ant-select {
                        .ant-select-selector {
                            position: relative;
                            background-color: #fff;
                            border: 1px solid #CBD6E2;
                            border-radius: 5px;
                            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
                            align-items: center;
                            height: 46px;
                            border: 1px solid #DCDCDC;
                            border-radius: 8px;
                        }
                        &.ant-select-focused {
                            .ant-select-selector {
                                box-shadow: none;
                            }
                        }
                    }
                    .ant-input {
                        background-color: #fff;
                        background-image: none;
                        border: 1px solid #cbd6e2;
                        border-radius: 5px;
                        transition: all 0.3s;
                        height: 46px;
                        border: 1px solid #DCDCDC;
                        border-radius: 8px;
                        // &:hover {
                        //  border-color: #DCDCDC;
                        // }
                        &:focus {
                            box-shadow: none;
                            border-color: #DCDCDC;
                        }
                        &.ant-picker-focused {
                            box-shadow: none;
                            border-color: #DCDCDC;
                        }
                    }
                }
                .ant-form-item.textarea {
                    .ant-input {
                        height: auto;
                    }
                }
                .add-files {
                    .upload-photo-btn {
                        background-color: transparent !important;
                        border: 1px dashed #A4A4A4 !important;
                        color: #707070 !important;
                        border-radius: 7px !important;
                        height: 118px !important;
                        justify-content: center;
                        &:hover {
                            background-color: transparent !important;
                            border: 1px dashed #A4A4A4 !important;
                            color: #707070 !important;
                        }
                        &:focus {
                            outline: 0 !important;
                            box-shadow: none !important;
                        }
                        .anticon {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: #49484c;
                            font-size: 25px !important;
                        }
                        p{
                            a{color: #FFA300;}
                        }
                    }
                }
                .ant-form-item
                    .ant-upload {
                        width: 100%;
                    }
                }
                .ant-upload.ant-upload-select {
                    // display: block;
                    .upload-photo-btn {
                        border: 1px solid #B2BFD2;
                        border-radius: 8px;
                        color: rgba(0,0,0,0.2);
                        font-size: 14px !important;
                        font-weight: 500 !important;
                        height: 45px;
                        padding: 4px 11px;
                        display: flex;
                        align-items: center;
                        &:hover {
                            border-color: #49484c;
                            color: #49484c !important;
                        }
                        &:focus {
                            box-shadow: none;
                            border-color: #49484c;
                        }
                        .anticon {
                            float: right;
                            width: 100%;
                            font-size: 20px;
                            svg {
                                float: right;
                            }
                        }
                    }
                }
                .ant-upload-list-picture .ant-upload-list-item,
                .ant-upload-list-picture-card .ant-upload-list-item {
                    height: 85px;
                    padding: 8px;
                    border: 0;
                    border-radius: 8px;
                    margin-top: 12px;
                    background-color: #fff;
                }
                .ant-upload-list-picture .ant-upload-list-item:hover,
                .ant-upload-list-picture-card .ant-upload-list-item:hover {
                    background: #fff;
                }
                .ant-upload-list-picture .ant-upload-list-item-thumbnail,
                .ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
                    top: 13px;
                    left: 11px;
                    width: 138px;
                    height: 63px;
                    line-height: normal;
                    opacity: 1;
                    object-fit: cover;
                }
                .ant-upload-list-picture .ant-upload-list-item-thumbnail img,
                .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
                    object-fit: cover;
                    width: 138px;
                    height: 60px;
                }
                .ant-upload-list-picture .ant-upload-list-item-name,
                .ant-upload-list-picture-card .ant-upload-list-item-name {
                    display: none;
                }
                .ant-upload-list-item-card-actions.picture {
                    top: 2px;
                    line-height: 0;px;
                    z-index: 99;
                    left: 135px;
                }
                .ant-upload-list-item-card-actions-btn {
                    background-color: #FF0000;
                    border-radius: 50% !important;
                    height: 20px !important;
                    width: 20px !important;
                    top: -28px;
                    left: -11px;
                    &:hover {
                        background-color: #FF0000 !important;
                    }
                }
                .ant-upload-list-item-card-actions .anticon {
                    color: #fff;
                    font-size: 13px;
                }
            }
        }
    }
    .ant-modal-footer {
       text-align: center;
    }
    .button-sections {
        margin: 0 -5px;
        button {
            flex: 1 1 0;
            margin: 0px 5px;
        }
    }
    .ant-btn-primary {
        background-color: #49484c;
        border: 1px solid #49484c;
        color: #ffffff;
        font-size: 14px;
        border-radius: 5px;
        padding: 9px 20px;
        cursor:pointer;
        height:46px;
        text-align: center;
        &:hover {
            background-color: #ffffff;
            border: 1px solid #49484c;
            color: #49484c;
            transition: all 0.6s ease-in-out;
        }
        @media only screen and (max-width: 767.98px){
            height: 40px;
            max-width: 100% !important;
            padding: 6px 20px !important;
        }
     }
    .btn-outline-primary {
        background-color: #ffffff;
        border: 1px solid #49484c;
        color: #49484c;
        font-size: 14px;
        border-radius: 5px;
        padding: 9px 20px;
        cursor: pointer;
        height: 46px;
        text-align: center;
        &:hover {
            background-color: #49484c;
            border: 1px solid #49484c;
            color: #ffffff;
            transition: all 0.6s ease-in-out;
        }
        @media only screen and (max-width: 767.98px){
            margin-bottom: 8px !important;
            height: 40px;
            max-width: 100% !important;
            padding: 6px 20px !important;
        }
    }
}
//feedback-modal
.feedback-modal {
    .ant-modal-content {
        border-radius: 10px;
        .ant-modal-close {
            display: none;
        }
        .ant-modal-header {
            padding: 16px 24px;
            border-radius: 10px 10px 0px 0px;
            background: #F5F5F5;
            .ant-modal-title {
                font-size: 18px;
                color: #202020;
                font-weight: 500;
            }
        }
        .ant-modal-body {
            padding: 20px 40px;
            h3 {
                color: #121213;
                font-size: 14px;
                font-weight: 600;
                margin-bottom: 15px;
            }
            .ant-btn-outline {
                background-color: #ffffff;
                border: 1px solid #49484c;
                color: #49484c;
                border-radius: 5px;
                height: 39px;
                font-size: 14px;
                margin-right: 10px;
                padding: 0px 30px;
                font-weight: 500;
            }
            .ant-btn-primary {
                background-color: #49484c;
                border: 1px solid #49484c;
                color: #ffffff;
                border-radius: 5px;
                height: 39px;
                font-size: 14px;
                padding: 0px 30px;
                font-weight: 500;
            }
            .ant-form-item {
                margin-bottom: 15px;
                .ant-form-item-label{
                    label {
                        line-height: 18px;
                        color: #000000;
                        font-weight: normal;
                        font-size: 12px;
                    }
                 }
                 .ant-input {
                    border: 1px solid #dddddd;
                    border-radius: 5px;
                    font-size: 12px;
                    padding: 8px 15px;
                    &::placeholder {
                        color: #000000;
                        font-size: 12px;

                    }
                    &:focus {
                        box-shadow: none;
                    }
                 }
                .ant-radio-group {
                    display: flex;
                    .ant-radio-button-wrapper {
                        background-color: #F5F5F5;
                        border: none;
                        border-left: none;
                        box-shadow: none;
                        height: 53px;
                        line-height: 53px;
                        padding: 0px 18px;
                        flex: 1;
                        > span {
                            font-size: 10px;
                            color: #000000;
                            font-weight: normal;
                            padding-left: 25px;
                        }
                        &:first-child {
                            border-radius: 10px 0px 0px 10px;
                        }
                        &:last-child {
                            border-radius: 0px 10px 10px 0px;
                        }
                        &:not(:first-child)::before {
                            background-color: transparent;
                        }
                        &::before {
                            content: "";
                            position: absolute;
                            background-repeat: no-repeat;
                            width: 19px;
                            height: 19px;
                            top: 18px;
                            left: 18px;
                        }
                        &.very-satisfied {
                            &::before {
                                background-image: url("/images/Very_satisfied_InActive.png");
                            }
                        }
                        &.satisfied {
                            &::before {
                                background-image: url("/images/Satisfied_InActive.png");
                            }
                        }
                        &.not-satisfied {
                            &::before {
                                background-image: url("/images/Not_Satisfied_InActive.png");
                            }
                        }
                        &.ant-radio-button-wrapper-checked {
                            &.very-satisfied {
                                &::before {
                                    background-image: url("/images/Very_satisfied_Active.png");
                                }
                            }
                            &.satisfied {
                                &::before {
                                    background-image: url("/images/Satisfied_Active.png");
                                }
                            }
                            &.not-satisfied {
                                &::before {
                                    background-image: url("/images/Not_Satisfied_Active.png");
                                }
                            }
                            &:not(.ant-radio-button-wrapper-disabled)::before {
                                background-color: transparent;
                            }
                        }
                    }
                }
            }
        }
    }
}
//SendRequest Modal
.send-request-modal {
    .ant-modal-content {
        background-color: #f5f5f5;
        border-radius: 8px;
        .ant-modal-close {
            display: none;
        }
        .ant-modal-body {
            padding:30px 24px;
            h3 {
                font-size: 18px;
                margin-bottom: 15px;
            }
            .ant-btn-primary {
                background-color: #49484c;
                border: 1px solid #49484c;
                width: 100%;
                height: 44px;
                border-radius: 5px;
                font-weight: normal;
                font-size: 15px;
            }
            .send-request-form {
                background-color: #ffffff;
                padding: 25px;
                border-radius: 8px;
                margin-bottom: 20px;
                .ant-form-item {
                    margin-bottom: 15px;
                    &:last-child {
                      margin-bottom: 0;
                    }
                    .ant-form-item-label {
                        label {
                            color: #1E1E1F;
                            font-size: 14px;
                            font-weight: 500;
                        }
                    }
                    .ant-input {
                        background-color: #F5F5F5;
                        height: 42px;
                        border-radius: 8px;
                        border: 1px solid #F5F5F5;
                        &:focus {
                            box-shadow: none;
                        }
                    }
                }
                p {
                    font-size: 18px;
                    color: #000000;
                    font-weight: 600;
                }

            }
        }
    }
}
//SortBy Modal
.sort-by-popup {
    .ant-modal-content {
        border-radius: 21px;
        .ant-modal-close {
            color: #000;
            height: 62px;
            @media  only screen and (max-width:575.98px) {
                height: 62px;
            }
            .ant-modal-close-x {
                @media  only screen and (max-width:575.98px) {
                    width: 59px;
                    height: 59px;
                    line-height: 59px;
                }
            }
            &:hover {
                color: rgba(0, 0, 0, 0.65);
            }
        }
        .ant-modal-header {
            padding: 20px 26px;
            @media  only screen and (max-width:575.98px) {
                padding: 18px;
            }
            .ant-modal-title {
                font-size: 19px;
                font-weight: bold;
                color: #252525;
                @media  only screen and (max-width:575.98px) {
                    font-size: 18px;
                }
            }
        }
        .ant-modal-body {
            padding: 10px 26px;
            .ant-radio-group {
                display: flex;
                flex-direction: column;
                .ant-radio-wrapper {
                    // border-bottom: 2px solid #D8D7DD;
                    padding: 10px;
                    &:last-child {
                        border-bottom: none;
                    }
                    &.ant-radio-wrapper-checked {
                        background-color: #EFEEF1;
                        border-radius: 8px;
                        border-color: transparent;
                        .ant-radio-checked {
                            .ant-radio-inner {
                                border-color: #000;
                                &::after {
                                    background-color: #000;
                                }
                            }
                        }
                    }
                    .ant-radio {
                        top: 3px;
                        .ant-radio-inner {
                            border-color: black;
                            border-style: solid;
                            border-width: 1.5px;
                        }
                    }
                }
            }
            .ant-radio-button-wrapper {
                width: 100%;
                padding: 0px 26px;
                font-size: 20px;
                border: none;
                border-radius: 0;
                height: 56px;
                line-height: 56px;
                background: #fff;
                color: #181A20;
                border-bottom: 2px solid #D8D7DD !important;
                @media  only screen and (max-width:575.98px) {
                    padding: 0px 20px;
                    font-size: 14px;
                    height: 55px;
                }
                &:hover {
                    border-bottom: 2px solid #D8D7DD !important;
                }
                &:not(:first-child) {
                    &::before {
                        background: transparent;
                    }
                }
                &.ant-radio-button-wrapper-checked {
                    &:not(:first-child) {
                        &::before {
                            background: transparent;
                        }
                    }
                    &:not(.ant-radio-button-wrapper-disabled) {
                        &:hover {
                            color: #000;
                            background: #EFEEF1;
                            border-color: #EFEEF1;
                        }
                    }
                    &::after {
                        content: "";
                        position: absolute;
                        background-image: url("/images/radio-check.svg");
                        width: 23px;
                        height: 22px;
                        right: 22px;
                        top: 18px;
                        background-repeat: no-repeat;
                        filter:grayscale(1);
                        display: none;
                        @media  only screen and (max-width:575.98px) {
                            background-size: 18px;
                        }
                    }
                    &:focus-within {
                        box-shadow: none;
                    }
                }
                &:last-child {
                    border-radius: 0 0 21px 21px;
                    border-bottom: none;
                }
                &:focus-within {
                    box-shadow: none;
                }
            }
        }
    }
.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
    border-color: #49484c;
}
.ant-slider-handle {
    border: solid 2px #49484c;
}
.ant-slider-track {
    background-color: #49484c;
}
//create Alert Modal
.create-alert-popup {
        .ant-modal-content {
            border-radius: 5px;
            .ant-modal-close-x {
                display: block;
            }
            .ant-modal-body {
                padding: 24px 50px;
                .ant-select-dropdown {
                    z-index: 1003;
                }
                .title {
                    color: #252525;
                    font-weight: 500;
                    font-size: 20px;
                }
                .ant-btn-primary {
                    border-radius: 5px;
                    font-size: 12px;
                    font-weight: 500;
                    height: 30px;
                    padding: 0px 30px;
                    border-color: #49484c;
                    background: #49484c;
                }
                .bedroom-section {
                    .title {
                        color: #252525;
                        font-weight: 500;
                        font-size: 16px;
                    }
                    .ant-radio-button-wrapper {
                        height: auto !important;
                        padding: 15px 20px !important;
                        font-size: 14px !important;
                        margin: 0px 7px !important;
                    }
                    .ant-radio-group.ant-radio-group-solid {
                        margin: 0px !important;
                    }
                }
                .ant-radio-group {
                    display: flex;
                    justify-content:space-around;
                    margin: 10px -15px;
                    .ant-radio-button-wrapper {
                        border: 1px solid #E0E0E0;
                        padding: 5px;
                        border-radius: 10px;
                        width: 25%;
                        margin: 0px 10px;
                        text-align: center;
                        height: 110px;
                        font-size: 12px;
                        &.ant-radio-button-wrapper-checked {
                            &:not(.ant-radio-button-wrapper-disabled) {
                                color: #49484c;
                                border: 1px solid #49484c !important;
                                background-color: rgba(0,0,0,0);
                            }
                        }
                        &:hover {
                            color: #49484c;
                            border: 1px solid #49484c !important;
                            background-color: rgba(0,0,0,0);
                        }
                        &:not(:first-child)::before {
                            background-color: transparent;
                        }
                        span {
                            .building-img {
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: center;
                                text-align: center;
                                line-height: 13px;
                                margin: 0;
                            }

                            .building-img img {
                                width: 56px;
                                height: 56px;
                            }
                        }
                    }
                }
                .flex-1 {
                    flex: 1;
                }
                .price-select {
                    font-size: 11px;
                    width: 120px;
                    &:not(.ant-select-customize-input) {
                            .ant-select-selector {
                                border: none !important;
                            }
                     }
                    .ant-select-arrow {
                        font-size: 8px;
                    }
                }
                .month-select {
                    font-size: 11px;
                    width: 120px;
                    &:not(.ant-select-customize-input) {
                            .ant-select-selector {
                                border: none !important;
                            }
                     }
                    .ant-select-arrow {
                        font-size: 8px;
                    }
                }
                .price-value {
                    color: #8F929E;
                    font-size: 14px;
                    margin-bottom: 70px;
                    &.size {
                        margin-bottom: 30px;
                    }
                    .ant-input-number {
                        margin: 0px 12px;
                        border-radius: 5px;
                        border: 1px solid #E6E6E6;
                        height: 35px;
                        line-height: 35px;
                        width: 100px;
                    }
                }
                .price-slider {
                    position: relative;
                    &.property-size {
                        margin-top:0px;
                        &::before {
                            display: none;
                        }
                    }
                    &::before {
                        content: "";
                        background-image: url(/images/price-slider.png);
                        position: absolute;
                        display: block;
                        top: -53px;
                        left: 0;
                        width: 100%;
                        height: 53px;
                        background-repeat: no-repeat;
                        background-position: center;
                    }
                    &:hover {
                        .ant-slider-handle:not(.ant-tooltip-open) {
                            border-color: #ffffff;
                        }
                        .ant-slider-track {
                            background-color: #49484c;
                        }
                    }
                    .ant-slider-handle {
                        position: absolute;
                        width: 30px;
                        height: 30px;
                        margin-top: -14px;
                        background-color: #49484c !important;
                        border: solid 10px #ffffff;
                        border-radius: 50%;
                        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.20);
                    }
                    .ant-slider-track {
                        position: absolute;
                        height: 4px;
                        background-color: #49484c;
                        border-radius: 2px;
                        -webkit-transition: background-color 0.3s;
                        -moz-transition: background-color 0.3s;
                        transition: background-color 0.3s;
                    }
                }
                .movein-section {
                    .ant-radio-button-wrapper {
                        border: 1px solid transparent !important;
                        margin: 0 5px !important;
                        text-align: left !important;
                        height: auto;
                        padding: 8px;
                        border-radius: 5px;
                        font-size: 13px;
                        &:hover {
                            color: #49484c;
                            border: none;
                        }
                        &.ant-radio-button-wrapper-checked {
                            &:not(.ant-radio-button-wrapper-disabled) {
                                color: #49484c;
                                border: none;
                            }
                        }
                    }
                }
                .create-alert-btn {
                    height: 46px;
                    line-height: 46px;
                    font-size: 14px;
                    border-radius: 5px;
                }
            }

        }
    }
// Signature Modal
    .SignatureModal {
        .ant-modal-content {
            border-radius: 11px;
            .ant-modal-close-x {
                color: #000000;
                font-weight: 700;
                width: 60px;
                height: 64px;
                font-size: 18px;
                line-height: 75px;
            }
            .ant-modal-header {
                height: 75px;
                padding: 26px 24px;
                background-color: #FFFFFF;
                border-radius: 10px 10px 10px 10px;
                border: 0;
                .ant-modal-title {
                    color: #202020;
                    font-size: 18px;
                    font-weight: 500;
                }
            }
            .ant-modal-body {
                background-color: #F4F7FB;
                border-radius: 0 0 10px 10px;
                padding: 30px 0 0 0;
                .sigCanvas {
                    background-color: #ffffff;
                    height: auto;
                    border-radius: 9px;
                    border: 1px solid #C6C6C6;
                    margin: 0 30px;
                }
                .error-text {
                    color:#F83403;
                }
                .ant-btn-primary {
                    background-color:#49484c;
                    border:1px solid #49484c;
                    color:#ffffff;
                    border-radius: 9px;
                    font-size:16px;
                    padding: 10px 50px;
                    height:55px;
                    // &:hover {
                    //     background-color:#ffffff;
                    //     border:1px solid #49484c;
                    //     color:#49484c;
                    // }
                }
                .btn-outline-primary {
                    background-color: transparent;
                    border: 0;
                    color:#49484c;
                    border-radius: 9px;
                    font-size: 16px;
                    padding:10px 50px 0;
                    margin-bottom: 6px;
                    height:55px;
                    &:hover {
                        background-color: transparent;
                        border: 0;
                        color: #49484c;
                    }
                    &:hover {
                        background-color: transparent;
                        border: 0;
                        outline: 0;
                        box-shadow: none;
                    }
                }
            }
        }
    }
.extend-extension-modal {
    .ant-modal-content {
        border-radius: 5px;
        .ant-modal-header {
            padding: 20px 24px;
            border-bottom: 0;
            .ant-modal-title {
                color: #202020;
                font-weight: 500;
                font-size: 20px;
            }
        }
        .ant-modal-close-x {
            color: #000000;
            font-weight: 700;
            width: 60px;
            height: 64px;
            font-size: 18px;
            line-height: 65px;
        }
        .ant-modal-body {
            background-color: #F5F5F5;
            border-radius: 5px;
            padding: 35px 24px;
            .ant-form.ant-form-horizontal {
                .ant-form-item-label {
                    padding-bottom: 5px !important;
                    > label {
                        height: 0;
                        color: #000000;
                        font-size: 15px;
                        font-weight: 500;
                    }
                }
                .ant-input {
                    color: #536475;
                    background-color: #ffffff;
                    border: 0;
                    border-radius: 10px;
                    height: 42px;
                    font-weight: 300;
                    &:hover {
                        border-color: #CBD6E2;
                    }
                    &:focus {
                        box-shadow: none;
                    }
                }
                .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
                    background-color: #fff;
                    border: 0;
                    border-radius: 10px;
                    color: #536475;
                    align-items: center;
                    font-weight: 300;
                    height: 42px;
                }
                .ant-btn {
                    height: 46px;
                    font-size: 15px;
                    opacity: 1;
                    font-weight: 500;
                    border-radius: 10px;
                }
                .card-deta {
                    .ant-card-body {
                        padding: 28px 24px;
                        h6 {
                            color: #000000;
                            font-size: 11px;
                            font-weight: 400;
                        }
                        h5 {
                            color: #000000;
                            font-size: 14px;
                            font-weight: 700;
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}
.redeem-popup {
    .ant-modal-content {
        border-radius: 10px;
        .ant-modal-header {
            padding: 20px 24px;
            border-bottom: 0;
            text-align: center;
            background-color:  #49484c;
            border-radius: 10px 10px 0 0;
            .ant-modal-title {
                color: #FFFFFF;
                font-weight: 500;
                font-size: 22px;
            }
        }
        .ant-modal-close {
            top: -14px;
            right: -15px;
            color: #333333;
            border-radius: 50%;
            background-color: #FFFFFF;
            &:hover {
                background-color:  #49484c;
                span.ant-modal-close-x {
                    color: #FFFFFF;
                }
            }
            .ant-modal-close-x {
                color: #333333;
                font-weight: 700;
                width: 30px;
                height: 30px;
                font-size: 16px;
                line-height: 31px;
            }
        }
        .ant-modal-body {
            background-color: #FFFFFF;
            border-radius: 10px;
            padding: 24px 40px 30px;
            .market-logo {
                height: 58px;
                width: 60px;
                margin: 0 auto;
                .bg-grey {
                    background-color: #EEEEEE;
                    img {
                        padding: 5px 5px;
                        height: 58px;
                        object-fit: contain;
                        width: 100%;
                    }
                }
            }
            h1 {
                color: #000000;
                font-weight: 400;
                font-size: 38px;
                margin-bottom: 5px;
                margin-top: 3px;
            }
            p {
                color: #000000;
                font-weight: 400;
                font-size: 13px;
            }
            p.main-con {
                color: #959595;
                font-weight: 400;
                font-size: 12px;
            }
            .ant-checkbox-wrapper {
                color: #959595;
                font-size: 12px;
                font-weight: normal;
                .ant-checkbox-inner {
                    width: 14px;
                    height: 14px;
                    border-radius: 0;
                    &::after {
                        left: 19%;
                    }
                }
                .ant-checkbox-checked::after {
                    border: 1px solid #49484c;
                }
                .ant-checkbox-checked .ant-checkbox-inner {
                    background-color: #49484c;
                    border-color: #49484c;
                }
            }
            .down-btn {
                padding: 2px 45px;
                height: 39px;
                font-size: 15px;
                font-weight: 400;
                color: #FFFFFF;
                margin-top: 25px;
                background-color: #49484c;
                border-radius:4px;
                border: none;
                span {
                    color: #FFFFFF;
                }
            }
            .redeem-offer-QR {
                .qr-code-img {
                    max-height: 185px;
                    min-height: 185px;
                    height: 100%;
                }
                h1 {
                    color:#49484c;
                    font-size: 14px;
                    font-weight: 700;
                }
                p {
                    color: #8F8C8C;
                    font-size: 12px;
                    font-weight: 400;
                }
                .close-btn {
                    padding: 1px 51px;
                    height: 38px;
                    font-size: 15px;
                    font-weight: 400;
                }
            }
        }
    }
}
//sticky wrapper
.prof-menu {
    background-color: transparent;
    border: 0 !important;
    li.ant-menu-item {
        &.ant-menu-item-selected {
            border-bottom: 0 !important;
            color: #000000;
        }
        &.ant-menu-item-active {
            border-bottom: 0 !important;
            color: #000000;
        }
        .notif-menu {
            position: absolute;
            top: 0;
            span.badge-danger {
                background-color: #fb2323;
                height: 8px;
                width: 8px;
                position: absolute;
                display: block;
                z-index: 20;
                top: 10px;
                right: 0;
                border-radius: 50%;
            }
        }
    }
}

//dropdown menu
.ant-menu-submenu-popup {
    border-radius: 0 0 5px 5px;
    z-index: 10501;
    .ant-menu-sub {
        position: absolute;
        top: -8px;
        left: 0px;
        border-radius: 0 0 5px 5px;
        min-width: 156.9px;
        color: #252525;
        background-color: #EBEEF3;
        box-shadow: none;
        .sub-menu-group {
            .ant-menu-item-group-title {
                display: none;
            }
            .ant-menu-item-group-list {
                .ant-menu-item {
                    border-bottom: 0;
                    margin-bottom: 0px;
                    margin-top: 0px;
                    padding: 0px 15px;
                    background-color: #EBEEF3;
                    color: #252525;
                    &:hover {
                        color: #09418A;
                        a {
                            color: #09418A;
                            margin-left: 19px;
                            &::before {
                                content: "";
                                background-image: url(/images/arrow-right.png);
                                background-repeat: no-repeat;
                                height: 16px;
                                width: 16px;
                                margin-right: 0px;
                                position: absolute;
                                top: 13px;
                                left: 14px;
                            }
                        }
                    }
                    &:last-child {
                        border-bottom: none;
                    }
                    a {
                        font-size: 14px;
                        color: #252525;
                        &.active {
                            color: #09418A;
                            margin-left: 19px;
                            &::before {
                                content: "";
                                background-image: url(/images/arrow-right.png);
                                background-repeat: no-repeat;
                                height: 16px;
                                width: 16px;
                                margin-right: 0px;
                                position: absolute;
                                top: 13px;
                                left: 14px;
                            }
                        }
                    }
                }
            }
        }
        &::after {
            display: none;
        }
        &:not(.ant-menu-horizontal) .ant-menu-item-selected {
            background-color: #EBEEF3;
        }
        &::before {
            display: none;
        }
    }
}
.pric-drop.ant-dropdown-open {
    .ant-dropdown {
        max-width: 296px;
        width: 100%;
    }
}
.price-slider-listing {
    margin: 10px 0 0 !important;
    max-width: 340px !important;
    width: 340px !important;
    padding: 10px 18px !important;
    border-radius: 5px !important;
    .ant-dropdown-menu-item {
        .fil-title-sec {
            .ant-typography {
                font-size: 13px;
                color: #252525;
                font-weight: 400;
                margin: 0 auto;
            }
            h5.ant-typography {
                @media  only screen and (max-width:575.98px) {
                    font-size: 14px;
                }
            }
            .close-icon {
                span {
                    color: rgba(0,0,0,0.5);
                }
            }
        }
        .bottom-border {
            border-bottom: 1px solid rgba(0,0,0,0.1);
            padding-bottom: 12px;
            margin-bottom: 2px;
        }
    }
    .ant-dropdown-menu-item:hover,
    .ant-dropdown-menu-submenu-title:hover {
        background-color: unset;
    }
}
.price-slider-home {
    width: 254px;
    border-radius: 5px !important;
    padding: 10px 6px !important;
    @media (min-width: 1500px) {
       max-width: 296px;
       width: 100%;
    }
    .ant-tooltip {
        z-index: 9999999 !important;
    }
    .filter-btns {
        display: inline-flex;
        justify-content: flex-end;
        width: 100%;
        .price-clear-btn {
            max-width: 80px;
            width: 80px;
            margin: 8px 0 0;
            font-size: 13px;
            border-radius: 5px;
            height: 34px;
            @media (min-width: 1500px) {
                max-width: 100px;
                width: 100px;
            }
        }
        .btn-outline {
            background-color: #ffffff;
            border: 1px solid #49484c;
            color: #49484c;
            max-width: 80px;
            width: 80px;
            margin: 8px 8px 0;
            font-size: 13px;
            border-radius: 5px;
            height: 34px;
            @media (min-width: 1500px) {
                max-width: 100px;
                width: 100px;
            }
            &:hover {
                background-color: #49484c;
                border: 1px solid #49484c;
                color: #ffffff;
                transition: inherit;
            }
        }
    }
    .ant-slider {
        margin-bottom: 3px;
        &:hover {
            .ant-slider-track {
                background-color: #49484c;
            }
            .ant-slider-handle:not(.ant-tooltip-open) {
                border-color: #49484c;
            }
        }
        .ant-slider-track {
            background-color: #49484c;
            height: 2px;
        }
        .ant-slider-handle {
            border: solid 1px #707070;
            width: 18px;
            height: 18px;
            margin-top: -9px;
        }
    }
    .ant-dropdown-menu-item {
        padding-top: 0;
        .min-text {
            display: block;
            font-size: 13px;
            color: #252525;
            font-weight: 400;
        }
        .price-value {
            display: flex;
            .ant-input {
                color: #252525;
                border-radius: 5px;
                padding-top: 0;
                border: 0;
                font-size: 12px;
                padding-bottom: 0;
                padding-left: 3px;
                padding-right: 0;
                &:focus,
                &:hover {
                    box-shadow: none;
                    outline: 0;
                    border-color: inherit;
                }
            }
        }
        .fil-title-sec {
            .ant-typography {
                font-size: 14px;
                color: #252525;
                font-weight: 400;
                margin: 0 auto;
            }
            h5.ant-typography {
                @media  only screen and (max-width:575.98px) {
                    font-size: 14px;
                }
            }
            .close-icon {
                span {
                    color: rgba(0,0,0,0.5);
                }
            }
        }
        .bottom-border {
            border-bottom: 1px solid rgba(0,0,0,0.1);
            padding-bottom: 12px;
            margin-bottom: 2px;
        }
    }
    .ant-dropdown-menu-item:hover,
    .ant-dropdown-menu-submenu-title:hover {
        background-color: unset;
    }
}
// .ant-select-dropdown {
//     .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
//         color: #000000;
//         font-weight: 400;
//         background-color: rgba(0,44,106,0.1);
//     }
// }
.ant-picker-today-btn,
.ant-picker-header-view button:hover,
.ant-picker-today-btn:hover {
    color: #49484c !important;
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    color: #fff !important;
    background: #49484c !important;
    border-color: #49484c !important;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: 1px solid #49484c !important;
}
.ant-spin {
    color: #49484c !important;
    display: flex;
    justify-content: center;
    span {
        i {
            background-color: #49484c !important;
        }
    }
}
//signup form
.select-signup {
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        width: 100%;
        min-height: 43px;
        border-radius: 5px;
        border-color: rgba(67, 67, 67, 0.35);
        color: #808186 !important;
        font-weight: 600;
        font-size: 12px;
        background-color: #fff !important;
        box-shadow: none !important;
    }
    .ant-select-single .ant-select-selector .ant-select-selection-item {
        line-height: 43px;
    }
}
.ant-menu {
    .ant-menu-item.about-drop {
        &.ant-menu-item-selected,
        &.ant-menu-item-selected:focus {
            background-color: #EBEEF3 !important;
            border: 0 !important;
            border-radius: 5px;
            color: #252525;
            height: 36px;
            line-height: 35px;
        }
        &:hover {
            color: #49484c;
        }
        span.ant-dropdown-trigger.ant-dropdown-link {
            padding: 18px 10px;
            .anticon-down {
                margin-left: 3px;
            }
        }
    }
}
.ant-dropdown {
    z-index: 10501;
}
.ant-dropdown-menu {
    border-radius:8px;
}
.inner-menu-about {
	background-color: #EBEEF3 !important;
    border-radius: 5px;
    box-shadow: none;
    left: 0;
    top: 7px;
    max-width:200px;
    width: 185px;
    .ant-dropdown-menu-item,
    .ant-dropdown-menu-submenu-title {
        font-size: 13px;
        color: #252525;
        background-color: transparent !important;
        transition: inherit;
    }
    .ant-dropdown-menu-title-content > a {
        transition: inherit;
    }
    .ant-dropdown-menu-title-content > a.active,
    .ant-dropdown-menu-title-content > a:hover {
        padding-left: 25px;
        transition: inherit;
        color: #49484c;
    }
    .ant-dropdown-menu-title-content > a.active::after,
    .ant-dropdown-menu-title-content > a:hover::after {
        content: "";
        background-image: url(/images/arrow-right.svg);
        background-repeat: no-repeat;
        height: 16px;
        width: 16px;
        margin-left: 0px;
        position: absolute;
        top: 9px;
        left: 14px;
        padding-left: 20px;
        transition: inherit;
    }
}
.inner-menu-properties{
    background-color: #EBEEF3 !important;
    border-radius: 8px;
    box-shadow: none;
    left: 0;
    top: 7px;
    max-width:100%;
    width: 500px;
    .ant-dropdown-menu-item,
    .ant-dropdown-menu-submenu-title {
        font-size: 13px;
        color: #252525;
        background-color: transparent !important;
        transition: inherit;
    }
    .ant-dropdown-menu-title-content > a {
        transition: inherit;
    }
    .ant-dropdown-menu-title-content > a.active,
    .ant-dropdown-menu-title-content > a:hover {
        padding-left: 25px;
        transition: inherit;
        color: #49484c;
    }
    .ant-dropdown-menu-title-content > a.active::after,
    .ant-dropdown-menu-title-content > a:hover::after {
        content: "";
        background-image: url(/images/arrow-right.svg);
        background-repeat: no-repeat;
        height: 16px;
        width: 16px;
        margin-left: 0px;
        position: absolute;
        top: 9px;
        left: 14px;
        padding-left: 20px;
        transition: inherit;
    }
}
.ant-image-preview-wrap {
    z-index: 10001 !important;
    background-color: rgba(0,0,0,0.4) !important;
    .ant-image-preview-operations {
        background: rgba(0, 0, 0, 0.5) !important;
        height: 70px;
    }
}
.ant-popconfirm {
    z-index: 11000;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
    color: #49484c !important;
}
.media-img{
    padding: 10px;
}
.text-info{
    color:#49484c !important;
}
.ant-btn:hover, .ant-btn:focus {
    color: #49484c;
    border-color: #49484c;
    background: #fff;
}
.accept-terms{
    display: inline-flex !important;
    position: relative;
    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #49484c;
        border-color: #49484c;
    }
}
    }

.home_icons{
    width: 20px;
    height: 20px;
    object-fit: cover;
    margin-right: 3px;
    filter:grayscale(1)
}
.profile-menu-div{
    align-items: center;
  }
  .ant-row.align-items-center {
    align-items: center;
}

.ant-radio-wrapper {
    .ant-radio {
        top:6px;
    }
}


//==========responsive===========================================================================
 @media (max-width: 991.98px) {
    .banner-cover h2{
        top:60% !important;
    }
    .ant-layout-content{
        padding-top: 65px;
    }
 }
 @media (max-width: 640.98px) {
    .ant-layout-content{
        // padding-top: 52px;
    }
}
body > iframe {
    display:none;
}
.login-wrapp{
    position:relative;
}
.login-eye {
    position: absolute;
    top: 11px;
    right: 8px;
    padding: unset;
    background: unset;
    cursor: pointer;
    border: none;
    &:hover{
        background: unset;
        
    }
}
.resp-container {
    padding-left:30px;
    padding-right:30px;
    width: 100%;
    margin: auto;
    @media (min-width: 768px) {
        max-width: 925px;
    }
    @media (min-width: 992px) {
        max-width: 970px;
    }
    @media (min-width: 1200px) {
        max-width: 1110px;
        padding-left:0;
        padding-right:0;
    }
    @media (min-width: 1500px) {
        max-width: 1340px;
    }
    @media (max-width: 480.98px) {
        padding-left: 25px;
        padding-right: 25px;
    }
}


//=======================================================================================================





//  ================================================
//  arabic styles
//  ================================================
body[dir="rtl"] {

        text-align:right;
        .ml-8 {margin-left: 0px;margin-right: 8px;}
        .mr-10 {margin-right: 0px;margin-left: 10px;}
        .mr-5 {margin-right: 0px;margin-left: 5px;}
        .mr-18 {margin-right: 0px;margin-left: 18px;}
        .ml-10 {margin-left: 0px;margin-right: 10px;}
        .ml-8 {margin-left: 0px;margin-right: 8px;}
        .pl-10 {padding-left: 0px;padding-right: 10px;}
        .pl-5 {padding-left: 5px;padding-right: 5px;}
        .pr-10 {padding-right: 0px;padding-left: 10px;}
        .pr-25 {padding-right: 0px;padding-left: 25px;}
        .pr-15 {padding-right: 0px;padding-left: 15px;}
        .mr-auto {margin-right: auto;}
        .pr-30 {padding-right: 0px;padding-left: 30px;}
        .text-right {text-align: left;}
        .a-ml-0 { margin-left: 0px !important; }

        span.badge-danger {
            left: 12px;
            right:inherit;
        }
        .dir-tel-no {
            direction: ltr;
            float: right;
        }
        .virtual-tour-btn {
            img {
              margin-left: 8px;
              margin-right: 0;
            }
            &:hover{
                span{
                    color:#ffffff !important;
                }
            }
        }
        .beta-text {
            margin-right: 13px !important;
            margin-left: 0 !important;
        }
        .beta-text.after-login {
            margin-right: 5px !important;
            margin-left: 0 !important;
        }
        .details_icon .product_checkbox {
            margin-right: 15px;
        }
        .mobile-header .ant-drawer-body .main-menu li button {
            text-align: right;
        }
        .mobile-menu-loggedin {
            padding-bottom: 15px !important;
            &.ant-menu-item-selected::after {
              border-bottom: 0;
            }
        }
        .inbox-popup.ant-modal {
            .inbox-time {
                text-align: left !important;
                direction: ltr;
            }
            .inbox-footer .inbox-btn span {
                transform: rotate(180deg);
            }
        }
        .area-dp{
                    padding-right: 10px;
                }
        .tabContent{border-radius: 8px 0px 8px 8px ;}
        .home-section-two{
            img{
              transform: scaleX(-1);
            }
          }
        .blg-details {
            direction: rtl;
            p {
                margin-right: 10px;
            }
        }
        .blg-date1,
        .blg-date {
            direction: ltr;
        }
        .social_media_links, .blg-details1 img {
            margin-left: 12px;
        }
        .store-sec a:last-child{
            margin-left: 0;
        }
        .explore-btn > span + .anticon {
           margin-right: 8px;
           margin-left: 0px;
           rotate: 180deg;
           line-height: .8;
        }
        .ant-dropdown-placement-bottomRight{
                      .inner-menu-properties {
                          .ant-dropdown-menu-title-content{
                              a{
                                   &:hover{padding-right: 0 !important;}
                              }
                          }
                      }
                  }
       .prop-dropdown{
           .ant-dropdown-menu-item:hover::after{
                left: 0;
                right: initial;
           }
       }
       .ant-breadcrumb {
           .ant-breadcrumb-link {
               .anticon {
                   font-size: 16px;
                   padding-left: 5px;
               }
           }
       }
        .footer-template2{
             .ant-list-sm {
                 .ant-list-item {
                    border-left: 1px solid #fff;
                    border-right: 0;
                    &: first-child{padding-right: 0;}
                    &: last-child{border-left: 0;}
                }
            }
        }
        .price-slider-home {
            span.price-value {
                justify-content: flex-start;
                display: flex;
                span.min-text {
                    justify-content: flex-start;
                    display: flex;
                }
            }
            .close-icon {
                float: left;
            }
            .filter-btns .btn-outline {
                margin-left: 12px;
                margin-right: 0;
            }
            .fil-title-sec {
                text-align: right;
            }
        }
        .make-an-offer-property {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
        .content_wrapper {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
        .content_wrapper .facility-box ul li {
            padding-left: 15px;
            padding-right: 0;
        }
        .content_wrapper .facility-box ul li span {
            padding-left: 0;
            padding-right: 8px;
        }
        .content_wrapper .facility-location > span {
            padding-left: 8px;
            padding-right: 0;
        }
        .details-box .payment-tabs .ant-tabs-nav .ant-tabs-nav-list {
            .ant-tabs-tab + .ant-tabs-tab {
                margin: 0 32px 0 0;
            }
        }
        .react-tel-input .form-control {
            padding-right: 48px;
            padding-left: 0;
        }
        .react-tel-input .selected-flag .arrow {
            right: 20px;
            left: inherit;
        }
        .react-tel-input .selected-flag {
            padding: 0 8px 0 0;
        }
        .react-tel-input .country-list .country {
            display: flex;
            align-items: center;
        }
        .react-tel-input .country-list .flag {
            margin-left: 7px;
            margin-right: inherit;
        }
        .react-tel-input .country-list .country-name {
            margin-left: 6px;
            margin-right: inherit;
        }
        .react-tel-input .country-list .search {
            text-align: right;
        }
        .react-tel-input .flag-dropdown {
            border-radius: 0 5px 5px 0;
        }
        .react-tel-input .flag-dropdown.open .selected-flag {
            border-radius: 0 5px 5px 0;
        }
        .react-tel-input .selected-flag {
            border-radius: 0 5px 5px 0;
        }
        .country-input .react-tel-input .selected-flag {
            border-radius: 0 10px 10px 0;
        }
        .country-input .react-tel-input .flag-dropdown {
            border-radius: 0 10px 10px 0;
        }
        .country-input .react-tel-input .flag-dropdown.open .selected-flag {
            border-radius: 0 10px 10px 0;
        }
        .cat-search .ant-select-arrow {
            left: 11px;
            right: inherit;
        }
        .share-dropdown-menu .ant-menu-item {
            display: flex;
        }
        .contactus-page .address-section .working-hours span {
            direction: ltr;
        }
        .login-popup.ant-modal .ant-modal-content .ant-modal-body .ant-select-single .ant-select-selector .ant-select-selection-item,
        .login-popup.ant-modal .ant-modal-content .ant-modal-body .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
            text-align: right;
        }
        .sticky-outer-wrapper .sticky-inner-wrapper .is_transparent .main_menu ul.ant-menu .ant-menu-item .ant-menu-title-content .ant-dropdown-link .anticon {
            padding-left: 0px;
            padding-right: 10px;
        }
        .working-hours {
            padding-right: 35px;
            padding-left: 0px;
        }
        .working-hours::before {
            left: initial;
            right: 0;
        }
        .prop-dropdown .ant-row .ant-col > div {
            border-left: 1px solid #E3E3E3;
            border-right: 0;
            padding-left: 6px;
            padding-right: 0px;
        }
        .prop-dropdown .ant-row .ant-col:nth-of-type(4n) > div {
            border-left: none;
        } 

        @media only screen and (min-width: 320px) and (max-width: 991.98px) {
        .ant-menu{
              text-align:right;
            }
            .mobile-header .ant-drawer-body .main-menu li a.active{
              border-left:none;
              border-right:4px solid #49484c;
            }
      }

   header {
        .navbar {
            &.is_transparent {
                a {
                   margin-left: 27px;
                   margin-right: 0 !important;
                }
                .avatar-menu{
                    .auth_menu {
                        .ant-menu {
                            .ant-menu-item {
                                .ant-btn {
                                    margin-right: 15px;
                                    margin-left: 0 !important;
                                }
                            }
                        }
                    }
                }
                .main_menu {
                    .ant-menu {
                        .ant-menu-item-active {
                            &.about-drop {
                                .ant-menu-title-content {
                                    .ant-dropdown-open {

                                    }
                                }
                            }
                        }
                    }
                }
                .avatar-dropdown ul.dropdown-menu li span a {
                    margin-left: 0;
                    margin-right: inherit;
                }
            }
        }
   }
   .ant-select-dropdown {
        &.ant-select-dropdown-placement-bottomLeft {
            .rc-virtual-list {
                .rc-virtual-list-holder-inner {
                    .ant-select-item {
                        .ant-select-item-option-content {
                            .home_icons {
                                margin-right: 0px;
                                margin-left: 10px;
                            }
                        }
                    }
                }
            }
        }
   }
   .ant-dropdown-placement-bottomRight {
        .ant-dropdown-menu {
            text-align: right;
        }
        .inner-menu-properties {
            border-radius:8px;
             .ant-dropdown-menu-title-content {
                a {
                    &:hover {
                        padding-right: 25px;
                        padding-left: 0px;
                        &::after {
                            margin-left: 0px;
                            right: 14px;
                            padding-right: 0;
                            left: auto;
                            padding-left: 0;
                            transform: rotate(184deg);
                        }
                    }
                }
             }
        }
    }
   .ant-dropdown-placement-bottomLeft{
        .ant-dropdown-menu {
            text-align: right;
        }
        .inner-menu-about {
            .ant-dropdown-menu-title-content {
                a {
                    &:hover {
                        padding-right: 25px;
                        padding-left: 0px;
                        &::after {
                            margin-left: 0px;
                            right: 14px;
                            padding-right: 0;
                            left: auto;
                            padding-left: 0;
                            transform: rotate(184deg);
                        }
                    }
                    &.active {
                        padding-right: 25px;
                        padding-left: 0px;
                        &::after {
                            padding-right: 20px;
                            padding-left: 0px;
                            transform: rotate(184deg);
                            left: auto;
                            right: 14px;
                        }
                    }
                }
            }
        }
   }
   .exe-collection {
        .ant-card {
            .ant-card-body {
                .ribbon-wrapper {
                    right:-3px;
                    left:auto;
                    .ribbon {
                        right:-48px;
                        left: auto;
                        transform: rotate(45deg);
                    }
                }
            }
        }
   }
   .ant-tabs {
   @media only screen and (max-width: 767.98px){
        .ant-tabs-nav-wrap {
            overflow-x: auto;
            overflow-y: hidden;
            .ant-tabs-nav-list {
                display: flex !important;
                margin: 0 auto !important;
                width: 800px !important;
                }
            }
        }
       &.carousel-tab {
            .ant-tabs-content-holder {
                direction: ltr;
            }
       }
   }
   .carousel-section {
        direction: ltr;
   }
    // media queries
    .media-center-tab {
        .ant-tabs-content-holder {
            .ant-form {
                .ant-form-item-label {
                    text-align: right;
                }
            }
            .video-section {
                .pic_txts {
                    // padding: 10px 52px 10px 0px;
                    // background:url(../images/picture_glr.png) right 15px center no-repeat #49484c;
                }
            }
            .address-section {
                .address-line {
                    p {
                        padding-right: 35px;
                        padding-left: 0px;
                        &::before {
                            right: 0;
                        }
                    }
                }
                .working-hours {
                    padding-right: 35px;
                    padding-left: 0px;
                    &::before {
                        right: 0;
                    }
                }
            }
        }
    }
    //share dropdown
    .ant-dropdown {
        .share-dropdown-menu {
            border: 1px solid #f0f0f0;
            border-radius: 8px;
            .ant-menu-item {
                .ant-menu-title-content {
                    .react-share__ShareButton {
                        img {
                            margin-right: 0px;
                            margin-left: 10px;
                        }
                    }
                    a {
                        img {
                            margin-right: 0px;
                            margin-left: 10px;
                        }
                    }
                }
            }
        }
    }
    //schedule modal
    .schedule-visit-modal {
        .ant-modal-content {
            .ant-modal-close {
                left: 16px;
                right: auto;
            }
            .ant-modal-body {
                .c-pointer {
                    .back-icon{
                        margin-right: 0px;
                        margin-left: 10px;
                        transform: rotate(180deg);
                    }
                }
                .slick-slider {
                    .slick-prev {
                        right: auto;
                        left: 56px;
                        top: -28px;
                    }
                    .slick-next {
                        left: 25px;
                        right: auto;
                        top: -28px;
                    }
                }
            }
        }
    }
    //sort by component
    .sort-by-popup {
        .ant-modal-content {
            .ant-modal-header {
                .ant-modal-title {
                    text-align:right;
                }
            }
            .ant-modal-body {
                .ant-radio-group {
                    text-align:right;
                    .ant-radio-button-wrapper {
                        &.ant-radio-button-wrapper-checked {
                            &::after {
                                right:auto;
                                left: 22px;
                            }
                        }
                    }
                }
            }
        }
    }
    //map carousel
    .map-toggle {
        .map-carousel{
            .slick-prev {
                right: -40px;
                left: auto;
                top: 35%;
                transform: rotate(180deg);
            }
            .slick-next {
                right: auto;
                left:-40px;
                top: 35%;
                transform: rotate(180deg);
            }
            .slick-list {
                .slick-track {
                    .slick-slide{
                        .map-item {
                            .ant-row {
                                direction: rtl;
                                .facility-box{
                                    ul {
                                        li {
                                            margin-left: 12px;
                                            margin-right: 0px;
                                            img {
                                                margin-left: 5px;
                                                margin-right: 0px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
   //contact us page
   .contactus-page {
        .address-section {
            .address-line {
                p {
                    padding-right: 35px;
                    padding-left: 0px;
                    &::before {
                        right: 0;
                    }
                }
            }
            .working-hours {
                padding-right: 35px;
                padding-left: 0px;
                &::before {
                    right: 0;
                }
            }
        }
        .ant-form {
            .ant-form-item {
                .ant-form-item-label {
                    text-align:right;
                }
            }
        }
   }
   //whistleblowing
   .prop-overview {
       .footer-links {
            .ant-list {
                .ant-list-items {
                    .anticon {
                            transform: rotate(180deg);
                    }
                }
            }
        }
       .report_form {
            .ant-form {
                .ant-form-item {
                    .ant-form-item-label {
                        text-align:right;
                    }
                }
            }
            .form_footer {
                .anticon {
                    margin-right: 0px;
                    margin-left: 12px;
                }
            }
       }
   }
   //my activity
   .activity-body {
	.act-tabs {
		.ant-tabs-content-holder {
			.ant-tabs-content {
				.visit-body {
								.ant-card {
									.ant-card-body {
										.prop-details {
											.inner {
												.prop-status {
													h6 {
														.anticon {
															margin-left: 8px;
															margin-right: 0px;
														}
													}
												}
												.features {
													span {
														> img {
															margin-right: 0px;
															margin-left: 5px;
														}
													}
												}
												.prop-address {
													img {
														margin-left: 3px;
														margin-right: 0px;
													}
												}
											}
										}
									}
								}
							}
			}
		}
	}
}
   // upload
   .ant-upload {
        .ant-btn > .anticon + span, .ant-btn > span + .anticon {
            margin-right: 8px;
        }
   }
   //form label
   .ant-form {
        .ant-form-item {
            .ant-form-item-label {
                text-align:right;
            }
        }
        .ant-btn {
            &.btn-outline-primary {
                margin-right: 0px !important;
                margin-left: 10px;
            }
        }
    }
   //login popup
   .login-popup {
        .ant-form{
            .ant-form-item  {
                .ant-form-item-control {
                    .ant-form-item-explain {
                        .ant-form-item-explain-error {
                            text-align: right;
                        }
                    }
                }
            }
        }
        .ant-row {
            .ant-col-md-12 {
                &:first-child {
                    text-align:right !important;
                }
            }
        }
   }
   //
   .print-bt {
    float:left;
    > .anticon + span {
    margin-left: 0px;
    margin-right: 8px;
    }
   }
   //
   .share-save {
	float: left;
        .share_div{
            .ShareDetails{
                .ant-dropdown-link {
                    margin-right: 0;
                    margin-left: 0.8rem;
                    @media (max-width:575.98px){
                        margin-left: 0 !important;
                    }
                    img {
                        margin-right: 0px !important;
                        margin-left: 10px !important;
                    }
                }
            }
        }
    }
   //footer
   footer {
        .footer-searches {
            .footer-links {
                .social_media_links {
                    a {
                        float: right;
                        margin-right: 0px;
                        margin-left: 10px;
                    }
                }
            }
        }
        .contact-link-det {
            .store-sec {
                a {
                    img {
                        margin-left: 7px;
                        margin-right: 0px;
                    }
                }
            }
        }
   }
    //market place details
   .back-breadcrumb-link {
        .anticon {
            margin-right: 0px !important;
            margin-left: 15px;
        }
   }
   .list-body {
        .banner-details-body {
            .bg-light-grey {
                .banner {
                    .inner-sec {
                        .available-time-offer {
                            &::before {
                                margin-right: 0px !important;
                                margin-left: 10px;
                            }
                        }
                    }
                }
            }
        }
        .desc-body {
            a {
                .ant-btn {
                    margin-right: 0px !important;
                    margin-left: 18px;
                }
            }
        }
    }
 //redeem popup
 .redeem-popup {
	.ant-modal-content {
		.ant-modal-close {
			right: auto !important;
			left: -15px;
            @media only screen and (max-width: 767.98px){
                left: 15px;
            }
		}
	}
}
//news details
.popural_post {
	.ant-card {
		.ant-card-body {
			.popural_post_img {
				margin-right: 0px !important;
				margin-left: 10px;
			}
		}
	}
}
//
.bg-theme {
     .facility-box {
        ul {
            li {
                padding: 0 15px 0 30px;
            }
        }
     }
}
// properties list
    .properties {
        .filter-by-map {
            margin: 20px 0px 20px 10px;
            img {
                 padding-right: 0px;
                 padding-left: 10px;
            }
        }
        .sort-by {
            img {
                padding-left: 0px;
                padding-right: 10px;
            }
        }
    }
    .Product-card {
        .ant-checkbox-wrapper-checked {
            .ant-checkbox {
                &.ant-checkbox-checked {
                    &::after {
                        right: 4px;
                        left: auto;
                    }
                }
            }
        }
    }
    .details_icon {
        .ant-checkbox-checked {
            &::after {
                right: 4px;
                left: auto;
            }
        }
    }
    .grid_wrapper {
        .grid_column {
            .Product-card {
                .ant-checkbox-wrapper-checked {
                    .ant-checkbox {
                        &.ant-checkbox-checked {
                            &::after {
                                right: 4px;
                                left: auto;
                            }
                        }
                    }
                }
                .content_wrapper {
                    .facility-box {
                        ul {
                            li {
                                padding-right: 0px;
                                padding-left: 20px;
                                span {
                                    padding-left: 0px;
                                    padding-right: 5px;
                                }
                            }
                        }
                    }
                    .facility-location {
                        span {
                            padding-right: 0px;
                            padding-left: 8px;
                        }
                    }
                }
            }
        }
    }
    .gallery-section {
        .pic_txts {
            // padding: 10px 52px 10px 0px;
            // background:url(../images/picture_glr.png) right 15px center no-repeat #49484c;
        }
    }
    .bg-theme {
        .facility-box {
            ul {
                li {
                    span {
                        padding-left: 0px;
                        padding-right: 8px;
                    }
                }
            }
        }
    }
    .facility-location {
        span {
            padding-right: 0;
            padding-left: 5px;
        }
    }
    .amenities p > span {
        padding-right: 0;
        padding-left: 8px;
    }
    .Product-card {
        .content_wrapper {
            .facility-box {
                ul {
                    li {
                        padding-right: 0px;
                        padding-left: 20px;
                        span {
                            padding-left: 0px;
                            padding-right: 5px;
                        }
                    }
                }
            }
            .facility-location {
                span {
                    padding-right: 0px;
                    padding-left: 8px;
                }
            }
        }
    }
    .avatar-dropdown {
        .dropdown-handler {
            margin-left: 0;
            margin-right: 8px;
        }
    }
    .ant-modal-close {
        left: 0;
        right: inherit;
    }
    .inbox-popup.ant-modal {
        margin-right: auto;
        margin-left: 118px;
    }
    .avatar-dropdown .dropdown-menu {
        left: 90px;
        right: inherit;
    }
    .ant-pagination {
        .ant-pagination-prev {
            margin-left: 8px;
            margin-right: 0px;
            transform: rotate(180deg);
        }
        .ant-pagination-item {
            margin-left: 8px;
            margin-right: 0px;
        }
        .ant-pagination-next {
            transform: rotate(180deg);
        }
    }
    .offer-percent {
        direction: rtl;
    }
    .ant-collapse {
    .ant-collapse-item {
    .ant-collapse-content {
    .ant-collapse-content-box {
        .faq-card {
            padding-left:0;
            padding-right:20px;
            h5 {
            &:before {
                left:auto;
                right:-19px;
            }
        } } } } }
}
        .react-tel-input.form-control
            {
            width: 100% !important;
        }

.text-decoration-none {
    text-decoration: none;
}
.hr-line {
    border: 1px solid #EEE8E8;
}
.pl-30{padding-left: 30px;}
.mr-2{
    margin-right:10px;
}
.border-bottom{
    border-bottom:0.5px solid;
}
.btn-outline {
    padding: 10px 20px;
    border: 1px solid #fff;
    color: #fff;
    text-align: center;
    font-size: 14px;
}
`;

export default GlobalStyle;