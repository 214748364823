
import {combineReducers} from 'redux'
import { propertiesApi } from './properties/propertyApi'
import { homeApi } from './properties/homeApi'
import * as general from '../features/generics/generalStates'

export const rootReducer = combineReducers({
    propertiesApi:propertiesApi.reducer,
    homeApi:homeApi.reducer,
    general:general.reducer,
})
export const RootState = rootReducer