import React, { Fragment } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Layout as LayoutWrapper } from 'antd';
import useWindowSize from 'library/hooks/useWindowSize';
import LayoutProvider from 'constants/LayoutProvider';
import ChartBoat from '../../pages/chatBoat/ChartBoat'
import {
  LISTING_POSTS_PAGE,
  LOGIN_PAGE,
  REGISTRATION_PAGE,
  AGENT_PROFILE_PAGE,
  AGENT_ACCOUNT_SETTINGS_PAGE,
  // ADD_HOTEL_PAGE,
  PRICING_PLAN_PAGE,
  SINGLE_POST_PAGE,
  PRIVACY_PAGE,
  CHANGE_PASSWORD_PAGE,
  FORGET_PASSWORD_PAGE,
  AGENT_MYDOCUMENTS_PAGE,
  AGENT_SETTINGS_PAGE,
  PAYMENT_BY_EMPLOYER,
  MAKE_AN_OFFER
} from 'setup/constant';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import ChatBox from '../../components/Footer/ChatBox/ChatBox'
const { Content } = LayoutWrapper;

export default function Layout() {
  let location = useLocation();
  const chatPage = '/chat'
  const { width } = useWindowSize();
  const singlePageUrlFromConst = SINGLE_POST_PAGE.split('/');
  const singlePageUrlFormLocation = location.pathname.split('/');

  return (
    <LayoutProvider>
      {location.pathname === LOGIN_PAGE ||
        location.pathname === CHANGE_PASSWORD_PAGE ||
        location.pathname === FORGET_PASSWORD_PAGE ||
        location.pathname === REGISTRATION_PAGE ? (
        <Content>
          <Outlet />
        </Content>
      ) : (
        <Fragment>
          <Header />
          <Content>
            <Outlet />
          </Content>
          <ChartBoat/>
          {
            // location.pathname === LISTING_POSTS_PAGE ||
            location.pathname === PRICING_PLAN_PAGE ||
              // location.pathname === MAKE_AN_OFFER ||
              location.pathname === PAYMENT_BY_EMPLOYER ||
              // location.pathname === ADD_HOTEL_PAGE ||
              location.pathname === AGENT_PROFILE_PAGE ||
              location.pathname === CHANGE_PASSWORD_PAGE ||
              location.pathname === FORGET_PASSWORD_PAGE ||
              // location.pathname === PRIVACY_PAGE ||
              location.pathname ===
              `${AGENT_ACCOUNT_SETTINGS_PAGE + AGENT_MYDOCUMENTS_PAGE}` ||
              location.pathname ===
              `${AGENT_ACCOUNT_SETTINGS_PAGE + AGENT_SETTINGS_PAGE}` ||
              location.pathname === AGENT_ACCOUNT_SETTINGS_PAGE ? (
              <div className='h-33px' />
            ) : (
              <Fragment>
                {location.pathname != chatPage ? <Footer /> : ''}
                <ChatBox />
                {singlePageUrlFormLocation[1] === singlePageUrlFromConst[1] && (
                  <Fragment>
                    {width < 1200 && <div className='h-74px' />}
                  </Fragment>
                )}
              </Fragment>
            )}
        </Fragment>
      )}
    </LayoutProvider>
  );
}
