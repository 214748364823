import React, { useRef, useState } from 'react';
import { Modal, Button, Row, Col, Spin, Alert } from 'antd';
import SignatureCanvas from 'react-signature-canvas';
import { SIGN_CONTRACT_URL_API } from '../../../setup/constant';

const SignatureCanvasPage = (props) => {

	const sigCanvas = useRef({});
	const clear = () => sigCanvas.current.clear();


	const [signContractState, setSignContractState] = useState({
		loading: false,
		response: null,
		error: null
	});
	const signDocs = () => {
		const contractId = props.contractIdSign;
		const contract = sigCanvas.current.fromDataURL();
		const canvas = sigCanvas.current.getCanvas();

		canvas.toBlob((blob) => {
			const formData = new window.FormData();
			formData.append('contract_id',contractId);
			formData.append('signature', blob, 'signature.png');
			
			props.signConract(SIGN_CONTRACT_URL_API,setSignContractState,formData, props.onContractSignedSuccessModalOpen);
		});
	}

	return (
		<>
			<Modal
				title="Add your Signature"
				width={514}
				visible={props.modalStatus}
				footer={null}
				onCancel={props.onCancel}
				className="SignatureModal"
			>
				<Row className="">
					<Col className="gutter-row" span={24}>
						<SignatureCanvas
							ref={sigCanvas}
							penColor="black"
							canvasProps={{
								width: 452,
								height: 200,
								className: 'sigCanvas mb-10',
							}}
						/>
					</Col>
				</Row>
				{/*<p className="error-text font-12">*/}
				{/*Error : Digital Signature is required to download the contract*/}
				{/*</p>*/}
				<Row className="">
					<Col className="gutter-row" span={24}>
						{signContractState.loading && (
							<Spin />
						)}
						{!signContractState.loading && (
							<>
								<Button block className="btn-outline-primary" onClick={clear}>
									Reset
								</Button>
								<Button block className="" type="primary" onClick={signDocs}>
									Submit
								</Button>
							</>
						)}
						{signContractState.error && (
							 <Alert message={signContractState.error} type="error" />
						)}
					</Col>
				</Row>
			</Modal>
		</>
	);
};

export default SignatureCanvasPage;
