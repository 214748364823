import styled, { keyframes } from 'styled-components';

const SapLandingWrapper= styled.div`
font-family: 'Poppins',sans-serif !important;
.content-offplan{
  max-width: 30rem;
}
.max-w-txt{
  max-width: 446px
}
.mb-40{
  margin-bottom:40px;
}
.m-auto{
    margin:0 auto;
}
.max-w{
    text-align: center;
    max-width: 625px;
    margin: 0 auto;
}
.bg-marketing{
  background-repeat: no-repeat;
  background-image: url("/images/shiny-night-city.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height:20rem;
  position:relative;
}
.banner-footer{
   
    background-repeat: no-repeat;
    background-image: url("/images/bgimages.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height:31rem;
}
.get-started{
  padding: 12px 126px !important;
    font-size: 16px !important;
  border-radius: 5px !important;
  font-weight: bold !important;
  color: #000 !important;
  background-color: #fff !important;
  border: 1px solid #d9d9d9 !important;
    &:hover{
        background-color: #fff !important;
        border-color: #fff !important;
        color: #F3B521 !important;
    }
}
.mr-2{
    margin-right:10px;
}
.form-control{
  height: 43px;
  border-radius: 10px;
  border: 1px solid gray;
  width: 34rem;
  padding: 0 10px;
}
.mb-4{
    margin-bottom: 20px;
}
.bg-theme{
    background-color: #36333D;
    padding: 30px;
    border-radius: 4px;
    max-width: 472px;
    margin: 0 auto;

} 
.mb-2{
    margin-bottom:5px;
}
label {
    color: gray;
    font-size: 15px;
}
   .mb-0{
    margin-bottom:0px !important;
   }
   .margin-top{
       margin-top: 3rem;
   }
   .main{
    p{
      color: gray;
      font-weight: 500;
      font-size: 14px;
    }
    .txt{
        margin-bottom:1rem;
    }
    .my-3{
        margin:30px 0px ;
    }
   }
  .fw-bolder{
    font-weight:bold!important ;
  }
  .mt-2{
    margin-top:20px;
  }
  .mt-3{
    margin-top:40px;
  }
  .mb-3{
    margin-bottom:40px;
  }
  .pt-3{
    padding-top:26px;
  }
  .pb-3{
    padding-bottom:40px;
  }
  .pb-6{
    padding-bottom:70px;
  }
  .bg-gray{
     background-color: #f5f5f5;
  }
  .font-30{
    font-size:30px;
  }
  .font-46{
    font-size:46px;
  }
  .font-18{
    font-size:18px;
  }
  li {
    list-style-type: disclosure-closed;
    margin-bottom: 10px;
}
.bg-black{
  background-color:#000;
}
.properties-card{
    margin: 0 7px;
    box-shadow: 0 0.125rem 0.25rem rgba(0,0,0,.090);
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    
}

.properties-images{
    height: 199px;
    width: 356px;
    object-fit: cover;
}
.properties-heading{
  font-size: 17px;
  color: gray;
  text-align: center;
  padding: 15px 0px 5px;
  margin: 0;
  font-weight: 600;
}
.properties-txt{
  font-size: 12px;
  color: gray;
  text-align: center;
 
  
}
.text-gray{
  color:gray;
}
.px-3{
  padding: 0 16px;
}
.font-600{
  font-weight: 600;
}
.border-bottom{
  border-bottom: 1px solid #c7c7c7;
}
.pb-1{
  padding-bottom:10px;
}
.mb-1{
  margin-bottom: 1rem;
}
.btn-properties{
  width: 100%;
     background-color: #F3B521;
     color: #2C2C2C;
    font-weight: 600;
    min-height: 41px;
    border: none;
    text-align:center;
    line-height: 40px;
    // &:hover{
    //   background-color: #F3B521;
    //   color: #2C2C2C;
    //   transition: all 0.6s ease-in-out;
    // }
}
.why-card{
  background-color: #ffffff;
    color: gray;
    padding: 29px;
    max-width: 35rem;
    border-radius: 18px;
    position: absolute;
    top: 86px;
    left: 28%;
}
.review{
  font-size: 32px;
    padding-top: 20PX;
    font-weight: 600 !IMPORTANT;
    color:#fff;
}
.customer-images{
     border-radius: 50%;
    max-width: 180px;
}
.star{
  max-width: 27px;
}
.max-custom{
  max-width: 180px
}
.mr-3{
  margin-right:30px;
}
.main-cus{
  max-width: 50rem;
  margin: 0 auto;
}
.max-width-form{
  max-width: 545px;
  margin: 0 auto;
  height: 6rem;
}
.community-card{
  background-color:#000;
  padding:10px;
  border-radius:10px;
  box-shadow: 0 0.125rem 0.25rem rgba(0,0,0,.090);
  margin:0 10px;
}
.community-img{
  max-width: 236px;
}
.community-txt{
  text-align: center;
    font-size: 20px;
    font-weight: 600;
    padding: 14px 0px 0;
}
`;
export default SapLandingWrapper;
