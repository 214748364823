import React from 'react';
import { Row, Col } from 'antd';
import Container from "../../../components/UI/Container/Container";
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';

const languages = [
  {
      code: 'en',
      name: 'English',
      country_code: 'gb',
  },
  {
      code: 'ar',
      name: 'العربية',
      dir: 'rtl',
      country_code: 'sa',
  },
];
const Ourvaluesnew = () => {
    const currentLanguageCode = cookies.get('i18next') || 'en';
const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
const { t } = useTranslation();
    return (
        <>
            <div className="ourmission-blog align-middle abt-mission mb-50" id="componentToScrollTo1">
                <Container>
                    <div className="our-mission-head">
                        <h4>{t('Our Values')}</h4>
                        {/* <p>
                            At RealCube, our mission is to revolutionize the property and
                            facilities management industry through our cutting-edge,
                            cloud-based technology.{" "}
                        </p> */}

                        <p>
                        {t('Integrity, innovation, and excellence are the core values that drive everything we do at RealCube. We believe in honesty, transparency and ethical business practices, and we always strive to do the right thing, even when no ')}
                        </p>
                    </div>
                    <Row gutter={[24, 0]} className="d-flex align-items-center">
                        <Col lg={6} md={12} xs={24}>
                            <img loading="lazy" src="/images/aboutus/value-1.png" alt="aboutus-img" className='abt-img-1' width="540" height="230"/>
                        </Col>
                        <Col lg={6} md={12} xs={24} className="align-self-end">
                            <img loading="lazy" src="/images/aboutus/value-2.png" alt="aboutus-img" className='abt-img-1  abt-img' width="540" height="230"/>
                        </Col>
                        <Col lg={6} md={12} xs={24} className="align-self-end">
                            <img loading="lazy" src="/images/aboutus/value-3.png" alt="aboutus-img" className='abt-img-1  abt-img' width="540" height="230"/>
                        </Col>
                        <Col lg={6} md={12} xs={24}>
                            <img loading="lazy" src="/images/aboutus/value-4.png" alt="aboutus-img" className='abt-img-1' width="540" height="230"/>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};
export default Ourvaluesnew;

