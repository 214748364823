import React, { useState, useReducer, useEffect } from 'react';
import AuthReducer from './reducers/AuthReducer';
import RegisterUserReducer from './reducers/RegisterUserReducer';
import ProfileReducer from './reducers/ProfileReducer';
import { useNavigate } from "react-router-dom";

import { AGENT_ACCOUNT_SETTINGS_PAGE_DASHBOARD, AGENT_ACCOUNT_SETTINGS_PAGE_DASHBOARD_LOCAL } from 'setup/constant';
import {
    notificationsReducer
} from './reducers/BasicReducer';

import {
    LOGIN_URL_API,
    SIGNUP_URL_API,
    SIGNUP_URL_API_OTP,
    USER_PROFILE_URL_API,
    NOTIFICATION_URL_API
} from '../setup/constant';
export const MainContext = React.createContext();

const userInfoFromStorage = localStorage.getItem('userInfo')
    ? JSON.parse(localStorage.getItem('userInfo'))
    : null;

const initialStateUser = {
    loading: false,
    userInfo: userInfoFromStorage,
    error: null
}

const initialStateRegister = {
    loading: false,
    registerationInfo: {},
    error: null
}

const initialStateUserProfile = {
    loading: false,
    profileDetails: {},
    error: null,
}

/* ************* from old basic reducer ************** */

const initialStateNotifications = {
    loading: false,
    notifications: [],
    error: null
}

/* ********** from old basic reducer *********** */

const MainProvider = (props) => {
    let navigate = useNavigate();
    let loggedInInitialState = localStorage.getItem('userInfo')
        ? true
        : false;
    useEffect(() => {
        if (loggedInInitialState) {

        } else {
            localStorage.removeItem('userInfo');
            localStorage.removeItem('accessToken');
            setLoggedIn(false)
        }
    }, [loggedInInitialState])
    const [loggedIn, setLoggedIn] = useState(loggedInInitialState);
    const [signUpMail, setSignUpMail] = useState("");
    const [mailResent, setMailResent] = useState(true);
    const [userState, dispatchLogin] = useReducer(AuthReducer, initialStateUser);
    const [registerState, dispatchRegister] = useReducer(RegisterUserReducer, initialStateRegister);
    const [userProfileState, dispatchUserProfile] = useReducer(ProfileReducer, initialStateUserProfile);


    const search = window.location.search;
    const params = new URLSearchParams(search);
    let txnId = 0
    let Id = params?.get('d');
    let locationRoute = params?.get('l');
    let locationRouteData = params?.get('datas');
    if (Id != null) {
        txnId = params.get('t');
    }
    /* ******* from old basic reducer *********** */
    const [notificationState, dispatchNotification] = useReducer(notificationsReducer, initialStateNotifications);
    /* ******* from old basic reducer *********** */

    const [propertyDetails, setPropertyDetails] = useState({});
    const [makeOfferFormValue, setMakeOfferFormValue] = useState({
        property_id: "",
        user_id: "",
        installment: "1",
        customer_name: "",
        start_date: "",
        end_date: "",
        mode_payment: "CREDIT_CARD",
        offer_request_price: "",
        employer_name: "",
        housing_allowance: "",
        add_on: ""
    });

    async function makeRequest(method, URL, setState, requestParams = '', formData = false, callback = () => { }) {
        try {
            setState({
                loading: true
            });

            let headers = {}
            let body = requestParams;

            if (!formData) {
                headers = {
                    'Content-Type': 'application/json',
                }
                body = JSON.stringify(requestParams);
            }

            if (userState.userInfo !== "undefined") {
                const token = userState.userInfo.access_token;
                headers = {
                    ...headers,
                    'Authorization': `Bearer ${token}`
                }
            }

            let init = method == "POST" ? {
                method: method,
                headers: headers,
                body: body
            } : {
                method: method,
                headers: headers
            };

            const response = await fetch(URL, init);
            const responseData = await response.json();

            if (responseData.code == 200 && responseData.errorCode == 0) {
                setState({
                    response: responseData.data,
                    loading: false
                });
                callback();
            } else if (responseData.code == 401) {
                navigate("/");
                logOut();
                if (responseData.errorDescription !== null && responseData.errorDescription !== '') {
                    throw responseData.errorDescription;
                } else {
                    throw "Server error 401";
                }
            } else {
                if (responseData.errorDescription !== null && responseData.errorDescription !== '') {
                    throw responseData.errorDescription;
                } else {
                    throw "Server error E";
                }
            }
        } catch (err) {
            console.error(err);
            let catchError = "";
            if (typeof err !== "string") {
                catchError = "Server error ";
            } else {
                catchError = err;
            }

            setState({
                loading: false, error: catchError, response: null
            });
        }
    }


    async function signIn(params) {
        // console.log('Location - ', window.location.pathname)
        try {
            dispatchLogin({
                type: 'USER_LOGIN_REQUEST',
            });

            let requestParams = {};

            if (params.source === "GOOGLE" || params.source === "FACEBOOK") {
                requestParams = params;
            } else {

                setSignUpMail(params?.username);
                setMailResent(true)
                requestParams = {
                    grant_type: "password",
                    client_id: 6,
                    client_secret: 'gJcODt3QkNwo6wrZQUvLehzXXNH2Te1l279I7AuL',
                    username: params.username,
                    password: params.password
                };
            }

            //console.log('login params', requestParams);

            const response = await fetch(
                LOGIN_URL_API,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(requestParams)
                }
            );
            /* console.log('login response',response); */


            const userData = await response.json();

            const userInfo = {
                ...userData.data.userProfile,
                access_token: userData.data.access_token,
                expires_in: userData.data.expires_in,
                refresh_token: userData.data.refresh_token,
                token_type: userData.data.token_type
            };

            //const userData = JSON.parse('{"code":200,"requestID":"","errorCode":0,"errorDescription":"","successMsg":"","data":{"token_type":"Bearer","expires_in":31535999,"access_token":"eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI2IiwianRpIjoiMTAxNDEzYTA1MDAxNDdkOGQ4Zjc0MzBjNjk0ZmFkOWMwZDUxZjk2NjA2YmIzODE4NzhmYjMzNjJlMGEwMDMzNjYzYmJkZmM4OTgzMzYzYzEiLCJpYXQiOjE2Mzg5MDk5NDAsIm5iZiI6MTYzODkwOTk0MCwiZXhwIjoxNjcwNDQ1OTM5LCJzdWIiOiI1Iiwic2NvcGVzIjpbXX0.oT-2fCaQBxTuatonzz4HaSQkBHU1sjpAqfWZ7wi0XYwEr-5_CFL4OnTGgZw5Wcg23aLV3Tlrns2zbCAvgvQpWQM6SSK7KZmtLjzNLuVzBgbzjQelObI4xsbuVv4DyP2i27YkcUZ-fWxd1jpKXWbSjwmRs7BiYVZGbKOUqdnq12yW3quKkZC31U-rcwN_rI1BP-9eShc0Idy0nyl2_cM08hEwyrTrMlsRnu30HV1F1scSxJpv6adbm3t74dp70Hn8WVhk-xPO8Izy06vrpsRiQM9IU-1aBPChCcBmkHuJED6fKpaJGAhwRM6QobdvOm0AoTyqLCDxvSbrOz70JBLQFFI3xyziSPgxM1bXoIig6qpEqPVckH9p_2_iZB2qBPe7Ek4AmwKa7owOjIVDDrhgSGhdPyhFXjJ4fZOcgn-tpeTB3GaXQ4Uhn9M8xLEU-521fC1lWryZww6KqXPlF7eTBWJ7vwdUT1h3p5ZQXbnOqBWQLbaoU6tVKnTxoaKj4YVo4ZDFeceKWmBokXApd58cIu_zyU39Z3E19-nxzi-2jfax8uF-NiLoGcziaPZyaUyVE7hVkDQ_rkqsnESf_4RBLvHUox9Rcr1nIt1huQfCzMHMsimlb9vl-v-lFkitHxRmfbnPBrvmKa0Wy7zYhOroVtdCJTyqXoTKMbRYoDgBIE8","refresh_token":"def502007d06ea948afbf85ef24a2be80a1eaea22c59374a4b3bb9b48c2734fef51be5cd08563cfd2793cf37a84fa30fe224cf4ec4548519d2b0c8c750ccfaeaac47627648eaeb0c9195d4cf08aab9edee27d2dacce53201989a4c5d479c22a3d01ae3e56cf499fed8654cedd7d906e1bbb4e0b55b75c761f21fb939edb5e78e59e18e826e6dda66ede821b8cb2db67738a79d1fcceae3a16d1f795d3fdfdb2f4ea8c4da038942787fe9c68c6dc3eb35fc68cbe0c951ff36fbd3dcba74ca9cb16f81d45e1121fd097dc31dd37cc4f9b7436fd7b9e931a010612fde95b3dae037b65c637d6d59ecc0cac0e21c04e6e11581f98a24413031f1142f6daa56dd8f69810028249a876e519ed3aa7966ef7e2f8b9a3d99f98e943f6342837c4cb5dcb87731586697f2dc8a6fa800100f006f5d74b9cabc977b11409179366472510f5bb7b3df79e78d4f150e9600aebc3e0a9bad8e9d2cea81abfaf75b3b00423f13a29b","userProfile":{"first_name":"User","last_name":"K","email":"user@localhost.co","id_role":2}}}');


            if (userData.code == 200 && userData.errorCode == 0) {
                if (userData.data.userProfile.is_email_verified === 1) {
                    dispatchLogin({
                        type: 'USER_LOGIN_SUCCESS',
                        payload: userInfo
                    });
                    getUserProfile(userInfo.access_token);
                    localStorage.setItem('userInfo', JSON.stringify(userInfo));
                    localStorage.setItem('accessToken', JSON.stringify(userInfo.access_token));
                    setLoggedIn(true);
                    const token = Buffer.from(userInfo?.access_token).toString('base64')
                    const idRole = Buffer.from(userInfo?.id_role.toString()).toString('base64')
                    if (!window.location.pathname.includes('/properties/')) {
                        window.location.assign(process.env.NODE_ENV !== 'production' ? AGENT_ACCOUNT_SETTINGS_PAGE_DASHBOARD_LOCAL + `?t=${token}&d=${idRole}&l=${Buffer.from('dashboard'.toString()).toString('base64')}` : AGENT_ACCOUNT_SETTINGS_PAGE_DASHBOARD + `?t=${token}&d=${idRole}&l=${Buffer.from('dashboard'.toString()).toString('base64')}`)
                    }
                } else {
                    throw "User not verified";
                }
            } else {
                if (userData.errorCode === 1 && userData.data.email_not_verified === 1 && userData.errorDescription !== '') {
                    throw [userData.errorDescription, 'not_verified'];
                }else if (userData.errorDescription !== null && userData.errorDescription !== '') {
                    throw userData.errorDescription;
                } else {
                    throw "Server error"
                }
            }

        } catch (err) {
            let catchError = "";
            if (Array.isArray(err) && err[1] === 'not_verified')
                catchError = err
            else if (typeof err !== "string") {
                catchError = "Server error";
            } else {
                catchError = err;
            }

            dispatchLogin({
                type: 'USER_LOGIN_FAIL',
                payload: catchError
            });
        }
    }

    const signUp = async (params) => {
        try {
            dispatchRegister({
                type: 'USER_REGISTER_REQUEST',
                payload: userData
            });
            let requestParams = {};
            if (params.source === "GOOGLE" || params.source === "FACEBOOK") {
                requestParams = params;
            } else {
                setSignUpMail(params?.email);
                requestParams = {
                    role_id: params?.customer_type === "1" ? 4 : 14,
                    first_name: params?.customer_type === "1" ? params.firstname : params.organization_name,
                    last_name: params?.lastname,
                    email: params?.email,
                    password: params?.password,
                    confirm_password: params?.password,
                    phone: (params?.phone?.length && params?.phone?.length > 4 ? params.phone.substring(3) : params?.phone),
                    phone_code: (params?.phone?.length && params?.phone?.length > 4 ? '+' + params.phone.substring(0, 3) : ''),
                    customer_type: params?.customer_type,
                }
            }
            const response = await fetch(
                SIGNUP_URL_API,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(requestParams)
                }
            );

            const userData = await response.json();
            const userInfo = {
                ...userData.data.userProfile,
                access_token: userData.data.access_token,
                expires_in: userData.data.expires_in,
                refresh_token: userData.data.refresh_token,
                token_type: userData.data.token_type
            };
            // if (userData.code != 200) {
            //     if (userData.errorDescription !== null && userData.errorDescription !== '') {
            //         throw userData.errorDescription;
            //     } else {
            //         throw "Server error"
            //     }
            // }
            if (userData.code == 200 && userData.errorCode == 0) {
                dispatchRegister({
                    type: 'USER_REGISTER_SUCCESS',
                    payload: userData
                });
                if (userData?.data?.userProfile?.is_email_verified === 1) {
                    dispatchRegister({
                        type: 'USER_REGISTER_SUCCESS',
                        payload: userData
                    });

                    getUserProfile(userInfo.access_token);
                    localStorage.setItem('userInfo', JSON.stringify(userInfo));
                    // document.cookie = "sso=" + "sso"
                    // // document.cookie ="domain=" + process.env.NODE_ENV === 'production' ? AGENT_ACCOUNT_SETTINGS_PAGE_DASHBOARD_LOCAL : AGENT_ACCOUNT_SETTINGS_PAGE_DASHBOARD
                    // document.cookie = "userDetail=" + JSON.stringify(userInfo)
                    // document.cookie = "key=value; expires=Tue, 16 Apr 2022 11:31:56 GMT; domain=AGENT_ACCOUNT_SETTINGS_PAGE_DASHBOARD"
                    // document.cookie ="G_AUTHUSER_H=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
                    setLoggedIn(true);
                    window.location.reload();
                }else if(userData.description === "Signup successful..."){
                    dispatchRegister({
                        type: 'USER_REGISTER_SUCCESS',
                        payload: userData
                    });
                    throw userData.description + ' Please verify your email address and login';
                }
            // }
            // else if (userData.code === 200 && userData.errorCode === 0 && userData.description === "Signup successful...") {

            //     dispatchRegister({
            //         type: 'USER_REGISTER_SUCCESS',
            //         payload: userData
            //     });
            //     throw userData.description + ' Please verify your email address and login';
            } else {
                if (userData.errorDescription !== null && userData.errorDescription !== '') {

                    throw userData.errorDescription;
                }
                else {
                    throw userData.description + ' Please verify your email address and login';
                }
            }
        } catch (err) {
            // console.log(err, 'Reg error');
            let catchError = "";
            if (typeof err !== "string") {
                catchError = "Server error";
            } else {
                catchError = err;
            }

            dispatchRegister({
                type: 'USER_REGISTER_FAIL',
                payload: catchError
            });
        }
    };
    const signUpWithOTP = async (params) => {
        try {
            dispatchRegister({
                type: 'USER_REGISTER_REQUEST',
                payload: userData
            });
            let requestParams = {};
            setSignUpMail(params?.email);
            requestParams = {

                email: params?.email,
                type: params?.type,
                otp: params?.otp
            }
            const response = await fetch(
                SIGNUP_URL_API_OTP,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(requestParams)
                }
            );

            const userData = await response.json();
            const userInfo = {
                ...userData.data.userProfile,
                access_token: userData.data.access_token,
                expires_in: userData.data.expires_in,
                refresh_token: userData.data.refresh_token,
                token_type: userData.data.token_type
            };
            if (userData.code == 200 && userData.errorCode == 0) {
                if (userData.data.userProfile.is_email_verified === 1) {
                    dispatchLogin({
                        type: 'USER_LOGIN_SUCCESS',
                        payload: userInfo
                    });
                    getUserProfile(userInfo.access_token);
                    localStorage.setItem('userInfo', JSON.stringify(userInfo));
                    localStorage.setItem('accessToken', JSON.stringify(userInfo.access_token));
                    setLoggedIn(true);
                    const token = Buffer.from(userInfo?.access_token).toString('base64')
                    const idRole = Buffer.from(userInfo?.id_role.toString()).toString('base64')
                    if (!window.location.pathname.includes('/properties/')) {
                        window.location.assign(process.env.NODE_ENV !== 'production' ? AGENT_ACCOUNT_SETTINGS_PAGE_DASHBOARD_LOCAL + `?t=${token}&d=${idRole}&l=${Buffer.from('dashboard'.toString()).toString('base64')}` : AGENT_ACCOUNT_SETTINGS_PAGE_DASHBOARD + `?t=${token}&d=${idRole}&l=${Buffer.from('dashboard'.toString()).toString('base64')}`)
                    }
                } else {
                    throw "User not verified";
                }
            } else {
                if (userData.errorDescription !== null && userData.errorDescription !== '') {
                    throw userData.errorDescription;
                } else {
                    throw "Server error"
                }
            }
        } catch (err) {

            let catchError = "";
            if (typeof err !== "string") {
                catchError = "Server error";
            } else {
                catchError = err;
            }

            dispatchRegister({
                type: 'USER_REGISTER_FAIL',
                payload: catchError
            });
        }
    };
    async function getUserProfile(token) {
        try {
            dispatchUserProfile({
                type: 'PROFILE_DETAILS_REQUEST',
            });
            const response = await fetch(
                USER_PROFILE_URL_API,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                }
            );

            const profileData = await response.json();


            if (profileData.code == 200 && profileData.errorCode == 0) {
                dispatchUserProfile({
                    type: 'PROFILE_DETAILS_SUCCESS',
                    payload: profileData
                });
                return profileData
            } else if (profileData.code == 401) {
                navigate("/");
                window.location.reload();
                logOut();
                if (profileData.errorDescription !== null && profileData.errorDescription !== '') {
                    throw profileData.errorDescription;

                } else {
                    throw "Server error 401";
                }
            } else {
                if (profileData.errorDescription !== null && profileData.errorDescription !== '') {
                    throw profileData.errorDescription;
                } else {
                    throw "Server error else";
                }
            }
        } catch (err) {

            let catchError = "";
            if (typeof err !== "string") {
                catchError = "Server error";
            } else {
                catchError = err;
            }

            dispatchUserProfile({
                type: 'PROFILE_DETAILS_FAIL',
                payload: catchError
            });
        }
    };

    const logOut = () => {
        localStorage.removeItem('userInfo');
        setLoggedIn(false);
        dispatchLogin({ type: 'USER_LOGOUT' });
        navigate("/");
    };
    const logOutSSO = () => {
        localStorage.removeItem('userInfo');
        // document.cookie = 'userDetail=' + ' '
        setLoggedIn(false);
        dispatchLogin({ type: 'USER_LOGOUT' });
        // window.location.reload();

    };

    /* *********** old basic action ************** */
    async function getNotifications(token) {
        try {
            dispatchNotification({
                type: 'NOTIFICATIONS_REQUEST',
            });

            const response = await fetch(
                NOTIFICATION_URL_API,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                }
            );

            const data = await response.json();


            if (data.code == 200 && data.errorCode == 0) {
                dispatchNotification({
                    type: 'NOTIFICATIONS_SUCCESS',
                    payload: data.data,
                    unreadCount: data.unreadCount,

                });
            } else if (data.code == 401) {
                navigate("/");
                logOut();
                if (data.errorDescription !== null && data.errorDescription !== '') {
                    throw data.errorDescription;
                } else {
                    throw "Server error 401";
                }
            } else {
                if (data.errorDescription !== null && data.errorDescription !== '') {
                    throw data.errorDescription;
                } else {
                    throw "Server error else";
                }
            }
        } catch (err) {
            dispatchNotification({
                type: 'NOTIFICATIONS_FAIL',
                payload: 'notification error'
            });
        }
    }
    useEffect(() => {
        let ssoToken
        let ssoRoleId
        let locations
        let datas
        if (txnId != null && txnId?.length > 0 > 0) {
            ssoToken = Buffer?.from(txnId?.toString(), 'base64')?.toString()
            ssoRoleId = (Buffer?.from(Id?.toString(), 'base64')?.toString())
            locations = (Buffer?.from(locationRoute?.toString(), 'base64')?.toString())
            datas = locationRouteData != null && JSON.parse((Buffer?.from(locationRouteData?.toString(), 'base64')?.toString()))
        }
        if (ssoRoleId === '4' || ssoRoleId === '5' || ssoRoleId === '6' || ssoRoleId === '7' || ssoRoleId === '14' || ssoRoleId === '15') {
            logOutSSO()
            ssoLogin()
        }
        async function ssoLogin() {
            // const ssoLogin = async () => {
            try {
                const data = await getUserProfile(ssoToken)
                if (data.code == 200 && data.errorCode === 0) {
                    const userInfo = {
                        ...data.data,
                        access_token: ssoToken,
                    }
                    dispatchLogin({
                        type: 'USER_LOGIN_SUCCESS',
                        payload: userInfo
                    });
                    localStorage.setItem('userInfo', JSON.stringify(userInfo))
                    localStorage.setItem('accessToken', JSON.stringify(ssoToken));
                    setLoggedIn(true);
                    if (locations && locationRouteData == null) {
                        // navigate(locations)
                        navigate(`${locations}`, { state: { type: 'sso' } });
                    } else if (locations && locationRouteData != null) {
                        navigate(`${locations}`, { state: { type: 'search', data: datas } });
                    } else {
                        navigate("/");
                    }
                } else {
                }
            } catch (error) { }
            finally {
            }
        }
    }, [txnId]);
    /* ***********end old basic action ************** */


    /* ********************************************** */

    /* ********************************************** */
    return (
        <MainContext.Provider
            value={{
                makeRequest,
                loggedIn,
                logOut,
                user: userState,
                signIn,
                userProfileState,
                signUp,
                signUpWithOTP,
                signUpMail,
                registerationInfo: registerState,
                getUserProfile,
                setMailResent,
                mailResent,

                notificationState,
                getNotifications,

                makeOfferFormValue,
                setMakeOfferFormValue,

                propertyDetails,
                setPropertyDetails
            }}
        >
            <>{props.children}</>
        </MainContext.Provider>
    );
};

export default MainProvider;