import React, { useState, useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Sticky from 'react-stickynode';
import { IoIosClose } from 'react-icons/io';
import { Button, Drawer, Menu, Popover, Input } from 'antd';
import Logo from '../../../components/UI/Logo/Logo';
import Text from '../../../components/UI/Text/Text';
import TextLink from '../../../components/UI/TextLink/TextLink';
import Navbar from '../../../components/Navbar/Navbar';
import { MainContext } from '../../../constants/MainProvider';
import { LayoutContext } from '../../../constants/LayoutProvider';
import useWindowSize from 'library/hooks/useWindowSize';
import { AGENT_ACCOUNT_SETTINGS_PAGE_DASHBOARD, AGENT_ACCOUNT_SETTINGS_PAGE_DASHBOARD_LOCAL, AGENT_PROFILE_PAGE } from '../../../setup/constant';
import AuthMenu from './AuthMenu';
import MainMenu from './MainMenu';
import MobileMenu from './MobileMenu';
import ProfileMenu from './ProfileMenu';
import NavbarSearch from './NavbarSearch';
import Container from '../../../components/UI/Container/Container';
import HeaderWrapper, {
  MobileNavbar,
  CloseDrawer,
  AvatarWrapper,
  AvatarImage,
  AvatarInfo,
  LogoArea,
} from './Header.style';
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import cookies from 'js-cookie'
import LogoutModal from '../../Auth/Logout/Logout';
import { SearchOutlined } from '@ant-design/icons';
import InboxModal from '../../InboxModal/InboxModal';

const languages = [
  {
    code: 'en',
    name: 'English',
    country_code: 'gb',
  },
  {
    code: 'ar',
    name: 'العربية',
    dir: 'rtl',
    country_code: 'sa',
  },
];

var avatarImg = `/images/avatar.webp`;

const LogoIcon = () => (
  <> </>
  // <svg width="25" height="27.984" viewBox="0 0 25 27.984" className="logo-svg">
  //   <g transform="translate(0 0)">
  //     <path
  //       d="M25.45,2.767a34.5,34.5,0,0,0-4,1.143,35.262,35.262,0,0,0-3.771,1.545,26.069,26.069,0,0,0-3.179,1.8,26.068,26.068,0,0,0-3.191-1.8A35.262,35.262,0,0,0,7.54,3.909,34.5,34.5,0,0,0,3.55,2.767L2,2.45V17.94a12.5,12.5,0,1,0,25,0V2.45ZM14.5,10.492c2.339,1.96,3.522,4.19,3.512,6.608a3.512,3.512,0,1,1-7.024,0h0C10.98,14.66,12.162,12.442,14.5,10.492Zm9.913,7.448a9.915,9.915,0,0,1-19.831,0V5.69a31.8,31.8,0,0,1,7.748,3.259,13.43,13.43,0,0,0-2.344,2.737A9.929,9.929,0,0,0,8.4,17.095a6.1,6.1,0,1,0,12.2,0,9.932,9.932,0,0,0-1.587-5.412,13.427,13.427,0,0,0-2.346-2.742,29.737,29.737,0,0,1,5.586-2.577c.819-.284,1.559-.51,2.158-.675Z"
  //       transform="translate(-2 -2.45)"
  //       fill="#fff"
  //     />
  //   </g>
  // </svg>
);

export default function Header() {
  let navigate = useNavigate();

  const currentLanguageCode = cookies.get('i18next') || 'en';
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();
  const [inboxModalVisible, setInboxModalVisible] = useState(false);

  useEffect(() => {
    document.body.dir = currentLanguage.dir || 'ltr';
    document.title = t('app_title')
  }, [currentLanguage, t]);
  const { logOut, user, loggedIn: logged, userProfileState } = useContext(MainContext);
  const { loading: loadingProfile, profileDetails, error: errorProfile } = userProfileState;
  const { userInfo } = user;
  let token;
  let idRole;
  if (logged && userInfo?.id_role > 0) {
    token = Buffer?.from(userInfo?.access_token)?.toString('base64')
    idRole = Buffer?.from(userInfo?.id_role?.toString())?.toString('base64')
  }
  useEffect(() => {
    if (profileDetails?.profile_img) {
      avatarImg = profileDetails.profile_img;
    }
  }, [profileDetails]);

  let location = useLocation();
  const [{ searchVisibility }] = useContext(LayoutContext);
  const { loggedIn } = useContext(MainContext);
  const { width } = useWindowSize();
  const [state, setState] = useState(false);
  const sidebarHandler = () => {
    setState(!state);
  };
  const headerType = location.pathname === '/' ? 'transparent' : 'transparent';

  const { Search } = Input;

  const [keyword, setKeyword] = useState("");
  const onSearch = (value) => {
    setKeyword(value);
    const datas = {
      keyword_search: value,
    };
    if (window.location.pathname != '/properties-listing') {
      navigate(`properties-listing/`, { state: { type: 'search', data: datas } });
    } else {
      navigate('/', { replace: true });
      setTimeout(() => {
        navigate('properties-listing', { state: { type: 'search', data: datas } });
      }, 2000);
    }
  }

  const searchBox = (
    <div>
      {/*<Search placeholder="input search text" onSearch={onSearch} enterButton />*/}
      <Search
        placeholder={t('keyword_search')}
        allowClear
        htmlType="submit"
        enterButton={t('search')}
        className="headder_keyword_search"
        size="large"
        onSearch={onSearch}
      />
    </div>
  );

  const onHandleInboxModalClose = () => {
    setInboxModalVisible(false);
  };

  const onHandleInboxModalOpen = () => {
    setState(!state);
    setInboxModalVisible(true);
  };

  return (
    <>
      <HeaderWrapper>
        <Sticky
          top={headerType === 'transparent' ? -1 : 0}
          innerZ={10001}
          activeClass="isHeaderSticky"
        >
          {width > 991 ? (
            <Container className="container">
              <Navbar
                logo={
                  <>
                    {headerType === 'transparent' && <LogoIcon />}
                    {/*<Logo*/}
                    {/*withLink*/}
                    {/*linkTo="/"*/}
                    {/*src="/images/realcube-logo.svg"*/}
                    {/*/>*/}
                    <Link to={"/"} className="logo-img">
                      <img
                        src="/images/realcube-logo.svg"
                        width="150"
                        height="27"
                        loading="lazy"
                        alt="RealCube Logo"
                      />
                    </Link>
                  </>
                }
                navMenu={<MainMenu />}
                authMenu={<AuthMenu />}
                isLogin={loggedIn}
                avatar={<Logo src={avatarImg} />}
                profileMenu={<ProfileMenu avatar={<Logo src={avatarImg} />} />}
                headerType={headerType}
                // searchComponent={<NavbarSearch />}
                location={location}
                searchVisibility={searchVisibility}
              />
            </Container>
          ) : (

            <MobileNavbar className={headerType}>
              <div className='mobile-container'>
                <LogoArea>
                  <>
                    {headerType === 'transparent' && <LogoIcon />}
                    <Logo
                      withLink
                      linkTo="/"
                      src="/images/realcube-logo.svg"
                    />
                  </>
                  <NavbarSearch />
                </LogoArea>
                <Button
                  className={`hamburg-btn ${state ? 'active' : ''}`}
                  onClick={sidebarHandler}
                >
                  <span />
                  <span />
                  <span />
                </Button>
                <Drawer
                  placement="right"
                  closable={false}
                  onClose={sidebarHandler}
                  width="387px"
                  className="mobile-header"
                  visible={state}
                >
                  <CloseDrawer>
                    <button onClick={sidebarHandler}>
                      <IoIosClose />
                    </button>
                  </CloseDrawer>
                  {loggedIn ? (
                    <>
                      <AvatarWrapper>
                        <AvatarImage>
                          <Logo src={avatarImg} />
                        </AvatarImage>
                        <AvatarInfo>
                          <Text as="h3" />
                          <a href={process.env.NODE_ENV !== 'production' ? AGENT_ACCOUNT_SETTINGS_PAGE_DASHBOARD_LOCAL + `?t=${token}&d=${idRole}` : AGENT_ACCOUNT_SETTINGS_PAGE_DASHBOARD + `?t=${token}&d=${idRole}`} target="_blank">{t('view_profile')}</a>
                        </AvatarInfo>
                      </AvatarWrapper>
                      <Menu mode="horizontal" className="mobile-menu-loggedin auth-menu">
                        <Menu.Item key="0" className="">
                          <Popover placement="left" overlayClassName='header-drop' content={searchBox} trigger="click">
                            <Button type="outline-primary" className="header-search-btn">
                              <SearchOutlined />
                            </Button>
                          </Popover>
                        </Menu.Item>
                        <Menu.Item
                          key="1"   
                        >
                          <Button type="primary" onClick={() => {
                            i18next.changeLanguage(currentLanguage.dir ? "en" : "ar")
                          }}>
                            {currentLanguage.dir ? 'English' : 'عربی'}
                          </Button>
                        </Menu.Item>
                        <Menu.Item
                          key="2"
                          className="notif-menu mobile-notif-menu"
                          onClick={onHandleInboxModalOpen}
                        >
                          <img
                            alt="Notifications"
                            src="/images/bell-icon.webp"
                            className="position-relative"
                            loading="lazy"
                            width="16"
                            height="20"
                          />
                          <span className="badge-danger"></span>
                        </Menu.Item>
                      </Menu>
                    </>
                  ) : (
                    <AuthMenu className="auth-menu" />
                  )}
                  <MobileMenu className="main-menu" />
                </Drawer>
              </div>
            </MobileNavbar>

          )}
        </Sticky>
      </HeaderWrapper>
      <InboxModal
        modalStatus={inboxModalVisible}
        onCancel={onHandleInboxModalClose}
      />
    </>
  );
}
