import React, { useState } from "react";
import { Modal, Button } from "antd";
import Heading from "../../components/UI/Heading/Heading";
import SuccessModalWrapper from "./success-popup.style";
import { useTranslation } from 'react-i18next'
import cookies from 'js-cookie'

const languages = [
    {
        code: 'en',
        name: 'English',
        country_code: 'gb',
    },
    {
        code: 'ar',
        name: 'العربية',
        dir: 'rtl',
        country_code: 'sa',
    },
];

const SuccessPopup = (props) => {
  const currentLanguageCode = cookies.get('i18next') || 'en';
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };
  return (
    <SuccessModalWrapper>
      <Modal
        visible={props.modalStatus}
        width={340}
        centered
        className="success-popup-modal"
        onCancel={props.onCancel}
      >
        <div className="text-center">
          <img src="/images/tick-circle.png" alt="check-mark" />
          <Heading
            as="h3"
            fontWeight="600"
            className="text-primary font-24"
            content={t('successful')}
          />
          <p className="font-12 text-gray mb-30px">{props.msg}</p>
          <Button className="" type="primary" onClick={props.onCancel}>
              {t('continue')}
          </Button>
        </div>
      </Modal>
    </SuccessModalWrapper>
  );
};

export default SuccessPopup;
