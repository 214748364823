import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Menu, Dropdown } from 'antd';
import { Row, Col } from 'antd';
import {
    HOME_PAGE,
    LISTING_POSTS_PAGE,
    // WHO_WE_ARE,
    // WHAT_WE_DO,
    // HOSPITALITY,
    // MEDIA_CENTER,
    // PRESS_RELEASE,
    // PICTURE_GALLERY,
    // VIDEO_GALLERY,
    // MEDIA_QUERIES,
    // MARKETPLACE_LIST_PAGE,
    // CONTACT_US_PAGE,
    // WHISTLE_BLOWING_PAGE,
    PROPERTY_LIST,
    UPCOMING_PROPERTY_LIST,
    LANDING
} from '../../../setup/constant';
import { DownOutlined } from '@ant-design/icons';
import { MainContext } from 'constants/MainProvider';
import { getCommunities } from 'library/helpers/axiosClient';
import { useTranslation } from 'react-i18next'
import cookies from 'js-cookie'
import i18next from 'i18next'
import Callback from '../Modal/Modal';

const languages = [
    {
        code: 'en',
        name: 'English',
        country_code: 'gb',
    },
    {
        code: 'ar',
        name: 'العربية',
        dir: 'rtl',
        country_code: 'sa',
    },
];

const MainMenu = ({ className }) => {
    const currentLanguageCode = cookies.get('i18next') || 'en';
    const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
    const { t } = useTranslation();
    const [dataList, setDataList] = useState([]);
    useEffect(() => {
        getCommunities().then((data) => {
            setDataList(data);
        });
    }, []);
    let navigate = useNavigate();
    let { loggedIn } = useContext(MainContext);
    const { SubMenu } = Menu;

    useEffect(() => {
        document.body.dir = currentLanguage.dir || 'ltr';
        document.title = t('app_title')
    }, [currentLanguage, t]);
    const [CallbackModalVisible, setCallbackModalVisible] = useState(false);

    const onHandleCallbackModalOpen = () => {
      setCallbackModalVisible(true);
    };
  
    const onHandleCallbackModalClose = () => {
      setCallbackModalVisible(false);
    };
    const aboutMenu = (
        <Menu className="inner-menu-about">
            {/* <Menu.Item key="0">
                <NavLink
                    className=""
                    to={WHO_WE_ARE}
                >
                    {t('who_we_are')}
                </NavLink>
            </Menu.Item>
            <Menu.Item key="1">
                <NavLink
                    className=""
                    to={WHAT_WE_DO}
                >
                    {t('what_we_do')}
                </NavLink>
            </Menu.Item> */}
        </Menu>
    );
    const ContactMenu = (
        <Menu className="inner-menu-about">
            {/* <Menu.Item key="0">
                <NavLink
                    className=""
                    to={WHISTLE_BLOWING_PAGE}
                >
                    {t('whistleblowing')}
                </NavLink>
            </Menu.Item> */}
        </Menu>
    );
    const PropertiesMenu = (
        <Menu className="inner-menu-properties">
            <div className="prop-dropdown">
                <Row gutter={8}>
                    {dataList?.data && dataList?.data?.length > 0
                        ? dataList?.data?.map((item, index) => {
                            var Pname = "";
                            if (item.community) {
                                Pname = item.community.replace(/\s+/g, "-").toLowerCase();
                            }
                            return (<Col className="gutter-row" span={6} key={index}>
                                <div>
                                    <Menu.Item key="0">
                                        <NavLink
                                            className=""
                                            to={`property-details/${Pname}/${item.id}`}
                                        >
                                            {currentLanguageCode && currentLanguageCode === 'ar' ? item?.community_ar : item?.community}
                                            
                                        </NavLink>
                                    </Menu.Item>
                                </div>
                            </Col>
                            )
                        })
                        : null}
                </Row>
            </div>
        </Menu>
    );
    const mediaCenterMenu = (
        <Menu className="inner-menu-about">
            {/* <Menu.Item key="0">
                <NavLink
                    className=""
                    to={PRESS_RELEASE}
                >
                    {t('press_release')}
                </NavLink>
            </Menu.Item>
            <Menu.Item key="1">
                <NavLink
                    className=""
                    to={PICTURE_GALLERY}
                >
                    {t('picture_gallery')}
                </NavLink>
            </Menu.Item> */}
            {/* <Menu.Item key="2">
                <NavLink
                    className=""
                    to={VIDEO_GALLERY}
                >
                    {t('video_gallery')}
                </NavLink>
            </Menu.Item> */}
            {/* <Menu.Item key="3">
                <NavLink
                    className=""
                    to={MEDIA_QUERIES}
                >
                    {t('media_query')}
                </NavLink>
            </Menu.Item> */}
        </Menu>
    );
    // const callBack = (
    //     <>
    //      <Menu className="callback prop-dropdown w-auto">
    //         <Menu.Item key="0" className="">
    //             <NavLink
    //                 className=""
    //                 onClick={() => setCallbackModalVisible(true)}
    //             >
    //                 {t('Sales Related')}
    //             </NavLink>
    //         </Menu.Item>
    //         <Menu.Item key="1">
    //             <NavLink
    //                 className=""
    //                 // to={PICTURE_GALLERY}
    //                 onClick={() => setCallbackModalVisible(true)}
    //             >
    //                 {t('Customer Care Related')}
    //             </NavLink>
    //         </Menu.Item>
    //     </Menu>
    //     <Callback
    //       modalStatus={CallbackModalVisible}

    //       onCancel={onHandleCallbackModalClose}
    //    />
    //     </>
       
    // );
    return (
        <>
        <Menu className={className} mode="inline">
            {/* <Menu.Item key="0" className="about-drop">
                <Dropdown overlay={aboutMenu}>
                    <span
                        className="ant-dropdown-link"
                        onClick={e => e.preventDefault()}
                    >
                        {t('about_us')}
                        <DownOutlined />
                    </span>
                </Dropdown>
            </Menu.Item> */}
            <Menu.Item key="1" className="about-drop area-guide">
                <Dropdown overlay={PropertiesMenu}>
                    <span
                        className="ant-dropdown-link"
                        onClick={() => { navigate(PROPERTY_LIST) }}
                    >
                        
                        {/*{t('properties')}*/}
                        {t('Area Guide')}
                        <DownOutlined />
                    </span>
                </Dropdown>
            </Menu.Item>
            <Menu.Item key="2">
                <NavLink
                    className=""
                    to={UPCOMING_PROPERTY_LIST}
                >
                    {t('Upcoming Properties')}
                </NavLink>
            </Menu.Item>
            <Menu.Item key="2">
                <NavLink
                    className=""
                    to={LANDING}
                >
                    {t('Marketing')}
                </NavLink>
            </Menu.Item>
            {/* <Menu.Item key="3" className="callback">
                <Dropdown overlay={callBack}>
                    <span
                        className="ant-dropdown-link"
                    >
                       
                       {t('Contact')}
                        <DownOutlined />
                    </span>
                </Dropdown>
            </Menu.Item> */}
            {/* <Menu.Item key="2">
                <NavLink
                    className=""
                    to={HOSPITALITY}
                >
                    {t('hospitality')}
                </NavLink>
            </Menu.Item> */}
            {/*<Menu.Item key="3" className="about-drop">
                <Dropdown overlay={mediaCenterMenu}>
                    <span
                        className="ant-dropdown-link"
                        onClick={() => { navigate(MEDIA_CENTER) }}
                    >
                        {t('media_center')}
                        <DownOutlined />
                    </span>
                </Dropdown>
            </Menu.Item>
             {loggedIn && <Menu.Item key="4">
                <NavLink
                    className=""
                    to={MARKETPLACE_LIST_PAGE}
                >
                    {t('market_place')}
                </NavLink>
            </Menu.Item>} */}
            {/* <Menu.Item key="5" className="about-drop">
                <Dropdown overlay={ContactMenu}>
                    <span
                        className="ant-dropdown-link"
                        onClick={() => { navigate(CONTACT_US_PAGE) }}
                    >
                        {t('contact_us')}
                        <DownOutlined />
                    </span>
                </Dropdown>
            </Menu.Item> */}
            {/*<Menu.Item key="6">
                <NavLink
                    className=""
                    to={LISTING_POSTS_PAGE}
                >
                    {t('property_search')}
                </NavLink>
            </Menu.Item>*/}
        </Menu>
     
        </>
    );
};

export default MainMenu;
