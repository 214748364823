import { Row, Col, Card } from 'antd';

export default function PaymentPlan() {
    return (
        <>
        <Row gutter={[16, 16]}>
            <Col span={6} className='gutter-row'>
                <Card className='plan-box'>
                    <span>20%</span>
                    <p>At the time of booking</p>
                </Card>
            </Col>
            <Col span={6} className='gutter-row'>
                <Card className='plan-box'>
                    <span>5%</span>
                    <p>60 days after the reservation date</p>
                </Card>
            </Col>
            <Col span={6} className='gutter-row'>
                <Card className='plan-box'>
                    <span>5%</span>
                    <p>On completion of 20% construction of the project</p>
                </Card>
            </Col>
            <Col span={6} className='gutter-row'>
                <Card className='plan-box'>
                    <span>5%</span>
                    <p>On completion of 30% construction of the project</p>
                </Card>
            </Col>
            <Col span={6} className='gutter-row'>
                <Card className='plan-box'>
                    <span>5%</span>
                    <p>On completion of 40% construction of the project</p>
                </Card>
            </Col>
            <Col span={6} className='gutter-row'>
                <Card className='plan-box'>
                    <span>5%</span>
                    <p>On completion of 50% construction of the project</p>
                </Card>
            </Col>
            <Col span={6} className='gutter-row'>
                <Card className='plan-box'>
                    <span>5%</span>
                    <p>On completion of 60% construction of the project</p>
                </Card>
            </Col>
            <Col span={6} className='gutter-row'>
                <Card className='plan-box'>
                    <span>50%</span>
                    <p>On completion</p>
                </Card>
            </Col>
        </Row>  
        </>
    )
}