import Container from '../../components/UI/Container/Container';
import { Col, Row } from "antd";
import SapLandingWrapper from "./SapLanding.style";


export default function Stages() {
    return (
        <SapLandingWrapper>
            <div className="bg-marketing">
                <Container className="container">
                    <div className='why-card'>
                        <h1 className="fw-bolder txt">Why Choose Realcube Properties ?</h1>
                        <p>
                            At RealCube, our mission is to revolutionize the property and facilities management industry through our cutting-edge, cloud-based technology.
                            We believe in the power of modern solutions to transform the way people manage communities, properties and facilities, making it easier, more efficient and more sustainable. We are committed to our customers and to making a positive impact in the world.
                        </p>
                    </div>
                </Container>
            </div>
        </SapLandingWrapper>
    )
}