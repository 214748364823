import React, { useState } from 'react';
import { Button, Modal, Input } from 'antd';
import { AudioOutlined } from '@ant-design/icons';
import {SendOutlined} from '@ant-design/icons';
import ChatInner from './ChatInner'


const ChatBox = () => {

    const [isModalVisible, setIsModalVisible] = useState(false);
    const onSearch = (value: string) => console.log(value);
    const { Search } = Input;

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
      };
    
      const handleCancel = () => {
        setIsModalVisible(false);
      };

    const bt = (
        <SendOutlined />
    );

    const headding = (
        <div>
            <h5 className="modal-title fw-bold text-white">
                <img alt="chat" src="/images/chat-bot-sm.png" className="me-5"/>
                Hi there!
            </h5>
        </div>
    );

    const footer = (
        <div>
            <Search placeholder="Message" onSearch={onSearch} enterButton={bt} className="model-fot-search" />
        </div>
    );

    return (
        <>
        <div className="message-box">
            {/* <Button type="" onClick={showModal}>
                <img
                    src="/images/Chat.webp"
                    className="logo-foot"
                    width="104"
                    height="45"
                    loading="lazy"
                />
            </Button> */}
            <Modal
                title={headding}
                visible={isModalVisible}
                footer={footer}
                className="message-box-model"
                onOk={handleOk} 
                onCancel={handleCancel}
            >
                <div className="row" id="kt_chat_messenger">
                    <div className="col-12" id='kt_chat_messenger'>
                        <ChatInner />
                    </div>
                </div>
            </Modal>
        </div>
        </>
    );
};

export default ChatBox;
