import React from 'react';
import { List } from 'antd';
import { Link } from 'react-router-dom'
import { InstagramOutlined, TwitterOutlined, YoutubeFilled,FacebookOutlined } from '@ant-design/icons';

export default function SocialMediaLinks() {
  return (
    <div className="footer-links">
      <div className="social_media_links">
        <a href='https://www.facebook.com/realcubecommunity/' target="_blank">
          <span>
            <img loading="lazy"
              width="11"
              height="21"
              alt="Facebook"
              src="/images/facebook.webp"
              className="facebook-icon"
            />
          </span>
        </a>
        <a href='https://www.instagram.com/realcubecommunity/' target="_blank">
          <span>
            {/* <img loading="lazy"
              width="21"
              height="21"
              alt="Instagram Share"
              src="/images/instagram.webp"
              className="instagram"
            /> */}
            <InstagramOutlined />
          </span>
        </a>
        <a href='https://twitter.com/realcubeestate' target="_blank">
          <span>
            <TwitterOutlined />
          </span></a>
        <a href='https://www.youtube.com/@realcubecommunity2885' target="_blank">
          <span>
            <YoutubeFilled />
          </span>
        </a>
      </div>
    </div>
  );
}
