import styled from 'styled-components';

const LandingDetailsWrapper= styled.div`
.ant-breadcrumb {
    padding: 25px 0;
}
.property-card {
    box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 10%);
    border-radius: 8px;
    margin-bottom:1rem;
    .flex-justify-between{
        display:flex;
        justify-content: space-between;
    }
    .title {
        margin-top:2rem
    }
    h2 {
        margin-bottom:0rem
    }
    .price-title {
        margin-bottom:0;
    }
    .price-offer {
        font-size: 16px;
        color :#000;
        line-height: 1.2;
        text-align-right;
        span {
            font-weight: bold;
            font-size:26px;
        }
    }
    .detail {
        text-align:right;
    }
    ul {
        padding-left:25px;
    }
    li {
        list-style-type: disc;
    }
    hr {
        opacity: 0.6;
    }
}
.requestInfo {
    .ant-select-selector,
    .input-cus { 
        height: 40px;
        border: 1px solid #DCDCDC;
        border-radius: 8px;
    }
    .ant-select-selection-item {
        padding-top: 3px;
    }
    .textarea-cus {
        border: 1px solid #DCDCDC;
        border-radius: 8px;
    }
}
.text-end {
    text-align:right;
}
.plan-box {
    // border:2px solid #ddd;
    text-align:center;
    height: 100%;
    background: #4c4a46;
    color: #fff;
    span {
        font-size:26px;
        font-weight:600;
        border-bottom: 2px solid #f6a41c;
    }
    p {
        margin:0;
        color: #fff;
        opacity: 0.8;
        margin-top: 5px;
        font-weight: 300;
        font-size: 13px;
    }
}
.slick-prev {
    left: -20px;
    z-index: 1;
    &:before {
        color: #444;
    }
}
.slick-next {
    right: -20px;
    z-index: 1;
    &:before {
        color: #444;
    }
}
.slick-slide {
    img {
        max-height:400px;
        object-fit: cover;
        box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 10%);
        border-radius: 8px;
    }
}
.properties-card{
    margin: 0 7px;
    box-shadow: 0 0.125rem 0.25rem rgba(0,0,0,.090);
    min-width: 0;
    word-wrap: break-word;
    background-color: #000;
    background-clip: border-box;
    width: 96% !important;
    border-radius:8px;
    margin-bottom: 62px !important;
    font-size: 12px;
}

.properties-images{
    height: 199px;
    width: 356px;
    object-fit: cover;
}
.properties-heading{
  font-size: 17px;
  color: #fff;
  text-align: center;
  padding: 15px 0px 5px;
  margin: 0;
  font-weight: 600;
}
.properties-txt{
  font-size: 12px;
  color:#fff;
  text-align: center;
  padding-bottom: 11px
  
}
.text-white{
    color:#fff;
}
.bg-black{
    background-color:#000; 
}
.px-3{
    padding: 0 16px;
  }
  .pb-3{
    padding-bottom:40px;
  }
  .border-bottom{
    border-bottom: 1px solid #4e4c4c;
  }
  .mt-1{
    margin-top: 10px;
  }
`;
export default LandingDetailsWrapper;
