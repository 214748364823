import { Avatar, Comment } from 'antd';
import React from 'react';


const ChatInner = () => {
    const ExampleComment: React.FC<{ children?: React.ReactNode }> = ({ children }) => (
        <Comment
            actions={[<span key="comment-nested-reply-to">10:04 AM, Jan 23, 2020</span>]}
            // author={<a> </a>}
            avatar={<Avatar src="/images/user-profile.png" alt="chat" />}
            content={
                <p>
                    We supply a series of design principles, practical patterns and high quality design
                    resources (Sketch and Axure).
                </p>
            }
        >
            {children}
        </Comment>
    );

    return (
        <>
            <ExampleComment>
                <ExampleComment>
                    <ExampleComment />
                    <ExampleComment />
                </ExampleComment>
            </ExampleComment>
        </>
    );
};

export default ChatInner;
