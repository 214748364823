import Container from '../../components/UI/Container/Container';
import { Col, Row } from "antd";
import SapLandingWrapper from "./SapLanding.style";


export default function Review() {
    return (
        <SapLandingWrapper>

            <div className="bg-black">
                <Container className="container">
                    <Row className="main pt-3 pb-3">
                        <Col lg={24}>
                            <h1 className="review text-center mb-0"> WHAT OUR CUSTOMERS SAY ABOUT US !</h1>

                        </Col>
                    </Row>
                    <div className='d-flex justify-content-between main-cus'>
                        <div className='max-custom'>
                            <div>
                                <img src="/images/customer-1.png" alt="check-mark" className="customer-images mb-1" />
                            </div>
                            <h3 className='text-white font-600 text-center'>Muhammad</h3>
                            <div className='d-flex justify-content-between mb-1'>
                                <img src="/images/star.png" alt="check-mark" className="star" />
                                <img src="/images/star.png" alt="check-mark" className="star" />
                                <img src="/images/star.png" alt="check-mark" className="star" />
                                <img src="/images/star.png" alt="check-mark" className="star" />
                                <img src="/images/star.png" alt="check-mark" className="star" />
                            </div>
                            <p className='text-white text-center'>I am extremely happy with my villa. It definitely is an investment for my future</p>


                        </div>
                        <div className='max-custom'>
                            <div>
                                <img src="/images/customer-3.jpg" alt="check-mark" className="customer-images mb-1" />
                            </div>
                            <h3 className='text-white font-600 text-center'>Muhammad</h3>
                            <div className='d-flex justify-content-between mb-1'>
                                <img src="/images/star.png" alt="check-mark" className="star" />
                                <img src="/images/star.png" alt="check-mark" className="star" />
                                <img src="/images/star.png" alt="check-mark" className="star" />
                                <img src="/images/star.png" alt="check-mark" className="star" />
                                <img src="/images/star.png" alt="check-mark" className="star" />
                            </div>
                            <p className='text-white text-center'>I am very happy to be a part of REALCUBE family. It was an amazing experience</p>


                        </div>
                        <div className='max-custom'>
                            <div>
                                <img src="/images/customer-2.avif" alt="check-mark" className="customer-images mb-1" />
                            </div>
                            <h3 className='text-white font-600 text-center'>Muhammad</h3>
                            <div className='d-flex justify-content-between mb-1'>
                                <img src="/images/star.png" alt="check-mark" className="star" />
                                <img src="/images/star.png" alt="check-mark" className="star" />
                                <img src="/images/star.png" alt="check-mark" className="star" />
                                <img src="/images/star.png" alt="check-mark" className="star" />
                                <img src="/images/star.png" alt="check-mark" className="star" />
                            </div>
                            <p className='text-white text-center'>The craftsmanship is incredible and Paramount is one of the properties in Downtown Dubai.</p>
                        </div>
                    </div>

                </Container>
            </div>
        </SapLandingWrapper>
    )
}