import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SliderWrapper from "./TimeSlotSlickCarousel.style";
import moment from 'moment';
export default function TimeSlotSlider({ getTime, data, dateSelected }) {
  var newData = [];
  if (data) {
    data?.data.forEach((item) => newData.push(item.visitTime.slice(0, -3)));
  }
  const [selectedItem, setSelectedItem] = useState(0);
  let timeNow = moment().format('HH:mm');
  var sliderDisplay = moment().format('HH:mm') >= '17:00' ? 6 : 5;
  getTime(selectedItem);
  var settings = {
    centerPadding: "60px",
    slidesToShow: sliderDisplay,
    slidesToScroll: 2,
    arrows: true,
    infinite: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: true,
          centerPadding: "40px",
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: true,
          centerPadding: "40px",
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };
  let dateNow = moment().format('YYYY-MM-DD');
  let selectedDate = moment(dateSelected).format('YYYY-MM-DD');
  var now = moment();
  var hourToCheck = (now.day() !== 0) ? 18 : 30;
  var dateToCheck = now.hour(hourToCheck).minute(30);
  useEffect(() => {
    if (selectedDate) { setSelectedItem() }
  }, [selectedDate]);
  let timeData = [
    { id: 1, time: "09:00" },
    { id: 2, time: "09:30" },
    { id: 3, time: "10:00" },
    { id: 4, time: "10:30" },
    { id: 5, time: "11:00" },
    { id: 6, time: "11:30" },
    { id: 8, time: "12:00" },
    { id: 9, time: "12:30" },
    { id: 10, time: "13:00" },
    { id: 12, time: "13:30" },
    { id: 13, time: "14:00" },
    { id: 14, time: "14:30" },
    { id: 15, time: "15:00" },
    { id: 16, time: "15:30" },
    { id: 17, time: "16:00" },
    { id: 18, time: "16:30" },
    { id: 19, time: "17:00" },
    { id: 20, time: "17:30" },
    { id: 21, time: "18:00" },
    { id: 22, time: "18:30" },
    { id: 23, time: "19:00" },
  ];
  return ( 
    <>
    <SliderWrapper>
          <Slider {...settings}>
        {timeData &&
          timeData.map((value, key) => {
            if (value.time >= timeNow && dateNow === selectedDate) {
              return (
                <div className="time-newslot">
                  <div
                    key={key}
                    className={
                      value.time === selectedItem && !newData.includes(value.time)
                        ? "time-slot-item slick-current"
                        : "time-slot-item"
                    }
                    title={newData.includes(value.time) ? "No slots" : timeNow >= value.time && dateNow === selectedDate ? "Select other dates" : null}

                    onClick={
                      newData.includes(value.time) || timeNow >= value.time && dateNow === selectedDate
                        ? () => setSelectedItem()
                        : () => setSelectedItem(value.time)
                    }
                  >
                    <h3
                      style={{
                        cursor: newData.includes(value.time) || timeNow >= value.time && dateNow === selectedDate
                          ? "no-drop"
                          : "pointer",
                        color: timeNow >= value.time && timeNow != newData.includes(value.time) ? "yellow" : "",
                        color: newData.includes(value.time) ? "red" : "",
                      }}
                    >
                      {value.time}
                    </h3>
                  </div>
                </div>
              );
            }
            else if (dateNow !== selectedDate) {
              return (
                <div className="time-newslot">
                  <div
                    key={key}
                    className={
                      value.time === selectedItem && !newData.includes(value.time)
                        ? "time-slot-item slick-current"
                        : "time-slot-item"
                    }
                    title={newData.includes(value.time) ? "No slots" : timeNow >= value.time && dateNow === selectedDate ? "Select other dates" : null}

                    onClick={
                      newData.includes(value.time) || timeNow >= value.time && dateNow === selectedDate
                        ? () => setSelectedItem()
                        : () => setSelectedItem(value.time)
                    }
                  >
                    <h3
                      style={{
                        cursor: newData.includes(value.time) || timeNow >= value.time && dateNow === selectedDate
                          ? "no-drop"
                          : "pointer",
                        color: timeNow >= value.time && timeNow != newData.includes(value.time) ? "yellow" : "",
                        color: newData.includes(value.time) ? "red" : "",
                      }}
                    >
                      {value.time}
                    </h3>
                  </div>
                </div>
              );
            }
          })}

      </Slider>
    </SliderWrapper>
    </>
  );
}
