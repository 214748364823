import React, { useEffect } from 'react';
import Heading from '../../../components/UI/Heading/Heading';
import { List } from 'antd';
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import cookies from 'js-cookie'

const languages = [
    {
        code: 'en',
        name: 'English',
        country_code: 'gb',
    },
    {
        code: 'ar',
        name: 'العربية',
        dir: 'rtl',
        country_code: 'sa',
    },
];


export default function FootMainMenu() {

    const currentLanguageCode = cookies.get('i18next') || 'en';
    const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
    const { t } = useTranslation();

    useEffect(() => {
        // console.log('Setting page stuff')
        document.body.dir = currentLanguage.dir || 'ltr';
        document.title = t('app_title')
    }, [currentLanguage, t]);

    const data = [

        {
            title: t('about_us'),
            link: '/about-us',
        },
        // {
        //     title: t('careers'),
        //     link: '/',
        // },
        {
            title: t('contact_us'),
            link: '/contact-us',
        },
        {
            title: t('terms_&_conditions'),
            link: '/terms-and-conditions',
        },
        {
            title: t('privacy_policy'),
            link: '/privacy-policy',
        },

        // {
        //     title: t('about_us'),
        //     link: '/about-us/who-we-are',
        // },
        // {
        //     title: t('careers'),
        //     link: '/careers',
        // },
        // {
        //     title: t('contact_us'),
        //     link: '/contact-us',
        // },
        // {
        //     title: t('terms_&_conditions'),
        //     link: '/terms-and-conditions',
        // },
        // {
        //     title: t('privacy_policy'),
        //     link: '/privacy-policy',
        // },


        // old template hide start
        // {
        //     title: t('about_us'),
        //     link: '/about-us/who-we-are',
        // },
        // {
        //     title: t('properties'),
        //     link: '/properties-listing',
        // },
        // {
        //     title: t('hospitality'),
        //     link: '/hospitality',
        // },
        // {
        //     title: t('media_center'),
        //     link: '/media-center',
        // },
        // old template hide close


        // {
        //     title: 'Contact Us',
        //     link: '/contact-us',
        // },
        // {
        //     title: 'Whistleblowing',
        //     link: '/whistleblowing',
        // },
    ];

    return (
        <div className="footer-links">
            <Heading
                as="h3"
                className="title"
                content={t('main_menu')}
            />
            <List
                size="small"
                dataSource={data}
                renderItem={(item) => (
                    <List.Item>
                        <Link to={item.link}>{item.title}</Link>
                    </List.Item>
                )}
            />
        </div>
    );
}
