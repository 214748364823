import styled from 'styled-components';

const BannerTextWrapper = styled.div`
  h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #ffffff;
    font-size: 26px;
    font-weight: 700;
    background-color: transparent;
    padding: 20px;
    border-radius: 0;
    width: 100%;
    text-align: center;
    max-width: inherit;
    text-transform: uppercase;
    @media (max-width: 640px) {
      font-size: 33px;
      width: 88%;
    }
    @media only screen and (min-width: 641px) and (max-width: 991px) {
      width: 88%;
    }
  }
  .ant-breadcrumb {
    background-color: rgba(0, 0, 0, 0.39);
    position: absolute;
    width: 100%;
    bottom: 0px;
    padding: 5px 9%;
    .ant-breadcrumb-link {
      color: #fff;
      font-size: 14px;
      a {
        color: #fff;
        font-size: 14px;
      }
    }
    .ant-breadcrumb-separator {
      color: rgba(255, 255, 255, 0.5);
      margin: 0 8px;
    }
  }
  .banner-cover {
    position: relative;  
  }
  .banner-cover::before {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 0;
    background-color: rgba(0,0,0,0.3);   
  }
  @media only screen and (min-width:320px) and (max-width:640px){
    .whistleblowing-title{
        font-size: 25px;
    }
   }
`;

export default BannerTextWrapper;
