import React, { useContext, useEffect, useState } from 'react';
import { Input, Button, Row, Col, Form, Spin, Alert, Select } from 'antd';
import { MainContext } from '../../../constants/MainProvider';
import PasswordStrengthMeter from './PasswordStrengthMeter';
import PhoneInput from 'react-phone-input-2'
import ReCAPTCHA from "react-google-recaptcha";
import 'react-phone-input-2/lib/style.css'

import { useTranslation } from 'react-i18next'
import cookies from 'js-cookie'
import { useDispatch } from 'react-redux';
import * as General from "constants/redux/features/generics/generalStates";

const languages = [
    {
        code: 'en',
        name: 'English',
        country_code: 'gb',
    },
    {
        code: 'ar',
        name: 'العربية',
        dir: 'rtl',
        country_code: 'sa',
    },
];

const { Option } = Select;
let setKey = process.env.REACT_APP_GOOGLE_RECAPTCHA
const SignUpForm = (props) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch()

    const { signUp, registerationInfo: registerationInfoState } = useContext(MainContext);

    const { loading, registerationInfo, error } = registerationInfoState;

    const [password, setPassword] = useState('');
    const [checkPassword, setCheckPassword] = useState('');
    const [phoneValid, setPhoneValid] = useState(true);
    const [firstLoad, setFirstLoad] = useState(true);
    const [phoneerror, setPhoneerror] = useState('');
    const [isVerified, setIsVerified] = useState(false);
    const onFinish = (values) => {
        setFirstLoad(false)
        // if (values?.phone?.length < 7) {
        // setPhoneerror('Please Enter Valid Mobile No.');
        if (values?.phone?.length < 12) {
            setPhoneValid(false)
        } else {
            setPhoneerror();
            dispatch(General.actions.signUpData(values?.email))
            signUp(values);
        }
    };

    const [phone, setPhone] = useState();
    useEffect(() => {
        if (registerationInfo.code == 200) {
            form.resetFields();
            props.onOTPModalOpen();
        }
    }, [registerationInfo]);
    const [customerType, setCustomerType] = useState('1');
    const [contactSuccessModalVisible, setContactSuccessModalVisible] = useState(false);
    const [process, setProcess] = useState(false);
    const onChangeCustomerType = (type) => {
        setCustomerType(type);
    };

    const allProcess = (done) => {
        setProcess(done?.true)
    }

    const checkInput = (e) => {
        const onlyDigits = e.target.value.replace(/\D/g, "");
        if (e.target.value?.length <= 7) {
            setPhoneerror('Please Enter Valid Mobile No.');
        } else {
            setPhoneerror();
        }
    };
    const onChangeCaptcha = (data) => {
        setIsVerified(true)
    }
    const currentLanguageCode = cookies.get('i18next') || 'en';
    const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
    const { t } = useTranslation();
    const [focus, setFocus] = useState({input1: false, input2: false});
    console.log(focus, 'focus');
    return (
        <Form
            form={form}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off">
            <Row className="mb-0" gutter={[18, 0]}>
                <Col className="gutter-row" lg={24} md={24} xs={24}>
                    <Form.Item
                        className="select-signup mb-10"
                        label={t('customer_type')}
                        name="customer_type"
                        rules={[{ required: true, message: t('customer_tye_error') }]}>
                        <Select getPopupContainer={(triggerNode) => { return triggerNode.parentNode; }} value={customerType} onChange={onChangeCustomerType} placeholder={t('please_select')} className="w-100" allowClear>
                            <Option value="1" selected> {t('individual')} </Option>
                            <Option value="2"> {t('Company')} </Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col className="gutter-row" lg={24} md={24} xs={24}>
                    {customerType === '2' ?
                        <Form.Item
                            className={"mb-10" + (focus.input1 ? ' customclass':'')}
                            label={t("Company Name")}
                            name="organization_name"
                            onBlur={() => setFocus((oState)=>({...oState, input1 : false}))}
                            onFocus={() => setFocus((oState)=>({...oState, input1 : true}))}
                            rules={[
                                {
                                    required: true,
                                    message: (t('Please Enter Company Name')),
                                }
                            ]}
                        >
                            <Input maxLength={30}
                                onKeyPress={(event) => {
                                    if (!/[a-zA-Z ]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }} />
                        </Form.Item> :
                        <Row className="mb-0" gutter={[18, 0]}>
                            <Col className="gutter-row" md={12} xs={24}>
                                <Form.Item
                                    className="mb-10"
                                    label={t('first_name')}
                                    name="firstname"
                                    rules={[
                                        {
                                            required: true,
                                            message: t('first_name_error')
                                        },
                                    ]}>
                                    <Input maxLength={30}
                                        onKeyPress={(event) => {
                                            if (!/[a-zA-Z+]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }} />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" md={12} xs={24}>
                                <Form.Item
                                    className="mb-10"
                                    label={t('last_name')}
                                    name="lastname"
                                    rules={[{
                                        required: true,
                                        message: t('last_name_error')
                                    },
                                    ]}>
                                    <Input maxLength={30} onKeyPress={(event) => {
                                        if (!/[a-zA-Z]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }} />
                                </Form.Item>
                            </Col>
                        </Row>
                    }
                </Col>
                <Col className="gutter-row" lg={12} md={24} xs={24}>
                    <Form.Item
                        className="mb-5 signup-form"
                        label={t('mobile_no')}
                        name="phone"
                        rules={[
                            { required: true, message: t('mobile_no_error') },
                        ]}>
                        <PhoneInput
                            name="mob"
                            country={'ae'}
                            preferredCountries={['ae']}
                            // onlyCountries={['ae']}
                            priority={{ ae: 0, in: 1, us: 2 }}
                            onChange={setPhone}
                            onBlur={(e) => checkInput(e)}
                            required={true}
                            autoFocus={true}
                            enableSearch={true}
                            countryCodeEditable={false}
                            enableAreaCodeStretch={true}
                            // defaultErrorMessage={t('mobile_no_error')}
                            // onKeyPress={(event) => {
                            //     if (event.key <=4) {
                            //         console.log(event.key)
                            //         return 'Please enter a valid phone number.';
                            //     }
                            // }}
                            // onKeyDown={(value) => {
                            //     if (value <=4) {
                            //         return 'Please enter a valid phone number.';
                            //     }
                            // }}
                            isValid={(value, country) => {
                                let noError = true
                                if (value.match(/123456/)) {
                                    noError = false
                                    // return 'Invalid value: ' + value + ', ' + country.name;
                                } else if (firstLoad && value.length === 3) { //For - Only UAE is allowed
                                    noError = true
                                    // return true;
                                } else if (country.name === 'United Arab Emirates' && value.length !== 12) {
                                    noError = false
                                    // return false;
                                } else if (value.length < 10) {
                                    noError = false
                                    // return false;
                                } else if (value.match(/00/)) {
                                    noError = false
                                    // return false;
                                }
                                //  else {
                                //     return true;
                                // }
                                setPhoneValid(noError)
                                return noError
                            }}
                        />
                    </Form.Item>
                    {!phoneValid ? (<><p className='text-danger font-11'> {t('mobile_no_error')}</p></>) : ''}
                    {/* {phoneerror?.length > 0 ? (<><p className='text-danger font-11'> {phoneerror}</p></>) : ''} */}
                </Col>
                <Col className="gutter-row" lg={12} md={24} xs={24}>
                    <Form.Item
                        className="mb-10"
                        label={t('email_id')}
                        name="email"
                        rules={[
                            { required: true, type: "email", message: t('email_id_error') },
                            ({ getFieldValue }) => ({
                                validator(_) {
                                    if (getFieldValue('email').split('@')[0].length < 2) {
                                        return Promise.reject('Email must have minimum 2 characters before @ symbol');
                                    }
                                    return Promise.resolve();
                                },
                            }),
                        ]}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col className="gutter-row" lg={12} md={24} xs={24}>
                    <Form.Item
                        className="mb-10"
                        label={t('profile_password')}
                        name="password"
                        rules={[{ required: true, message: t('profile_please_enter_password') }]}>
                        <Input.Password onChange={e => setPassword(e.target.value)} />
                    </Form.Item>
                    <PasswordStrengthMeter password={password} chk='0' allProcess={allProcess} />
                </Col>
                <Col className="gutter-row" lg={12} md={24} xs={24}>
                    <Form.Item
                        className="mb-15"
                        label={t('profile_confirm_password')}
                        name="confirm_password"
                        rules={[
                            {
                                required: true,
                                message: t('profile_please_confirm_enter_password'),
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    // return Promise.reject(new Error(''));
                                },
                            }),
                        ]}
                    >
                        <Input.Password onChange={e => setCheckPassword(e.target.value)} />
                    </Form.Item>
                    <PasswordStrengthMeter password={password} checkPassword={checkPassword} chk='1' allProcess={allProcess} />
                </Col>
                {/* <Col className="gutter-row" lg={24} md={24} xs={24}>
                    <Form.Item
                        className="mb-15"
                        name="ReCAPTCHA"
                        rules={[{ required: true, message: t('Please Verifiy the CAPTCHA'), },]}
                    >
                        <ReCAPTCHA
                            sitekey={setKey}
                            onChange={onChangeCaptcha}
                        />
                    </Form.Item>
                </Col> */}
                <Col className="gutter-row" lg={24} md={24} xs={24}>
                    {!loading &&
                        <Button className="signin-btn" type="primary" htmlType="submit" size="large" disabled={!process} loading={loading}>
                            {t('sign_up')}
                        </Button>
                    }
                    {loading &&

                        <Button className="signin-btn" type="primary" htmlType="submit" size="large" disabled={!process} loading={loading}>
                            {t('sign_up')}
                        </Button>
                    }
                    {error &&
                        <Alert message={error} type="error" />
                    }
                </Col>
            </Row>
            <Row>
                <Col md={24} xs={24} className="text-center gutter-row">
                    <span className="crt-new">
                        {t('already_account')} ? <span className="c-pointer external-links" onClick={props.onLoginModalOpen}> {t('login')} </span>
                    </span>
                </Col>
            </Row>
        </Form>
    );
};

export default SignUpForm;
