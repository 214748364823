import styled from 'styled-components';

const LoaderWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 52px;
    height: 100px;
    display: inline-block;
  }
  &.h-full{
    min-height: 90vh;
  }
  &.h-half{
    min-height: 50vh;
  }
`;

export default LoaderWrapper;
