import React, { Component } from 'react';
import Slider from 'react-slick';
class SimpleSlider extends Component {
  render() {
    var settings = {
      dots: false,
      infinite: false,
      navs: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
    };
    return (
      <Slider {...settings}>
        <div>
          <img src="https://exalogic-store.s3.us-east-2.amazonaws.com/realcube-property/property/4.jpg" className="w-100"/>
        </div>
        <div>
          <img src="https://exalogic-store.s3.us-east-2.amazonaws.com/realcube-property/property/5.jpg" className="w-100"/>
        </div>
        <div>
          <img src="https://exalogic-store.s3.us-east-2.amazonaws.com/realcube-property/property/6.webp" className="w-100"/>
        </div>
        <div>
          <img src="https://exalogic-store.s3.us-east-2.amazonaws.com/realcube-property/community/community-4.png" className="w-100"/>
        </div>
        <div>
          <img src="https://exalogic-store.s3.us-east-2.amazonaws.com/realcube-property/residential/commerical-new.png" className="w-100"/>
        </div>
        <div>
          <img src="https://exalogic-store.s3.us-east-2.amazonaws.com/realcube-property/community/community-1.png" className="w-100"/>
        </div>
      </Slider>
    );
  }
}
export default SimpleSlider;
