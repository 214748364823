import LandingDetailsWrapper from './LandingDetail.style';
import { Form, Button, Row, Col, Input } from 'antd';
const { TextArea } = Input;

export default function RegisterInterstModal() {
    return (
        <LandingDetailsWrapper>
            <Form name="requestForm" autoComplete="off">
                <Row gutter={[16, 16]} className="requestInfo">
                    <Col span={24} className='gutter-row'>
                        <Input placeholder="Name" className='input-cus'/>
                    </Col>
                    <Col span={24} className='gutter-row'>
                        <Input placeholder="Email" className='input-cus'/>
                    </Col>
                    <Col span={24} className='gutter-row'>
                        <Input placeholder="Phone" className='input-cus'/>
                    </Col>
                    <Col span={24} className='gutter-row'>
                        <TextArea rows={4} placeholder="" className='textarea-cus'/>
                    </Col>
                    <Col span={24} className='text-end'>
                        <Button type="primary" htmlType="submit">Submit</Button>
                    </Col>
                </Row>
            </Form>
        </LandingDetailsWrapper>
    )
}