import styled, { keyframes } from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
const MakeanOfferWrapper = styled.div`
    .bg-gray {
        background-color:#F7F7F7;
        background-image: url(https://exalogic-store.s3.us-east-2.amazonaws.com/realcube-property/make-an-offer-bg.png);
    }
    .py-50 {
        padding-top:50px;
        padding-bottom:50px;
    }
    .title {
        font-size: 16px;
        font-weight: 500;
    }
    .d-flex{
        display:flex;
    }
    .ant-radio-checked .ant-radio-inner{
        border-color: #F3B521;
    }
    .ant-radio-inner::after {
        background-color: #F3B521;
    }
    .justify-content-center {
        justify-content:center;
    }
    .align-items-center {
        align-items:center;
    }
    .make-an-over-box {
        padding:30px 0px 50px;
    }
    .fw-bold {
        font-weight:bold;
    }
    .fw-500 {
        font-weight:500;
    }
    .img-fluid {
        max-width: 100%;
        height: auto;
    }
    .mb-30px {
        margin-bottom: 30px;
    }
    .mb-3 {
        margin-bottom: 3px;
    }
    .font-20 {
        font-size:20px;
    }
    .font-34 {
        font-size:34px;
        @media  only screen and (max-width:575.98px) {  
            font-size: 22px;
        }
    }
    .font-13 {
        font-size:13px;
    }
    .font-14 {
        font-size:14px;
    }
    .text-black {
        color:#000;
    }
    .text-gray {
        color:#707070;
    }
    .fw-bold {
        font-weight:bold;
    }
    .back-link {
        display:block;
        font-size:18px;
        color:#fff;
        span.anticon {
            font-size: 18px;
            vertical-align: middle;
            line-height: 24px;
            margin-right:10px;
        }
    }    
    .make-an-offer-property {
        height: 100%;
        min-height: 134px;
        // max-height: 150px;
        object-fit: cover;
        border-radius: 10px;
    }
    .content_wrapper {
        // border-color: transparent;
        // border-top-right-radius: 10px;
        // border-bottom-right-radius: 10px;
        // background-color: #f5f5f5;
        padding: 10px 15px 0;
        // height: 100%;
        // @media  only screen and (max-width:575.98px) {  
        //     border-bottom-left-radius: 10px;
        //     border-bottom-right-radius: 10px;
        //     border-top-right-radius: 0;
        //     padding: 8px 10px 15px;
        // }
        h5,
        h2 {
            font-size:11px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-weight:400;
            color:#393939;
            // height: 60px;
            // background: #f5f5f5;
            // border-radius: 5px;
            // padding: 10px;
        }
        .price {
            font-size:12px;
            color:#000;
            @media  only screen and (max-width:575.98px) {  
                margin-bottom: 5px !important;
            }
            span {
                font-weight:bold;
                font-size:19px;
            }
        }
        .facility-location {
            font-size: 11px;
            color: #393939;
            font-weight: 400;
            padding-top: 8px;
            // border-top: 1px solid #e6e6e6;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            > span {
                padding-right: 8px;
                img{
                    width: 12px;
                    height: 16px;
                    filter:grayscale(1)
                }
            }
        }
        .facility-box {
            padding-bottom: 5px;
            ul {
                margin-top: 15px;
                margin-bottom: 8px; 
                @media  only screen and (max-width:575.98px) {  
                    margin-top: 0;
                    margin-bottom: 0;
                }
                li {
                    display: inline-block;
                    padding-right: 15px;
                    img {
                        width: 16px;
                        height: 16px;
                        filter:grayscale(1)
                    }
                    span {
                        font-size: 12px;
                        color: #000000;
                        font-weight: normal;
                        padding-left: 8px;
                    }
                }   
            }
        }
    }
    .details-box { 
        margin-top:10px;
        margin-bottom:8px;
        .make-an-offer-banner {
            object-fit: cover;
            height: 132px;
            border-radius: 10px 0 0 10px;
        }
        label {
            display:block;
            color:#707070;
            font-size:12px;
            margin-right: 40px;
            .ant-radio{
                top: 4px;
            }
        }        
        #flexCheckDefault{
            width: 18px;
            height: 18px;
            input[type="checkbox"]:checked {
              background-color: #49484c;
            }
        }
        .form-control {
            width:100%;
            border:1px solid #CBD6E2;
            border-radius:5px;
            color:#000000;
            font-size: 13px;
            height:46px;
            padding:10px;
            &:focus {
                outline:none;
                border:1px solid #CBD6E2;
            }
            &::placeholder {
                font-size:12px;
                color:#CBD6E2;
            }
            .anticon{
                font-size:20px;
                color:#CBD6E2;
            }
            @media  only screen and (max-width:575.98px) {  
                height: 43px;
            }
        }
        .ant-picker-input > input {
            font-size: 13px;
        }
        .payment-tabs {
            .ant-tabs-nav {
                .ant-tabs-nav-wrap{
                    @media only screen and (min-width:320px) and (max-width:640px){
                        display: grid;
                        overflow-x: scroll;
                    }
                }
                .ant-tabs-nav-list {
                    width: 100%;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    background-color: #F6F6F6;
                    padding: 20px;
                    border-radius: 6px;
                    .ant-tabs-tab {
                        text-align: center;
                        justify-content: center;
                        flex: 1;
                        border: 1px solid #DCDCDC;
                        border-radius: 5px;
                        font-size:12px;
                        color:#000000;
                        height: 73px;
                        align-items: center;
                        background-color: #ffffff;
                        &.ant-tabs-tab-active {
                            color: #F3B521;
                            font-weight: normal;
                            background-color: #ffffff;
                            border-color: #F3B521;
                            .ant-tabs-tab-btn {
                                color: #F3B521;
                                text-shadow: none;
                                 &:focus {
                                    color: #F3B521;
                                 }
                            }
                            &::after {
                                border: 4px solid #F3B521;
                            }
                        }
                        &:hover {
                            color: #F3B521;
                            font-weight: normal;
                            background-color: #ffffff;
                            border-color: #F3B521;
                            &::after {
                                border: 4px solid #F3B521;
                            }
                        } 
                        @media only screen and (min-width:320px) and (max-width:640px){
                            width: 175px;
                        }
                    }      
                    .ant-tabs-tab + .ant-tabs-tab {
                        @media  only screen and (max-width:575.98px) {  
                            margin: 0 0 0 15px;
                        }
                    }              
                    > .ant-tabs-tab::before {
                        position:absolute;
                        content:"";
                        background-repeat:no-repeat;
                        right: 5px;
                        bottom: 0;
                        display: block;
                        margin: 0 auto 0px;
                        width: 40px;
                        top: 50%;
                        right: 10px;
                        background-size: contain;
                        height: 30px;
                        transform: translateY(-50%);
                        background-position: right center;
                    }
                    > .ant-tabs-tab::after {
                        position:absolute;
                        content:"";
                        background-color:#ffffff;
                        border: 4px solid #D3D3D3;
                        top: auto;
                        left: 10px;
                        border-radius: 50%;
                        width: 21px;
                        height: 21px;
                    }
                    > .ant-tabs-tab:nth-child(1) {
                         &::before {
                            background-image:url('/images/cash-inactive.svg');
                         }
                    }
                    > .ant-tabs-tab:nth-child(2) {
                         &::before {
                            background-image:url('/images/bank-check-inactive.svg');
                         }
                    }
                    > .ant-tabs-tab:nth-child(3) {
                         &::before {
                            background-image:url('/images/payment-inactive.svg');
                         }
                    }
                    > .ant-tabs-tab.ant-tabs-tab-active:nth-child(1),
                    > .ant-tabs-tab:nth-child(1):hover {
                         &::before {
                            background-image:url('/images/cash-active.svg');
                         }
                    }
                    > .ant-tabs-tab.ant-tabs-tab-active:nth-child(2),
                    > .ant-tabs-tab:nth-child(2):hover {
                         &::before {
                            background-image:url('/images/bank-check-active.svg');
                         }
                    }
                    > .ant-tabs-tab.ant-tabs-tab-active:nth-child(3),
                    > .ant-tabs-tab:nth-child(3):hover {
                         &::before {
                            background-image:url('/images/payment-active.svg');
                         }
                    }
                }
                .ant-tabs-nav-operations {
                    @media  only screen and (max-width:575.98px) {  
                        display: none;
                    }
                }
            }  
            .ant-tabs-content-holder {
                .ant-tabs-content {
                    .total-amount-card {
                        display: flex;
                        justify-content: space-between;
                        background-color: #F6F6F6;
                        padding:35px 25px;
                        border-radius: 13px;
                        align-items: center;
                        p {
                            margin-bottom: 0;
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 20px;
                            letter-spacing: 0em;
                            text-align: left;
                            &:last-child {
                                font-size: 22px;
                                font-weight: 600;
                                line-height: 20px;
                                letter-spacing: 0em;
                                text-align: left;
                            }
                        }
                    }
                    .imp-text {
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 20px;
                        letter-spacing: 0em;
                        text-align: left;
                        color: #393939;
                        img {
                            margin-right: 10px;
                        }
                    }
                }
            }
            .accept-terms{
                display: flex;
                .ant-checkbox-checked{
                    .ant-checkbox-inner {
                        background-color: #49484c;
                        border-color: #49484c;
                    }
                }
            }
        }
        .ant-tabs-ink-bar {
            background: transparent;
        }
        .ant-tabs-top > .ant-tabs-nav::before {
            border-bottom: 1px solid transparent;
        }
        .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
            color: #fff;
            background: #49484c;
            border-color: #49484c;
        }
        .ant-radio-button-wrapper-checked {
            span {
                color:#ffffff !important;
            }
        }
        .ant-radio-group.ant-radio-group-solid {
            width: 100%;
            display: flex;
            // border: 1px solid #DDDDDD;
            padding: 0px;
            border-radius: 5px;
            margin-bottom:10px;
            .ant-radio-button-wrapper {
                display: inline-block;
                font-size: 12px;
                margin-bottom: 0px;
                // width:25%;
                padding:0;
                border-radius: 5px;
                border: 1px solid #ffffff;
                &::before {
                    display:none;
                }
                &:first-child {
                    border-left:none;
                }
                span {
                    text-align: center;
                    margin: auto;
                    display: block;
                    font-size: 12px;
                    color: #000;
                }
            }
        }
        .choose-radio-label {
            font-size: 14px;
            color:#261C20;
            font-weight: bold;
            margin:20px 0px 15px;
            @media  only screen and (max-width:575.98px) {  
                margin: 10px 0px 15px;
            }
        }
        .question-card {
            font-size:12px;
            font-weight:bold;
            color:#000000;
            padding:8px 12px;
            box-shadow:0px 3px 6px rgba(0,0,0,0.16);
            background-color:#ffffff;
            border-radius:5px;
            max-width:250px;
            text-align:center;
            margin:15px auto;
            display:block;
            span {
                color:#49484c;
                font-size:12px;
                margin:0px 3px;
            }
        }
        .Makeanoffer-card {
            border-radius: 8px;
            background-color: #ffffff;
            border: 1px solid #AAAAAA;
            padding: 20px;
            margin: 20px 0px 8px;
            @media  only screen and (max-width:575.98px) {  
                margin: 20px 0;
            }
            p {
                color:#261C20;
                font-weight:bold;
                font-size:18px;
                @media  only screen and (max-width:575.98px) {  
                    font-size: 14px;
                }
            }
        }
        .ant-slider {
            &:hover {
                .ant-slider-track {
                    position: absolute;
                    height: 4px;
                    background-color: #F3B521;
                }
                .ant-slider-handle:not(.ant-tooltip-open) {
                    border-color: #F3B521;
                }
            }
        }
        .ant-slider-handle {
            position: absolute;
            width: 18px;
            height: 18px;
            margin-top: 0px;
            background-color: #fff;
            border: solid 1px #F3B521;
            border-radius: 50%;
            top: -3px;
            @media  only screen and (max-width:575.98px) {  
                width: 22px;
                height: 22px;
                margin-top: -10px;
            }
            &:focus {
               border-color:#F3B521;
               box-shadow: 0 0 0 5px rgba(0,0, 0,0.12); 
            }
            &:hover {
                border-color:#F3B521;
            }
        }
        .ant-slider-track {
            position: absolute;
            height: 4px;
            background-color: #F3B521;
        }
        .ant-btn-primary {
            background-color: #49484c;
            border: 1px solid #49484c;
            color: #ffffff;
            font-size: 14px;
            border-radius: 5px;
            padding: 9px 20px;
            cursor:pointer;
            height:46px;
            width: 100%;
            display: block;
            text-align: center;
            &:hover {
                background-color: #ffffff;
                border: 1px solid #49484c;
                color: #49484c;
                transition: all 0.6s ease-in-out;  
            }
            .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
                background: #49484c;
            }
            .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
                border: 1px solid #49484c;
            }
            .ant-picker-panel .ant-picker-footer {
                display:none;
            }
        }
        .make-an-affer{
            background: #FFC50D;
            border-color: #FFC50D;
            color: #000000;
            height: 55px;
            border-radius: 5px;
            white-space: normal;
            font-family: Poppins;
            font-size: 17px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: center;
            @media  only screen and (max-width:575.98px) {  
                font-size: 14px;
                white-space: normal;
                padding: 6px 0;
                height: auto;
            }
        }
        .ant-upload.ant-upload-select {
            width: 100%;
            border: 1px solid #CBD6E2;
            border-radius: 5px;
            color: #000000;
            font-size: 12px;
            height: 46px;
            .ant-btn-default {
                width: 100%;
                border: 0;
                height: 44px;
                padding: 0;
                border-radius: 5px;
                color: #49484c;
            }
        }
    }
    .card-make-offer {
        border:0;
        border-radius:12px;
        .ant-card-body {
            @media  only screen and (max-width:575.98px) {  
                padding: 15px;
            }
        } 
    } 
    .make-an-instal-sec {
        @media  only screen and (max-width:575.98px) {
            overflow-x: auto;
            overflow-y: hidden;  
        }
        .ant-radio-group.ant-radio-group-solid {
            @media  only screen and (max-width:575.98px) {
                width: 600px;
            }
        }
    }       
    .content_wrapper {
        .facility-location {
            padding-top: 5px;
        }
        .facility-box {
            padding-bottom: 0;
            ul {
                margin-top: 8px;
                margin-bottom: 0;
                background-color: #F3F3F3;
                border-radius: 6px;
                padding: 5px;
                display: flex;
                flex-wrap: wrap;
                li {
                    flex: 50%;
                    padding: 5px;
                }
            }
        }
    }
`;
export default MakeanOfferWrapper;
