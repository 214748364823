import React, { useState } from 'react';
import {Link} from 'react-router-dom'
import { Modal, Row, Col, Button } from 'antd';
import PaymentSuccessWrapper from 'pages/PaymentSuccessModal/PaymentSuccessModal.style';
import Heading from 'components/UI/Heading/Heading';

const CallbackSuccessModal = (props) => {

    return (
        <PaymentSuccessWrapper>
            <div>
                <Modal
                    className="success-popup-modal"
                    centered
                    width={340}
                    visible={props.modalStatus}
                    footer={null}
                    onCancel={props.onCancel}>
                    <div className="text-center">
                        <img src="/images/tick-circle.png" alt="check-mark" className="mb-10" width={70}/>
                        <Heading as="h3" fontWeight="600" className="text-primary font-24" content="Successful" />
                       
                        <p className='font-14 success-text mb-0'>Thank you for contacting us, we will be in touch shortly.</p>
                        <Button type="primary" className="w-100 rounded-5 cont-btn" onClick={props.onCancel}>
                            Close
                        </Button>
                    </div>
                </Modal>
            </div>
        </PaymentSuccessWrapper>
    );
};

export default CallbackSuccessModal;
