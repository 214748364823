import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import footerBg from "./footer-bg.svg";

const FooterWrapper = styled.footer`
  @media (max-width: 640px) {
    .ft-bt {
      padding-top: 5px;
      padding-bottom: 5px;
      @media (max-width: 767px) {
        margin-bottom: 5px !important;
      }
    }
    .pin-the img {
      height: 26px;
    }
    .pin-the {
      display: flex;
    }
    .copyright {
      display: block;
      padding-bottom: 9px;
      text-align: center;
      padding-top: 9px;
    }
  }
  width: 100%;
  background-color: #000000;
  // background-color: ${themeGet("color.1", "#ffffff")};
  // background-image: url(${(props) =>
    props.bgSrc ? props.bgSrc : footerBg});
  // background-repeat: no-repeat;
  // background-position: bottom;
  // background-size: 100%;
  // padding: 47px 0 66px;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // justify-content: center;
  //
  // @media (max-width: 480px) {
  //   padding: 60px 0 50px;
  // }
  .foot_icons {
    width: 35px;
    height: 30px;
    background: #000;
    text-align: center;
    vertical-align: middle;
    border-radius: 50px;
    padding: 6px;
    position: relative;
    display: table;
    @media (max-width: 767px) {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
    }
    .place_icon {
      width: 23px;
      @media (max-width: 767px) {
        width: 20px !important;
      }
    }
    span {
      font-size: 18px !important;
      text-align: center;
      color: #fff !important;
      vertical-align: -5px !important;
      @media (max-width: 767px) {
        font-size: 16px !important;
      }
    }
  }
  .contact-bg {
    background-color: #f5f5f5;
    padding-top: 40px;
    padding-bottom: 40px;
    color: #49484c;
    h4 {
      color: #49484c;
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 12px;
    }
    .address-det {
      span {
        color: #49484c;
        font-size: 13px;
        font-weight: 400;
        margin-left: 8px;
        a {
          color: #49484c;
        }
      }
    }
  }
  .copy-right-bg {
    background-color: #f3f3f3;
    color: #000000;
    padding: 15px 0;
    color: #000000;

    span {
      font-size: 14px;
      font-weight: 400;
    }
  }
  .contact-link-det {
    color: #49484c;
    align-items: center;
    .d-flex {
      display: flex;
    }
    .align-items-center {
      align-items: center;
    }
    .img {
      height: 25px;
      width: 25px;
    }
    span {
      color: #000000;
      font-size: 13px;
      font-weight: 400;
      a {
        color: #000000;
        font-size: 13px;
        font-weight: 400;
        @media (max-width: 767px) {
          font-size: 12px !important;
        }
      }
    }
    .store-sec {
      a {
        img {
          margin-right: 7px;
          @media (max-width: 767.98px) {
            margin-bottom: 10px !important;
          }
          @media (min-width: 768px) and (max-width: 1023px) {
            max-width: 89px;
          }
        }
      }
    }
  }
  //footer-searches
  .footer-searches {
    .footer-links {
      @media (max-width: 767px) {
        margin-bottom: 10px !important;
      }
      @media only screen and (min-width: 320px) and (max-width: 640px) {
        margin-top: 20px;
      }
      h3 {
        font-size: 14px;
        font-weight: bold;
        color: #000000;
        margin-bottom: 15px;
        text-transform: uppercase;
        @media (max-width: 767px) {
          margin-bottom: 10px !important;
        }
      }
      .ant-list {
        .ant-list-items {
          .ant-list-item {
            padding: 0;
            border-bottom: none;
            a {
              // color: rgba(0,0,0,0.4);
              color: #757575;
              font-size: 14px;
              font-weight: 400;
              padding: 0 0 14px;

              @media (max-width: 767px) {
                font-size: 13px !important;
                padding: 0 !important;
              }
              @media (max-width: 575px) {
                font-size: 12px !important;
                font-size: 12px !important;
              }

              &:hover {
                color: #49484c;
              }
            }
          }
        }
      }
      .social_media_links {
        a {
          width: 40px;
          height: 36px;
          background: #fff;
          display: block;
          text-align: center;
          vertical-align: middle;
          padding: 4px;
          float: left;
          margin: 0 10px 10px 0;
          border-radius: 5px;
          @media only screen and (min-width: 641px) and (max-width: 991px) {
            width: 27px;
            height: 27px;
          }
          &:hover {
            background: #ccd2dc;
          }
          span {
            font-size: 23px;
            vertical-align: text-top;
            display: flex;
            align-items: center;
            margin: 0 auto;
            height: 100%;
            justify-content: center;
            @media (max-width: 991px) {
              font-size: 19px !important;
            }
            @media (max-width: 767.98px) {
              font-size: 15px !important;
            }
            @media only screen and (min-width: 320px) and (max-width: 640px) {
              font-size: 18px;
            }
            @media (max-width: 767px) {
              display: flex;
              margin: auto !important;
              align-items: center;
              height: 100%;
              svg {
                font-size: 20px !important;
              }
            }
          }
          .facebooc {
            color: #3d6ad6;
            @media (max-width: 767.98px) {
              max-width: 100%;
              height: 15px;
              width: auto;
            }
          }
          .instagram {
            @media (max-width: 767.98px) {
              max-width: 100%;
              height: 15px;
              width: auto;
            }
          }
          .anticon-twitter {
            color: #1da1f3;
            &:hover {
              color: #49484c;
            }
          }
          .anticon-youtube {
            color: #ff0000;
            &:hover {
              color: #49484c;
            }
          }
          @media (max-width: 991px) {
            .social_media_links img {
              height: 15px !important;
            }
          }
          @media (max-width: 767px) {
            .instagram,
            .facebooc {
              margin-top: 0 !important;
              margin: 0 auto;
            }
          }
        }
      }
    }
  }
  .footer-pad30 {
    padding: 30px 0;
  }
  .footer-template2 {
    h3 {
      &.title {
        display: none;
      }
    }
    .ant-list-sm {
      .ant-list-item {
        display: inline-block;
        border-bottom: none;
        border-right: 1px solid #fff;
        padding: 0 20px;
        @media (max-width: 767px) {
          padding: 0 12px;
          margin-bottom: 8px;
          padding-left: 0;
          margin-right: 8px;
          padding-right: 12px;
        }
        @media (min-width: 992px) and (max-width: 1199px) {
          padding: 0 12px;
        }
        a {
          color: #fff;
        }
      }
    }

    .store-sec a {
      margin-left: 16px;
      @media (max-width: 767px) {
        margin-left: 0px;
        margin-right: 16px;
      }
      @media (min-width: 768px) and (max-width: 1199px) {
        margin-right: 7px;
      }
      img {
        border-radius: 4px;
        @media (max-width: 767px) {
          margin-top: 4px;
        }
        @media (min-width: 768px) and (max-width: 1199px) {
          max-width: 77px;
        }
      }
    }
    .social_media_links {
      a {
        width: 34px;
        height: 34px;
        background-color: rgba(255, 255, 255, 0.4);
        border-radius: 50%;
        display: inline-block;
        vertical-align: top;
        text-align: center;
        color: #000;
        font-size: 17px;
        line-height: 34px;
        margin-right: 12px;
        @media (min-width: 768px) and (max-width: 1199px) {
          width: 28px;
          height: 28px;
          line-height: 27px;
        }
        &:hover {
          color: #fff;
        }
        .facebook-icon {
          filter: grayscale(1) brightness(0.1);
        }
        &:hover {
          .facebook-icon {
            filter: grayscale(1) brightness(100);
          }
        }
      }
    }
  }
  @media (max-width: 767px) {
    .footer-template2 {
      .d-flex {
        flex-wrap: wrap;
      }
    }
    .footer-links,
    .store-sec {
      width: 100%;
      margin: 8px 0;
    }
  }
  .btn-outline {
    padding: 10px 20px;
    border: 1px solid #fff;
    color: #fff;
    text-align: center;
    font-size: 14px;
}
.mr-2{
    margin-right:10px;
}
`;

export const MenuWrapper = styled.div`
  margin-top: 51px;
  padding-left: 10px;

  @media (max-width: 480px) {
    margin-top: 29px;
    padding-left: 0;
  }

  ul,
  .ant-menu,
  ul.ant-menu {
    display: flex;
    align-items: center;
    border: 0;
    background-color: transparent;

    @media (max-width: 480px) {
      flex-wrap: wrap;
    }

    li {
      margin: 0 30px;
      padding: 0;
      height: auto;
      margin-bottom: 0 !important;

      @media (max-width: 480px) {
        margin: 0 7px;
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      &.ant-menu-item-selected {
        background-color: transparent;
      }

      color: ${themeGet("text.0", "#2C2C2C")};
      font-size: 15px;
      line-height: 18px;
      font-weight: 700;
      transition: color 0.2s ease-in-out;

      a {
        color: ${themeGet("text.0", "#2C2C2C")};
        transition: color 0.2s ease-in-out;
        &:hover {
          color: ${themeGet("primary.0", "#008489")};
        }
      }
    }
  }
`;

export const CopyrightArea = styled.div`
  color: ${themeGet("text.1", "#909090")};
  font-size: 15px;
  line-height: 18px;
  font-weight: 400;
  margin-top: 29px;

  @media (max-width: 480px) {
    margin-top: 14px;
  }
`;

export const SecondaryFooter = styled.div`
  @media (max-width: 1200px) {
    height: 74px;
  }
`;

export default FooterWrapper;
