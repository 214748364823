import React from 'react';
import { Breadcrumb } from 'antd';
import { BannerBreadCrumbWrapper } from './BannerBreadCrumb.style';
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import cookies from 'js-cookie'
import { HomeOutlined } from '@ant-design/icons';

const languages = [
    {
        code: 'en',
        name: 'English',
        country_code: 'gb',
    },
    {
        code: 'ar',
        name: 'العربية',
        dir: 'rtl',
        country_code: 'sa',
    },
];

const BannerBreadCrumb = ({ data, data1, data2 }) => {

    const currentLanguageCode = cookies.get('i18next') || 'en';
    const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
    const { t } = useTranslation();
    const location = useLocation();

    return (
        <>
            {window.location.pathname === '/hospitality'
                || window.location.pathname === '/contact-us'
                || window.location.pathname === '/market-place' ?
                <BannerBreadCrumbWrapper>
                    <Breadcrumb separator=">">
                        {/*<div className='resp-container' style={{display:'flex'}}>*/}
                            <Breadcrumb.Item>
                                <HomeOutlined /><Link to={'/'}>{t('home_1')}</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Link to={''}>{data}</Link>
                            </Breadcrumb.Item>
                        {/*</div>*/}
                    </Breadcrumb>
                </BannerBreadCrumbWrapper>
                : window.location.pathname === '/media-center/gallery-details'
                    || window.location.pathname === '/media-center/video-gallery-details' ?
                    <BannerBreadCrumbWrapper>
                        <Breadcrumb separator=">">
                            {/*<div className='resp-container' style={{display:'flex'}}>*/}
                                <Breadcrumb.Item>
                                    <HomeOutlined /><Link to={'/'}>{t('home_1')}</Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    <Link to={''}>{data}</Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    <Link to={''}>{data1}</Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    <Link to={''}>{data2}</Link>
                                </Breadcrumb.Item>
                           {/* </div>*/}
                        </Breadcrumb>
                    </BannerBreadCrumbWrapper>
                    : window.location.pathname === '/whistleblowing' ?
                        <BannerBreadCrumbWrapper>
                            <Breadcrumb separator=">">
                                {/*<div className='resp-container' style={{display:'flex'}}>*/}
                                    <Breadcrumb.Item>
                                        <HomeOutlined /><Link to={'/'}>{t('home_1')}</Link>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                        <Link to={'/contact-us'}>{data}</Link>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                        <Link to={''}>{data1}</Link>
                                    </Breadcrumb.Item>
                                {/*</div>*/}
                            </Breadcrumb>
                        </BannerBreadCrumbWrapper>
                        :
                        <BannerBreadCrumbWrapper>
                            <Breadcrumb separator=">">
         { /*<div className='resp-container' style={{display:'flex'}}>*/}
                                    <Breadcrumb.Item>
                                        <HomeOutlined /><Link to={'/'}>{t('home_1')}</Link>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                        <Link to={''}>{data}</Link>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                        <Link to={''}>{data1}</Link>
                                    </Breadcrumb.Item>

          {/*</div>*/}
                            </Breadcrumb>
                        </BannerBreadCrumbWrapper>
            }
        </>
    );
};

export default BannerBreadCrumb;
