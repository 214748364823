import React, { useContext, useState } from 'react';
import { Input, Button, Row, Col, Form, Spin, Alert } from 'antd';
import FormControl from '../../../components/UI/FormControl/FormControl';
import { MainContext } from '../../../constants/MainProvider';
import { FieldWrapper, SwitchWrapper, Label } from '../Auth.style';
import { useTranslation } from 'react-i18next'
import ReCAPTCHA from "react-google-recaptcha";
import cookies from 'js-cookie'
import { useDispatch } from 'react-redux';
import * as General from "constants/redux/features/generics/generalStates";
import ResendEmail from './ResendEmail/ResendEmail';
import FloatInput from '../../../components/FloatInput/FloatInput'
import { Link } from 'react-router-dom'
import Loader from '../../../components/Loader/Loader'
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
const languages = [
    {
        code: 'en',
        name: 'English',
        country_code: 'gb',
    },
    {
        code: 'ar',
        name: 'العربية',
        dir: 'rtl',
        country_code: 'sa',
    },
];
const SignInForm = (props) => {
    const dispatch = useDispatch()

    const { signIn, user, mailResent } = useContext(MainContext);
    const [isVerified, setIsVerified] = useState(false);
    const { loading, userInfo, error } = user;


    const onFinish = (data) => {
        signIn(data);
        dispatch(General.actions.signUpData(data?.username))
    };
    const currentLanguageCode = cookies.get('i18next') || 'en';
    const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
    const { t } = useTranslation();

    const onChangeCaptcha = (data) => {
        setIsVerified(true)
    }
    const [shown, setShown] = React.useState(false)
    return (
        <Form
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off">

            <Form.Item
                name="username"
                rules={[
                    { required: true, message: t('Email / Mobile is required') },
                ]}>
                <FloatInput className="mb-10"
                    label={t('Email / Mobile number')} placeholder="email@example.com OR +971xxxxxxxxx" />
            </Form.Item>
            <div className='login-wrapp'>
                <Form.Item
                    name="password"
                    rules={[{ required: true, message: t('password_error') }]}

                >
                <FloatInput className="mb-10"
                    label={t('password')}
                    placeholder={t("Enter your password")}
                    type={shown ? 'text' : 'password'}
                />

                </Form.Item>
                <button type='button' className='login-eye' onClick={() => setShown((ostate) => !ostate)}>
                    {shown ? (
                        <EyeOutlined />
                    ) : (
                        <EyeInvisibleOutlined />
                    )}
                </button>
            </div>


            <Row>
                <Col xs={24} className="gutter-row  text-right mb-20">
                    <span
                        className="c-pointer external-links "
                        onClick={props.onForgotModalOpen}
                    >
                        {t('forget_yr_password')}
                    </span>
                </Col>
            </Row>

            {/* <Form.Item
                className="mb-10"
                name="ReCAPTCHA"
                rules={[{ required: true, message: t('Please Verifiy the CAPTCHA'), },]}
            >
                <ReCAPTCHA
                    sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA}
                    onChange={onChangeCaptcha}
                />
            </Form.Item> */}
            {!loading &&
                <Button
                    className="signin-btn"
                    type="primary"
                    htmlType="submit"
                    size="large"
                >
                    {t('log_in')}
                </Button>}
            {loading &&
                <Row justify="space-around" gutter={[0, 40]} align="middle">
                    <Col className="gutter-row">
                        <Loader />
                    </Col>
                </Row>
            }

            <div className="mb-10">
                {error &&
                    <>
                        {Array.isArray(error) && error.length === 2 ?
                            <Alert message={error[0]} type="error" />
                            :
                            <Alert message={error} type="error" />
                        }
                    </>
                }
            </div>
            <div className="or-condition">{t('Or')}</div>
            <div className="signup-uaepass">
                <Link to="" className='signin-pass'><img src="/images/uae-pass-icon.png" alt="UAE passs" /> {t('Sign in with UAE pass')}   </Link>
            </div>
            <Row>
                <Col xs={24} className=" text-center gutter-row">
                    <span className="crt-new otp-modal-bt">
                        {t('create_new')} <span className="c-pointer external-links" onClick={props.onSignUpModalOpen}> {t('sign_up')}</span>
                    </span>
                </Col>
                {/* {error && error === 'Email not verified.' && mailResent && */}
                {error && (Array.isArray(error) && error.length === 2 && error[1] === 'not_verified') && mailResent &&
                    <ResendEmail onOTPModalOpen={props.onOTPModalOpen} />
                }
            </Row>
        </Form>
    );
};

export default SignInForm;
