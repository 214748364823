import Container from '../../components/UI/Container/Container';
import { Button, Col, Row } from "antd";
import SapLandingWrapper from "./SapLanding.style";
import Consultant from './AboutConsultant';
import Stages from './Stages';
import Footer from './FooterLanding';
import OurProperties from './OurProperties';
import Brand from './BrandColabation';
import Review from './CustomerReview';


export default function SapLanding() {
    return (
        <SapLandingWrapper>
            {/* <div className="bg-white">
                <Container className="container">
                    <Row className="main">
                        <Col className="gutter-row margin-top" span={12}>
                            <h1 className="mb-0 fw-bolder">Transform your business into an </h1>
                            <h1 className="fw-bolder txt">intelligent enterprise with SAP SAP S/4 HANA Public Cloud</h1>
                            <div>
                                <p className="mb-0">Welcome to the Comprehensive</p>
                                <p className="mb-0">Assessment of Your Business</p>
                                <p className="mb-0">Processes Towards Automation!</p>
                            </div>
                            <Col className="gutter-row my-3" xs={8} sm={8} md={10} lg={12} >
                                <Button
                                    
                                    type="primary"
                                    block
                                    htmlType="submit"
                                    size="large"
                                    className="filter-btn"
                                >
                                    AVAIL YOUR FREE CONSULTATION
                                </Button>
                            </Col>
                        </Col>
                        <Col className="gutter-row mt-2" span={12}>
                            <img src="/images/banner-landing.png" alt="check-mark" className="mb-10" />
                        </Col>
                    </Row>
                </Container>
            </div>
            <Consultant/>
            <Stages/> */}
            <Footer/>
            <Consultant/>
           
            <OurProperties/>
            <Stages/>
         
            <Review/>
               <Brand/>
        </SapLandingWrapper>
    )
}