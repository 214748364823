import styled from 'styled-components';

const ChartBoatWrapper = styled.div` 
    #chatbot-container {
        z-index: 999;
        color: white;
        position: fixed;
        right: 55px;
        bottom: 70px;
        width: 370px;
        height: 75%;
        display: flex;
        align-items: center;
    }
    .font-weight-500 {
        font-weight: 500;
    }
    .font-weight-600 {
        font-weight: 600;
    }
    .bg-transparent {
        background-color: transparent;
    }
    #chatbot-interface {
        width: 100%;
        max-width: 370px;
        background-color: white;
        border-radius: 0.5rem;
        box-shadow: 0 0 9px 0 rgb(0 0 0/15%);
    }
    #chatbot-header {
        font-weight: 600;
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
        background: #F3B521;
        padding: 14px;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.8) 5%, rgb(243, 181, 33) 75%);
        transition: background-color .7s ease-in-out;
        .chatbot-profile {
            background-color: #fff;
            padding: 4px;
            height: 2.5rem;
            width: 2.5rem;
            border-radius: 50%;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin-right: 0.5rem !important;
            .chatbot-chat-img {
                width: 30px;
                height: 30px;
            }
        }
        .chatbot-header-text {
            h5 {
                font-weight: 600;
                font-size: 15px !important;
                color: rgb(255, 255, 255) !important;
                margin-bottom: 0;
            }
            p {
                font-size: 12px;
                font-weight: normal;
                margin-bottom: 0;
                color: #fff;
                line-height: 13px;
            }
        }
    }
    .chatbot-chat_outer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative !important;
        .btn {
            font-size: 12px !important;
            padding: 5px 15px !important;
        }
    }
    #chatbot-chat {
        height: calc(100vh - 369px);
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
        padding: 0.5rem 0.8rem;
        overflow-y: auto;
        overflow-x: hidden;
        display: flex;
        flex-wrap: wrap;
        position: relative !important;
        .btn {
            border-radius: 50px;
        }
    }
    #chatbot-chat::-webkit-scrollbar {
        width: 5px;
        height: 5px;
        border-radius: 30px;
    }
    #chatbot-chat::-webkit-scrollbar-track {
        background-color: #fff;
    }
    #chatbot-chat::-webkit-scrollbar-thumb {
        background-color: #ddd;
    }
    .loader-view {
        &.w-auto {
            width: auto;
        }
        .font-14 {
            font-size: 14px;
        }
        .text-muted {
            color: #858796;
        }
        .pt-1 {
            padding-top: 0.25rem;
        }
        &.mb-2 {
            margin-bottom: 0.5rem;
        }
        &.mx-2 {
            margin-left: 0.5rem;
            margin-right: 0.5rem;
        }
    }
    .lds-ellipsis {
        display: inline-block;
        position: relative;
        width: 80px;
    }
    .lds-ellipsis div {
        position: absolute;
        top: 8px;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: rgb(133 135 150 / 80%);
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }
    .lds-ellipsis div:nth-child(1) {
        left: 8px;
        animation: lds-ellipsis1 0.8s infinite;
    }
    .lds-ellipsis div:nth-child(2) {
        left: -4px;
        animation: lds-ellipsis2 0.8s infinite;
    }
    .lds-ellipsis div:nth-child(3) {
        left: 8px;
        animation: lds-ellipsis2 0.8s infinite;
    }
    .lds-ellipsis div:nth-child(4) {
        left: 30px;
        animation: lds-ellipsis3 0.8s infinite;
    }
    @keyframes lds-ellipsis1 {
        0% {
            transform: scale(0);
        }
        100% {
            transform: scale(1);
        }
    }
    @keyframes lds-ellipsis3 {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(0);
        }
    }
    @keyframes lds-ellipsis2 {
        0% {
            transform: translate(0, 0);
        }
        100% {
            transform: translate(24px, 0);
        }
    }
    .chatbot-message {
        position: relative;
        margin-left: 0.1rem;
        width: 100%;
        margin-top: 20px !important;
        &:before {
            content: " ";
            background: url(/images/user.png);
            background-size: 1rem;
            width: 1rem;
            height: 1rem;
            left: 0px;
            top: -15px;
            border-radius: 50%;
            margin-right: 0;
            position: absolute;
            background-repeat: no-repeat;
        }
        .chat-user-title-rec {
            color: #aca5a5;
            font-size: 11px;
            font-weight: normal;
            display: inline;
            position: absolute;
            top: -14px;
            left: 22px;
            .ml-1 {
                margin-left: 0.25rem!important;
            }
        }
        .input-details {
            .font-14 {
                font-size: 14px;
            }
            .font-12 {
                font-size: 12px;
            }
            .mb-2 {
                margin-bottom: 0.5rem;
            }
            .px-3 {
                padding-left: 1rem;
                padding-right: 1rem;
            }
            .form-control {
                border-radius: 0;
                height: inherit;
                padding: 0.375rem 0.75rem;
                border: 1px solid rgba(110, 113, 113, 0.4);
                color: #000000;
                width: 100%;
                font-size: 12px;
                font-weight: 400;
                line-height: 1.5;
                background-color: #fff;
                &:focus {
                    border-color: rgba(51, 51, 51, .5);
                    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
                    color: #6e707e;
                    background-color: #fff;
                    outline: 0;
                }
            }
        }
    }
    .chatbot-messages {
        padding: 0.9rem;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        margin-top: 0.3rem;
        margin-bottom: 0.3rem;
        width: fit-content;
        &.chatbot-received-messages {
            .btn {
                margin: 5px 0px;
            }
            .multiple-items {
                &.px-3 {
                    padding-left: 1rem;
                    padding-right: 1rem;
                }
                &.py-2 {
                    padding-bottom: 0.5rem;
                    padding-top: 0.5rem;
                }
            }
        }
    }
    .chatbot-received-messages, 
    .chatbot-received-messages pre, 
    .chatbot-received-messages span {
        color: #000;
        width: 100%;
        border: 0;
        background-color: rgba(243, 181, 33, 0.1);
        border-radius: 0px 5px 5px 5px !important;
    }
    .chatbot-messages p {
        margin-bottom: 0 !important;
        font-size: 12px;
        font-weight: normal;
    }
    #chatbot-input-container {
        width: 100%;
    }
    #chatbot-input {
        width: calc(100% - 1rem);
        padding: 0.5rem;
        color: #000;
        font-size: 12px;
        background-color: #fff;
        border: 1px solid #000;
        border-radius: 5px;
        &:focus {
            outline-offset: 0px !important;
            outline: none !important;
        }
    }
    #chatbot-new-message-send-button {
        cursor: pointer;
        background-color: #292A2D;
        padding: 7px 11px;
        display: flex;
        border-radius: 5px;
        justify-content: center;
        align-items: center;
        margin-left: -8px;
        margin-right: 5px;
        height: 37px;
        .anticon {
            color: #fff;
            font-size: 17px;
        }
        &:hover {
            background-color: #F3B521;
            .anticon {
                color: #49484c;
            }
        }
    }
    #chatbot-clear-button {
        background-color: #fff;
        border-radius: 5px;
        padding: 5px 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 37px;
        border: 1px solid #49484c;
        cursor: pointer;
        .anticon {
            color: #49484c;
            font-size: 17px;
        }
        &:hover {
            background-color: #49484c;
            border-color: #49484c;
            .anticon {
                color: #ffffff;
            }
        }
    }
    #chatbot-open-container {
        position: fixed;
        bottom: 1rem;
        right: 2rem;
        background: #000;
        align-items: center;
        justify-content: center;
        display: inline-flex;
        color: white;
        border-radius: 50%;
        width: 3rem;
        height: 3rem;
        text-align: center;
        cursor: pointer;
        z-index: 1000;
        .anticon-wechat {
            font-size:22px;
        }
        .anticon-close {
            font-size:20px;
        }
    }
    #ser_req.btn-primary, 
    #queries.btn-primary, 
    .btn-primary.button-click2, 
    .btn-primary.faq-click, 
    .btn-primary.show-button, 
    .btn-primary.show-button-less {
        color: #000;
        background-color: #fff !important;
        font-weight: 400 !important;
        border-color: #000 !important;
        transition: all 0.2s ease;
    }
    a.btn-primary.button-click2 {
        display: inline-block;
        border-width: 1px;
        border-style: solid;
        text-align: center;
    }
    #ser_req.btn-primary:hover, 
    #queries.btn-primary:hover, 
    .btn-primary.show-button:hover, 
    .btn-primary.show-button-less:hover, 
    .btn-primary.faq-click:hover, 
    .btn-primary.button-click2:hover, 
    .btn-primary.faq-click.active, 
    #ser_req.btn-primary.active, 
    #queries.btn-primary.active, 
    .btn-primary.button-click2.active {
        background-color: #F3B521 !important;
        border-color: #F3B521 !important;
        color: #fff !important;
    }
    .btn-primary{
        color: #000;
        background-color: #fff;
        font-weight: 600;
        border-color: #000;
        transition: all 0.2s ease;
        border-radius: 5px;
        font-size: 12px;
        border-width: 1px;
        padding: 4px 8px;
        cursor: pointer;
        &:hover {
            background-color: #000;
            border-color: #000;
            color: #fff;
        }
    }
    .qurey-view {
        div:first-child {
            position: relative;
            > p {
                color: #aca5a5;
                font-size: 11px;
                font-weight: normal;
                display: inline;
                position: absolute;
                top: 2px;
                right: 22px;
                margin: 0;
                .ml-1 {
                    margin-left: 0.25rem!important;
                }
            }
        }
    }
    .chatbot-sent-messages {
        color: #fff;
        background-color: rgba(243, 181, 33, 1);
        border-radius: 5px 0 5px 5px;
        border: 0;
        margin-left: auto;
        margin-right: 0.1rem;
        position: relative;
        font-weight: normal;
        margin-top: 20px;
        &::before {
            content: " ";
            background: url(/images/user.png);
            display: inline-block;
            background-size: 1rem;
            width: 1rem;
            height: 1rem;
            right: 0px;
            top: -19px;
            border-radius: 50%;
            margin-left: 0;
            position: absolute;
            background-repeat: no-repeat;
        }
    }
    .flex-justify-between {
        display:flex;
        justify-content: space-between;
    }
    #chatbot-footer {
        padding: 10px;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-around;
        align-items: center;
        background-color: #eee;
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
    }
`
export default ChartBoatWrapper;