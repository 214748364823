import LandingDetailsWrapper from './LandingDetail.style'
import Container from '../../../components/UI/Container/Container';
import { Button, Col, Row, Card, Modal, Breadcrumb } from 'antd';
import { FilePdfOutlined } from '@ant-design/icons';
import RequestMoreInformation from './RequestMoreInformation'
import React, { useState } from 'react';
import RegisterInterstModal from './RegisterInterstModal';
import PaymentPlan from './PaymentPlan';
import SimpleSlider from './Silder'
import { HomeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import OurSimilarProperties from './OurSimilarProperties'

export default function LandingDetails() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
      setIsModalOpen(false);
    };
  
    const handleCancel = () => {
      setIsModalOpen(false);
    };

    return (
        <>
        <LandingDetailsWrapper> 
            <Container className=''>
                <Breadcrumb separator=">" className="breadcrumb">
                    <Breadcrumb.Item className="back-breadcrumb-link">
                        <HomeOutlined /><Link to='/Marketing'> Marketing</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to={''}>Marketing Details</Link>
                    </Breadcrumb.Item>
                </Breadcrumb>
            </Container>
            <Container className=''>
                <Card className='property-card'>
                    <div className='flex-justify-between'>
                        <div className=''>
                            <h2>Belgravia Heights 2</h2>
                            <p>JUMEIRAH VILLAGE CIRCLE (JVC),</p>
                        </div>
                        <div>
                            <p className='price-title'>Prices Starting From</p>
                            <p class="price-offer">AED <span>480,828</span></p>
                        </div>
                    </div>
                    <Row className=''>
                        <Col flex="none">Bedrooms</Col>
                        <Col flex="auto" className='detail'>Studio - 1 Bedroom - 2 Bedroom Options</Col>
                    </Row>
                    <hr/>
                    <Row className=''>
                        <Col flex="none">Community</Col>
                        <Col flex="auto" className='detail'>Jumeirah Village Circle (JVC)</Col>
                    </Row>
                    <hr/>
                    <Row className=''>
                        <Col flex="none">Register Your Interest</Col>
                        <Col flex="auto" className='detail'> 
                            <Button type="default" onClick={showModal}>
                                Click Here
                            </Button>  
                        </Col>
                    </Row>
                </Card>
                <div className='mb-10'>
                    <SimpleSlider/>
                </div>
                <Card className='property-card'>
                    <div className='mb-10'>
                    <p>Afeter the successful launch and sale of Belgravia Heights 1, Ellington Properties brings you Belgravia Heights 2. Located in the fast up and coming Jumeirah Village Circle community this project features a range of Studio, 1 and 2 bedroom apartments.</p>
                    <p>Featuring the usual innovative design and high quality we are now accustomed to seeing from Ellington Properties we see Belgravia Height’s as a great investment option. A product that is very unique and one that will experience huge demand from both tenants and future buyers.</p>
                    <p>Show apartment is available so call us today to arrange your visit or simply get more information.</p>
                    <h3 className='title'>PROJECT HIGHLIGHTS</h3>
                    <ul>
                        <li>Developed by Ellington Properties, a developer with an outstanding reputation for quality and service</li>
                        <li>Great design, layouts, finishing and all round quality</li>
                        <li>Choose from a range of Studio, One, Two bedroom apartments</li>
                        <li>50/50% payment plan</li>
                        <li>Completion Date Q2 2020</li>
                    </ul>
                    <h3 className='title'>APARTMENT TYPES AND SIZES</h3>
                    <ul>
                        <li>Studio: Ranges from 405 sq.ft. to 510 sq.ft</li>
                        <li>1 Bedroom: Ranges from 732 sq.ft to 938 sq.ft</li>
                        <li>2 Bedroom: Ranges from 1,027 sq.ft to 1,128 sq.ft</li>
                    </ul>
                    <h3 className='title'>STARTING PRICES</h3>
                    <ul>
                        <li>Studios: AED 480,828</li>
                        <li>1 Bedroom: AED 758,828</li>
                        <li>2 Bedroom: AED 1,070,828</li>
                    </ul>
                    <h3 className='title'>BELGRAVIA HEIGHTS 2 PAYMENT PLAN</h3>
                    <PaymentPlan/>
                    <h3 className='title'>Attachments</h3>
                    <a href="https://off-planproperties.b-cdn.net/wp-content/uploads/2018/06/Belgravia-Heights-Brochure.pdf" target="_blank">
                        <span><FilePdfOutlined /></span> Belgravia Heights 2 Brochure
                    </a>
                    </div>
                    <RequestMoreInformation/>
                </Card>
                <OurSimilarProperties/>
            </Container>  
        </LandingDetailsWrapper>
        <Modal title="Register Your Interest" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer=''>
            <RegisterInterstModal/>
        </Modal>
        
        </>
    )
}