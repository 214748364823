// import Container from '../../../components/UI/Container/Container';
import { Col, Row } from "antd";
import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import Slider from 'react-slick';

export default function OurSimilarProperties() {
    var settings = {
        dots: false,
        infinite: false,
        navs: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: false,
      };
    return (
        <>
            <div className="">
                {/* <Container className="container"> */}
                    <Row className="main pt-3 mb-10">
                        <Col lg={24}>
                            <h2 className="fw-bolder font-46 mb-0"> Our Similar Properties</h2>
                        </Col>
                    </Row>
                    <div>
                        
                    </div>
                    <Slider {...settings}>
                        <div className='properties-card'>
                            <Link to={'/marketing-details'}>
                                <img src="/images/properties-1.webp" alt="check-mark" className="properties-images" />
                            </Link>
                            <div className='px-3'>
                                <h4 className='properties-heading mb-2'>
                                    Ellington House 4
                                </h4>
                                <p className='properties-txt border-bottom'>DUBAI HILLS ESTATE,</p>
                                <div className='border-bottom d-flex justify-content-between mb-1 mt-1'>
                                    <p className='text-white font-600 '>Bedrooms</p>
                                    <p className="text-white">4,5 and 6 Bedroom Options</p>
                                </div>
                                <div className='border-bottom d-flex justify-content-between mb-1 mt-1'>
                                    <p className='text-white font-600 '>Community</p>
                                    <p  className="text-white">Dubai Hills Estate</p>
                                </div>
                                <div className='border-bottom d-flex justify-content-between mb-1 mt-1'>
                                    <p className='text-white font-600 '>Type</p>
                                    <p  className="text-white">Apartment</p>
                                </div>
                            </div>
                            <div>
                                <p className='btn-properties mb-0'>Prices Starting From AED</p>
                            </div>
                        </div>

                        <div className='properties-card'>
                                <Link to={'/marketing-details'}>
                                <img src="/images/properties-2.webp" alt="check-mark" className="properties-images" />
                            </Link>
                            <div className='px-3'>
                                <h4 className='properties-heading mb-2'>
                                    The Sanctuary By Ellington
                                </h4>
                                <p className='properties-txt border-bottom'>MOHAMMED BIN RASHID CITY,</p>
                                <div className='border-bottom d-flex justify-content-between mb-1 mt-1'>
                                    <p className='text-white font-600 '>Bedrooms</p>
                                    <p className="text-white">4,5 and 6 Bedroom Options</p>
                                </div>
                                <div className='border-bottom d-flex justify-content-between mb-1 mt-1'>
                                    <p className='text-white font-600 '>Community</p>
                                    <p className="text-white">Mohammed Bin Rashid City</p>
                                </div>
                                <div className='border-bottom d-flex justify-content-between mb-1 mt-1'>
                                    <p className='text-white font-600 '>Type</p>
                                    <p className="text-white">Villa</p>
                                </div>
                            </div>
                            <div>
                                <p className='btn-properties mb-0'>AED 1,787,828</p>
                            </div>
                        </div>

                        <div className='properties-card'>
                            <Link to={'/marketing-details'}>
                                <img src="/images/properties-3.webp" alt="check-mark" className="properties-images" />
                            </Link>
                            <div className='px-3'>
                                <h4 className='properties-heading mb-2'>
                                    Belgravia Heights 2
                                </h4>
                                <p className='properties-txt border-bottom'>JUMEIRAH VILLAGE CIRCLE (JVC),</p>
                                <div className='border-bottom d-flex justify-content-between mb-1 mt-1'>
                                    <p className='text-white font-600 '>Bedrooms</p>
                                    <p className="text-white">4,5 and 6 Bedroom Options</p>
                                </div>
                                <div className='border-bottom d-flex justify-content-between mb-1 mt-1'>
                                    <p className='text-white font-600 '>Community</p>
                                    <p className="text-white">Jumeirah Village Circle (JVC)</p>
                                </div>
                                <div className='border-bottom d-flex justify-content-between mb-1 mt-1'>
                                    <p className='text-white font-600 '>Type</p>
                                    <p className="text-white">Villa</p>
                                </div>
                            </div>
                            <div>
                                <p className='btn-properties mb-0'>Prices Starting From AED 480,828</p>
                            </div>
                        </div>

                        <div className='properties-card'>
                            <Link to={'/marketing-details'}>
                                <img src="/images/properties-1.webp" alt="check-mark" className="properties-images" />
                            </Link>
                            <div className='px-3'>
                                <h4 className='properties-heading mb-2'>
                                Wilton Terraces Ellington
                                </h4>
                                <p className='properties-txt border-bottom'>DUBAI HILLS ESTATE,</p>
                                <div className='border-bottom d-flex justify-content-between mb-1 mt-1'>
                                    <p className='text-white font-600 '>Bedrooms</p>
                                    <p className="text-white">4,5 and 6 Bedroom Options</p>
                                </div>
                                <div className='border-bottom d-flex justify-content-between mb-1 mt-1'>
                                    <p className='text-white font-600 '>Community</p>
                                    <p className="text-white">Dubai Hills Estate</p>
                                </div>
                                <div className='border-bottom d-flex justify-content-between mb-1 mt-1'>
                                    <p className='text-white font-600 '>Type</p>
                                    <p className="text-white">Apartment</p>
                                </div>
                            </div>
                            <div>
                                <p className='btn-properties mb-0'>Prices Starting From AED</p>
                            </div>
                        </div>
                    </Slider>
                {/* </Container> */}
            </div>
        </>
    )
}