import React, { useContext, useState, useRef, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Menu } from 'antd';
import useOnClickOutside from 'library/hooks/useOnClickOutside';
import { MainContext } from 'constants/MainProvider';
import { Button } from 'antd';
import { Popover } from 'antd';
import { Input } from 'antd';
import {
    AGENT_PROFILE_PAGE,
    AGENT_ACCOUNT_SETTINGS_PAGE, LISTING_POSTS_PAGE,
    AGENT_SETTINGS_PAGE, AGENT_ACCOUNT_SETTINGS_PAGE_DASHBOARD, AGENT_ACCOUNT_SETTINGS_PAGE_DASHBOARD_LOCAL
} from 'setup/constant';
import { SearchOutlined } from '@ant-design/icons';
import InboxModal from '../../InboxModal/InboxModal';
import PaymentModal from '../../PaymentModal/PaymentModal';
import ScheduleModal from '../../ScheduleVisit/ScheduleVisit';
import SignatureModal from '../../ContactView/SignatureCanvas/SignatureCanvas';
import PaymentSuccessModal from '../../PaymentSuccessModal/PaymentSuccessModal';
import ScheduleSuccessModal from '../../../components/SuccessModal/success-popup';
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import cookies from 'js-cookie'
import LogoutModal from '../../Auth/Logout/Logout';

const languages = [
    {
        code: 'en',
        name: 'English',
        country_code: 'gb',
    },
    {
        code: 'ar',
        name: 'العربية',
        dir: 'rtl',
        country_code: 'sa',
    },
];

export default function ProfileMenu({ avatar, props }) {

    const currentLanguageCode = cookies.get('i18next') || 'en';
    const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
    const { t } = useTranslation();

    useEffect(() => {
        document.body.dir = currentLanguage.dir || 'ltr';
        document.title = t('app_title')
    }, [currentLanguage, t]);

    let navigate = useNavigate();
    const { logOut, user, loggedIn: logged ,notificationState} = useContext(MainContext);
    const { userInfo } = user;
    const { loading, notifications, unreadCount, error } = notificationState;

    // const token = Buffer.from(userInfo?.access_token).toString('base64')
    // const idRole = Buffer.from(userInfo?.id_role.toString()).toString('base64')

    let token;
    let idRole;
    if (logged && userInfo?.id_role > 0) {
        token = Buffer?.from(userInfo?.access_token)?.toString('base64')
        idRole = Buffer?.from(userInfo?.id_role?.toString())?.toString('base64')
    }
    const [state, setState] = useState(false);
    const handleDropdown = () => {
        setState(!state);
    };
    const closeDropdown = () => {
        setState(false);
    };
    const dropdownRef = useRef(null);
    useOnClickOutside(dropdownRef, () => setState(false));
    function handleLogout() {
        logOut();
        // navigate('/', { replace: true });
    }

    const [inboxModalVisible, setInboxModalVisible] = useState(false);
    const [paymentModalVisible, setPaymentModalVisible] = useState(false);
    const [scheduleModalVisible, setScheduleModalVisible] = useState(false);
    const [signatureModalVisible, setSignatureModalVisible] = useState(false);
    const [paymentSuccessModalVisible, setPaymentSuccessModalVisible] = useState(false);
    const [scheduleSuccessModalVisible, setScheduleSuccessModalVisible] = useState(false);
    const [logoutModalVisible, setLogoutModalVisible] = useState(false);

    const onHandlePaymentModalOpen = () => {
        setInboxModalVisible(false);
        setPaymentModalVisible(true);
    };

    const onHandleScheduleModalOpen = () => {
        setInboxModalVisible(false);
        setScheduleModalVisible(true);
    };

    const onHandleSignatureModalOpen = () => {
        setInboxModalVisible(false);
        setSignatureModalVisible(true);
    };

    const onHandlePaymentSuccessModalOpen = () => {
        setPaymentModalVisible(false);
        setPaymentSuccessModalVisible(true);
    };

    const onHandlePaymentModalClose = () => {
        setPaymentModalVisible(false);
    };

    const onHandleSignatureModalClose = () => {
        setSignatureModalVisible(false);
    };

    const onHandleScheduleModalClose = () => {
        setScheduleModalVisible(false);
    };

    const onHandleScheduleSuccessModalOpen = () => {
        setScheduleModalVisible(false);
        setScheduleSuccessModalVisible(true);
    };

    const onHandleScheduleSuccessModalClose = () => {
        setScheduleSuccessModalVisible(false);
    };

    const onHandlePaymentSuccessModalClose = () => {
        setPaymentSuccessModalVisible(false);
    };

    const onHandleInboxModalClose = () => {
        setInboxModalVisible(false);
    };

    const onHandleLogoutModalClose = () => {
        setLogoutModalVisible(false);
    };

    const { Search } = Input;
    const [keyword, setKeyword] = useState("");
    const onSearch = (value) => {
        setKeyword(value);
    }
    useEffect(() => {
        const datas = {
            keyword_search: keyword,
        };
        // navigate(`properties-listing/`, { state: { type: 'search', data: datas } });
    }, [keyword]);
    const searchBox = (
        <div>
            {/*<Search placeholder="input search text" onSearch={onSearch} enterButton />*/}
            <Search
                placeholder={t('keyword_search')}
                allowClear
                htmlType="submit"
                enterButton={t('search')}
                className="headder_keyword_search"
                size="large"
                onSearch={onSearch}
            />
        </div>
    );

    return (
        <>
            <div className="d-flex profile-menu-div">
                {/* <Menu className='search-box'>
                    <Popover placement="left" overlayClassName='header-drop' content={searchBox} trigger="click">
                        <Button type="outline-primary" className="header-search-btn">
                            <SearchOutlined />
                        </Button>
                    </Popover>
                </Menu> */}
                 <Menu className='search-box border-0'>
                        <Menu.Item ClassName='header-drop m-0 p-0'>
                            <NavLink to="/global-search" className="search-button2">
                                <SearchOutlined />
                            </NavLink>
                        </Menu.Item>
                </Menu>
                <div className="avatar-dropdown" ref={dropdownRef}>


                    <Menu className="flex-css border-0 w-100 bg-transparent justify-content-end prof-menu">
                        <Menu.Item key="1" className="p-0 mt-9">
                            <Button  className='switch-lng' onClick={() => {
                                i18next.changeLanguage(currentLanguage.dir ? "en" : "ar")
                            }}>
                                <span className={currentLanguage.code == "ar" ? "active" : ""}>عربی</span>
                                <span className={currentLanguage.code == "en" ? "active" : ""}>English</span>
                                {/* {currentLanguage.dir ? 'English' : 'عربی'} */}
                            </Button>
                        </Menu.Item>




                        <Menu.Item key="2" className="mt-8">
                            <div className="notif-menu" 
                            // onClick={() => setInboxModalVisible(true)}
                            >
                                <img
                                    alt="Notifications"
                                    src="/images/bell-icon.webp"
                                    className="position-relative"
                                    loading="lazy"
                                    width="16"
                                    height="20"
                                />
                                 
                                 <a href={process.env.NODE_ENV !== 'production' ? AGENT_ACCOUNT_SETTINGS_PAGE_DASHBOARD_LOCAL + 
                                 `?t=${token}&d=${idRole}&l=${Buffer.from('notifications'.toString()).toString('base64')}` :
                                  AGENT_ACCOUNT_SETTINGS_PAGE_DASHBOARD + `?t=${token}&d=${idRole}&l=${Buffer.from('notifications'.toString()).toString('base64')}`} 
                                  target="_self"> </a>
                                {/* <span className="badge-danger"> */}
                                {unreadCount && Number(unreadCount)>0 ?<span className="badge-danger"></span>:''}

                                {/* </span> */}
                            </div>
                        </Menu.Item>
                        {/* <div className='beta-text after-login'>
                            <h6>{t("Beta version")}</h6>
                            <p>{t("Price or availability is not applicable")}</p>
                        </div> */}
                        <div className="dropdown-handler mr-0" onClick={handleDropdown}>
                        <div className="topbar-polygon">
                            {avatar}
                        </div>
                        </div>
                        <Menu className={`dropdown-menu ${state ? 'active' : 'hide'}`}>
                            <Menu.Item onClick={closeDropdown} key="0">
                                <a href={process.env.NODE_ENV !== 'production' ? AGENT_ACCOUNT_SETTINGS_PAGE_DASHBOARD_LOCAL + `?t=${token}&d=${idRole}` : AGENT_ACCOUNT_SETTINGS_PAGE_DASHBOARD + `?t=${token}&d=${idRole}`} target="_blank">{t('view_profile')}</a>
                            </Menu.Item>
                            {/*<Menu.Item onClick={closeDropdown} key="1"> */}
                            {/*<NavLink to={AGENT_ACCOUNT_SETTINGS_PAGE+`/`+AGENT_SETTINGS_PAGE}>Account Settings</NavLink>*/}
                            {/*</Menu.Item>*/}
                            <Menu.Item key="2">
                                <button
                                    // onClick={handleLogout}
                                    onClick={() => setLogoutModalVisible(true)}
                                >
                                    {t('logout')}
                                </button>
                            </Menu.Item>
                        </Menu> {" "}
                    </Menu>
                </div>
            </div>

            <InboxModal
                modalStatus={inboxModalVisible}
                onCancel={onHandleInboxModalClose}
                onPaymentModalOpen={onHandlePaymentModalOpen}
                onScheduleModalOpen={onHandleScheduleModalOpen}
                onSignatureModalOpen={onHandleSignatureModalOpen}
            />
            <PaymentModal
                modalStatus={paymentModalVisible}
                onCancel={onHandlePaymentModalClose}
                onPaymentSuccessModalOpen={onHandlePaymentSuccessModalOpen}
            />
            <PaymentSuccessModal
                modalStatus={paymentSuccessModalVisible}
                onCancel={onHandlePaymentSuccessModalClose}
            />
            <ScheduleModal
                modalStatus={scheduleModalVisible}
                onCancel={onHandleScheduleModalClose}
                onScheduleSuccessModalOpen={onHandleScheduleSuccessModalOpen}
            />
            <ScheduleSuccessModal
                modalStatus={scheduleSuccessModalVisible}
                onCancel={onHandleScheduleSuccessModalClose}
            />
            <SignatureModal
                modalStatus={signatureModalVisible}
                onCancel={onHandleSignatureModalClose}
            />
            <LogoutModal
                modalStatus={logoutModalVisible}
                onCancel={onHandleLogoutModalClose}
            />
        </>
    );
}
