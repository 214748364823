import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import FooterWrapper, {
  MenuWrapper,
  CopyrightArea,
  SecondaryFooter,
} from './Footer.style';
import Container from '../UI/Container/Container';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom'
import UseFulLinks from './UseFulLinks/UseFulLinks';
import FootMainMenu from './FootMainMenu/FootMainMenu';
import SocialMediaLinks from './SocialMediaLinks/SocialMediaLinks';
import { EnvironmentFilled, PhoneFilled, MailFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next'
import cookies from 'js-cookie'
import moment from 'moment';

const languages = [
  {
    code: 'en',
    name: 'English',
    country_code: 'gb',
  },
  {
    code: 'ar',
    name: 'العربية',
    dir: 'rtl',
    country_code: 'sa',
  },
];

const Footer = ({ logo, menu, bgSrc, copyright, className, path }) => {

  const currentLanguageCode = cookies.get('i18next') || 'en';
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();

  useEffect(() => {
    // console.log('Setting page stuff')
    document.body.dir = currentLanguage.dir || 'ltr';
    document.title = t('app_title')
  }, [currentLanguage, t]);
  const year = moment().format('YYYY');
  return (
    <>
      {/* <FooterWrapper id="footer" className="footer">
        <div className="">
          <Container>
            <Row className="contact-link-det" gutter={[24, 0]}>
              <Col className="gutter-row" xl={10} lg={10} md={11} xs={24}>
                <Row>
                  <Col className="gutter-row mb-25" lg={24} md={24} xs={24}>
                    <Link to={"/"}>
                      <span>
                        <img
                          src="/images/realcube-white-logo.svg"
                          width="168"
                          height="45"
                          loading="lazy"
                          alt="RealCube Logo"
                        />
                      </span>
                    </Link>
                  </Col>
                  <Col className="gutter-row mb-20" lg={24} md={24} xs={24}>
                    <div className="store-sec">
                      <a
                        href="/"
                        target="_blank"
                      >
                        <span>
                          <img
                            loading="lazy"
                            width="112"
                            height="33"
                            alt="Play Store"
                            src="/images/google_play_store.webp"
                            className=""
                          />
                        </span>
                      </a>
                      <a
                        href="/"
                        target="_blank"
                      >
                        <span>
                          <img
                            loading="lazy"
                            alt="App Store"
                            src="/images/app_store.webp"
                            className=""
                            width="112"
                            height="33"
                          />
                        </span>
                      </a>
                       
                    </div>
                  </Col>
                  <Col
                    className="gutter-row ft-bt mb-20"
                    lg={16}
                    md={18}
                    xs={24}
                  >
                    <div className="d-flex align-items-top">
                      <div className="foot_icons">
                        <EnvironmentFilled className="place_icon" />
                      </div>
                      <span className="ml-8">
                        {t("international_capital_trading")},<br />
                        {t("Building 12 4th Floor")}, <br />
                        {t("Al Muntazah")}, {t("Zone 1 - Ministries Complex")},
                        <br />
                        Dubai
                      </span>
                    </div>
                  </Col>
                  <Col
                    className="gutter-row ft-bt mb-20"
                    lg={24}
                    md={24}
                    xs={24}
                  >
                    <div className="d-flex align-items-center">
                      <div className="foot_icons">
                        <PhoneFilled />
                      </div>
                      <span className="ml-8 dir-tel-no">
                        <a href="tel:+97126525555">+9712255589</a>
                      </span>
                    </div>
                  </Col>
                  <Col className="gutter-row ft-bt" lg={24} md={24} xs={24}>
                    <div className="d-flex align-items-center">
                      <div className="foot_icons">
                        <MailFilled />
                      </div>
                      <span className="ml-8">
                        <a href="mailto:realcube@exalogic.co">contactus@realcube.ae</a>
                      </span>
                    </div>
                  </Col>

                </Row>
              </Col>
              <Col className="gutter-row" xl={7} lg={7} md={7} xs={24}>
                <Col className="gutter-row mb-25 opacity-0" lg={24} md={24} xs={24}>
                  <Link to={"/"}>
                    <span>
                      <img
                        src="/images/realcube-logo.svg"
                        width="168"
                        height="45"
                        loading="lazy"
                        alt="RealCube Logo"
                      />
                    </span>
                  </Link>
                </Col>
                <div className="footer-searches">
                  <UseFulLinks />
                </div>
              </Col>
              <Col className="gutter-row" xl={7} lg={7} md={6} xs={24}>
                <Col className="gutter-row mb-25 opacity-0" lg={24} md={24} xs={24}>
                  <Link to={"/"}>
                    <span>
                      <img
                        src="/images/realcube-logo.svg"
                        width="168"
                        height="45"
                        loading="lazy"
                        alt="RealCube Logo"
                      />
                    </span>
                  </Link>
                </Col>
                <div className="footer-searches">
                  <FootMainMenu />
                   <SocialMediaLinks />  
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="copy-right-bg">
          <Container>
            <Row className="" justify="center" gutter={[24, 0]}>
              <Col className="gutter-row text-center" lg={24} md={24} xs={24}>
                <span className="copyright">
                  {t("©_copyright_2022_realcube_all_rights_reserved", { year })}
                </span>
              </Col>
            </Row>
          </Container>
        </div>
      </FooterWrapper> */}
      <FooterWrapper id="footer" className="footer">
        <div className="footer-pad30">
          <Container>
            <Row className="contact-link-det mb-25" gutter={[24, 0]}>
              <Col className="gutter-row" lg={12} md={12} xs={24}>
                <Link to={"/"}>
                  <span>
                    <img
                      src="/images/realcube-white-logo.svg"
                      width="168"
                      height="45"
                      loading="lazy"
                      alt="RealCube Logo"
                    />
                  </span>
                </Link>
              </Col>
              <Col className="gutter-row text-right" lg={12} md={12} xs={24}>
                <Link to={"/broker-login"} className='btn btn-outline mr-2'>
                  Broker Login
                </Link>
                <Link to={"/broker-registration"} className='btn btn-outline'>
                  Broker Registration
                </Link>
              </Col>
            </Row>
            <Row>
              <Col lg={14} md={14} xs={24}>
                <div className='footer-template2'>
                  <FootMainMenu />
                </div>
              </Col>
              <Col lg={10} md={10} xs={24}>
                <div className='footer-template2'>
                  <div className='d-flex justify-content-end align-items-center'>
                    <SocialMediaLinks />
                    <div className="store-sec">
                      <a
                        href="https://play.google.com/store/apps/details?id=com.realcube&pli=1"
                        target="_blank"
                      >
                        <span>
                          <img
                            loading="lazy"
                            width="112"
                            height="33"
                            alt="Play Store"
                            src="/images/google_play_store.webp"
                            className="facebook-icon"
                          />
                        </span>
                      </a>
                      <a
                        href="https://apps.apple.com/ae/app/realcube/id1539880838"
                        target="_blank"
                      >
                        <span>
                          <img
                            loading="lazy"
                            alt="App Store"
                            src="/images/app_store.webp"
                            className=""
                            width="112"
                            height="33"
                          />
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="copy-right-bg">
          <Container>
            <Row className="" justify="center" gutter={[24, 0]}>
              <Col className="gutter-row text-center" lg={24} md={24} xs={24}>
                <span className="copyright">
                  {t("©_copyright_2022_realcube_all_rights_reserved", { year })}
                </span>
              </Col>
            </Row>
          </Container>
        </div>
      </FooterWrapper>
    </>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
  logo: PropTypes.element,
  menu: PropTypes.element,
  bgSrc: PropTypes.string,
  copyright: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default Footer;
