import Container from '../../components/UI/Container/Container';
import { Button, Col, Input, Row } from "antd";
import SapLandingWrapper from "./SapLanding.style";


export default function Consultant() {
    return (
        <SapLandingWrapper>
            <div className="bg-gray">
                <Container className="container">
                    <Row className="main">
                        <Col className="gutter-row margin-top" span={12}>
                            <h1 className="fw-bolder txt">ABOUT REALCUBE</h1>
                            <div>
                                <p className="content-offplan">
                                RealCube is a proprietary solution developed by Exalogic Consulting.
                                With close to two decades of expertise in ERP consulting, IT product development and integration, we have grown to become a globally recognized IT consulting firm with presence in America, the Middle East, Europe, and Asia. Our close-knit team of business consultants, engineers and developers has uniquely empowered us to be at the forefront of the exciting new developments in digital technology that promises to revolutionize IT.
                                 </p>
                                <p className="content-offplan">
                                 Our pedigree includes conceptualizing, implementing, and managing the third wave turnkey solutions for some of the largest property management firms in the world.
                                </p>

                            </div>

                        </Col>
                        <Col className="gutter-row mt-3 mb-3" span={12}>
                            {/* <div className='bg-theme'>
                                <p className='text-white text-center fw-bold'>Free Consultation</p>
                                <Col className='mb-4'>
                                    <label className="mb-2">First Name<span className='text-danger'>*</span></label>
                                    <Input type="text" className="form-control" name="" placeholder="Enter your first name"/>
                                </Col>
                                <Col className='mb-4'>
                                    <label className="mb-2">Last Name<span className='text-danger'>*</span></label>
                                    <Input type="text" className="form-control" name="" placeholder="Enter your last name"/>
                                </Col>
                                <Col className='mb-4'>
                                    <label className="mb-2">Email Address<span className='text-danger'>*</span></label>
                                    <Input type="text" className="form-control" name="" placeholder="Enter your email address"/>
                                </Col>
                                <Col className='mb-4'>
                                    <label className="mb-2">Phone Number<span className='text-danger'>*</span></label>
                                    <Input type="text" className="form-control" name="" placeholder="Enter your Phone Number"/>
                                </Col>
                                <Col className='mb-4'>
                                    <label className="mb-2">Designation<span className='text-danger'>*</span></label>
                                    <Input type="text" className="form-control" name="" placeholder="Indicate your destination"/>
                                </Col>
                                <Col className='mb-4'>
                                    <label className="mb-2">Company<span className='text-danger'>*</span></label>
                                    <Input type="text" className="form-control" name="" placeholder="Enter your Company Number"/>
                                </Col>
                                <Col className='mb-4'>
                                    <label className="mb-2">Apply Code</label>
                                    <Input type="text" className="form-control" name="" placeholder="Enter code"/>
                                </Col>
                                <Col className='mb-4' lg={24}>
                                    <div className="form-check form-check-sm custom me-5">
                                        <input className="form-check-input mr-2" type="checkbox" id="flexCheckDefault" />
                                        <label className="form-check-label txt-check" htmlFor="flexCheckDefault">
                                        By checking this box, I agree that my contact details can be used by Exalogic Consulting to send me news about Exalogic services.
                                        </label>
                                    </div>
                                </Col>
                                <Col className="gutter-row my-3" lg={24} >
                                <Button
                                    
                                    block
                                    htmlType="submit"
                                    size="large"
                                    className="w-100 get-started"
                                >
                                    Get Started
                                </Button>
                            </Col>
                            </div> */}
                            <img src="/images/section-2.webp" alt="check-mark" className="mb-10" />
                        </Col>
                    </Row>
                </Container>
            </div>
        </SapLandingWrapper>
    )
}