import React, { useContext, useState } from 'react';
import { resendOTP } from 'library/helpers/axiosClient';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const ResendEmail = (props) => {
    const { t } = useTranslation();

    const signUpDatas = useSelector(state => state.general.signUpData)

    //Handle OTP Send
    const handleResendEmail = () => {
        var config = { data: { email: signUpDatas } };
        resendOTP(config).then((data) => {
            //Show next screen and wait 60 sec
        })
        props.onOTPModalOpen()
    }

    return (
        <span className="crt-new resent-emaildata">
            <span className="c-pointer external-links" onClick={handleResendEmail}> {t('resend')}</span>
        </span>
    );
};

export default ResendEmail;
