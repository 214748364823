// General Page Section
export const HOME_PAGE = "/";
export const AGENTS_PAGE = "/agents";

// Listing Single Page Section
export const LISTING_POSTS_PAGE = "/properties-listing"; // /listing/(page)?/:page?/(sort)?/:sort?
export const LISTING_POSTS_PAGE2 = "/properties-listing/:name/:id"; // /listing/(page)?/:page?/(sort)?/:sort?
export const LISTING_POSTS_PAGE3 = "/properties-listing/:name/:name1/:id"; // /listing/(page)?/:page?/(sort)?/:sort?
export const LISTING_POSTS_PAGE4 = "/properties-listing/:name";
export const PROPERTY_LIST = "/property-list";
export const UPCOMING_PROPERTY_LIST = "/upcoming-properties";
export const GLOBAL_SEARCH = "/global-search";
export const LANDING = "/Marketing";
export const LANDING_DETAILS = "/marketing-details";
export const SINGLE_POST_PAGE = "/post";
export const HOSPITALITY = "/hospitality";
export const HOSPITALITY_DETAILS = "/hospitality/al-raha-beach-hotel";
export const MEDIA_CENTER = "/media-center";
export const PRESS_RELEASE = "/media-center/press-release";
export const PICTURE_GALLERY = "/media-center/picture-gallery";
export const GALLERY_DETAILES = "/media-center/gallery-details";
export const VIDEO_GALLERY = "/media-center/video-gallery";
export const VIDEO_GALLERY_DETAILES = "/media-center/video-gallery-details";
export const MEDIA_QUERIES = "/media-center/media-queries";
export const NEWS_DETAILS = "/media-center/press-release/:title/:id";
// export const ABOUT_US = "/about-us";
export const WHO_WE_ARE = "/about-us/who-we-are";
export const WHAT_WE_DO = "/about-us/what-we-do";
export const ABOUTUS_NEWTEMPLATE = "/about-us/";
export const MARKETPLACE_LIST_PAGE = "/market-place";
export const MARKETPLACE_DETAILS_PAGE = "/market-place-details/:id";
export const CONTACT_US_PAGE = "/contact-us";
export const AGENT_REGISTRATION_PAGE = "/broker-registration";
export const WHISTLE_BLOWING_PAGE = "/whistleblowing";
export const DETAILS_PAGE = "/properties/:name/:id";
export const PROPERTY_DETAILS_PAGE = "/property-details/:name/:id";
export const MAKE_AN_OFFER = "/make-an-offer";
export const PAYMENT_BY_EMPLOYER = "/payment-by-employer";
export const CONTRACT_VIEW = "/contract-view";

// Agent Profile Page Section
export const AGENT_PROFILE_PAGE = "/profile";
export const AGENT_LOGIN_PAGE = "/broker-login";
export const AGENT_PROFILE_FAVORITE = "favorite-post";
export const AGENT_PROFILE_CONTACT = "contact";
export const AGENT_ACCOUNT_SETTINGS_PAGE = "/account-settings";
export const AGENT_ACCOUNT_SETTINGS_PAGE_DASHBOARD = process.env.REACT_APP_APPDASHBOARD_URL;
export const AGENT_ACCOUNT_SETTINGS_PAGE_DASHBOARD_LOCAL = "http://localhost:3011/";
export const AGENT_ACCOUNT_SETTINGS_PAGE_OFFER = process.env.REACT_APP_APPDASHBOARD_URL + "pt-activity/my-offer";
export const AGENT_PROFILE_EDIT_PAGE = "edit";
export const AGENT_MYDOCUMENTS_PAGE = "my-documents";
export const AGENT_MYALERTS_PAGE = "my-alerts";
export const AGENT_MYACTIVITY_PAGE = "my-activity";
export const AGENT_MYACTIVITY_BROKER_PAGE = "my-activity-broker";
export const AGENT_STATEMENT_PAGE = "statement";
export const AGENT_SETTINGS_PAGE = "settings";
export const AGENT_FAQ_PAGE = "faq";
export const AGENT_PROFILE_DELETE = "/delete";
export const AGENT_PROFILE_FAVOURITE = "/favourite-post";

// Other Pages
export const TERMS_AND_CONDITIONS = "/terms-and-conditions";
export const PRIVACY_PAGE = "/privacy-policy";
export const ADD_HOTEL_PAGE = "/add-hotel";
export const PRICING_PLAN_PAGE = "/pricing-plan";
export const DISCLAIMER_PAGE = "/disclaimer";
export const CAREERS_PAGE = "/careers";
export const SITEMAP_PAGE = "/sitemap-listing";

export const PAYMENT_RETURN_PAGE = "/payment/return/:status";
export const CHAT_PAGE = "/chat";
export const USER_VERIFICATION_PAGE = "//:code";



// Login / Registration Page
export const LOGIN_PAGE = "/sign-in";
export const REGISTRATION_PAGE = "/sign-up";

export const CHANGE_PASSWORD_PAGE = "/change-password";
export const FORGET_PASSWORD_PAGE = "/forget-password";
export const REST_PASSWORD_PAGE = "/reset-password/:token";
export const VERIFICATION_PAGE = "/user-verification/:token";

// Offer Pages
export const EXTEND_OFFER_PAGE = "/extend-offer";

//Resident Details
export const RESIDENT_DETAILS = "/resident-details";

//export const BASE_URL_API = 'http://localhost:8000/api';
export const BASE_URL_API = process.env.REACT_APP_API_URL;
export const LOGIN_URL_API = BASE_URL_API + '/login';
export const SIGNUP_URL_API = BASE_URL_API + '/signup/web';
export const SIGNUP_URL_API_OTP = BASE_URL_API + '/otp/verify/user';
export const PROPERTYLIST_URL_API = BASE_URL_API + '/propertyFilter';
export const USER_PROFILE_URL_API = BASE_URL_API + '/profile';

/* export const VISITED_PROPERTY_URL_API = BASE_URL_API+'/visitedProperties';
export const UPCOMING_VISITS_URL_API = BASE_URL_API+'/upcomingVisit'; */

export const VISITED_PROPERTY_URL_API = BASE_URL_API + '/myVisits/visited';
export const UPCOMING_VISITS_URL_API = BASE_URL_API + '/myVisits/upcoming';

export const MAKE_OFFER_URL_API = BASE_URL_API + '/makeOffer';
export const MAKE_OFFER_GET_ADD_ONS_URL_API = BASE_URL_API + '/getAddOns';
export const FAQ_URL_API = BASE_URL_API + '/faq';
export const ADD_ALERTS_URL_API = BASE_URL_API + '/addAlerts';
export const GET_ALERTS_URL_API = BASE_URL_API + '/getAlerts';
export const UNSUBSCRIBE_ALERTS_URL_API = BASE_URL_API + '/myAlertStatus/remove';
/* export const GET_ALERTS_URL_API = BASE_URL_API+'/getAllAlerts'; */
export const MY_LIKES_URL_API = BASE_URL_API + '/getAllUserLikes';
export const GET_CONTRACT_URL_API = BASE_URL_API + '/getAllContract';
export const NOTIFICATION_URL_API = BASE_URL_API + '/userNotification';
export const MY_OFFERS_URL_API = BASE_URL_API + '/offerList';
export const UPDATE_PROFILE_URL_API = BASE_URL_API + '/updateProfile';
export const UPDATE_PASSWORD_URL_API = BASE_URL_API + '/updatePassword';
export const OFFER_DETAIS_URL_API = BASE_URL_API + '/offerDetail/';
export const PAYMENT_PAY_URL_API = BASE_URL_API + '/payment/pay';
export const CHECK_PAYMENT_STATUS_URL_API = BASE_URL_API + '/payment/checkPaymentStatus';
export const CREATE_CONTRACT_URL_API = BASE_URL_API + '/contract/create';
export const BANK_TRANSFER_URL_API = BASE_URL_API + '/bankTransfer';
export const PAY_BY_CHEQUE_URL_API = BASE_URL_API + '/payByCheque';
export const EXTEND_OFFER_URL_API = BASE_URL_API + '/extendOffer';
export const GET_PROPERTY_FILTER_URL_API = BASE_URL_API + '/property/getPropertyFilterData';
export const ADD_MY_DOC_URL_API = BASE_URL_API + '/addMyDoc';
export const GET_MY_DOC_URL_API = BASE_URL_API + '/getMyDoc';
export const DELETE_MY_DOC_URL_API = BASE_URL_API + '/deleteMyDoc/';

export const SIGN_CONTRACT_URL_API = BASE_URL_API + '/contract/signature/add';
export const STORE_MY_LIKES_URL_API = BASE_URL_API + '/myLikes';   
