import React, { useState } from 'react';
import {Link} from 'react-router-dom'
import { Modal, Row, Col, Button } from 'antd';
import PaymentSuccessWrapper from './PaymentSuccessModal.style';
import Heading from '../../components/UI/Heading/Heading';

const PaymentSuccessModal = (props) => {

    return (
        <PaymentSuccessWrapper>
            <div>
                <Modal
                    className="success-popup-modal"
                    centered
                    width={340}
                    visible={props.modalStatus}
                    footer={null}
                    onCancel={props.onCancel}>
                    <div className="text-center">
                        <img src="/images/tick-circle.png" alt="check-mark" className="mb-10" />
                        <Heading as="h3" fontWeight="600" className="text-primary font-24" content="Successful" />
                        <p className="font-12 success-text mb-30px">Payment Done Successfully!</p>
                        <Button type="primary" className="w-100 rounded-5 cont-btn" onClick={props.onCancel}>
                            Continue
                        </Button>
                    </div>
                </Modal>
            </div>
        </PaymentSuccessWrapper>
    );
};

export default PaymentSuccessModal;
