const notificationsReducer = (state, action) => {
	switch (action.type) {
		case 'NOTIFICATIONS_REQUEST':
			return {
				loading: true
			}
		case 'NOTIFICATIONS_SUCCESS':
			return {
				// loading: false, notifications: action.payload
				loading: false, notifications: action.payload, unreadCount: action.unreadCount
			
			}
		case 'NOTIFICATIONS_FAIL':
			return {
				loading: false, error: action.payload
			}
		default:
			return state;
	}
}

export {
    notificationsReducer
}