import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { Modal, Row, Col, Button } from 'antd';
import {
    StarFilled,
    ArrowRightOutlined,
} from '@ant-design/icons';
import InboxWrapper from './InboxModal.style';
import { MainContext } from '../../constants/MainProvider';
import Loader from 'components/Loader/Loader';
import { useTranslation } from 'react-i18next'
import cookies from 'js-cookie'
import { AGENT_ACCOUNT_SETTINGS_PAGE_DASHBOARD, AGENT_ACCOUNT_SETTINGS_PAGE_DASHBOARD_LOCAL } from './../../setup/constant';
import moment from 'moment';

const languages = [
    {
        code: 'en',
        name: 'English',
        country_code: 'gb',
    },
    {
        code: 'ar',
        name: 'العربية',
        dir: 'rtl',
        country_code: 'sa',
    },
];
const InboxModal = (props) => {

    const currentLanguageCode = cookies.get('i18next') || 'en';
    const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
    const { t } = useTranslation();

    useEffect(() => {
        document.body.dir = currentLanguage.dir || 'ltr';
        document.title = t('app_title')
    }, [currentLanguage, t]);

    const { user, notificationState, getNotifications, loggedIn: logged } = useContext(MainContext);
    const { userInfo } = user;

    const { loading, notifications, error } = notificationState;
    let token;
    let idRole;
    if (logged && userInfo?.id_role > 0) {
        token = Buffer?.from(userInfo?.access_token)?.toString('base64')
        idRole = Buffer?.from(userInfo?.id_role?.toString())?.toString('base64')
    }
    useEffect(() => {
        if (user.userInfo !== null) {
            if (Object.keys(user.userInfo).length !== 0) {
                getNotifications(user.userInfo.access_token);
            }
        }
    }, [user]);
    const visitLink = (link) => {
        window.location.assign(process.env.NODE_ENV !== 'production' ? AGENT_ACCOUNT_SETTINGS_PAGE_DASHBOARD_LOCAL + `?t=${token}&d=${idRole}&l=${Buffer.from(`${link?.buttonURL}`.toString()).toString('base64')}` : AGENT_ACCOUNT_SETTINGS_PAGE_DASHBOARD + `?t=${token}&d=${idRole}&l=${Buffer.from(`${link?.buttonURL}`.toString()).toString('base64')}`)


    }
    return (<div></div>)
    return (
        <InboxWrapper>
            <div>
                <Modal
                    className="inbox-popup"
                    title={t('inbox')}
                    width={710}
                    visible={props.modalStatus}
                    footer={null}
                    onCancel={props.onCancel}
                >
                    <div className="bg-light">
                        <div className="inbox-body">
                            {loading && (
                                <Loader />
                            )}
                            {notifications &&

                                notifications?.map((notification, index) => {

                                    let notificationObj = {}

                                    if (notification.notification_type == "accept_offer") {
                                        notificationObj.title = notification.title;
                                        notificationObj.buttons = [
                                            {
                                                buttonName: 'View Offer',
                                                buttonURL: 'usernotifications/' + 'offer/' + notification.notification_id + '/view',
                                                buttonType: ''
                                            },
                                            {
                                                buttonName: 'Proceed Payment',
                                                buttonURL: 'usernotifications/' + 'offer/' + notification.notification_id + '/view',
                                                buttonType: 'primary'
                                            }
                                        ];
                                    } else if (notification.notification_type == "offer_approved") {
                                        notificationObj.title = notification.title;
                                        notificationObj.buttons = [
                                            {
                                                buttonName: 'View Offer',
                                                buttonURL: 'usernotifications/' + 'offer/' + notification.notification_id + '/view',
                                                buttonType: 'primary'
                                            }
                                        ];

                                    } else if (notification.notification_type == "make_offer") {
                                        notificationObj.title = notification.title;
                                        notificationObj.buttons = [
                                            {
                                                buttonName: 'View Offer',
                                                buttonURL: 'usernotifications/' + 'offer/' + notification.notification_id + '/view',
                                                buttonType: 'primary'
                                            }
                                        ];

                                    } else if (notification.notification_type == "approve_contract") {
                                        notificationObj.title = notification.title;
                                        notificationObj.buttons = [
                                            {
                                                buttonName: 'View Contract',
                                                buttonURL: 'usernotifications/' + 'contract/' + notification.notification_id + '/view',
                                                buttonType: ''
                                            },
                                            {
                                                buttonName: 'Sign Contract',
                                                buttonURL: 'usernotifications/' + 'contract/' + notification.notification_id + '/view',
                                                buttonType: 'primary'
                                            }
                                        ];

                                    } else if (notification.notification_type == "contract_sign") {
                                        notificationObj.title = notification.title;
                                        notificationObj.buttons = [
                                            {
                                                buttonName: 'Sign Contract',
                                                buttonURL: 'usernotifications/' + 'contract/' + notification.notification_id + '/view',
                                                buttonType: 'primary'
                                            }
                                        ];

                                    } else if (notification.notification_type == "extend_offer") {
                                        notificationObj.title = notification.title;
                                        notificationObj.buttons = [
                                            {
                                                buttonName: 'View Offer',
                                                buttonURL: 'usernotifications/' + 'offer/' + notification.notification_id + '/view',
                                                buttonType: ''
                                            },
                                            {
                                                buttonName: 'Proceed Payment',
                                                buttonURL: 'usernotifications/' + 'offer/' + notification.notification_id + '/view',
                                                buttonType: 'primary'
                                            }
                                        ];
                                    } else if (notification.notification_type == "contract_generated") {
                                        notificationObj.title = notification.title;
                                        notificationObj.buttons = [
                                            {
                                                buttonName: 'Download',
                                                buttonURL: 'usernotifications/' + 'contract/' + notification.notification_id + '/view',
                                                buttonType: 'primary'
                                            }
                                        ];
                                    } else if (notification.notification_type == "chat_msg_received") {
                                        notificationObj.title = notification.title;
                                        notificationObj.buttons = [
                                            {
                                                buttonName: 'View Message',
                                                buttonURL: 'dashboard',
                                                buttonType: 'primary'
                                            }
                                        ];
                                    } else if (notification.notification_type == "create_announcements") {
                                        notificationObj.title = notification.title;
                                        notificationObj.buttons = [
                                            {
                                                buttonName: 'View',
                                                buttonURL: 'announcements',
                                                buttonType: 'primary'
                                            }
                                        ];
                                    } else if (notification.notification_type == "visit_scheduled" || notification.notification_type == "visit_schedule") {
                                        notificationObj.title = notification.title;
                                        notificationObj.buttons = [
                                            {
                                                buttonName: 'Cancel',
                                                buttonURL: 'usernotifications/' + 'visit_scheduled/' + notification.notification_id + '/cancel',
                                                buttonType: 'primary'
                                            },
                                            {
                                                buttonName: 'Reschedule',
                                                buttonURL: 'usernotifications/' + 'visit_scheduled/' + notification.notification_id + '/cancel',
                                                buttonType: 'primary'
                                            },
                                        ];
                                    } else {
                                        notificationObj.title = '';
                                        notificationObj.buttons = [{
                                            buttonName: '',
                                            buttonURL: '',
                                            buttonType: ''
                                        },];
                                    }

                                    notificationObj.description = notification.description;
                                    notificationObj.time = moment(notification.created_at).fromNow();

                                    return (
                                        notificationObj.title != '' && <div className="bg-white box mb-15">
                                            <Row className="mb-0" gutter={[10, 10]}>
                                                <Col className="gutter-row text-center" lg={2} md={2}>
                                                    <StarFilled className="filled-yellow" />
                                                </Col>
                                                <Col className="gutter-row" lg={16} md={16}>
                                                    <h5 className="inbox-title mb-0">{notificationObj.title}</h5>
                                                </Col>
                                                <Col className="gutter-row text-right" lg={6} md={6}>
                                                    <span className="inbox-time">{notificationObj.time}</span>
                                                </Col>
                                            </Row>
                                            <Row className="mb-0">
                                                <Col className="gutter-row" offset={2} lg={19} md={19}>
                                                    <p className="inbox-content mb-0">
                                                        {notificationObj.description}
                                                    </p>
                                                </Col>
                                                <Col className="gutter-row text-right" lg={24} md={24}>
                                                    <div className="actions-inbox">
                                                        <Row justify="end" className="row-inbox-btn" gutter={[15, 0]}>
                                                            {notificationObj.buttons.map(button => (
                                                                <Col className="gutter-row" lg={8} md={9}>
                                                                    <Link to={''} onClick={() => visitLink(button)}>
                                                                        <Button
                                                                            className="inbox-btn w-100 mt-10"
                                                                            size="small"
                                                                            onClick={props.onCancel}
                                                                            type={button.buttonType}
                                                                        >
                                                                            {button.buttonName}
                                                                        </Button>
                                                                    </Link>
                                                                </Col>
                                                            ))}
                                                        </Row>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    )
                                })}


                            {/* <div className="bg-white box mb-15">
                                <Row className="mb-0" gutter={[10, 10]}>
                                    <Col className="gutter-row text-center" lg={2} md={2}>
                                        <StarOutlined />
                                    </Col>
                                    <Col className="gutter-row" lg={18} md={18}>
                                        <h5 className="inbox-title mb-0">
                                            You AC Repair is done on 12-Jun-2021 at 11:29 AM
                                        </h5>
                                    </Col>
                                    <Col className="gutter-row" lg={4} md={4}>
                                        <span className="inbox-time">12:35am</span>
                                    </Col>
                                </Row>
                                <Row className="mb-0" gutter={[0, 10]}>
                                    <Col className="gutter-row" offset={2} lg={19} md={19}>
                                        <p className="inbox-content mb-0">
                                            Lorem Ipsum és un text de farciment usat per la indústria
                                            de la tipografia i la impremta. Lorem Ipsum ha estat el
                                            text estàndard de la tres tipogràfiques.
                                        </p>
                                    </Col>
                                    <Col className="gutter-row text-right" lg={24} md={24}>
                                        <div className="actions-inbox">
                                            <Row justify="end" gutter={[15, 0]}>
                                                <Col className="gutter-row" lg={9} md={10}>
                                                    <Link to={``}>
                                                        <Button
                                                            className="inbox-btn outline w-100"
                                                            size="small"
                                                        >
                                                            Rate Us
                                                        </Button>
                                                    </Link>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className="bg-white box mb-15">
                                <Row className="mb-0" gutter={[10, 10]}>
                                    <Col className="gutter-row text-center" lg={2} md={2}>
                                        <StarOutlined />
                                    </Col>
                                    <Col className="gutter-row" lg={18} md={18}>
                                        <h5 className="inbox-title mb-0">
                                            The Contract is Generated
                                        </h5>
                                    </Col>
                                    <Col className="gutter-row" lg={4} md={4}>
                                        <span className="inbox-time">1:55pm</span>
                                    </Col>
                                </Row>
                                <Row className="mb-0" gutter={[0, 10]}>
                                    <Col className="gutter-row" offset={2} lg={19} md={19}>
                                        <p className="inbox-content mb-0">
                                            Lorem Ipsum és un text de farciment usat per la indústria
                                            de la tipografia i la impremta. Lorem Ipsum ha estat el
                                            text estàndard de la tres tipogràfiques.
                                        </p>
                                    </Col>
                                    <Col className="gutter-row text-right" lg={24} md={24}>
                                        <div className="actions-inbox">
                                            <Row justify="end" gutter={[15, 0]}>
                                                <Col className="gutter-row" lg={9} md={10}>
                                                    <Link to={`/contract-view`}>
                                                        <Button
                                                            className="inbox-btn outline w-100"
                                                            size="small"
                                                            onClick={props.onCancel}
                                                        >
                                                            Download
                                                        </Button>
                                                    </Link>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className="bg-white box mb-15">
                                <Row className="mb-0" gutter={[10, 10]}>
                                    <Col className="gutter-row text-center" lg={2} md={2}>
                                        <StarOutlined />
                                    </Col>
                                    <Col className="gutter-row" lg={18} md={18}>
                                        <h5 className="inbox-title mb-0">Sign Contract</h5>
                                    </Col>
                                    <Col className="gutter-row" lg={4} md={4}>
                                        <span className="inbox-time">3:34pm</span>
                                    </Col>
                                </Row>
                                <Row className="mb-0" gutter={[0, 10]}>
                                    <Col className="gutter-row" offset={2} lg={19} md={19}>
                                        <p className="inbox-content mb-0">
                                            Your offer has been Approved by the Owner/Agent
                                        </p>
                                    </Col>
                                    <Col className="gutter-row text-right" lg={24} md={24}>
                                        <div className="actions-inbox">
                                            <Row justify="end" gutter={[15, 0]}>
                                                <Col className="gutter-row" lg={8} md={9}>
                                                    <Link to={``}>
                                                        <Button
                                                            className="inbox-btn outline w-100"
                                                            size="small"
                                                        >
                                                            View Contract
                                                        </Button>
                                                    </Link>
                                                </Col>
                                                <Col className="gutter-row" lg={9} md={10}>
                                                    <Link to={``}>
                                                        <Button
                                                            className="inbox-btn w-100"
                                                            type="primary"
                                                            size="small"
                                                            onClick={props.onSignatureModalOpen}
                                                        >
                                                            Sign Contract
                                                        </Button>
                                                    </Link>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className="bg-white box mb-15">
                                <Row className="mb-0" gutter={[10, 10]}>
                                    <Col className="gutter-row text-center" lg={2} md={2}>
                                        <StarOutlined />
                                    </Col>
                                    <Col className="gutter-row" lg={18} md={18}>
                                        <h5 className="inbox-title mb-0">Extend offer</h5>
                                    </Col>
                                    <Col className="gutter-row" lg={4} md={4}>
                                        <span className="inbox-time">3:34pm</span>
                                    </Col>
                                </Row>
                                <Row className="mb-0" gutter={[0, 10]}>
                                    <Col className="gutter-row" offset={2} lg={19} md={19}>
                                        <p className="inbox-content mb-0">
                                            Your contract has been closed, you can extend or pay now.
                                        </p>
                                    </Col>
                                    <Col className="gutter-row text-right" lg={24} md={24}>
                                        <div className="actions-inbox">
                                            <Row justify="end" gutter={[15, 0]}>
                                                <Col className="gutter-row" lg={8} md={9}>
                                                    <Link to={``}>
                                                        <Button
                                                            className="inbox-btn outline w-100"
                                                            size="small"
                                                        >
                                                            View Offer
                                                        </Button>
                                                    </Link>
                                                </Col>
                                                <Col className="gutter-row" lg={9} md={10}>
                                                    <Button
                                                        className="inbox-btn w-100"
                                                        type="primary"
                                                        size="small"
                                                        onClick={props.onPaymentModalOpen}
                                                    >
                                                        Proceed Payment
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className="bg-white box mb-15">
                                <Row className="mb-0" gutter={[10, 10]}>
                                    <Col className="gutter-row text-center" lg={2} md={2}>
                                        <StarOutlined />
                                    </Col>
                                    <Col className="gutter-row" lg={18} md={18}>
                                        <h5 className="inbox-title mb-0">
                                            Your Visit has been Scheduled
                                        </h5>
                                    </Col>
                                    <Col className="gutter-row" lg={4} md={4}>
                                        <span className="inbox-time">3:34pm</span>
                                    </Col>
                                </Row>
                                <Row className="mb-0" gutter={[0, 10]}>
                                    <Col className="gutter-row" offset={2} lg={19} md={19}>
                                        <p className="inbox-content mb-0">
                                            Your Visit has been Scheduled
                                        </p>
                                    </Col>
                                    <Col className="gutter-row text-right" lg={24} md={24}>
                                        <div className="actions-inbox">
                                            <Row justify="end" gutter={[15, 0]}>
                                                <Col className="gutter-row" lg={8} md={9}>
                                                    <Link to={``}>
                                                        <Button
                                                            className="inbox-btn outline w-100"
                                                            size="small"
                                                        >
                                                            Cancel
                                                        </Button>
                                                    </Link>
                                                </Col>
                                                <Col className="gutter-row" lg={9} md={10}>
                                                    <Button
                                                        className="inbox-btn w-100"
                                                        type="primary"
                                                        size="small"
                                                        onClick={props.onScheduleModalOpen}
                                                    >
                                                        Reschedule
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </div> */}
                        </div>
                    </div>
                    <div className="inbox-footer">
                        <span
                            className="inbox-btn w-100 text-center d-block"
                            onClick={props.onCancel}
                        >
                            <span className="mr-10"></span>
                            <a href={process.env.NODE_ENV !== 'production' ? AGENT_ACCOUNT_SETTINGS_PAGE_DASHBOARD_LOCAL + `?t=${token}&d=${idRole}&l=${Buffer.from('notifications'.toString()).toString('base64')}` : AGENT_ACCOUNT_SETTINGS_PAGE_DASHBOARD + `?t=${token}&d=${idRole}&l=${Buffer.from('notifications'.toString()).toString('base64')}`} target="_self">{t("View all")} <ArrowRightOutlined /></a>

                            {/*t(<ArrowLeftOutlined />)*/}
                        </span>
                    </div>
                </Modal>
            </div>
        </InboxWrapper>
    );
};

export default InboxModal;
