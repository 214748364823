import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next'
import cookies from 'js-cookie'
import { Input, Button, Modal, Row, Col, Checkbox, Form, Alert } from 'antd';
import { useSelector } from 'react-redux';
import OTPInput, { ResendOTP } from "otp-input-react";
import { resendOTP } from './axiosClient';
import { MainContext } from '../../constants/MainProvider';

const languages = [
    {
        code: 'en',
        name: 'English',
        country_code: 'gb',
    },
    {
        code: 'ar',
        name: 'العربية',
        dir: 'rtl',
        country_code: 'sa',
    },
];

const VerifyOTP = (props) => {

    const { signUpWithOTP, registerationInfo: registerationInfoState } = useContext(MainContext);
    const { loading, registerationInfo, error } = registerationInfoState;
    const signUpDatas = useSelector(state => state.general.signUpData)
    const currentLanguageCode = cookies.get('i18next') || 'en';
    const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
    const { t } = useTranslation();
    const [OTP, setOTP] = useState("");
    const handleOnSubmit = () => {
        const data = {
            email: signUpDatas,
            type: "EMAIL",
            otp: OTP
        };
        signUpWithOTP(data);
    }
    const handelResendClick = () => {
        const data = {
            email: signUpDatas
        };
        var config = {
            data: data,
        };
        resendOTP(config).then((data) => {
            if (data?.code === 200 && data?.errorCode != 1) {

            } else {

            }
        });
    }
    useEffect(()=>{
        if(!loading && error)
            setOTP("")
    },[registerationInfoState])

    return (
        <div>
            <Modal
                className="redeem-popup verify-otp-popup"
                width={500}
                title={t("OTP Verification")}
                visible={props.modalStatus}
                footer={null}
                onCancel={()=>{props.onCancel(); setOTP("")}}
                maskClosable={false}
            >
                <Form
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                >
                    <Row className="" gutter={[25, 0]}>
                        <Col className="otp-img" span={24} align="middle">
                            <img src="/images/otp_verification.png" alt="Image" className="mb-5" width={112} height={112}/>
                        </Col>
                        <Col className="" span={24}>
                            <h3 className="text-black text-center">
                                {t("Enter the 4 digit code sent to your email")} <strong> {signUpDatas} </strong>
                            </h3>
                        </Col>
                        <Col className="gutter-row text-center" lg={24} md={24} xs={24}>
                            <div className='digit-box'>
                                {/* <div className='flex-css justify-content-center mb-10'>
                                    <Input maxLength={1} />
                                    <Input maxLength={1} />
                                    <Input maxLength={1} />
                                    <Input maxLength={1} />
                                    <Input maxLength={1} />
                                    <Input maxLength={1} />
                                </div>
                                <p className='text-danger mb-10'>
                                    {t("Enter a Valid OTP")}
                                </p>
                                <p className='c-pointer'>
                                    {t("Resend")}
                                </p> */}
                                <OTPInput
                                    className='gutter-row text-center flex-css justify-content-center mb-20'
                                    value={OTP}
                                    onChange={setOTP}
                                    autoFocus
                                    OTPLength={6}
                                    otpType="number"
                                    disabled={false}
                                    secure={false}
                                />
                                <ResendOTP onResendClick={() => handelResendClick()} className="otp_resend" display={'block'}/>
                            </div>
                        </Col>
                        <Col className="gutter-row" span={24}>
                            <div className="text-center">
                                <Button className="down-btn rounded-5 btn-outline-primary" type="primary" size="large" onClick={handleOnSubmit} loading={loading}>
                                    {t('submit')}
                                </Button>
                            </div>
                            {error &&
                                <>
                                    <p></p>
                                    <Alert message={error} type="error" />
                                </>
                            }
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    );
};

export default VerifyOTP;
