import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const NavbarWrapper = styled.nav`
  display: none;
  width: 100%;
  padding: 0 25px;
  min-height: 82px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0;
  box-shadow: 0 0.1rem 0.6rem rgba(0, 0, 0, 0.15) !important;
  background-color: ${themeGet('color.1', '#ffffff')};
  @media (min-width: 991px) {
    display: flex;
  }

  &.is_transparent {
    background: transparent;
    position: relative;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 999;
    border-bottom: 0;
    background-color: transparent;
    transition: all 0.3s ease-out;
    height: 80px;
    min-height: 70px;
    .ant-menu {
      li {
        &:focus {
          background-color: transparent !important;
        }
        &.ant-menu-item-selected {
          background-color: transparent;
          border-radius: 0px;
        }
        a {
          color: ${themeGet('color.1', '#ffffff')};
          &.active {
            border-bottom: 0;
          }
        }
      }
      &.dropdown-menu {
        li {
          a {
            color: ${themeGet('text.0', '#2C2C2C')};
          }
        }
      }
    }
  }
  .main_menu{
//       margin-right:24px;
  }
`;

export const Container = styled.div`
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
  @media (min-width: 991px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const LogoArea = styled.div`
  display: flex;
  align-items: center;
  max-width: 650px;
  width: 100%;
  > a {
    // margin-right: 27px;
  }
`;

export const MenuArea = styled.div`
  display: flex;
  align-items: center;
`;

export const AvatarWrapper = styled.div`
  margin-right: auto;
  margin-left: 0;

  .avatar-dropdown {
    position: relative;
    display: flex;
    align-items: center;

    .dropdown-handler {
      margin-left: 10px;
      margin-right: 10px;
        // overflow: hidden;
        border-radius: 50%;
        cursor: pointer;
        width: 50px;
        height: 50px;
      img {
      max-width: 42px;
      max-height: 42px;
    border-radius: 50%;
        width: 35px;
        height: 35px;
        object-fit: cover;
      }
    }

    .dropdown-menu {
      min-width: 180px;
      border-right: 0;
      position: absolute;
      right: 90px;
      top: 52px;
      border-radius: 5px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
      transition: all 0.3s ease;
      padding: 10px 10px;
      &.hide {
        opacity: 0;
        visibility: hidden;
      }
      &.active {
        opacity: 1;
        visibility: visible;
      }
      li {
        color: #252525;
        font-size: 15px;
        line-height: 0;
        font-weight: 400;
        height: 30px;
        padding: 0;
        margin: 0;
        transition: color 0.2s ease-in-out;
        &.ant-menu-item-selected,
        &.ant-menu-item-active {
          background-color: transparent;
          border-radius: 0px;
        }
        a {
          display: block;
          padding: 16px 16px !important;
          color: #252525;
          transition: color 0.2s ease-in-out;
          &:hover {
            color: #49484c;
          }
          &.active {
            font-weight: 400;
            color: #252525;
            background-color: #EBEEF3;
            border-radius: 5px !important;
          }
        }
        button {
          padding: 0;
          border: 0;
          cursor: pointer;
          padding: 16px 16px !important;
          width: 100%;
          text-align: left;
          background-color: transparent;
          transition: color 0.2s ease-in-out;
          &:hover {
            color: #49484c;
          }
          &:focus {
            outline: none;
          }
        }
      }
    }
  }
`;

export const MenuWrapper = styled.div`
  .ant-menu {
    li.ant-menu-submenu.ant-menu-submenu-horizontal.dropdown-menu.ant-menu-submenu-open.ant-menu-submenu-active {
      color: #252525;
      background-color: #EBEEF3 !important;
      border: 0 !important;
      border-radius: 5px 5px 0 0 !important;
      padding: 0;
      height: 36px;
      line-height: 35px; 
      min-width: 156.9px;
      .ant-menu-submenu-title {
        color: #252525;
        padding-left: 10px;
      }
    }
    li:hover {
        border-bottom: 0 !important;
    }
    .ant-menu-horizontal > .ant-menu-item:hover,
    .ant-menu-horizontal > .ant-menu-submenu:hover,
    .ant-menu-horizontal > .dropdown-menu,
    .ant-menu-horizontal > .dropdown-menu,
    .ant-menu-horizontal > .dropdown-menu,
    .ant-menu-horizontal > .dropdown-menu,
    .ant-menu-horizontal > .ant-menu-item-selected,
    .ant-menu-horizontal > .ant-menu-submenu-selected,
    .ant-menu-horizontal > .ant-menu-submenu-open,
    .ant-menu-horizontal > .ant-menu-submenu-active {
      color: #252525;
      background-color: #EBEEF3 !important;
      border: 0 !important;
      border-radius: 5px 5px 0 0 !important;  
      .ant-menu-submenu-title {
        color: #252525;
      }
    }
    li.dropdown-menu {
      line-height: 32px;
    }

    .dropdown-menu {
      .ant-menu-submenu-title {
        color: ${themeGet('text.0', '#2C2C2C')};
        &:hover {
          color: #fff;
        }
        &:active {
          background-color: #49484c;
          color: #fff;
        }
      }
    }
  }
  
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after, 
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after, 
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after, 
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after, 
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after, 
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after, 
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after, 
    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
        border-bottom: 0 !important;
    }
    
    .ant-menu-horizontal > .ant-menu-item::after, 
    .ant-menu-horizontal > .ant-menu-submenu::after {
        position: inherit;
        border-bottom: inherit;
        transition: inherit;
        content: '';
    }
    .ant-menu-horizontal > .ant-menu-submenu > .ant-menu-submenu-title {
        padding: 0 20px;
    }

  ul,
  .ant-menu,
  ul.ant-menu {
    display: flex;
    align-items: center;
    border: 0;
    background-color: transparent;
    li {
      margin: 0 5px !important;
      padding: 0 !important;
      height: auto;
      margin-bottom: 0 !important;
      color: ${themeGet('text.0', '#2C2C2C')};
      font-size: 13px;
      line-height: 0;
      font-weight: 700;
      text-transform: uppercase;
      transition: color 0.2s ease-in-out;
      @media (max-width: 1200px) {
        margin: 0 10px;
      }
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      &.ant-menu-item-selected,
      &.ant-menu-item-active {
        background-color: transparent;
      }
      &:focus {
        background-color: transparent !important;
      }
      &:hover {
        border-bottom: 0 !important;
      }

      a {
        display: block;
        padding: 30px 16px;
        color: ${themeGet('text.0', '#2C2C2C')};
        transition: color 0.2s ease-in-out;
        @media (max-width: 1200px) {
          padding: 30px 10px;
        }
        &:hover {
          color: ${themeGet('primary.0', '#49484c')};
        }
        &.active {
          font-weight: 700;
          color: ${themeGet('primary.0', '#49484c')};
          border-bottom: 3px solid ${themeGet('primary.0', '#49484c')};
        }
      }
    }
  }
  .ant-menu-horizontal > .ant-menu-item-selected {
    border-bottom: 0 !important;
  }
`;

export const AuthWrapper = styled.div`
  ul,
  .ant-menu,
  ul.ant-menu {
    display: flex;
    align-items: center;
    border: 0;
    background-color: transparent;

    li {
      margin: 0;
      padding: 0;
      height: auto;
      margin-bottom: 0 !important;
      color: ${themeGet('text.0', '#2C2C2C')};
      font-size: 15px;
      line-height: 0;
      font-weight: 400;
      transition: color 0.2s ease-in-out;
      &.ant-menu-item-selected {
        background-color: transparent;
      }
      &:focus {
        background-color: transparent !important;
      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 78px;
        min-height: 38px;
        border-radius: 3px;
        color: ${themeGet('text.0', '#2C2C2C')};
        transition: color 0.2s ease-in-out;
        &:hover {
          color: ${themeGet('primary.0', '#49484c')};
        }
      }

      &:last-child {
        a {
          color: #252525;
          background-color: transparent;
          transition: opacity 0.2s ease;

          &:hover {
            opacity: 1;
          }
          &.active {
            color: #252525;
          }
        }
      }
    }
  }
`;

export default NavbarWrapper;