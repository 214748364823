import React, { useContext } from 'react';
import Wrapper from '../Auth.style';
import { useTranslation } from 'react-i18next'
import cookies from 'js-cookie'
import { Input, Button, Modal, Row, Col, Checkbox, Form } from 'antd';
import { MainContext } from './../../../constants/MainProvider';

const languages = [
	{
		code: 'en',
		name: 'English',
		country_code: 'gb',
	},
	{
		code: 'ar',
		name: 'العربية',
		dir: 'rtl',
		country_code: 'sa',
	},
];
const Logout = (props) => {
    const { logOut, user, loggedIn: logged } = useContext(MainContext);

	const currentLanguageCode = cookies.get('i18next') || 'en';
	const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
	const { t } = useTranslation();
	function handleLogout() {
        logOut();
        // navigate('/', { replace: true });
    }
	return (
		<Wrapper>
			<div>




				<Modal
					className="redeem-popup logout-popup"
					width={500}
					title={t('logout!')}
					visible={props.modalStatus}
					footer={null}
					onCancel={props.onCancel}
				>
                    <Row className="" gutter={[25, 0]}>
                        <Col className="text-center" span={24}>
                        <img src="/images/logout.png" alt="view-all" className="img-fluid logout-img  mb-30" />
                        </Col>
                        <Col className="" span={24}>
							<h1 class="text-black text-center">
								{t('are_you_sure_youd_like_to_log_out')}
							</h1>
                            <p className="mb-20 font-14 text-black text-center">
								{t('any_unsaved_changes_will_be_discarded')}
							</p>
                        </Col>	
                        <Col className="gutter-row" span={12}>
								<Button className="down-btn rounded-5 w-100" onClick={props.onCancel} type="outline" size="large">
									{t('cancel')}
                                </Button>
                        </Col>
                        <Col className="gutter-row" span={12}>
                                <Button className="down-btn rounded-5 w-100" type="primary" size="large"  onClick={handleLogout}>
									{t('logout')}
                                </Button>
                        </Col>
                    </Row>
                </Modal>
			</div>
		</Wrapper>
	);
};

export default Logout;
