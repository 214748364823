import React, { useContext, useEffect } from 'react';
import {
    Routes,
    Route,
    useLocation,
    Navigate,
} from 'react-router-dom';
import { MainContext } from 'constants/MainProvider';
import Layout from 'pages/Layout/Layout';
import Loader from 'components/Loader/Loader';
import {
    HOME_PAGE,
    LISTING_POSTS_PAGE,
    LISTING_POSTS_PAGE2,
    LISTING_POSTS_PAGE3,
    LISTING_POSTS_PAGE4,
    DETAILS_PAGE,
    PROPERTY_DETAILS_PAGE,
    PROPERTY_LIST,
    GLOBAL_SEARCH,
    // HOSPITALITY,
    // HOSPITALITY_DETAILS,
    // MEDIA_CENTER,
    // PRESS_RELEASE,
    PICTURE_GALLERY,
    GALLERY_DETAILES,
    VIDEO_GALLERY_DETAILES,
    VIDEO_GALLERY,
    // MEDIA_QUERIES,
    NEWS_DETAILS,
    // WHO_WE_ARE,
    // WHAT_WE_DO,
    MARKETPLACE_LIST_PAGE,
    // MARKETPLACE_DETAILS_PAGE,
    CONTACT_US_PAGE,
    AGENT_LOGIN_PAGE,
    AGENT_REGISTRATION_PAGE,
    // WHISTLE_BLOWING_PAGE,
    // SINGLE_POST_PAGE,
    AGENT_PROFILE_PAGE,
    AGENT_PROFILE_FAVORITE,
    AGENT_PROFILE_CONTACT,
    PRICING_PLAN_PAGE,
    PRIVACY_PAGE,
    LOGIN_PAGE,
    REGISTRATION_PAGE,
    FORGET_PASSWORD_PAGE,
    REST_PASSWORD_PAGE,
    VERIFICATION_PAGE,
    // ADD_HOTEL_PAGE,
    AGENT_MYDOCUMENTS_PAGE,
    AGENT_MYALERTS_PAGE,
    AGENT_MYACTIVITY_PAGE,
    AGENT_MYACTIVITY_BROKER_PAGE,
    AGENT_STATEMENT_PAGE,
    AGENT_SETTINGS_PAGE,
    AGENT_FAQ_PAGE,
    AGENT_ACCOUNT_SETTINGS_PAGE,
    MAKE_AN_OFFER,
    PAYMENT_BY_EMPLOYER,
    TERMS_AND_CONDITIONS,
    // DISCLAIMER_PAGE,
    // CAREERS_PAGE,
    // SITEMAP_PAGE,
    PAYMENT_RETURN_PAGE,
    CHAT_PAGE,
    ABOUTUS_NEWTEMPLATE,
    UPCOMING_PROPERTY_LIST,
    LANDING,
    LANDING_DETAILS
} from '../../setup/constant';
import AboutUsTemplate from 'pages/AboutUsNew/AboutUsTemplate';
import SapLanding from 'pages/SAPlandingPage/SapLanding';
import LandingDetails from 'pages/SAPlandingPage/sap_landing_details/LandingDetails';


// protected route
function RequireAuth({ children }) {
    let { loggedIn } = useContext(MainContext);
    let location = useLocation();
    if (!loggedIn) {
        return <Navigate to={LOGIN_PAGE} state={{ from: location }} />;
    }
    return children;
}

// public routes
const HomePage = React.lazy(() => import('pages/Home/Home'));
const ListingPage = React.lazy(() => import('pages/Listing/Listing'));
const DetailsPage = React.lazy(() => import('pages/Details/details'));
const PropertyDetailsPage = React.lazy(() => import('pages/PropertyDetails/PropertyDetails'));
const PropertyListPage = React.lazy(() => import('pages/PropertyList/PropertyList'));
const GlobalSearchPage = React.lazy(() => import('pages/GlobalSearch/GlobalSearch'));
const UpcomingPropertyListPage = React.lazy(() => import('pages/UpcomingPropertyList/UpcomingPropertyList'));

const MakeanOfferPage = React.lazy(() => import('pages/MakeanOffer/MakeAnOffer'));
const PaymentByEmployerPage = React.lazy(() => import('pages/MakeanOffer/PaymentByEmployer'));
const AboutUsMenuOne = React.lazy(() => import('pages/AboutUs/Hospitality/Hospitality'));
const HospitalityDetails = React.lazy(() => import('pages/AboutUs/HospitalityDetails/HospitalityDetails'));
const AboutUsMenuTwo = React.lazy(() => import('pages/AboutUs/MediaCenter/MediaCenter'));

const MediaCenterHome = React.lazy(() => import('pages/AboutUs/MediaCenter/MediaCenterOverview/MediaCenterHome'));
const PreeReleaseHome = React.lazy(() => import('pages/AboutUs/MediaCenter/PressReleaseOverview/PressReleaseHome'));
const PictureGalleryHome = React.lazy(() => import('pages/AboutUs/MediaCenter/Picturegallery/PictureGalleryHome'));
const GalleryDetailesHome = React.lazy(() => import('pages/AboutUs/MediaCenter/Picturegallery/GalleryDetailesHome'));
const VideoGalleryHome = React.lazy(() => import('pages/AboutUs/MediaCenter/VideoGallery/VideoGalleryHome'));
const VideoGalleryDetailsHome = React.lazy(() => import('pages/AboutUs/MediaCenter/VideoGallery/VideoGalleryDetailsHome'));
const MediaQueriesHome = React.lazy(() => import('pages/AboutUs/MediaCenter/MediaQueriesOverview/MediaQueriesHome'));


const NewsDetails = React.lazy(() => import('pages/AboutUs/NewsDetails/NewsDetails'));
// const AboutUsMenuThree = React.lazy(() => import('pages/AboutUs/AboutUs'));
const WhoWeAreHome = React.lazy(() => import('pages/AboutUs/WhoWeAre/WhoWeAreHome'));
const WhatWeDoHome = React.lazy(() => import('pages/AboutUs/WhatWeDo/WhatWeDoHome'));
const MarketPlaceList = React.lazy(() => import('pages/MarketPlace/MarketPlace'));
const MarketPlaceDetails = React.lazy(() => import('pages/MarketPlace/MarketPlaceDetails/MarketPlaceDetails'));
const ContactUs = React.lazy(() => import('pages/ContactUs/ContactUs'));
const AgentLoginPage = React.lazy(() => import('pages/Agent/BrokerLogin/BrokerLogin'));
const AgentRegistrationPage = React.lazy(() => import('pages/Agent/BrokerRegistration/BrokerRegistration'));
const TermsAndConditions = React.lazy(() => import('pages/TermsAndConditions/TermsAndConditions'));
const WhistleblowingPage = React.lazy(() => import('pages/Whistleblowing/Whistleblowing'));
const DisclamierPage = React.lazy(() => import('pages/Disclamier/Disclamier'));
const CareersPage = React.lazy(() => import('pages/Careers/Careers'));
const PrivacyPolicy = React.lazy(() => import('pages/PrivacyPolicy/PrivacyPolicy'));
const SiteMapPage = React.lazy(() => import('pages/SiteMap/SiteMap'));

const SinglePageView = React.lazy(() =>
    import('pages/SinglePage/SinglePageView')
);
const AgentDetailsViewPage = React.lazy(() =>
    import('pages/Agent/AccountDetails/AgentDetailsViewPage')
);
const AgentItemLists = React.lazy(() =>
    import('pages/Agent/AccountDetails/AgentItemLists')
);
const Agentlogin = React.lazy(() =>
    import('pages/Agent/BrokerLogin/BrokerLogin')
);
const AgentFavItemLists = React.lazy(() =>
    import('pages/Agent/AccountDetails/AgentFavItemLists')
);
const AgentContact = React.lazy(() =>
    import('pages/Agent/AccountDetails/AgentContact')
);
const PricingPage = React.lazy(() => import('pages/Pricing/Pricing'));
const SignInPage = React.lazy(() => import('pages/Auth/SignIn/SignIn'));
const SignUpPage = React.lazy(() => import('pages/Auth/SignUp/SignUp'));
const ForgetPasswordPage = React.lazy(() =>
    import('pages/Auth/ForgetPassword')
);
const ResetPassword = React.lazy(() => import('pages/Auth/ResetPassword'));
const UserVerificationPage = React.lazy(() => import('pages/Auth/UserVerification'));
const NotFound = React.lazy(() => import('pages/404/404'));
// protected route

const AgentAccountSettingsPage = React.lazy(() =>
    import('pages/Agent/AccountSettings/AgentAccountSettingsPage')
);
const AgentCreateOrUpdateForm = React.lazy(() =>
    import('pages/Agent/AccountSettings/AgentCreateOrUpdateForm')
);
const AgentPictureChangeForm = React.lazy(() =>
    import('pages/Agent/AccountSettings/MyDocuments')
);
const Myalerts = React.lazy(() =>
    import('pages/Agent/AccountSettings/MyAlerts')
);
const MyActivity = React.lazy(() =>
    import('pages/Agent/MyActivity/MyActivity')
);
const MyActivityBroker = React.lazy(() =>
    import('pages/Agent/MyActivityBroker/MyActivityBroker')
);
const Statement = React.lazy(() =>
    import('pages/Agent/Statement/Statement')
);
const Settings = React.lazy(() =>
    import('pages/Agent/AccountSettings/Settings')
);
const Faq = React.lazy(() =>
    import('pages/Agent/AccountSettings/Faq')
);
const PaymentStatusPage = React.lazy(() =>
    import('pages/PaymentStatusPage')
);
const Chat = React.lazy(() =>
    import('pages/chat')
);
export default function PrivateRoutes() {
    const { user, userProfileState, getUserProfile } = useContext(MainContext);

    const { loading: loadingProfile, profileDetails, error: errorProfile } = userProfileState;


    useEffect(() => {
        if (user.userInfo !== null) {
            /* if(user.userInfo && user.userInfo !== 'null' && user.userInfo !== 'undefined'){ */
            if (Object.keys(user.userInfo).length !== 0) {
                // getNotifications(user.userInfo.access_token);

                if (Object.keys(profileDetails).length === 0 && profileDetails.constructor === Object) {
                    getUserProfile(user.userInfo.access_token);
                }
            }
        }
    }, [user]);


    return (
        <Routes>
            <Route path={HOME_PAGE} element={<Layout />}>
                <Route
                    exact
                    index
                    element={
                        <React.Suspense fallback={<Loader className='h-full' />}>
                            <HomePage />
                        </React.Suspense>
                    }
                />
                <Route
                    exact
                    path={LISTING_POSTS_PAGE}
                    element={
                        <React.Suspense fallback={<Loader className='h-full' />}>
                            <ListingPage />
                        </React.Suspense>
                    }
                />
                <Route
                    exact
                    path={LISTING_POSTS_PAGE2}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <ListingPage />
                        </React.Suspense>
                    }
                />
                <Route
                    exact
                    path={LISTING_POSTS_PAGE3}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <ListingPage />
                        </React.Suspense>
                    }
                />
                <Route
                    exact
                    path={LISTING_POSTS_PAGE4}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <ListingPage />
                        </React.Suspense>
                    }
                />
                <Route
                    exact
                    path={AGENT_REGISTRATION_PAGE}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <AgentRegistrationPage />
                        </React.Suspense>
                    }
                />
                <Route
                    exact
                    path={DETAILS_PAGE}
                    element={
                        <React.Suspense fallback={<Loader className='h-full' />}>
                            <DetailsPage />
                        </React.Suspense>
                    }
                />
                {/* <Route
                    exact
                    path={DISCLAIMER_PAGE}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <DisclamierPage />
                        </React.Suspense>
                    }
                /> */}
                {/* <Route
                    exact
                    path={CAREERS_PAGE}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <CareersPage />
                        </React.Suspense>
                    }
                /> */}
                {/* <Route
                    exact
                    path={SITEMAP_PAGE}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <SiteMapPage />
                        </React.Suspense>
                    }
                /> */}
                <Route
                    exact
                    path={PROPERTY_DETAILS_PAGE}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <PropertyDetailsPage />
                        </React.Suspense>
                    }
                />
                <Route
                    exact
                    path={PROPERTY_LIST}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <PropertyListPage />
                        </React.Suspense>
                    }
                />
                <Route
                    exact
                    path={UPCOMING_PROPERTY_LIST}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <UpcomingPropertyListPage />
                        </React.Suspense>
                    }
                />
                <Route
                    exact
                    path={GLOBAL_SEARCH}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <GlobalSearchPage />
                        </React.Suspense>
                    }
                />
                <Route
                    exact
                    path={LANDING}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <SapLanding />
                        </React.Suspense>
                    }
                />
                <Route
                    exact
                    path={LANDING_DETAILS}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <LandingDetails />
                        </React.Suspense>
                    }
                />
                <Route
                    exact
                    path={MAKE_AN_OFFER}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <MakeanOfferPage />
                        </React.Suspense>
                    }
                />
                <Route
                    exact
                    path={PAYMENT_BY_EMPLOYER}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <PaymentByEmployerPage />
                        </React.Suspense>
                    }
                />
                {/* <Route
                    exact
                    path={HOSPITALITY}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <AboutUsMenuOne />
                        </React.Suspense>
                    }
                />
                <Route
                    exact
                    path={HOSPITALITY_DETAILS}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <HospitalityDetails />
                        </React.Suspense>
                    }
                />
                <Route
                    exact
                    path={MEDIA_CENTER}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <MediaCenterHome />
                        </React.Suspense>
                    }
                />
                <Route
                    exact
                    path={PRESS_RELEASE}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <PreeReleaseHome />
                        </React.Suspense>
                    }
                /> */}
                <Route
                    exact
                    path={NEWS_DETAILS}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <NewsDetails />
                        </React.Suspense>
                    }
                />
                <Route
                    exact
                    path={PICTURE_GALLERY}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <PictureGalleryHome />
                        </React.Suspense>
                    }
                />
                {/* <Route
                    exact
                    path={GALLERY_DETAILES}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <GalleryDetailesHome />
                        </React.Suspense>
                    }
                /> */}
                {/* <Route
                    exact
                    path={VIDEO_GALLERY}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <VideoGalleryHome />
                        </React.Suspense>
                    }
                /> */}
                {/* <Route
                    exact
                    path={VIDEO_GALLERY_DETAILES}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <VideoGalleryDetailsHome />
                        </React.Suspense>
                    }
                /> */}
                {/* <Route
                    exact
                    path={MEDIA_QUERIES}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <MediaQueriesHome />
                        </React.Suspense>
                    }
                /> */}
                {/* <Route
                    exact
                    path={WHO_WE_ARE}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <WhoWeAreHome />
                        </React.Suspense>
                    }
                /> */}
                {/* <Route
                    exact
                    path={WHAT_WE_DO}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <WhatWeDoHome />
                        </React.Suspense>
                    }
                /> */}
                {/* <Route
                    exact
                    path={MARKETPLACE_LIST_PAGE}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <MarketPlaceList />
                        </React.Suspense>
                    }
                /> */}
                {/* <Route
                    exact
                    path={MARKETPLACE_DETAILS_PAGE}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <MarketPlaceDetails />
                        </React.Suspense>
                    }
                /> */}
                <Route
                    exact
                    path={CONTACT_US_PAGE}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <ContactUs />
                        </React.Suspense>
                    }
                />
                <Route
                    exact
                    path={AGENT_LOGIN_PAGE}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <Agentlogin />
                        </React.Suspense>
                    }
                />
                {/* <Route
                    exact
                    path={CONTACT_US_PAGE}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <ContactUs />
                        </React.Suspense>
                    }
                /><Route
                    path={WHISTLE_BLOWING_PAGE}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <WhistleblowingPage />
                        </React.Suspense>
                    }
                />
                <Route
                    exact
                    path={`${SINGLE_POST_PAGE}/:slug`}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <SinglePageView />
                        </React.Suspense>
                    }
                /> */}
                {/* Nested routes for agent page */}
                <Route
                    exact
                    path={AGENT_PROFILE_PAGE}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <AgentDetailsViewPage />
                        </React.Suspense>
                    }
                >
                    <Route
                        exact
                        path={AGENT_PROFILE_PAGE}
                        element={
                            <React.Suspense fallback={<Loader />}>
                                <AgentItemLists />
                            </React.Suspense>
                        }
                    />

                    <Route
                        exact
                        path={AGENT_PROFILE_FAVORITE}
                        element={
                            <React.Suspense fallback={<Loader />}>
                                <AgentFavItemLists />
                            </React.Suspense>
                        }
                    />
                    <Route
                        exact
                        path={AGENT_PROFILE_CONTACT}
                        element={
                            <React.Suspense fallback={<Loader />}>
                                <AgentContact />
                            </React.Suspense>
                        }
                    />
                </Route>
                {/* end of Nested routes */}
                <Route
                    exact
                    path={PRICING_PLAN_PAGE}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <PricingPage />
                        </React.Suspense>
                    }
                />
                <Route
                    exact
                    path={TERMS_AND_CONDITIONS}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <TermsAndConditions />
                        </React.Suspense>
                    }
                />
                <Route
                    exact
                    path={PRIVACY_PAGE}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <PrivacyPolicy />
                        </React.Suspense>
                    }
                />
                <Route
                    exact
                    path={ABOUTUS_NEWTEMPLATE}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <AboutUsTemplate />
                        </React.Suspense>
                    }
                />
                <Route
                    exact
                    path={LOGIN_PAGE}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <SignInPage />
                        </React.Suspense>
                    }
                />
                <Route
                    exact
                    path={REGISTRATION_PAGE}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <SignUpPage />
                        </React.Suspense>
                    }
                />
                <Route
                    exact
                    path={FORGET_PASSWORD_PAGE}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <ForgetPasswordPage />
                        </React.Suspense>
                    }
                />
                <Route
                    exact
                    path={REST_PASSWORD_PAGE}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <ResetPassword />
                        </React.Suspense>
                    }
                />
                <Route
                    exact
                    path={VERIFICATION_PAGE}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <UserVerificationPage />
                        </React.Suspense>
                    }
                />
                {/* Protected routes */}

                <Route
                    exact
                    path={AGENT_ACCOUNT_SETTINGS_PAGE}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <RequireAuth>
                                <AgentAccountSettingsPage />
                            </RequireAuth>
                        </React.Suspense>
                    }
                >
                    <Route
                        exact
                        path={AGENT_ACCOUNT_SETTINGS_PAGE}
                        element={
                            <React.Suspense fallback={<Loader />}>
                                <AgentCreateOrUpdateForm />
                            </React.Suspense>
                        }
                    />
                    <Route
                        exact
                        path={AGENT_MYDOCUMENTS_PAGE}
                        element={
                            <React.Suspense fallback={<Loader />}>
                                <AgentPictureChangeForm />
                            </React.Suspense>
                        }
                    />
                    <Route
                        exact
                        path={AGENT_MYALERTS_PAGE}
                        element={
                            <React.Suspense fallback={<Loader />}>
                                <Myalerts />
                            </React.Suspense>
                        }
                    />
                    <Route
                        exact
                        path={AGENT_MYACTIVITY_PAGE}
                        element={
                            <React.Suspense fallback={<Loader />}>
                                <MyActivity />
                            </React.Suspense>
                        }
                    />
                    <Route
                        exact
                        path={AGENT_MYACTIVITY_BROKER_PAGE}
                        element={
                            <React.Suspense fallback={<Loader />}>
                                <MyActivityBroker />
                            </React.Suspense>
                        }
                    />
                    <Route
                        exact
                        path={AGENT_STATEMENT_PAGE}
                        element={
                            <React.Suspense fallback={<Loader />}>
                                <Statement />
                            </React.Suspense>
                        }
                    />
                    <Route
                        exact
                        path={AGENT_SETTINGS_PAGE}
                        element={
                            <React.Suspense fallback={<Loader />}>
                                <Settings />
                            </React.Suspense>
                        }
                    />
                    <Route
                        exact
                        path={AGENT_FAQ_PAGE}
                        element={
                            <React.Suspense fallback={<Loader />}>
                                <Faq />
                            </React.Suspense>
                        }
                    />
                </Route>
                {/* end of Protected routes*/}
                <Route
                    exact
                    path={PAYMENT_RETURN_PAGE}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <PaymentStatusPage />
                        </React.Suspense>
                    }
                />
                <Route
                    exact
                    path={CHAT_PAGE}
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <Chat />
                        </React.Suspense>
                    }
                />
                <Route
                    exact
                    path="*"
                    element={
                        <React.Suspense fallback={<Loader />}>
                            <NotFound />
                        </React.Suspense>
                    }
                />

            </Route>
        </Routes>
    );
}
