import { CloseOutlined, SendOutlined, WechatOutlined } from "@ant-design/icons";
import { Col, Row, message } from "antd";
import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import ChartBoatWrapper from "./ChartBoat.Style";
import axios from "axios";
import moment from "moment";

function FaqItem({ message, handleLoaderChange }) {
  const [solve, setSolve] = useState(false);
  const [issues, setIssues] = useState(false);
  const [raise, setRaise] = useState(false);
  const [Callback, setCallback] = useState(false);
  const [save, setSave] = useState(false);
  const[Confirm, setConfirm] = useState(false);
  const [rephrase, setRephrase] = useState(false);
  const [saveRaise, setSaveRaise] = useState(false);
  const bottomRef = useRef(null);

  const handleIssues = () => {
    handleLoaderChange(true);
    setTimeout(() => {
      setIssues(true);
      handleLoaderChange(false);
    }, 1000);
  };

  useEffect(() => {
    // Scroll to this item when it's rendered
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [solve, issues, rephrase, raise, saveRaise, save, Callback, Confirm]);

  return (
    <>
      {message.check && (
        <>
          <div className="w-100 faq-question clear_msg" id="faq_question">
            <div className="chatbot-messages chatbot-received-messages">
              <p className="m-0">Did that resolve your query?</p>
              <Row className="" gutter={[18, 0]}>
                {issues || (<Col>
                  <button
                    className="btn-primary faq-click yes_btn_hide btn"
                    id="yes_dis"
                    onClick={() => {
                      handleLoaderChange(true);
                      setTimeout(() => {
                        setSolve(true);
                        handleLoaderChange(false);
                      }, 1000);
                    }}
                    disabled={solve}
                  >
                    Yes
                  </button>
                </Col>)}
                {solve || <Col>
                  <button
                    className="btn-primary faq-click no_btn_hide btn"
                    id="no_dis"
                    onClick={() => {
                      handleLoaderChange(true);
                      setTimeout(() => {
                        setIssues(true);
                        handleLoaderChange(false);
                      }, 1000);
                    }}
                    disabled={issues}
                  >
                    No
                  </button>
                </Col>}
              </Row>
            </div>
          </div>

          {solve && (
            <div className="chatbot-message clear_msg" id="finish_msg">
              <div className="chat-user-title-rec">
                {" "}
                <p className="m-0">
                  {" "}
                  RealCube Properties{" "}
                  <span className="ml-1 msgTime" name="">
                    {moment().format("h:mm A")}
                  </span>{" "}
                </p>{" "}
              </div>
              <div className="chatbot-messages chatbot-received-messages">
                <p>OK Great! Please proceed to the next question</p>
              </div>
            </div>
          )}
          {issues && (
            <div
              className="chatbot-message mt-1 clear_msg"
              id="rephase_sentence"
            >
              <div className="chat-user-title-rec">
                {" "}
                <p className="m-0">
                  {" "}
                  RealCube Properties{" "}
                  <span className="ml-1 msgTime">
                    {moment().format("h:mm A")}
                  </span>{" "}
                </p>{" "}
              </div>
              <div className="chatbot-messages chatbot-received-messages">
                <p>Do you want to Rephrase your question?</p>
                <Row gutter={[18, 0]}>
                  {raise || Callback || (<Col>
                    <button
                      className="btn-primary faq-click btn"
                      id="next_yes_btn"
                      onClick={() => {
                        handleLoaderChange(true);
                        setTimeout(() => {
                          setRephrase(true);
                          handleLoaderChange(false);
                        }, 1000);
                      }}
                      disabled={rephrase}
                    >
                      Yes
                    </button>
                  </Col>)}
                  {rephrase || Callback || (<Col>
                    <button
                      className="btn-primary faq-click btn"
                      id="raise_serv"
                      onClick={() => {
                        handleLoaderChange(true);
                        setTimeout(() => {
                          setRaise(true);
                          handleLoaderChange(false);
                        }, 1000);
                      }}
                      disabled={raise}
                    >
                      Raise a Service Request
                    </button>
                  </Col>)}
                  {raise || rephrase || (<Col>
                    <button
                      className="btn-primary faq-click btn"
                      id="req_callback"
                      onClick={() => {
                        handleLoaderChange(true);
                        setTimeout(() => {
                          setCallback(true);
                          handleLoaderChange(false);
                        }, 1000);
                      }}
                      disabled={Callback}
                    >
                      Request a Callback
                    </button>
                  </Col>)}
                </Row>
              </div>
            </div>
          )}
          {raise && (
            <div className="chatbot-message clear_msg" id="detail_enter">
              <div className="chat-user-title-rec">
                <p className="m-0">
                  {" "}
                  RealCube Properties{" "}
                  <span className="ml-1 msgTime">
                    {" "}
                    {moment().format("h:mm A")}
                  </span>{" "}
                </p>
              </div>
              <div className="input-details">
                <div className="chatbot-messages chatbot-received-messages">
                  <Row gutter={[20, 8]} className="mb-2">
                    <Col span={24} className="font-12">
                      Please enter the following:
                    </Col>
                    <Col span={24} className="font-12">
                      <label className="w-100">
                        Email :{" "}
                        <input
                          className="form-control"
                          type="text"
                          value="realcube@exalogic.co"
                        />
                      </label>
                    </Col>
                    <Col span={24} className="font-12">
                      <label className="w-100">
                        Name :{" "}
                        <input
                          className="form-control"
                          type="text"
                          value="Realcube"
                        />
                      </label>
                    </Col>
                  </Row>
                  <Row gutter={[20, 0]}>
                    {saveRaise || (
                    <>
                    <Col>
                      <button className="btn-primary faq-click btn">
                        Edit
                      </button>
                    </Col>
                    <Col>
                      <button className="btn-primary faq-click btn">
                        Cancel
                      </button>
                    </Col>
                    </>)}
                    <Col>
                      <button
                        className="btn-primary faq-click btn"
                        onClick={() => {
                          handleLoaderChange(true);
                          setTimeout(() => {
                            setSaveRaise(true);
                            handleLoaderChange(false);
                          }, 1000);
                        }}
                        disabled={saveRaise}
                      >
                        Save
                      </button>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          )}

          {saveRaise && (
            <div className="chatbot-message clear_msg" id="detail_enter">
              <div className="chat-user-title-rec">
                <p className="m-0">
                  {" "}
                  RealCube Properties{" "}
                  <span className="ml-1 msgTime">
                    {moment().format("h:mm A")}
                  </span>{" "}
                </p>
              </div>
              <div className="input-details">
                <div className="chatbot-messages chatbot-received-messages">
                  <Row gutter={[20, 8]} className="mb-2">
                    <Col span={24} className="font-12">
                      Please enter the following:
                    </Col>
                    <Col span={24} className="font-12">
                      <label className="w-100">
                        Community :{" "}
                        <select
                          className="font-12 w-100 form-control"
                          name="community"
                          id="community"
                        >
                          <option value="0">RealCube Village</option>
                          <option value="1">Bab Al Bhar</option>
                        </select>
                      </label>
                    </Col>
                    <Col span={24} className="font-12">
                      <label className="w-100">
                        Unit :{" "}
                        <select
                          className="font-12 w-100 form-control"
                          name="unit"
                          id="unit"
                        >
                          <option value="0">AH-AHV / AH-AHV</option>
                          <option value="1">AH-BT / AH-BT</option>
                          <option value="2">AH-BT-E / AH-BT-E</option>
                          <option value="3">BAB-K101</option>
                        </select>
                      </label>
                    </Col>
                  </Row>
                  <Row gutter={[20, 0]}>
                    {Confirm || (<Col>
                      <button className="btn-primary faq-click btn">
                        Cancel
                      </button>
                    </Col>)}
                    <Col>
                      <button
                        className="btn-primary faq-click btn"
                        onClick={() => {
                          handleLoaderChange(true);
                          setTimeout(() => {
                            setConfirm(true);
                            handleLoaderChange(false);
                          }, 1000);
                        }}
                        disabled={Confirm}
                      >
                        Confirm
                      </button>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          )}

          {Callback && (
            <>
              <div className="chatbot-message clear_msg" id="detail_enter">
                <div className="chat-user-title-rec">
                  <p className="m-0">
                    {" "}
                    RealCube Properties{" "}
                    <span className="ml-1 msgTime">
                      {" "}
                      {moment().format("h:mm A")}{" "}
                    </span>{" "}
                  </p>
                </div>
                <div className="input-details">
                  <div className="chatbot-messages chatbot-received-messages">
                    <Row gutter={[20, 8]} className="mb-2">
                      <Col span={24} className="font-12">
                        Please enter the following:
                      </Col>
                      <Col span={24} className="font-12">
                        <label className="w-100">
                          Email :{" "}
                          <input
                            className="form-control"
                            type="text"
                            value="realcube@exalogic.co"
                          />
                        </label>
                      </Col>
                      <Col span={24} className="font-12">
                        <label className="w-100">
                          Name :{" "}
                          <input
                            className="form-control"
                            type="text"
                            value="Realcube"
                          />
                        </label>
                      </Col>
                      <Col span={24} className="font-12">
                        <label className="w-100">
                          Phone :{" "}
                          <input
                            className="form-control"
                            type="text"
                            value="564876980"
                          />
                        </label>
                      </Col>
                    </Row>
                    <Row gutter={[20, 0]}>
                    {save || (
                    <>
                    <Col>
                      <button className="btn-primary faq-click btn">
                        Edit
                      </button>
                    </Col>
                    <Col>
                      <button className="btn-primary faq-click btn">
                        Cancel
                      </button>
                    </Col>
                    </>)}
                      <Col>
                        <button
                          className="btn-primary faq-click btn"
                          onClick={() => {
                            handleLoaderChange(true);
                            setTimeout(() => {
                              setSave(true);
                              handleLoaderChange(false);
                            }, 1000);
                          }}
                          disabled={save}
                        >
                          Save
                        </button>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </>
          )}
          {save && (
            <div className="chatbot-message clear_msg" id="info_msg">
              <div className="chat-user-title-rec">
                {" "}
                <p className="m-0">
                  {" "}
                  RealCube Properties{" "}
                  <span className="ml-1 msgTime">
                    {moment().format("h:mm A")}
                  </span>{" "}
                </p>{" "}
              </div>
              <div className="chatbot-messages chatbot-received-messages">
                <p>Thank you! We will call you shortly.</p>
              </div>
            </div>
          )}

          {rephrase && (
            <div className="chatbot-message clear_msg" id="write_ques">
              <div className="chat-user-title-rec">
                {" "}
                <p className="m-0">
                  {" "}
                  RealCube Properties{" "}
                  <span className="ml-1 msgTime">
                    {moment().format("h:mm A")}{" "}
                  </span>{" "}
                </p>{" "}
              </div>
              <div className="chatbot-messages chatbot-received-messages">
                <p>Please write your question?</p>
              </div>
            </div>
          )}
          {Confirm && (
            <div className="chatbot-message clear_msg" id="info_msg">
            <div className="chat-user-title-rec">
              {" "}
              <p className="m-0">
                {" "}
                RealCube Properties{" "}
                <span className="ml-1 msgTime">{moment().format("h:mm A")}{" "}</span>{" "}
              </p>{" "}
            </div>
            <div className="chatbot-messages chatbot-received-messages">
              <p>Your Service Request is created.</p>
              <p>Service Request#: #13234555</p>
              <p>Priority: P3</p>
              <p>SLA: One Business Day</p>
            </div>
          </div>
          )}
          <div ref={bottomRef} />
        </>
      )}
    </>
  );
}

export default function ChartBoat() {
  const [openChatView, setOpenChartView] = useState(false);

  const openChat = () => {
    setOpenChartView(!openChatView);
  };

  const bottomRef = useRef(null);

  const [categories, setCategories] = useState(false);
  const [change, setChange] = useState("");
  const [loader, setLoader] = useState(false);
  const [dataArray, setDataArray] = useState([]);
  const [more, setMore] = useState(false);
  const [buttonStates, setButtonStates] = useState({
    query: false,
    utilities: false,
    community_fee: false,
    contractors: false,
    moving: false,
    parking: false,
    modifications: false,
    common: false,
    landscaping: false,
    garbage: false,
    violations: false,
    security: false,
    pets: false,
    vip: false,
    payments: false,
    access: false,
  });

  const handleLoaderChange = (newLoaderState) => {
    setLoader(newLoaderState);
  };

  function updateButtonState(keyToUpdate) {
    // Create a copy of the current buttonStates
    const updatedStates = { ...buttonStates };

    // Set the specified key to true
    updatedStates[keyToUpdate] = true;

    // Set all other keys to false
    for (const key in updatedStates) {
      if (key !== keyToUpdate) {
        updatedStates[key] = false;
      }
    }

    // Update the buttonStates
    setButtonStates(updatedStates);
  }

  const handleClick = () => {
    setCategories((prevIsActive) => !prevIsActive);
  };

  const handleMore = () => {
    setMore((prevIsActive) => !prevIsActive);
  };

  const handleChange = (e) => {
    setChange(e.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    setTimeout(() => {
      setChange("");
    }, 1000);
    const checkValue = true;
    fetchDataAndProcess(change, checkValue);
  };

  function fetchDataAndProcess(value, checkValue) {
    setLoader(true);
    const tempChatboatUrl =
      "https://chatbot.realcube.estate/faqs?" + `question=${value}`;
    axios
      .get(tempChatboatUrl, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((resp) => {
        if (resp?.data?.match_type === "exact_match") {
          setLoader(false);

          const key = value;
          const message = resp?.data?.response;
          const btn1 = resp?.data?.recommended_buttons[0];
          const btn2 = resp?.data?.recommended_buttons[1];
          const btn3 = resp?.data?.recommended_buttons[2];
          handleDataArray(
            key,
            message,
            btn1,
            btn2,
            btn3,
            "",
            "",
            "",
            undefined,
            undefined,
            undefined,
            checkValue
          );
        } else if (resp?.data?.match_type === "partial_match") {
          setLoader(false);
          const key = value;
          const message = resp?.data?.response;
          const btn1 = resp?.data?.buttons[0];
          const btn2 = resp?.data?.buttons[1];
          const btn3 = resp?.data?.buttons[2];
          handleDataArray(
            key,
            message,
            btn1,
            btn2,
            btn3,
            "",
            "",
            "",
            undefined,
            undefined,
            undefined,
            checkValue
          );
        } else if (resp?.data?.match_type === "no_match") {
          setLoader(false);
          const key = value;
          const message = resp?.data?.response;
          handleDataArray(
            key,
            message,
            "",
            "",
            "",
            "",
            "",
            "",
            undefined,
            undefined,
            undefined,
            checkValue
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }

  const staticValues = (value1, value2, checkValue) => {
    const key = value1;
    const message = value2;
    const btn1 = "";
    const btn2 = "";
    const btn3 = "";
    const link1 = "";
    const link2 = "";
    const link3 = "";
    const para1 = "";
    const para2 = "";
    const para3 = "";
    handleDataArray(
      key,
      message,
      btn1,
      btn2,
      btn3,
      link1,
      link2,
      link3,
      para1,
      para2,
      para3,
      checkValue
    );
  };
  const handleDataArray = (
    key,
    message,
    btn1,
    btn2,
    btn3,
    link1,
    link2,
    link3,
    para1,
    para2,
    para3,
    checkValue
  ) => {
    setLoader(true);
    setTimeout(() => {
      const newMessage = {
        key,
        message,
        btn1,
        btn2,
        btn3,
        link1,
        link2,
        link3,
        para1,
        para2,
        para3,
        check: checkValue,
      };
      setDataArray((prevDataArray) => [...prevDataArray, newMessage]);
      setLoader(false);
    }, 500);
  };

  const handleEmpty = () => {
    setChange("");
    setDataArray([]);
    setCategories(false);
    setButtonStates({
      query: false,
      utilities: false,
      community_fee: false,
      contractors: false,
      moving: false,
      parking: false,
      modifications: false,
      common: false,
      landscaping: false,
      garbage: false,
      violations: false,
      security: false,
      pets: false,
      vip: false,
      payments: false,
      access: false,
    });    setMore(false)
  };

  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [dataArray]);

  return (
    <ChartBoatWrapper>
      {openChatView ? (
        <div id="chatbot-container">
          <div id="chatbot-interface">
            <div id="chatbot-header">
              <div className="d-flex align-items-center">
                <div className="chatbot-profile">
                  <img
                    className="chatbot-chat-img img-fluid"
                    src="/images/chat-head-logo.png"
                    alt="Chatbot"
                  />
                </div>
                <div className="chatbot-header-text">
                  <h5>RealCube Help Desk</h5>
                  <p>Want to know something? - Let's chat</p>
                </div>
              </div>
            </div>
            <div className="chatbot-chat_outer">
              <div id="chatbot-chat">
                {/* The First Greeting Message Starts */}
                <div className="chatbot-message">
                  <div className="chat-user-title-rec">
                    <p className="m-0">
                      RealCube Properties{" "}
                      <span className="ml-1 msgTime">
                        {" "}
                        {moment().format("h:mm A")}{" "}
                      </span>
                    </p>
                  </div>
                  <div className="chatbot-messages chatbot-received-messages">
                    <p className="m-0">
                      Greetings from RealCube helpdesk, I will be able answer
                      your queries related to guidelines and rules of RealCube
                      Community.
                    </p>
                    <Row gutter={[20, 10]}>
                      <Col id="sr_btn" className="gutter-row" span={12}>
                        <button className="btn-primary btn w-100" id="ser_req">
                          Service Request
                        </button>
                      </Col>
                      <Col
                        id="qr_btn"
                        className="gutter-row"
                        span={12}
                        onClick={handleClick}
                      >
                        <button
                          className={
                            buttonStates.query
                              ? "btn-primary btn w-100 active"
                              : "btn-primary btn w-100"
                          }
                          id="queries"
                        >
                          Queries
                        </button>
                      </Col>
                    </Row>
                  </div>
                  {categories && (
                    <div
                      className="chatbot-messages chatbot-received-messages"
                      id="ser_req_btn"
                    >
                      <Row className="no-gutters" justify="space-between">
                        <Col className="mt-2">
                          <Row
                            className="ser_req_btn"
                            data-value="class"
                            justify="space-between"
                            gutter={[20, 0]}
                          >
                            <>
                              <Col span={12}>
                                <button
                                  className={`btn-primary btn button-click2 w-100 ${
                                    buttonStates.utilities ? "active" : ""
                                  }`}
                                  value="utilities"
                                  id="uti_btn"
                                  onClick={() => {
                                    handleDataArray(
                                      "Utilities",
                                      "Need information about your utility bills? I'm here to help.",
                                      "View Current Bill",
                                      "Bill History",
                                      "Payment Options",
                                      "https://customer.alhamracommunities.com/units/unitOutstanding",
                                      "https://customer.alhamracommunities.com/units/unitOutstanding",
                                      "https://customer.alhamracommunities.com/units/unitOutstanding"
                                    );
                                    updateButtonState("utilities");
                                  }}
                                >
                                  Utilities
                                </button>
                              </Col>
                              <Col span={12}>
                                <button
                                  className={`btn-primary btn button-click2 w-100 ${
                                    buttonStates.community_fee ? "active" : ""
                                  }`}
                                  value="communnity_fee"
                                  id="com_fee"
                                  onClick={() => {
                                    handleDataArray(
                                      "Community Fee",
                                      "Need information about your community fee? I'm here to help.",
                                      "View Current Bill",
                                      "Bill History",
                                      "Payment Options",
                                      "https://customer.alhamracommunities.com/units/unitOutstanding",
                                      "https://customer.alhamracommunities.com/units/unitOutstanding",
                                      "https://customer.alhamracommunities.com/units/unitOutstanding"
                                    );
                                    updateButtonState("community_fee");
                                  }}
                                >
                                  Community Fee
                                </button>
                              </Col>
                              <Col span={12}>
                                <button
                                  className={`btn-primary btn button-click2 w-100 ${
                                    buttonStates.contractors ? "active" : ""
                                  }`}
                                  value="contractors"
                                  id="contract"
                                  onClick={() => {
                                    handleDataArray(
                                      "Contractors",
                                      "Welcome to the Contractors Assistance! How can I assist you with your transition?",
                                      "Required Documents",
                                      "Move-out Guidelines",
                                      "Transfer Utilities",
                                      "",
                                      "",
                                      "",
                                      "For the move-in process, you'll need to provide identification, lease agreement, and any necessary fees. Check out our 'Move-in Checklist' for detailed info.",
                                      "Yes, we have guidelines to ensure a smooth move-out, such as cleaning responsibilities and returning keys.",
                                      "Certainly! We can guide you through transferring utilities, updating your address, and more. Just let us know how we can assist."
                                    );
                                    updateButtonState("contractors");
                                  }}
                                  //
                                >
                                  Contractors
                                </button>
                              </Col>
                              <Col span={12}>
                                <button
                                  className={`btn-primary btn button-click2 w-100 ${
                                    buttonStates.moving ? "active" : ""
                                  }`}
                                  value="moving_in/out"
                                  id="moving_in_out"
                                  onClick={() => {
                                    handleDataArray(
                                      "Moving In/Out",
                                      "Welcome to the Move-in/Move-out Assistance! How can I assist you with your transition?",
                                      "Required Documents",
                                      "Move-out Guidelines",
                                      "Transfer Utilities",
                                      "",
                                      "",
                                      "",
                                      "For the move-in process, you'll need to provide identification, lease agreement, and any necessary fees. Check out our 'Move-in Checklist' for detailed info.",
                                      "Yes, we have guidelines to ensure a smooth move-out, such as cleaning responsibilities and returning keys.",
                                      "Certainly! We can guide you through transferring utilities, updating your address, and more. Just let us know how we can assist."
                                    );
                                    updateButtonState("moving");
                                  }}
                                >
                                  Moving In/Out
                                </button>
                              </Col>
                              <Col span={12}>
                                <button
                                  className={`btn-primary btn button-click2 w-100 ${
                                    buttonStates.parking ? "active" : ""
                                  }`}
                                  value="parking/driving"
                                  id="parking_driving"
                                  onClick={() => {
                                    handleDataArray(
                                      "Parking/driving",
                                      " Welcome to the Parking Assistance! How can I help you with your parking-related questions?",
                                      "Visitor Parking",
                                      "Reserved Parking",
                                      "Parking Regulations",
                                      "",
                                      "",
                                      "",
                                      "We provide visitor parking facilities for your guests. Our visitor spots are conveniently located near the entrance. Feel free to ask about visitor parking rules and availability.",
                                      "Interested in securing a reserved parking spot? Our reserved parking areas are ideal for residents who prefer a designated parking space. Contact us at realcube@exalogic.co to learn more about availability and pricing.",
                                      "To ensure a smooth parking experience for all residents, we have certain parking regulations in place. These rules are designed to maintain order and accessibility. Please inquire for more details about our parking regulations."
                                    );
                                    updateButtonState("parking");
                                  }}
                                >
                                  Parking/driving
                                </button>
                              </Col>
                              {more || (
                                <Col span={12}>
                                  <button
                                    className="btn-primary btn show-button w-100"
                                    id="ShowMore"
                                    onClick={handleMore}
                                  >
                                    Show More
                                  </button>
                                </Col>
                              )}
                            </>
                            {more && (
                              <>
                                <Col className="show-more-content" span={12}>
                                  <button
                                    className={`btn-primary btn button-click2 w-100 ${
                                      buttonStates.modifications ? "active" : ""
                                    }`}
                                    value="modifications"
                                    id="modif"
                                    onClick={() => {
                                      handleDataArray(
                                        "Modifications",
                                        "Modification services enhance your property. What do you need?",
                                        "Request Modification",
                                        "Maintenance Schedule",
                                        "",
                                        "https://customer.alhamracommunities.com/service/allServiceReq",
                                        "https://customer.alhamracommunities.com/communitywall/index/1"
                                      );
                                      updateButtonState("modifications");
                                    }}
                                  >
                                    Modifications
                                  </button>
                                </Col>
                                <Col className="show-more-content" span={12}>
                                  <button
                                    className={`btn-primary btn button-click2 w-100 ${
                                      buttonStates.common ? "active" : ""
                                    }`}
                                    value="common_area"
                                    id="com_area_btn"
                                    onClick={() => {
                                      handleDataArray(
                                        "Common area",
                                        "Information about common areas is essential. How can I help?",
                                        "Common Area Facilities",
                                        "Book Amenities",
                                        "Common Area Usage Rules",
                                        "https://customer.alhamracommunities.com/amenity/index/1",
                                        "https://customer.alhamracommunities.com/amenity/index/1",
                                        "https://customer.alhamracommunities.com/communitywall/index/1"
                                      );
                                      updateButtonState("common");
                                    }}
                                  >
                                    Common area
                                  </button>
                                </Col>
                                <Col className="show-more-content" span={12}>
                                  <button
                                    className={`btn-primary btn button-click2 w-100 ${
                                      buttonStates.landscaping ? "active" : ""
                                    }`}
                                    value="landscaping"
                                    id="land_scaping"
                                    onClick={() => {
                                      handleDataArray(
                                        "Landscaping",
                                        "Landscaping services enhance your property. What do you need?",
                                        "Request Landscaping",
                                        "Maintenance Schedule",
                                        "",
                                        "https://customer.alhamracommunities.com/service/allServiceReq",
                                        "https://customer.alhamracommunities.com/communitywall/index/1"
                                      );
                                      updateButtonState("landscaping");
                                    }}
                                  >
                                    Landscaping
                                  </button>
                                </Col>
                                <Col className="show-more-content" span={12}>
                                  <button
                                    className={`btn-primary btn button-click2 w-100 ${
                                      buttonStates.garbage ? "active" : ""
                                    }`}
                                    value="garbage"
                                    id="gar_bage"
                                    onClick={() => {
                                      handleDataArray(
                                        "Garbage",
                                        "Garbage collection is important for a clean environment. What can I assist with?",
                                        "Garbage Collection Schedule",
                                        "Request Dispose",
                                        "",
                                        "",
                                        "https://customer.alhamracommunities.com/service/allServiceReq",
                                        "",
                                        "9:00 AM  12:00 PM  3:00 PM  05:00 PM",
                                        ""
                                      );
                                      updateButtonState("garbage");
                                    }}
                                  >
                                    Garbage
                                  </button>
                                </Col>
                                <Col className="show-more-content" span={12}>
                                  <button
                                    className={`btn-primary btn button-click2 w-100 ${
                                      buttonStates.violations ? "active" : ""
                                    }`}
                                    value="violations"
                                    id="vio_lations"
                                    onClick={() => {
                                      handleDataArray(
                                        "Violations",
                                        "Handling property violations is crucial. How can I guide you?",
                                        "Report a Violation",
                                        "Violation Status",
                                        "",
                                        "https://customer.alhamracommunities.com/service/complaint",
                                        "https://customer.alhamracommunities.com/service/complaint"
                                      );
                                      updateButtonState("violations");
                                    }}
                                  >
                                    Violations
                                  </button>
                                </Col>
                                <Col className="show-more-content" span={12}>
                                  <button
                                    className={`btn-primary btn button-click2 w-100 ${
                                      buttonStates.security ? "active" : ""
                                    }`}
                                    value="security"
                                    id="sec_urity"
                                    onClick={() => {
                                      handleDataArray(
                                        "Security",
                                        "Ensuring security is a priority. How can I provide information?",
                                        "Security Guidelines",
                                        "Emergency Contacts",
                                        "Safety Tips",
                                        "",
                                        "",
                                        "",
                                        [
                                          <p>
                                            Ensuring the safety and security of
                                            our residents is of paramount
                                            importance. To maintain a secure
                                            living environment, we kindly
                                            request all residents to adhere to
                                            the following guidelines:{" "}
                                          </p>,
                                          <p>
                                            <span className="font-weight-600 bg-transparent">
                                              {" "}
                                              Access Control:{" "}
                                            </span>{" "}
                                            Please ensure that external doors
                                            are securely locked at all times and
                                            do not allow entry to unfamiliar
                                            individuals.
                                          </p>,
                                          <p>
                                            {" "}
                                            <span className="font-weight-600 bg-transparent">
                                              {" "}
                                              Visitor Registration:{" "}
                                            </span>{" "}
                                            Kindly register all guests at the
                                            front desk and provide them with
                                            temporary access passes.
                                          </p>,
                                          <p>
                                            {" "}
                                            <span className="font-weight-600 bg-transparent">
                                              {" "}
                                              Surveillance:{" "}
                                            </span>{" "}
                                            We employ comprehensive surveillance
                                            systems to enhance the safety of
                                            common areas. Your cooperation in
                                            respecting privacy is appreciated.
                                          </p>,
                                          <p>
                                            {" "}
                                            <span className="font-weight-600 bg-transparent">
                                              {" "}
                                              Emergency Awareness:{" "}
                                            </span>{" "}
                                            Familiarize yourself with the
                                            emergency evacuation routes and
                                            protocols established for the
                                            building.
                                          </p>,
                                          <p>
                                            {" "}
                                            <span className="font-weight-600 bg-transparent">
                                              {" "}
                                              Reporting Suspicious Activity:{" "}
                                            </span>{" "}
                                            If you observe any suspicious
                                            activity or individuals, promptly
                                            report it to the building management
                                            or security personnel.
                                          </p>,
                                        ],
                                        "800 AL HAMRA (800-2542672)",
                                        "Ensuring the safety of our residents is our utmost priority. To promote a secure living environment within our apartments, we recommend always locking your doors and windows when leaving your unit. Additionally, familiarize yourself with emergency exit routes and locations of fire extinguishers. Please avoid propping open common area doors to maintain controlled access. Lastly, promptly report any suspicious activities or concerns to our dedicated security personnel for swift assistance. Your safety is our commitment."
                                      );
                                      updateButtonState("security");
                                    }}
                                  >
                                    Security
                                  </button>
                                </Col>
                                <Col className="show-more-content" span={12}>
                                  <button
                                    className={`btn-primary btn button-click2 w-100 ${
                                      buttonStates.pets ? "active" : ""
                                    }`}
                                    value="pets"
                                    id="pets_sub"
                                    onClick={() => {
                                      handleDataArray(
                                        "Pets",
                                        "Pets are part of the community. How can I assist with pet-related queries?",
                                        "Pet Policy",
                                        "Pet Registration",
                                        "",
                                        "",
                                        "",
                                        "",
                                        "Our apartment community values the presence of pets as part of our close-knit environment. To ensure harmonious living for all residents, we have established clear and considerate pet policies. Pets are required to be registered with management, accompanied by up-to-date vaccinations and licenses. We provide designated pet-friendly areas within the premises and request all pet owners to adhere to leash and waste disposal guidelines. By upholding these policies, we aim to create a safe and enjoyable space for both our human and furry residents.",
                                        "Write an email to realcube@exalogic.co for any pet registration requests, rewrite in a formal tone",
                                        ""
                                      );
                                      updateButtonState("pets");
                                    }}
                                  >
                                    Pets
                                  </button>
                                </Col>
                                <Col className="show-more-content" span={12}>
                                  <button
                                    className={`btn-primary btn button-click2 w-100 ${
                                      buttonStates.vip ? "active" : ""
                                    }`}
                                    value="vip_card"
                                    id="vip_sub"
                                    onClick={() => {
                                      handleDataArray(
                                        "VIP Card",
                                        "Welcome to the VIP Card Information! How can I help you with questions about VIP cards?",
                                        "Request New Card",
                                        "Lost or Stolen Card",
                                        "Replacement Fees",
                                        "https://customer.alhamracommunities.com/service/allServiceReq",
                                        "",
                                        "",
                                        "",
                                        "If your VIP card is lost or stolen, it's important to report it to us immediately. Contact our office so we can deactivate the old card and issue a new one.",
                                        "Please note that you need to pay a 300 AED replacement fee for lost or damaged VIP cards. You can find the fee details on our 'VIP Card Policies' page."
                                      );
                                      updateButtonState("vip");
                                    }}
                                  >
                                    VIP Card
                                  </button>
                                </Col>
                                <Col className="show-more-content" span={12}>
                                  <button
                                    className={`btn-primary btn button-click2 w-100 ${
                                      buttonStates.payments ? "active" : ""
                                    }`}
                                    value="payments"
                                    id="pay_sub"
                                    onClick={() => {
                                      handleDataArray(
                                        "Payments",
                                        "Let's take care of your payments. How can I assist you?",
                                        "Make a Payment",
                                        "Payment History",
                                        "Payment Methods",
                                        "https://customer.alhamracommunities.com/units/unitOutstanding",
                                        "https://customer.alhamracommunities.com/units/paymentHistory",
                                        "",
                                        "",
                                        "",
                                        "You can make a payment either through Quick Pay or logging into your account"
                                      );
                                      updateButtonState("payments");
                                    }}
                                  >
                                    Payments
                                  </button>
                                </Col>
                                <Col className="show-more-content" span={12}>
                                  <button
                                    className={`btn-primary btn button-click2 w-100 ${
                                      buttonStates.access ? "active" : ""
                                    }`}
                                    value="access_card"
                                    id="access_sub"
                                    onClick={() => {
                                      handleDataArray(
                                        "Access Card",
                                        "Welcome to the Access Card Information! How can I help you with questions about access cards?",
                                        "Request New Card",
                                        "Lost or Stolen Card",
                                        "Replacement Fees",
                                        "https://customer.alhamracommunities.com/service/allServiceReq",
                                        "",
                                        "",
                                        "",
                                        "If your access card is lost or stolen, it's important to report it to us immediately. Contact our office so we can deactivate the old card and issue a new one.",
                                        "Please note that you need to pay a 300 AED replacement fee for lost or damaged access cards. You can find the fee details on our 'Access Card Policies' page."
                                      );
                                      updateButtonState("access");
                                    }}
                                  >
                                    Access Card
                                  </button>
                                </Col>

                                <Col className="show-more-content" span={12}>
                                  <button
                                    className="btn-primary btn button-click2 w-100"
                                    id="ShowLess"
                                    onClick={() => setMore(false)}
                                  >
                                    Show Less
                                  </button>
                                </Col>
                              </>
                            )}
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  )}
                </div>
                {/* The First Greeting Message Ends */}
                {/* Category & Subcategory Q&A Static Starts */}
                {dataArray?.map((message, index) => {
                  return (
                    <>
                      <div className="w-100 clear_msg qurey-view">
                        <div>
                          {" "}
                          <p>
                            {" "}
                            You{" "}
                            <span className="ml-1">
                              {" "}
                              {moment().format("h:mm A")}{" "}
                            </span>{" "}
                          </p>{" "}
                        </div>
                      </div>
                      {message.check ? (
                        <div
                          className="chatbot-messages chatbot-sent-messages clear_msg"
                          id="cateorgy_msg"
                        >
                          <p>{message?.key}</p>
                        </div>
                      ) : (
                        <div
                          className="chatbot-messages chatbot-sent-messages clear_msg"
                          id="cateorgy_msg"
                        >
                          <p>Cateorgy: Queries</p>
                          <p>Sub-Category: {message?.key}</p>
                        </div>
                      )}

                      <div className="chatbot-message mt-1 clear_msg">
                        <div className="chat-user-title-rec">
                          <p className="m-0">
                            {" "}
                            RealCube Properties{" "}
                            <span className="ml-1 msgTime">
                              {moment().format("h:mm A")}
                            </span>{" "}
                          </p>
                        </div>
                        <div className="chatbot-messages chatbot-received-messages">
                          <p>{message?.message}</p>
                          <Row
                            gutter={[20, 0]}
                            justify="space-between"
                            className="ser_req_btn"
                            data-value="class"
                          >
                            {message?.link1 ? (
                              <Col span={24}>
                                <Link
                                  target="_blank"
                                  to={message?.link1}
                                  className="btn-primary btn button-click2 w-100"
                                >
                                  {message?.btn1}
                                </Link>
                              </Col>
                            ) : message?.btn1 &&
                              message?.para1 !== undefined ? (
                              <Col span={24}>
                                <button
                                  className="btn-primary btn button-click2 w-100"
                                  onClick={() =>
                                    staticValues(
                                      message?.btn1,
                                      message?.para1,
                                      true
                                    )
                                  }
                                >
                                  {message?.btn1}
                                </button>
                              </Col>
                            ) : message.btn1 && message?.para1 == undefined ? (
                              <Col span={24}>
                                <button
                                  className="btn-primary btn button-click2 w-100"
                                  onClick={() =>
                                    fetchDataAndProcess(message?.btn1, true)
                                  }
                                >
                                  {message?.btn1}
                                </button>
                              </Col>
                            ) : (
                              ""
                            )}
                            {message?.link2 ? (
                              <Col span={24}>
                                <Link
                                  target="_blank"
                                  to={message?.link2}
                                  className="btn-primary btn button-click2 w-100"
                                >
                                  {message?.btn2}
                                </Link>
                              </Col>
                            ) : message?.btn2 &&
                              message?.para2 !== undefined ? (
                              <Col span={24}>
                                <button
                                  className="btn-primary btn button-click2 w-100"
                                  onClick={() =>
                                    staticValues(
                                      message?.btn2,
                                      message?.para2,
                                      true
                                    )
                                  }
                                >
                                  {message?.btn2}
                                </button>
                              </Col>
                            ) : message?.btn2 && message?.para2 == undefined ? (
                              <Col span={24}>
                                <button
                                  className="btn-primary btn button-click2 w-100"
                                  onClick={() =>
                                    fetchDataAndProcess(message?.btn2, true)
                                  }
                                >
                                  {message?.btn2}
                                </button>
                              </Col>
                            ) : (
                              ""
                            )}
                            {message?.link3 ? (
                              <Col span={24}>
                                <Link
                                  target="_blank"
                                  to={message?.link3}
                                  className="btn-primary btn button-click2 w-100"
                                >
                                  {message?.btn3}
                                </Link>
                              </Col>
                            ) : message?.btn3 &&
                              message?.para3 !== undefined ? (
                              <Col span={24}>
                                <button
                                  className="btn-primary btn button-click2 w-100"
                                  onClick={() =>
                                    staticValues(
                                      message?.btn3,
                                      message?.para3,
                                      true
                                    )
                                  }
                                >
                                  {message?.btn3}
                                </button>
                              </Col>
                            ) : message?.btn3 && message?.para3 == undefined ? (
                              <Col span={24}>
                                <button
                                  className="btn-primary btn button-click2 w-100"
                                  onClick={() =>
                                    fetchDataAndProcess(message?.btn3, true)
                                  }
                                >
                                  {message?.btn3}
                                </button>
                              </Col>
                            ) : (
                              ""
                            )}
                          </Row>
                        </div>
                      </div>
                      <FaqItem
                        key={index}
                        message={message}
                        handleLoaderChange={handleLoaderChange}
                      />
                    </>
                  );
                })}
                <div ref={bottomRef} />
                {/* Resolve Query RCallback Ends */}
                {/* Resolve Query No Reply Ends */}
                {/* Resolve Query Ends */}
              </div>

              {/* Query Loader View Starts */}
              {loader && (
                <div className="loader-view w-auto mx-2 mb-2">
                  <div className="chatbot-message pt-1">
                    <div className="chat-user-title-rec">
                      <p className="m-0">RealCube Properties</p>
                    </div>
                    <div className="chatbot-messages chatbot-received-messages mb-0">
                      <div className="m-0 d-flex font-14 text-muted">
                        Loading{" "}
                        <div className="lds-ellipsis">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* Query Loader View Ends */}
            </div>
            <div id="chatbot-footer">
              <div id="chatbot-input-container">
                <input
                  type="text"
                  className="chatbot-input-class"
                  id="chatbot-input"
                  name="chatbot-input"
                  placeholder="Type a msg here..."
                  value={change}
                  onChange={handleChange}
                  onKeyPress={handleKeyPress}
                />
              </div>
              <div
                id="chatbot-new-message-send-button"
                title="Send"
                onClick={handleSubmit}
              >
                <SendOutlined />
              </div>
              <div
                id="chatbot-clear-button"
                title="Clear chat"
                onClick={handleEmpty}
              >
                <CloseOutlined />
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {openChatView ? (
        <div id="chatbot-open-container" onClick={openChat}>
          <CloseOutlined />
        </div>
      ) : (
        <div id="chatbot-open-container" onClick={openChat}>
          <WechatOutlined />
        </div>
      )}
    </ChartBoatWrapper>
  );
}
