import React, { useState, useEffect, useContext } from "react";
import { Modal, Button } from "antd";
import Heading from "../../components/UI/Heading/Heading";
import Calendar from "react-calendar";
import TimeSlotSlider from "./TimeSlotSlickCarousel";
import CreateScheduleVisit from "./CreateScheduleVisit";
import { LeftOutlined } from "@ant-design/icons";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import {
	getVisitScheduleList,
	postBrokerVisitSchedule,
	postVisitSchedule,
	scheduleBrokerVisit,
} from "../../library/helpers/axiosClient";
import ScheduleSuccessModal from "../../components/SuccessModal/success-popup";
import { MainContext } from "constants/MainProvider";
import GeneralModals from "library/helpers/generalModals";
import styled from 'styled-components';
import { useTranslation } from 'react-i18next'
import cookies from 'js-cookie'

const languages = [
	{
		code: 'en',
		name: 'English',
		country_code: 'gb',
	},
	{
		code: 'ar',
		name: 'العربية',
		dir: 'rtl',
		country_code: 'sa',
	},
];

const CalendarContainer = styled.div`
  /* ~~~ container styles ~~~ */
  .react-calendar__tile--active {
	 
}
.react-calendar__tile {
}
`;

const ScheduleVisit = (props) => {
	const { logOut, user, loggedIn: logged } = useContext(MainContext);
	const { userInfo } = user;
	const [startDate, setStartDate] = useState(new Date());
	// const onHandleSuccessModalClose = () => {
	// 	setSuccessModalVisible(false);
	// };
	const { alreadyBooked} = props
	let loggedInInitialState = JSON.parse(localStorage.getItem("userInfo"));
	const modalMessage = { msg: "", class: "",img:"" };
	const [modalMessages, setmodalMessages] = useState(modalMessage);
	const [generalModalsVisible, setgeneralModalsVisible] = useState(false);
	const [openDetails, setOpenDetails] = useState(false);
	const [closeList, setCloseList] = useState(true);
	const [occupiedTimeSlots, setoccupiedTimeSlots] = useState();
	const [timeSlot, setTimeSlot] = React.useState(null);
	const [inputs, setInputs] = React.useState(null);
	const [disable, setDisable] = useState(false);
	const [formErrors, setFormErrors] = useState({});
	const [isSubmit, setIsSubmit] = useState(false);
	const [displayTIme, setDisplayTIme] = useState(false);
	const [scheduleSuccessModalVisible, setScheduleSuccessModalVisible] =
		useState(false);
	const onHandleScheduleSuccessModalClose = () => {
		setScheduleSuccessModalVisible(false);
		window.location.reload();
	};
	const currentLanguageCode = cookies.get('i18next') || 'en';
	const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
	const { t } = useTranslation();
	const onHandlegeneralModalsClose = () => {
		setgeneralModalsVisible(false);		
		window.location.reload();
	};
	//id_role = 6 and 5 => Broker Admin and Broker
	const visitSchedulesApis = (userInfo?.id_role === 6 || userInfo?.id_role === 5) ? postBrokerVisitSchedule  : postVisitSchedule 
	
	const getTime = (index) => {
		setTimeSlot(index);
	};
	const getFormData = (data) => {
		setInputs(data);
	};
	useEffect(() => {
		if (logged && props.id) {
			var data = {
				property_id: props.id,
				visit_date: moment().format("YYYY-MM-DD"),
			};
			check(moment().format("YYYY-MM-DD"))
			var config = {
				headers: {
					Authorization: `Bearer ${userInfo?.access_token}`,
				},
				data: data,
			};
			getVisitScheduleList(config).then((data) => {
				if (data.data.errorDescription === "The token is expired or invalid") {
					setmodalMessages((state) => ({
						...state,
						msg: data.data.errorDescription + " Please login again",
						emoji: "sad",
					}));
					logOut();
					setgeneralModalsVisible(true);
				} else if (data.data.code === 200 && data.data.errorCode === 1) {
					props.id &&  alreadyBooked(true)
				}else {
					props.id &&  alreadyBooked(false)
					setoccupiedTimeSlots(data);
				}
			});
		}
	}, []);
	function onChange(value) {

		setStartDate(value);
		check(moment(value).format("YYYY-MM-DD"))
		setTimeSlot('00:00');
		setDisable(true);
		var data = {
			property_id: props.id,
			visit_date: moment(value).format("YYYY-MM-DD"),
		};
		var config = {
			headers: {
				Authorization: `Bearer ${userInfo?.access_token}`,
			},
			data: data,
		};
		getVisitScheduleList(config).then((data) => {
			if (data.data.errorDescription === "The token is expired or invalid") {
				setmodalMessages((state) => ({
					...state,
					msg: data.data.errorDescription + " Please login again",
					emoji: "sad",
				}));
				logOut();
				setgeneralModalsVisible(true);
			} else {
				setoccupiedTimeSlots(data);
			}
		});
	}
	function openDetailsPage() {
		setOpenDetails(true);
		setCloseList(false);
	}
	function backList() {
		setOpenDetails(false);
		setCloseList(true);
	}
	const submitHandler = (e) => {
		e.preventDefault();
		// props.onCancel()
		setFormErrors(validate(inputs));
		setIsSubmit(true);
	};
	useEffect(() => {
		startDate && timeSlot ? setDisable(false) : setDisable(true);
	}, [startDate, timeSlot]);

	function check(selectedDates) {
		var now = moment();
		var hourToCheck = (now.day() !== 0) ? 18 : 30;
		var dateToCheck = now.hour(hourToCheck).minute(30);

		if (moment().format("YYYY-MM-DD") === selectedDates && moment().isAfter(dateToCheck)) {
			setDisplayTIme(true);
		}
		else {
			setDisplayTIme(false);
		}
	}

	useEffect(() => {
		if (Object.keys(formErrors).length === 0 && isSubmit) {
			var data = "";
			if (inputs.customerType == "1") {
				data = {
					property_id: props.id,
					user_unique_id: userInfo.id,
					start_time:
						moment(startDate).format("YYYY-MM-DD") + " " + timeSlot + ":00",
					end_time:
						moment(startDate).format("YYYY-MM-DD") +
						" " +
						moment(timeSlot, "H:mm")
							.add(0, "seconds")
							.add(30, "minutes")
							.format("HH:mm:ss"),
					visit_date: moment(startDate).format("YYYY-MM-DD"),
					email: inputs.email ? inputs.email : userInfo.email,
					mobile: inputs.mob,
					customer_name: inputs.firstName
						? inputs.firstName
						: userInfo.first_name +
						" " +
						inputs.middleName +
						" " +
						inputs.lastName,
					customer_type: inputs.customerType,
					title: "",
					comments: inputs.comments,
				};
			} else {
				data = {
					property_id: props.id,
					user_unique_id: userInfo.id,
					start_time:
						moment(startDate).format("YYYY-MM-DD") + " " + timeSlot + ":00",
					end_time:
						moment(startDate).format("YYYY-MM-DD") +
						" " +
						moment(timeSlot, "H:mm")
							.add(0, "seconds")
							.add(30, "minutes")
							.format("HH:mm:ss"),
					visit_date: moment(startDate).format("YYYY-MM-DD"),
					email: inputs.email ? inputs.email : userInfo.email,
					mobile: inputs.mob,
					customer_type: inputs.customerType,
					organization_name: inputs.org,
				};
			}

			var config = {
				headers: {
					Authorization: `Bearer ${userInfo?.access_token}`,
				},
				data: data,
			};
			visitSchedulesApis(config).then((data) => {
				// console.log(data.data);
				props.onCancel()
				if (data.data.code === 200 && data.data.errorCode === 0) {
					setmodalMessages((state) => ({
						...state,
						msg: `${data?.data?.data?.message}`,
						img: data.data?.data?.qrCode,
						emoji: "",
					}));
					setgeneralModalsVisible(true);
					// setScheduleSuccessModalVisible(true);
					// props.onScheduleSuccessModalOpen(true);
				} else {
					// alert("Close and try for another slot");
					setmodalMessages((state) => ({
						...state,
						msg: data.data.data,
						emoji: "sad",
					}));
					setgeneralModalsVisible(true);
				}
			});
		}
	}, [formErrors]);
	const validate = (values) => {
		const errors = {};
		const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
		if (!values.customerType) {
			errors.customerType = t('customer_tye_error');
		} else if (values.customerType == 0) {
			errors.customerType = t('customer_tye_error');
		}
		if (values.customerType === "1") {
			if (!values.firstName) {
				errors.firstName = t('first_name_error');
			}
			if (!values.lastName) {
				errors.lastName = t('last_name_error');
			}
			if (!values.email) {
				errors.email = t('email_id_error');
			} else if (!regex.test(values.email)) {
				errors.email = t('valid_email');
			}
			if (!values.mob) {
				errors.mob = t('mobile_no_error');
			}
		} else {
			if (!values.org) {
				errors.org = t('org_error');
			}
			if (!values.email) {
				errors.email = t('email_id_error');
			} else if (!regex.test(values.email)) {
				errors.email = t('valid_email');
			}
			if (!values.mob) {
				errors.mob = t('mobile_no_error');
			}
		}
		return errors;
	};
	return (
		<>
			<Modal
				visible={props.modalStatus}
				width={562}
				footer={null}
				onCancel={props.onCancel}
				className="schedule-visit-modal font-18 mb-15"
				maskClosable={false}
			// title="Schedule Visit"
			>
				{closeList && (
					<>
						<div className=" mb-10">
							<Heading
								as="h3"
								fontWeight="600"
								className="font-18 modal-title"
								content={t('schedule_visit')}
							/>
						</div>
						<div className="mb-10">
							<CalendarContainer>
								<Calendar
									selected={startDate}
									// onChange={(date) => setStartDate(date)}
									onChange={onChange}
									minDate={moment().toDate()}
								/></CalendarContainer>
							{!displayTIme ? <><Heading
								as="h3"
								fontWeight="600"
								className="font-18 mb-15"
								content={t('select_time')} /><TimeSlotSlider getTime={getTime} {...occupiedTimeSlots} dateSelected={startDate} /></> : t('no_slots')}
						</div>
						<div className="d-flex button-sections">
							<Button className="btn-outline-primary" onClick={props.onCancel}>
								{t('cancel')}
							</Button>
							<Button
								type="primary"
								onClick={openDetailsPage}
								disabled={disable || displayTIme ? true : false}
								className={
									`c-${startDate && timeSlot && !disable && !displayTIme ? "pointer" : "no-drop bg-blue-light border-blue-light"}
								`}
								title={startDate && timeSlot ? null : t('select_date_&_time')}
							>
								{startDate && timeSlot && !disable
									? t('continue')
									: t('select_date_&_time')}
							</Button>
						</div>
					</>
				)}
				{openDetails && (
					<>
						<div  className=" mb-20">
							<LeftOutlined className="back-icon c-pointer" onClick={backList}/>
							<Heading
								as="h3"
								fontWeight="600"
								className="font-18 mb-15 modal-title"
								content={t('schedule_visit')}
							/>
						</div>
						<CreateScheduleVisit
							getFormData={getFormData}
							formErrors={formErrors}
						/>
						<div className="d-flex button-sections">
							<Button className="btn-outline-primary" onClick={props.onCancel}>
								{t('cancel')}
							</Button>
							<Button
								type="primary"
								// onClick={props.onScheduleSuccessModalOpen}
								onCancel={props.onCancel}
								onClick={submitHandler}
							>
								{t('submit')}
							</Button>
						</div>
					</>
				)}
			</Modal>
			<ScheduleSuccessModal
				{...modalMessages}
				modalStatus={scheduleSuccessModalVisible}
				onCancel={onHandleScheduleSuccessModalClose}
			/>
			<GeneralModals
				{...modalMessages}
				modalStatus={generalModalsVisible}
				onCancel={onHandlegeneralModalsClose}
			/>
		</>
	);
};

export default ScheduleVisit;
