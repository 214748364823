var APIs = {
	URL: process.env.REACT_APP_API_URL,
	propertyFilter: {
		endPoint: "propertyFilter",
		headers: { Rmethod: "post", redirect: "follow" },
	},
	propertyDetails: {
		endPoint: "propertyDetails",
		headers: { method: "get", redirect: "follow" },
	},
	getAllMarketPlace: {
		endPoint: "getAllMarketPlace",
		headers: { method: "get", redirect: "follow" },
	},

	updateIsRedeemed: {
		endPoint: "updateMarketPlace",
		headers: { method: "post", redirect: "follow" },
	},
	marketPlaceDetails: {
		endPoint: "marketPlaceDetails",
		headers: { method: "get", redirect: "follow" },
	},
	addAlert: {
		endPoint: "addAlerts",
		headers: { method: "post", redirect: "follow" },
	},
	visitScheduleList: {
		endPoint: "visitScheduleList",
		headers: { method: "post", redirect: "follow" },
	},
	scheduleVisit: {
		endPoint: "scheduleVisit",
		headers: { method: "post", redirect: "follow" },
	},
	scheduleBrokerVisit: {
		endPoint: "pm/scheduleVisit",
		headers: { method: "post", redirect: "follow" },
	},
	getPropertyFilterData: {
		endPoint: "property/getPropertyFilterData",
		headers: { method: "get", redirect: "follow" },
	},
	contactUs: {
		endPoint: "contactUs",
		headers: { method: "post", redirect: "follow" },
	},
	getMediaInFo: {
		endPoint: "mc",
		headers: { method: "get", redirect: "follow" },
	},
	getExclusivePropertyFilterData: {
		endPoint: "property/exclusive",
		headers: { method: "get", redirect: "follow" },
	},
	getExclusivePropertyFilterDetails: {
		endPoint: "property/exclusiveDetails",
		headers: { method: "get", redirect: "follow" },
	},
	resendVerificationEmail: {
		endPoint: "user/email/verification/resend",
		headers: { method: "post", redirect: "follow" },
	},
	getHospitalityList: {
		endPoint: "hospitality/list",
		headers: { method: "get", redirect: "follow" },
	},
	//*  Submit Interest
	submitInterest: {
		endPoint: "cpt/interest/create",
		headers: { method: "post", redirect: "follow" },
	},
	// ** Get Communities List
	getCommunities: {
		endPoint: "pm/communities/list",
		headers: { method: "get", redirect: "follow" },
	},
	//*  make offer
	makeAnOffer: {
		endPoint: "makeOffer",
		headers: { method: "post", redirect: "follow" },
	},
	//*  make offer broker admin
	postMakeAnOfferBroker: {
		endPoint: "pm/makeOffer",
		headers: { method: "post", redirect: "follow" },
	},
	//* trending properties
	trending: {
		endPoint: "property/trending",
		headers: { method: "post", redirect: "follow" },
	},
	//* OTP verifyEmail
	otpVerify: {
		endPoint: "otp/verify/user",
		headers: { method: "post", redirect: "follow" },
	},
	//* Resend OTP
	resendOTP: {
		endPoint: "user/email/verification/resend",
		headers: { method: "post", redirect: "follow" },
	},
	//*Tenant Search
	tenantSearch: {
		endPoint: 'userList',
		headers: { method: 'get', redirect: 'follow' },
	},
	//*Create new user(PT)
	createTenant: {
		endPoint: 'pm/createTenant',
		headers: { method: 'post', redirect: 'follow' },
	},
	//** Testimonial Get 
	getTestimonial: {
		endPoint: "mc/testimonials",
		headers: { method: "get", redirect: "follow" },
	},
	// ** Get general Configs
	getConfig: {
		endPoint: "getConfig",
		headers: { method: "get", redirect: "follow" },
	},

	
};
module.exports = { APIs };
