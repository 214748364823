import styled from 'styled-components';

const SliderWrapper = styled.div`
  margin: 0px;
  .slick-initialized .slick-slide {
    margin: 0px 5px;
    @media only screen and (max-width: 767.98px){
      width: 75px !important;
    }
  }
  .slick-prev {
    left: auto;
    right: 56px;
    top: -28px;
    @media only screen and (max-width: 767.98px){
      right: 32px;
    }
    &:hover {
      &:before {
        color: #49484c;
      }
    }
    &:before {
      opacity: 1;
      color: #e4e4e4;
    }
  }
  .slick-next {
    right: 25px;
    top: -28px;
    @media only screen and (max-width: 767.98px){
      right: 0;
    }
    &:hover {
      &:before {
        color: #49484c;
      }
    }
    &:before {
      opacity: 1;
      color: #e4e4e4;
    }
  }
  .slick-track {
    width: max-content !important;
  }
  .slick-current .time-slot-item.disabled {
    background-color: #ccc;
  }

  .slick-current.time-slot-item {
    background-color: #f8e7bd;
    color: #49484c;
  }
  .slick-current.time-slot-item h3 {
    color: #49484c;
  }
  .time-slot-item {
    // background-color: #f5f5f5;
    border:1px solid #f5f5f5;
    border-radius: 5px;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.95);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    backdrop-filter: blur(40px);
    border-radius: 8px;
    h3 {
      margin-bottom: 0;
      text-align: center;
      font-size: 13px;
      padding: 10px;
      color: #3d3d3d;
      @media only screen and (max-width: 767.98px){
        font-size: 12px;
      }
    }
    &.disabled {
      h3 {
        font-size: 12px;
        color: rgba(61, 61, 61, 0.3);
      }
    }
  }
  
    .react-tel-input.form-control {
        width: 100% !important;
    }
`;
export default SliderWrapper;
