import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
export const AboutUsTemplateWrapper = styled.div`
.slas{
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size:20px;
  padding : 5px 10px;
}

  .ant-breadcrumb {
    font-size: 16px;
    font-weight: 500;
  }
    .anticon{
      font-weight: 500;
      font-size:16px;
      margin-right: 10px;
    }
.aboutus-tabs{
  display: flex;
  flex-direction: column;
  justify-content: center;
 div:hover{
    width:200px;
    box-shadow: 0 2px 18px rgb(0 0 0 / 50%);
  }

}
  .aboutus-sec1 {
    padding: 60px 0;
    h2 {
      font-weight: 600;
      font-size: 48px;
      line-height: 72px;
    }
    p {
      font-size: 16px;
      margin: 1.5rem 0;
    }
  }
  .ourvalues {
    border-top: 1px solid #ededed;
    border-bottom: 1px solid #ededed;
    padding: 1rem 0;
    span {
      color: #49484c;
      font-size: 18px;
      font-weight: 700;
      margin-left: 20px;
    }
  }
  .abt-mission {
    .abt-img-1 {
      height: 300px;
      box-shadow: 0 2px 18px rgb(0 0 0 / 50%);
      border-radius: 8px;
      object-fit: cover;
    }
    .abt-img{
      height: 250px;
    }
  }
  .our-mission-head {
    text-align:center;
    margin-bottom: 3rem;
    h4 {
      font-weight: 600;
      font-size: 38px;
      line-height: 49px;
      margin-bottom:1rem; 
    }
    p{
      color: #49484c;
      font-size: 20px;
      font-weight: 400;

    }
    
  }
  .abt-exalogic{
    img{
      box-shadow: 0 2px 18px rgb(0 0 0 / 50%);
      
    border-radius: 12px;

    }

  }
`;
export const BannerTextWrapper = styled.div`
  h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #ffffff;
    font-size: 26px;
    font-weight: 700;
    background-color: transparent;
    padding: 20px;
    border-radius: 0;
    width: 100%;
    text-align: center;
    max-width: inherit;
    text-transform: uppercase;
    @media (max-width: 640px) {
      font-size: 22px;
      padding: 0;
      width: 88%;
    }
    @media only screen and (min-width: 641px) and (max-width: 991px) {
      width: 88%;
    }
  }
  .banner-cover {
    position: relative;
  }
  .banner-cover::before {
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0.3);
  }
  .ant-breadcrumb {
    background-color: rgba(0, 0, 0, 0.39);
    position: absolute;
    width: 100%;
    bottom: 0px;
    padding: 5px 0;
    .ant-breadcrumb-link {
      color: #fff;
      font-size: 16px;
      a {
        color: #fff;
        font-size: 16px;
      }
    }
    .ant-breadcrumb-separator {
      color: rgba(255, 255, 255, 0.5);
      margin: 0 8px;
    }
  }
`;
export const BannerWrapper = styled.div`
  width: 100%;
  max-height: 500px;
  position: relative;
  background-color: ${themeGet("color.1", "#ffffff")};
  overflow: auto;
  @media (max-width: 640px) {
    // min-height: 60vh;
  }

  @media only screen and (min-width: 641px) and (max-width: 991px) {
    // min-height: 60vh;
  }

  @media (max-width: 991px) {
    > div {
      max-width: 100%;
    }
  }

  .glide,
  .glide__track,
  .glide__slides {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: all;
  }

  .glide__slide {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .glide__bullets {
    position: absolute;
    bottom: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }

  .glide__bullet {
    width: 7px;
    height: 7px;
    background-color: ${themeGet("color.1", "#ffffff")};
    transition: width 0.3s ease;
  }

  .glide__bullet--active {
    width: 25px;
    border-radius: 8px;
    background-color: ${themeGet("color.1", "#ffffff")};
  }

  > div {
    position: relative;
    // min-height: 100vh;
  }

  &:after {
    display: block;
    content: "";
    width: 100%;
    // height: 60%;
    position: absolute;
    bottom: 200px;
    // background: linear-gradient(transparent, rgba(0, 0, 0, 0.5));
    pointer-events: none;
    z-index: 0;
  }
  .search-banner {
    object-fit: cover;
    width: 100%;
    max-height: 400px;
    min-height: 400px;
    height: 100%;
    @media (max-width: 640px) {
      min-height: 60vh;
    }
    @media only screen and (min-width: 641px) and (max-width: 991px) {
      // min-height: 60vh;
    }
  }
`;
